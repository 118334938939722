@media only screen and (max-width: 480px) {

    /* verify_an_agent_header_success starts */
    .verify_an_agent_header_success {
        background-color: #1C1CA8;
        padding-top: 30px;
        padding-bottom: 48%;
    }

    /* verify_an_agent_header_success ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* verify_an_agent_header_success starts */
    /* .verify_an_agent_header_success {
        background-color: #1C1CA8;
        padding-top: 30px;
        padding-bottom: 50px;
    } */

    /* verify_an_agent_header_success ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

     /* verify_an_agent_header_success starts */
    .verify_an_agent_header_success {
        background-color: #1C1CA8;
        padding-top: 3.9062vw;
        padding-bottom: 10.4166vw;
    }

    /* verify_an_agent_header_success ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* verify_an_agent_header_success starts */
    .verify_an_agent_header_success {
        background-color: #1C1CA8;
        padding-top: 100px;
        padding-bottom: 150px;
    }

    /* verify_an_agent_header_success ends */

}
@media only screen and (min-width: 1601px) {

    /* verify_an_agent_header_success starts */
    .verify_an_agent_header_success {
        background-color: #1C1CA8;
        padding-top: 100px;
        padding-bottom: 150px;
    }

    /* verify_an_agent_header_success ends */

}