.privacy_and_policy_up_arrow_img_mobile {
    display: none;
}

.privacy_and_policy_greatings_img_mobile {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* privacy_and_policy starts */
    .privacy_and_policy {
        background-color: #1C1CA8;
        padding-top: 16.611vw;
        padding-bottom: 13.3333vw;
        position: relative;
    }

    .privacy_and_policy_up_arrow_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0vw;
        left: 19vw;
        display: none;
    }

    .privacy_and_policy_up_arrow_img_mobile {
        grid-column: 2 / span 3;
        grid-row: 1;
        z-index: 2;
        width: 68.0556vw;
        position: absolute;
        bottom: 0vw;
        right: -1.6667vw;
        display: block;
    }

    .privacy_and_policy_up_arrow_img img {
        width: 80%;
    }

    .privacy_and_policy_heading {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .privacy_and_policy_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 11.113vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .privacy_and_policy_greatings_img {
        position: absolute;
        bottom: -10.611vw;
        left: 4.911vw;
        z-index: 8;
        display: none;
    }

    .privacy_and_policy_greatings_img_mobile {
        display: block;
        position: absolute;
        bottom: -13.0556vw;
        left: 2.5vw;
        width: 30.2778vw;
        z-index: 8;
    }

    .privacy_and_policy_greatings_img img {
        width: 26.111vw;
    }

    .privacy_and_policy_greatings_img_mobile img {
        width: 100%;
    }

    /* privacy_and_policy ends */

    /* privacy_and_policy_content starts */
    .privacy_and_policy_content {
        background-color: #FFFFFF;
        padding-top: 17.801vw;
        padding-bottom: 6.6667vw;
    }

    .privacy_and_policy_info {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .privacy_and_policy_info h1:first-child,
    .privacy_and_policy_info h2:first-child,
    .privacy_and_policy_info h3:first-child {
        margin-top: 0px;
    }
    
    .privacy_and_policy_info h1,
    .privacy_and_policy_info h2,
    .privacy_and_policy_info h3 {
        margin-top: 10.133vw;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 6.631vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.722vw;
    }

    .privacy_and_policy_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 4.528vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.6667vw;
    }

    /* privacy_and_policy_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* privacy_and_policy starts */
    /* .privacy_and_policy {
        background-color: #1C1CA8;
        padding-top: 14.911vw;
        padding-bottom: 13.711vw;
        position: relative;
    }

    .privacy_and_policy_up_arrow_img {
        grid-column: 4 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0vw;
        left: 0vw;
    }

    .privacy_and_policy_up_arrow_img img {
        width: 100%;
    }

    .privacy_and_policy_heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .privacy_and_policy_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 9.653vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .privacy_and_policy_greatings_img {
        position: absolute;
        bottom: -23.111%;
        left: 5.111%;
        z-index: 8;
    }

    .privacy_and_policy_greatings_img img {
        width: 24.111vw;
    }

    /* privacy_and_policy ends */

    /* privacy_and_policy_content starts */

    /*   .privacy_and_policy_content {
        background-color: #FFFFFF;
        padding-top: 13.401vw;
        padding-bottom: 13.401vw;
    }

    .privacy_and_policy_info {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
    }

    .privacy_and_policy_info h1:first-child, .privacy_and_policy_info h2:first-child, .privacy_and_policy_info h3:first-child {
        font-style: normal;
    }
    
    .privacy_and_policy_info h1, .privacy_and_policy_info h2, .privacy_and_policy_info h3 {
        margin-top: 0px;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        margin-top: 7.133vw;
        font-weight: 700;
        font-size: 4.543vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 2.722vw;
    }

    .privacy_and_policy_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 3.389vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 3.722vw;
    }

    /* privacy_and_policy_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* privacy_and_policy starts */
    .privacy_and_policy {
        background-color: #1C1CA8;
        padding-top: 13.211vw;
        padding-bottom: 12.111vw;
        position: relative;
    }

    .privacy_and_policy_up_arrow_img {
        grid-column: 4 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0vw;
        left: 0vw;
    }

    .privacy_and_policy_up_arrow_img img {
        width: 100%;
    }

    .privacy_and_policy_heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .privacy_and_policy_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 7.253vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .privacy_and_policy_greatings_img {
        position: absolute;
        bottom: -17.111%;
        left: 7.111%;
        z-index: 8;
    }

    .privacy_and_policy_greatings_img img {
        width: 15.111vw;
    }

    /* privacy_and_policy ends */

    /* privacy_and_policy_content starts */

    .privacy_and_policy_content {
        background-color: #FFFFFF;
        padding-top: 10.401vw;
        padding-bottom: 10.401vw;
    }

    .privacy_and_policy_info {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
        margin-bottom: 3.9063vw;
    }

    .privacy_and_policy_info h1:first-child,
    .privacy_and_policy_info h2:first-child,
    .privacy_and_policy_info h3:first-child {
        margin-top: 0px;
    }
    
    .privacy_and_policy_info h1,
    .privacy_and_policy_info h2,
    .privacy_and_policy_info h3 {
        margin-top: 4.833vw;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 3.673vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 2.922vw;
    }

    .privacy_and_policy_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 2.6042vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 3.125vw;
    }

    /* privacy_and_policy_content ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* privacy_and_policy starts */
    .privacy_and_policy {
        background-color: #1C1CA8;
        padding-top: 7.701vw;
        padding-bottom: 6.875vw;
        position: relative;
    }

    .privacy_and_policy_up_arrow_img {
        grid-column: 7 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 35.3472vw;
        position: absolute;
        bottom: 0vw;
        left: 0vw;
        margin-left: 5.8333vw;
    }

    .privacy_and_policy_up_arrow_img img {
        width: 100%;
    }

    .privacy_and_policy_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 4;
        padding: 0vw 1.147vw;
    }

    .privacy_and_policy_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 4.231vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .privacy_and_policy_greatings_img {
        grid-column: 2/span 2;
        width: 100%;
        position: absolute;
        left: 1.25vw;
        bottom: -6.1805vw;
        z-index: 8;
    }

    .privacy_and_policy_greatings_img img {
        width: 13.6806vw;
    }

    /* privacy_and_policy ends */

    /* privacy_and_policy_content starts */

    .privacy_and_policy_content {
        background-color: #FFFFFF;
        padding-top: 7.401vw;
        padding-bottom: 4.8611vw;
    }

    .privacy_and_policy_info {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .privacy_and_policy_info h1:first-child,
    .privacy_and_policy_info h2:first-child,
    .privacy_and_policy_info h3:first-child {
        margin-top: 0px;
    }
    
    .privacy_and_policy_info h1,
    .privacy_and_policy_info h2,
    .privacy_and_policy_info h3 {
        margin-top: 4.5833vw;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 2.143vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.722vw;
    }

    .privacy_and_policy_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1.389vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.0833vw;
    }

    /* privacy_and_policy_content ends */

}

@media only screen and (min-width: 1441px) {

    /* privacy_and_policy starts */

    .privacy_and_policy {
        background-color: #1C1CA8;
        padding-top: 110px;
        padding-bottom: 109px;
        position: relative;
    }

    .privacy_and_policy_up_arrow_img {
        grid-column: 7 / span 5;
        grid-row: 1;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        left: 0%;
        margin-left: 84px;
    }


    .privacy_and_policy_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .privacy_and_policy_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .privacy_and_policy_greatings_img {
        grid-column: 2/span 2;
        position: absolute;
        bottom: -74px;
        left: 36px;
    }


    /* privacy_and_policy ends */

    /* privacy_and_policy_content starts */

    .privacy_and_policy_content {
        background-color: #FFFFFF;
        padding-bottom: 66px;
        padding-top: 106px;
    }

    .privacy_and_policy_info {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .privacy_and_policy_info h1:first-child,
    .privacy_and_policy_info h2:first-child,
    .privacy_and_policy_info h3:first-child {
        margin-top: 0px;
    }
    
    .privacy_and_policy_info h1,
    .privacy_and_policy_info h2,
    .privacy_and_policy_info h3 {
        margin-top: 70px;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .privacy_and_policy_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 30px;
    }

    /* privacy_and_policy_content ends */

}