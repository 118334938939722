@media only screen and (max-width: 480px) {

    /* still_have_question_right_side_thank_you_content starts */
    .still_have_question_right_side_thank_you_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-top: 20px;
    }

    .still_have_question_thank_you_box {
        background-color: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        padding: 20px 10px;
    }

    .still_have_question_thank_you_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .still_have_question_thank_you_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .still_have_question_thank_you_box_thank_you_img img {
        width: 50%;
    }

    .send_another_message svg {
        width: 100%;
        height: 66px;
    }

    .send_another_message_btn {
        width: 70.125vw !important;
        height: 4.584vw !important;
        margin: 0vw auto;
        padding: 5.611vw 4.715vw !important;
    }

    /* still_have_question_right_side_thank_you_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* still_have_question_right_side_thank_you_content starts */
    .still_have_question_right_side_thank_you_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 20px;
    }

    .still_have_question_thank_you_box {
        background-color: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        padding: 20px 10px;
    }

    .still_have_question_thank_you_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .still_have_question_thank_you_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .still_have_question_thank_you_box_thank_you_img img {
        width: 50%;
    }

    .send_another_message svg {
        width: 100%;
        height: 66px;
    }

    .send_another_message_btn {
        width: 29.125vw !important;
        height: 4.584vw !important;
        margin: 0vw auto;
        padding: 1.611vw 2.715vw !important;
    }

    /* still_have_question_right_side_thank_you_content ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* still_have_question_right_side_thank_you_content starts */
    .still_have_question_right_side_thank_you_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 20px;
    }

    .still_have_question_thank_you_box {
        background-color: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        padding: 20px 10px;
    }

    .still_have_question_thank_you_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .still_have_question_thank_you_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .still_have_question_thank_you_box_thank_you_img img {
        width: 50%;
    }

    .send_another_message svg {
        width: 100%;
        height: 66px;
    }

    .send_another_message_btn {
        width: 32.125vw !important;
        height: 5.584vw !important;
        margin: 0vw auto;
        padding: 1.611vw 2.715vw !important;
    }

    /* still_have_question_right_side_thank_you_content ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* still_have_question_right_side_thank_you_content starts */
    .still_have_question_right_side_thank_you_content {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 11.495vw;
    }

    .still_have_question_thank_you_box {
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 2.557vw 1.722vw;
        text-align: center;
    }

    .still_have_question_thank_you_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        /* font-size: 48px; */
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.711vw !important;
    }

    .still_have_question_thank_you_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.359vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.711vw !important;
    }

    .still_have_question_thank_you_box_thank_you_img {
        margin-bottom: 2.685vw;
    }

    .still_have_question_thank_you_box_thank_you_img img {
        width: 14.445vw;
        height: 11.667vw;
    }

    .send_another_message svg {
        width: 100%;
        height: 66px;
    }

    .send_another_message_btn {
        width: 23.125vw !important;
        height: 4.584vw !important;
        margin: 0vw auto;
        padding: 1.611vw 1.715vw !important;
    }

    /* still_have_question_right_side_thank_you_content ends */

}

@media only screen and (min-width: 1601px) {

    /* still_have_question_right_side_thank_you_content starts */
    .still_have_question_right_side_thank_you_content {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
    }

    .still_have_question_thank_you_box {
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 50px 60px;
        text-align: center;
    }

    .still_have_question_thank_you_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .still_have_question_thank_you_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .still_have_question_thank_you_box_thank_you_img img {
        width: 50%;
    }

    .send_another_message svg {
        width: 100%;
        height: 66px;
    }

    .send_another_message_btn {
        width: 23.125vw !important;
        height: 4.584vw !important;
        margin: 0vw auto;
        padding: 1.611vw 1.715vw !important;
    }

    /* still_have_question_right_side_thank_you_content ends */

}