@media only screen and (max-width: 480px) {

    /* about_us_ourstory_center_content starts */
    .about_us_ourstory {
        background-color: #ffffff;
        padding-top: 16.6667vw;
    }

    .about_us_ourstory_center_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        text-align: center;
        width: 312px;
        margin-left: auto;
        margin-right: auto;
    }

    .about_us_ourstory_center_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 9.1667vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .about_us_ourstory_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 16.6667vw;
    }

    /* about_us_ourstory_center_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_ourstory_center_content starts */
    /* .about_us_ourstory {
        background-color: #ffffff;
        padding-top: 20px;
    }

    .about_us_ourstory_center_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        text-align: center;
    }

    .about_us_ourstory_center_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .about_us_ourstory_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    } */

    /* about_us_ourstory_center_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* about_us_ourstory_center_content starts */
    .about_us_ourstory {
        background-color: #ffffff;
        padding-top: 7.16145vw;
    }

    .about_us_ourstory_center_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        text-align: center;
    }

    .about_us_ourstory_center_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 7.9427vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.128vw;
    }

    .about_us_ourstory_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.60416vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* about_us_ourstory_center_content ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_ourstory_center_content starts */
    .about_us_ourstory {
        background-color: #ffffff;
    }

    .about_us_ourstory_center_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        text-align: center;
        margin-top: 7.3611vw;
    }

    .about_us_ourstory_center_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;
    }

    .about_us_ourstory_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* about_us_ourstory_center_content ends */

}

@media only screen and (min-width: 1441px) {

    /* about_us_ourstory_center_content starts */
    .about_us_ourstory {
        background-color: #ffffff;
        padding-top: 106px;
    }

    .about_us_ourstory_center_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        text-align: center;
    }

    .about_us_ourstory_center_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .about_us_ourstory_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 0px;
    }

    /* about_us_ourstory_center_content ends */

}