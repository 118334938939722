.contact_us_para a:link,
.contact_us_para a:visited,
.contact_us_para a:hover,
.contact_us_para a:active {

    color: #84858a !important;
    text-decoration: none;

}

.footer_below_left_side_content a {
    color: #84858a !important;
    text-decoration: none;
}

.help_margin_top {
    margin-bottom: 0px !important;
}

@media only screen and (max-width: 480px) {

    /*footer starts*/
    .footer-section {
        background-color: #F7F8FA;
        padding-bottom: 0px;
    }

    .img_section {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 45.1vw;
        margin-top: 8.889vw;
    }

    .img_section svg {
        width: 34.201vw;
        height: 8.891vw;
    }

    .contact_us {
        padding-top: 8.888vw;
        grid-column: 1/ span 4;
        grid-row: 2;
        width: 90.88vw;

    }

    .footer_headings {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        /* font-size: 12px; */
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 4.544vw;
    }

    .connect_mt {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        /* font-size: 12px; */
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-top: 8.8888vw;
        margin-bottom: 6.6666vw;
    }

    .contact_us_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 3.889vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company {
        grid-column: 1/ span 2;
        grid-row: 3;
        width: 43.151vw;
        margin-top: 9.089vw;
    }

    .company_section {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 3.889vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company_section:hover {
        color: #84858a;
    }

    .help {
        grid-column: 3/ span 2;
        grid-row: 3;
        width: 43.151vw;
        margin-top: 9.089vw;
    }

    .connect {
        grid-column: 1/ span 4;
        grid-row: 4;
        width: 90.87vw;
    }

    .connect-icons svg {
        width: 6.667vw;
        height: 6.667vw;
    }

    .connect-icons {
        margin-right: 4.4447vw;
    }

    .contact_us_margin_top {
        margin-bottom: 8.889vw;
    }

    .company_margin_top {
        margin-bottom: 6.667vw;
    }

    .connect_margin_top {
        margin-bottom: 8.989vw;
    }

    .verify_agent {
        margin-top: 0 !important;

    }

    /*footer ends*/

    /* footer_copy_right starts */
    .footer_copy_right {
        background-color: #FFFFFF;
        width: 100%;
        padding-top: 5vw;
        padding-bottom: 5vw;
        height: 21.667vw;
    }

    .footer_below_left_side_content {
        grid-column: 1 / span 2;
        grid-row: 1;
        width: 85%;
    }

    .footer_below_right_side_img {
        grid-column: 4/ span 1;
        grid-row: 1;
        justify-self: end;
        width: 23.611vw;
        height: 8.889vw;
        margin-top: 1.389vw;
        margin-bottom: 1.389vw;
    }

    .footer_below_left_side_content p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #84858a;
        margin-bottom: 0;
        /*padding-top: 8px;*/
    }

    .footer_below_right_side_img p svg {
        width: 85px;
        height: 32px;
    }


    /* footer_copy_right ends */

    .footer_auth_wrapper {
        font-family: 'Circular Std Bk';
        padding-top: 5.555vw;
        padding-bottom: 6.666vw;
        padding-left: 2.222vw;
        padding-right: 2.222vw;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 3.888vw;
    }

    .footer_auth_logo {
        width: 32.5vw;
        height: 8.333vw;
        margin: auto;
        position: relative;
    }

    .footer_auth_content {
        text-align: center;
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 5.833vw;
        color: #5B5B62;
        display: flex;
        flex-direction: column;
    }

    .footer_auth_content a {
        color: #5B5B62;
    }

    .footer_auth {
        background-color: white;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /*footer starts*/
    .footer-section {
        background-color: #F7F8FA;
        padding-bottom: 3.333vw;
    }

    .img_section {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.623vw;
    }

    .img_section svg {
        width: 8.542vw;
        height: 2.223vw;
    }

    .contact_us {
        grid-column: 3/ span 2;
        grid-row: 1;
        width: 14.141vw;
        padding-top: 4.792vw;

    }

    .footer_headings {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 0.833vw;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 1.657vw;
    }

    .connect_mt {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 0.833vw;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 1.657vw;
    }

    .contact_us_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.972vw;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company {
        grid-column: 5/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;
    }

    .company_section {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.972vw;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company_section:hover {
        color: #84858a;
    }

    .help {
        grid-column: 7/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;

    }

    .connect {
        grid-column: 9/ span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;
    }

    .connect-icons svg {
        width: 1.667vw;
        height: 1.667vw;
    }

    .connect-icons {
        margin-right: 1.171vw;
    }

    /*footer ends*/

    /* footer_copy_right starts */
    .footer_copy_right {
        background-color: #FFFFFF;
        width: 100%;
        aspect-ratio: 1440/78;
    }

    .footer_below_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .footer_below_right_side_img {
        grid-column: 12/ span 1;
        grid-row: 1;
        text-align: end;
        width: 100%;
        margin-top: 1.597vw;
        margin-bottom: 1.597vw;
        width: 5.903vw;
        height: 2.222vw;
        justify-self: end;
    }

    .footer_below_left_side_content p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #84858a;
        margin-top: 2.014vw;
        margin-bottom: 1.944vw;
    }

    .footer_auth_wrapper {
        font-family: 'Circular Std Bk';
        padding-top: 3.125vw;
        padding-bottom: 3.125vw;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        position: relative;
    }

    .footer_auth_logo {
        width: 10.28vw;
        height: 2.604vw;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .footer_auth_content {
        text-align: center;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
        color: #5B5B62;
    }

    .footer_auth_content a {
        color: #5B5B62;
    }



    .footer_auth {
        background-color: white;
    }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /*footer starts*/
    .footer-section {
        background-color: #F7F8FA;
        padding-bottom: 3.333vw;
    }

    .img_section {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.623vw;
    }

    .img_section svg {
        width: 8.542vw;
        height: 2.223vw;
    }

    .contact_us {
        grid-column: 3/ span 2;
        grid-row: 1;
        width: 14.141vw;
        padding-top: 4.792vw;

    }

    .footer_headings {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 0.833vw;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 1.657vw;
    }

    .connect_mt {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 0.833vw;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 1.657vw;
    }

    .contact_us_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.972vw;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company {
        grid-column: 5/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;
    }

    .company_section {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.972vw;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company_section:hover {
        color: #84858a;
    }

    .help {
        grid-column: 7/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;

    }

    .connect {
        grid-column: 9/ span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;
    }

    .connect-icons svg {
        width: 1.667vw;
        height: 1.667vw;
    }

    .connect-icons {
        margin-right: 1.171vw;
    }

    /*footer ends*/

    /* footer_copy_right starts */
    .footer_copy_right {
        background-color: #FFFFFF;
        width: 100%;
        aspect-ratio: 1440/78;
    }

    .footer_below_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .footer_below_right_side_img {
        grid-column: 12/ span 1;
        grid-row: 1;
        text-align: end;
        width: 100%;
        margin-top: 1.597vw;
        margin-bottom: 1.597vw;
        width: 5.903vw;
        height: 2.222vw;
        justify-self: end;
    }

    .footer_below_left_side_content p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #84858a;
        margin-top: 2.014vw;
        margin-bottom: 1.944vw;
    }

    .footer_auth_wrapper {
        font-family: 'Circular Std Bk';
        padding-top: 3.125vw;
        padding-bottom: 3.125vw;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        position: relative;
    }

    .footer_auth_logo {
        width: 10.28vw;
        height: 2.604vw;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .footer_auth_content {
        text-align: center;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
        color: #5B5B62;
    }

    .footer_auth_content a {
        color: #5B5B62;
    }



    .footer_auth {
        background-color: white;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /*footer starts*/
    .footer-section {
        background-color: #F7F8FA;
        padding-bottom: 3.333vw;
    }

    .img_section {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.623vw;
    }

    .img_section svg {
        width: 8.542vw;
        height: 2.223vw;
    }

    .contact_us {
        grid-column: 3/ span 2;
        grid-row: 1;
        width: 14.141vw;
        padding-top: 4.792vw;

    }

    .footer_headings {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 0.833vw;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 1.657vw;
    }

    .connect_mt {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 0.833vw;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 1.657vw;
    }

    .contact_us_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.972vw;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company {
        grid-column: 5/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;
    }

    .company_section {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.972vw;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company_section:hover {
        color: #84858a;
    }

    .help {
        grid-column: 7/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;

    }

    .connect {
        grid-column: 9/ span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 4.792vw;
    }

    .connect-icons svg {
        width: 1.667vw;
        height: 1.667vw;
    }

    .connect-icons {
        margin-right: 1.171vw;
    }

    /*footer ends*/

    /* footer_copy_right starts */
    .footer_copy_right {
        background-color: #FFFFFF;
        width: 100%;
        aspect-ratio: 1440/78;
    }

    .footer_below_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .footer_below_right_side_img {
        grid-column: 12/ span 1;
        grid-row: 1;
        text-align: end;
        width: 100%;
        margin-top: 1.597vw;
        margin-bottom: 1.597vw;
        width: 5.903vw;
        height: 2.222vw;
        justify-self: end;
    }

    .footer_below_left_side_content p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #84858a;
        margin-top: 2.014vw;
        margin-bottom: 1.944vw;
    }

    .footer_below_right_side_img p svg {}

    /* footer_copy_right ends */

    .footer_auth_wrapper {
        font-family: 'Circular Std Bk';
        padding-top: 1.388vw;
        padding-bottom: 1.388vw;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        position: relative;
    }

    .footer_auth_logo {
        width: 7.361vw;
        height: 1.875vw;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .footer_auth_content {
        text-align: center;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #5B5B62;
    }

    .footer_auth_content a {
        color: #5B5B62;
    }


    .footer_auth {
        background-color: white;
    }
}

@media only screen and (min-width: 1441px) {

    /*footer starts*/
    .footer-section {
        background-color: #F7F8FA;
        padding-bottom: 48px;
    }

    .img_section {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 123px;
        padding-top: 66px;
    }

    .img_section svg {
        width: 100%;
    }

    .contact_us {
        grid-column: 3/ span 2;
        grid-row: 1;
        padding-top: 69px;
    }

    .footer_headings {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 23px;
    }

    .contact_us_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company {
        grid-column: 5/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 69px;
    }

    .company_section {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #84858a;
    }

    .company_section:hover {
        color: #84858a;
    }

    .help {
        grid-column: 7/ span 2;
        grid-row: 1;
        width: 100%;
        padding-top: 69px;
    }

    .connect {
        grid-column: 9/ span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 69px;
    }

    .connect_mt {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        color: #FF4600;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 23px;
    }

    .connect-icons svg {
        width: 24px;
        height: 24px;
    }

    .connect-icons {
        margin-right: 16px;
    }

    /*footer ends*/

    /* footer_copy_right starts */
    .footer_copy_right {
        background-color: #FFFFFF;
        width: 100%;
        aspect-ratio: 1440/78;
    }

    .footer_below_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .footer_below_right_side_img {
        grid-column: 12/ span 1;
        grid-row: 1;
        text-align: end;
        width: 100%;
        margin-top: 23px;
        margin-bottom: 23px;
        width: 85px;
        height: 32px;
        justify-self: end;
    }

    .footer_below_left_side_content p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #84858a;
        margin-top: 29px;
        margin-bottom: 28px;
    }

    .footer_below_right_side_img p svg {}



    /* footer_copy_right ends */

    .footer_auth_wrapper {
        font-family: 'Circular Std Bk';
        padding-top: 20px;
        padding-bottom: 20px;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        position: relative;
    }

    .footer_auth_logo {
        width: 106px;
        height: 27px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .footer_auth_content {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #5B5B62;
    }

    .footer_auth_content a {
        color: #5B5B62;
    }


    .footer_auth {
        background-color: white;
    }
}