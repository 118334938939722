.become_an_agent_onw_boss_right_side_img_mobile {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* become_an_agent_onw_boss starts */
    .become_an_agent_container>div {
        overflow: hidden;
    }

    .become_an_agent_onw_boss {
        background-color: #1c1ca8;
        padding: 0vw 16vw;
        position: relative;
    }

    .become_an_agent_onw_boss_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 8.889vw;
    }

    .become_an_agent_onw_boss_right_side_img {
        display: none;
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100vw;
        z-index: 2;
        padding-top: 5%;
    }

    .become_an_agent_onw_boss_right_side_img img {
        width: 100vw;
    }

    .become_an_agent_onw_boss_right_side_img_mobile {
        grid-column: 1 / span 4;
        display: block;
        width: 96vw;
        align-self: flex-end;
        margin-left: -1.667vw;
    }

    .become_an_agent_onw_boss_right_side_img_mobile img {
        width: 98.072vw;
        max-width: 100%;
    }

    .become_an_agent_onw_boss_right_side_bg_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: -25%;
        right: -19%;
    }

    .become_an_agent_onw_boss_right_side_bg_img img {
        width: 100%;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0%;
        right: -6%;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view img {
        width: 100%;
    }

    .become_an_agent_onw_boss_div {

        margin-bottom: 6.667vw;
    }

    .become_an_agent_onw_boss_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 6.667vw;
    }

    .become_an_agent_onw_boss_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 6.667vw;
    }

    .become_an_agent_onw_boss_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 4.444vw;
    }

    .become_an_agent_onw_boss_left_side_content_fouth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 200%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 10px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btn_bg_color {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btn2_bg_color {
        border-radius: 25px;
        border: none;
        padding: 0px 35px;
        padding-top: 13px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_font_size {
        margin-left: 4.444vw;
    }

    .mobile_view_start_application_btn svg {
        width: 100%;
        height: auto;
        aspect-ratio: 312/50;
    }

    .mobile_view_carry_on_where_i_left_off_btn svg {
        width: 100%;
        height: auto;
        margin-top: 14px;
    }

    .first_btn {
        display: none;
    }

    .second_btn {
        display: none;
    }

    .become_an_agent_onw_boss_left_button.become_an_agent_onw_boss_left_button_old {
        white-space: nowrap;
        width: 88.6111vw !important;
    }

    .become_an_agent_onw_boss_left_button {
        width: 88.6111vw !important;
        margin-top: 4.444vw;
    }

    .become_an_agent_onw_boss_right_button {
        width: 88.6111vw !important;
        background-color: #1c1ca8 !important;
        margin-top: 4.444vw;
        margin-bottom: 8.889vw;
    }

    /* become_an_agent_onw_boss ends */

    /* submit_details_popup start */
    .modal {
        width: 106.572vw !important;
        left: 0vw !important;
    }

    .su_your_de_margin_b {
        margin-bottom: 4.4444vw;
    }

    .modal-footer {
        border-top: none !important;
    }

    .pop_up {
        padding: 4.1668vw 4.1668vw !important;
    }

    .input_field_bg_color {
        background-color: #EEEEF0;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    }

    .sign_up_form_content_popuo {
        padding: 4.444vw !important;
        margin-top: 4.111vw;
    }

    .sign_up_form_content_popuo form>div {
        padding: 5.2vw 3.75vw;
    }

    .error_messages_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: red;
    }

    /* submit_details_popup end */

    /* thank_you_popup starts */
    .application_form_complete {
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 1.557vw 1.722vw;
        text-align: center;
    }

    .application_form_complete_content img {
        width: 30.111vw;
        height: 25.511vw;
    }

    .application_form_complete_content_first_linepp {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 13.333vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.011vw !important;
    }

    .application_form_complete_content_second_line p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 4.444vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* thank_you_popup ends */
    .become_agent_application_register {
        grid-column: 1 / span 4;
        grid-row: 2;
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 400;
        font-size: 3.8888vw;
        color: white;
        margin-top: 3.6363vw;
        margin-bottom: 5.4545vw;
    }

    .button_action.become_an_agent_thank_you_close_btn {
        width: 100%;
        height: 13.888vw;
        font-size: 5.555vw;
    }

    .partner_sign_up_form_popup_body {
        padding: 2.222vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* become_an_agent_onw_boss starts */
    .become_an_agent_container>div {
        overflow: hidden;
    }

    /* .become_an_agent_onw_boss {
        background-color: #1c1ca8;
        padding: 0px 16px;
        padding-top: 8%;
        position: relative;
        overflow: hidden;
    }

    .become_an_agent_onw_boss_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-bottom: 20px;
    }

    .become_an_agent_onw_boss_right_side_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        right: -6%;
    }

    .become_an_agent_onw_boss_right_side_img img{
        width: 100%;
    }

    .become_an_agent_onw_boss_right_side_bg_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: -25%;
        right: -17%;
        display: block;
    }
    .become_an_agent_onw_boss_right_side_bg_img img{
        width: 100%;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0%;
        right: -6%;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view img {
        width: 100%;
    }

    .become_an_agent_onw_boss_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 8px !important;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .become_an_agent_onw_boss_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 19px !important;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_fouth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_btn_bg_color {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_btn2_bg_color {
        border-radius: 25px;
        border: none;
        padding: 0px 35px;
        padding-top: 13px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_third_line ul li img {
        width: 12px;
        height: 12px;
    }

    .become_an_agent_onw_boss_left_side_content_fouth_line ul li img {
        width: 12px;
        height: 12px;
    }

    .become_an_agent_onw_boss_left_side_font_size {
        font-size: 7px !important;
        margin-top: 2px;
        margin-left: 5px;
    }

    .start_application_btn_size svg {
        width: 45%;
        height: auto;
    }

    .carry_on_where_i_left_off_btn_size svg {
        width: 55%;
        height: auto;
    }

    .become_an_agent_onw_boss_div ul {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mobile_view_carry_on_where_i_left_off_btn {
        display: none;
    }

    .mobile_view_start_application_btn {
        display: none;
    }

    .become_an_agent_onw_boss_left_button{
        max-width: 116px;
        min-width: 116px;
        width: 37%;
        height: 25px;
        font-size : 8px;
        padding: 0px 10px;
    }
    .become_an_agent_onw_boss_right_button{
        max-width: 131px;
        min-width: 131px;
        width: 50%;
        height: 25px;
        background-color: #1c1ca8;
        margin-left: 4%;
        font-size: 8px;
        padding: 0px 10px;
    }
    .btn-w svg{
        width: 14px;
        height: 16px
    }
    .btn-w{
        margin-left: 0px !important;
    }
    .button_section{
        display: flex;
    }

    /* become_an_agent_onw_boss ends */

    .button_action.become_an_agent_thank_you_close_btn {
        width: 100%;
        height: 7.738vw;
        font-size: 2.38vw;
    }

    .partner_sign_up_form_popup_body {
        padding: 0.555vw;
    }

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* become_an_agent_onw_boss starts */
    .become_an_agent_container>div {
        overflow: hidden;
    }

    .become_an_agent_onw_boss {
        background-color: #1c1ca8;
        position: relative;
        overflow: visible !important;

    }

    .become_an_agent_onw_boss_left_side_content {
        grid-column: 1 / span 5;
        grid-row: 1;
        width: 58.096vw;
        margin-top: 4.5573vw;

    }

    .become_an_agent_onw_boss_right_side_img {
        grid-column: 5 / span 5;
        grid-row: 1;
        width: 48.34vw;
        z-index: 2;
        position: absolute;
        bottom: 0%;
    }

    .become_an_agent_onw_boss_right_side_img img {
        width: 48.667vw;
    }

    .become_an_agent_onw_boss_right_side_bg_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 48.34vw;
        z-index: 1;
        margin-left: 13%;
        margin-top: 27%;
    }

    .become_an_agent_onw_boss_right_side_bg_img img {
        width: 48.34vw;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0%;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view img {
        width: 100%;
    }

    .become_an_agent_onw_boss_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.125vw;
    }

    .become_an_agent_onw_boss_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .become_an_agent_onw_boss_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_fouth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_btn_bg_color {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_btn2_bg_color {
        border-radius: 25px;
        border: none;
        padding: 0px 35px;
        padding-top: 13px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .become_an_agent_onw_boss_left_side_content_third_line ul li img {
        width: 3.125vw;
    }

    .become_an_agent_onw_boss_left_side_content_fouth_line ul li img {
        width: 3.12vw;
    }

    .become_an_agent_onw_boss_left_side_font_size {
        margin-left: 2.0833vw;
        width: 45.4427vw;
        font-size: 1.9531vw;
    }

    .start_application_btn_size svg {
        width: 45%;
        height: auto;
    }

    .carry_on_where_i_left_off_btn_size svg {
        width: 55%;
        height: auto;
    }

    .become_an_agent_onw_boss_div {
        margin-bottom: 3.125vw;
    }

    .become_an_agent_onw_boss_div ul {
        margin-top: 0px;
        margin-bottom: 2.0833vw;
    }

    .mobile_view_carry_on_where_i_left_off_btn {
        display: none;
    }

    .mobile_view_start_application_btn {
        display: none;
    }

    .become_an_agent_onw_boss_left_button.become_an_agent_onw_boss_left_button_old {
        white-space: nowrap;
        width: 35vw !important;
    }

    .become_an_agent_onw_boss_left_button {
        padding: 1.785vw 2.38vw !important;
        height: 5.952vw !important;
        width: 35vw !important;
        margin-bottom: 1.111vw;
    }

    .become_an_agent_onw_boss_right_button {
        width: 35vw !important;
        padding: 1.785vw 2.38vw !important;
        height: 5.952vw !important;
        background-color: #1c1ca8 !important;
        margin-top: 2.0833vw;
        margin-bottom: 3.125vw;
    }

    .btn-w svg {
        width: 3.323vw;
        height: 3.697vh;
    }

    .btn-w {
        margin-left: 0px !important;
    }

    .button_section {
        display: flex;
        flex-wrap: wrap;
    }

    /* become_an_agent_onw_boss ends */

    /* submit_details_popup start */
    .modal {
        width: 44.572vw !important;
        left: 27.655vw !important;
    }

    .su_your_de_margin_b {
        margin-bottom: 2.0833vw;
    }

    .modal-footer {
        border-top: none !important;
    }

    .pop_up {
        padding: 1.9533vw 1.9533vw !important;
    }

    .input_field_bg_color {
        background-color: #EEEEF0;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    }

    .sign_up_form_content_popuo {
        padding: 1.904vw !important;
        margin-top: 4.111vw;
    }

    .sign_up_form_content_popuo form>div {
        padding: 2.976vw 2.142vw;
    }

    .error_messages_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: red;
    }

    /* submit_details_popup end */

    /* thank_you_popup starts */
    .application_form_complete {
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 1.557vw 1.722vw;
        text-align: center;
    }

    .application_form_complete_content img {
        width: 18.111vw;
        height: 15.511vw;
    }

    .application_form_complete_content_first_linepp {
        font-family: 'Azo Sans Bk' !important;
        font-style: normal !important;
        font-weight: 900 !important;
        font-size: 5.714vw;
        line-height: 110% !important;
        text-align: center !important;
        letter-spacing: -0.02em !important;
        color: #1C1CA8 !important;
        margin-bottom: 1.011vw !important;
    }

    .application_form_complete_content_second_line p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.904vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* thank_you_popup ends */

    .become_agent_application_register {
        grid-column: 1 / span 4;
        grid-row: 2;
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 400;
        font-size: 1.8229vw;
        color: white;
        margin-top: 2vw;
        margin-bottom: 3vw;
    }


    .button_action.become_an_agent_thank_you_close_btn {
        width: 100%;
        height: 7.738vw;
        font-size: 2.38vw;
    }

    .partner_sign_up_form_popup_body {
        padding: 0.555vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {


    /* become_an_agent_onw_boss starts */
    .become_an_agent_container>div {
        overflow: hidden;
    }

    .become_an_agent_onw_boss {
        background-color: #1c1ca8;
        position: relative;
        overflow: visible !important;
        aspect-ratio: 1440/805;
        height: 55vw;
        overflow: hidden;
    }

    .become_an_agent_onw_boss_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        margin-top: 6.875vw;
    }

    .become_an_agent_onw_boss_right_side_img {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 48.34vw;
        z-index: 2;
        width: 56.736vw;
        height: 52.083vw;
        position: absolute;
        left: 0.347vw;
        bottom: -0.389vw;
    }

    .become_an_agent_onw_boss_right_side_img img {
        width: 56.736vw;
    }

    .become_an_agent_onw_boss_right_side_bg_img {
        grid-column: 7 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        display: block;
        position: absolute;
        bottom: -17%;
        right: -6%;
    }

    .become_an_agent_onw_boss_right_side_bg_img img {
        width: 104%;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view {
        display: none;
    }

    .become_an_agent_onw_boss_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 1.667vw;
    }

    .become_an_agent_onw_boss_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.667vw;
    }

    .become_an_agent_onw_boss_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.171vw;
    }

    .img_padding_top img {
        width: 2.083vw;
        height: 2.083vw;
    }

    .become_an_agent_onw_boss_left_side_content_fouth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btn_bg_color {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 20px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btns_margin {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .become_an_agent_onw_boss_left_side_content_btn_bg_color img {
        margin-left: 35px;
    }

    .become_an_agent_onw_boss_left_side_content_btn2_bg_color {
        border-radius: 25px;
        border: none;
        padding: 15px 25px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btn2_bg_color img {
        margin-left: 30px;
    }

    .become_an_agent_onw_boss_left_side_font_size {
        margin-left: 1.171vw;
        font-size: 1.389vw;
    }

    .start_application_btn_size svg {
        width: 36%;
        height: auto;
    }

    .carry_on_where_i_left_off_btn_size svg {
        width: 46%;
        height: auto;
        margin-left: 2%;
    }

    .second_btn {
        margin: 0px 21px;
    }

    .mobile_view_start_application_btn {
        display: none;
    }

    .mobile_view_carry_on_where_i_left_off_btn {
        display: none;
    }


    .become_an_agent_onw_boss_right_button {
        width: 18.194vw !important;
        height: 3.75vw !important;
        background-color: #1c1ca8 !important;
        margin-left: 1.667vw;
        padding: 1.211vw 1.397vw !important;
    }

    .btn-w {
        margin-left: 0px !important;
    }

    .button_section {
        display: flex;
        margin-top: 1.667vw;
    }

    .become_an_agent_onw_boss_left_button.become_an_agent_onw_boss_left_button_old {
        white-space: nowrap;
        width: 22.152vw !important;
    }

    .become_an_agent_onw_boss_left_button {
        padding: 1.041vw 1.388vw !important;
        height: 3.75vw !important;
        width: 14.791vw !important;
    }

    /* become_an_agent_onw_boss ends */

    /* submit_details_popup start */
    .modal {
        width: 35.572vw !important;
        left: 32.211vw !important;
    }

    .su_your_de_margin_b {
        margin-bottom: 1.1111vw;
    }

    .modal-footer {
        border-top: none !important;
    }

    .pop_up {
        padding: 1.0418vw 1.0418vw !important;
    }

    .input_field_bg_color {
        background-color: #EEEEF0;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    }

    .sign_up_form_content_popuo {
        padding: 1.111vw !important;
        /* margin-top: 2.111vw; */
    }

    .sign_up_form_content_popuo form>div:first-child {
        padding: 1.736vw 1.25vw;
    }

    .error_messages_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: red;
    }

    /* submit_details_popup end */

    /* thank_you_popup starts */
    .application_form_complete {
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 1.557vw 1.722vw;
        text-align: center;
    }

    .application_form_complete_content img {
        width: 13.111vw;
        height: 10.511vw;
    }

    .application_form_complete_content_first_linepp {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.011vw !important;
    }

    .application_form_complete_content_second_line p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.111vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* thank_you_popup ends */

    .become_agent_application_register {
        grid-column: 1 / span 4;
        grid-row: 2;
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9722vw;
        color: white;
        margin-top: 2.8537vw;
    }

    .button_action.become_an_agent_thank_you_close_btn {
        width: 100%;
        height: 4.513vw;
        font-size: 1.3888vw;
    }

    .partner_sign_up_form_popup_body {
        padding: 0.555vw;
    }
}

@media only screen and (min-width: 1441px) {

    /* become_an_agent_onw_boss starts */
    .become_an_agent_container>div {
        overflow: hidden;
    }

    .become_an_agent_onw_boss {
        background-color: #1c1ca8;
        position: relative;
        overflow: visible !important;
        aspect-ratio: 1440/805;
    }

    .become_an_agent_onw_boss_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        margin-top: 99px;
    }

    .become_an_agent_onw_boss_right_side_img {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        /* padding-top: 12%;
        padding-left: 5%; */
        position: absolute;
        left: 7px;
        bottom: 0;
    }

    .become_an_agent_onw_boss_right_side_img img {}

    .become_an_agent_onw_boss_right_side_bg_img {
        grid-column: 7 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        display: block;
        position: absolute;
        bottom: -17%;
        right: -6%;
    }

    .become_an_agent_onw_boss_right_side_bg_img img {
        width: 104%;
    }

    .become_an_agent_onw_boss_right_side_bg_img_mobile_view {
        display: none;
    }

    .become_an_agent_onw_boss_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .become_an_agent_onw_boss_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .become_an_agent_onw_boss_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 16px;
    }

    .img_padding_top {

        width: 1.851vw;

    }

    .img_padding_top img {

        width: 2.034vw;
    }

    .become_an_agent_onw_boss_left_side_content_fouth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btn_bg_color {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 20px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btns_margin {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .become_an_agent_onw_boss_left_side_content_btn_bg_color img {
        margin-left: 35px;
    }

    .become_an_agent_onw_boss_left_side_content_btn2_bg_color {
        border-radius: 25px;
        border: none;
        padding: 15px 25px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_an_agent_onw_boss_left_side_content_btn2_bg_color img {
        margin-left: 30px;
    }

    .become_an_agent_onw_boss_left_side_font_size {
        margin-left: 16px;
        font-size: 20px;
    }

    .start_application_btn_size svg {
        width: 36%;
        height: auto;
    }

    .carry_on_where_i_left_off_btn_size svg {
        width: 46%;
        height: auto;
        margin-left: 2%;
    }

    .second_btn {
        margin: 0px 21px;
    }

    .mobile_view_start_application_btn {
        display: none;
    }

    .mobile_view_carry_on_where_i_left_off_btn {
        display: none;
    }


    .become_an_agent_onw_boss_right_button {
        width: 262px !important;
        background-color: #1c1ca8 !important;
        margin-left: 24px;
    }

    .btn-w {
        margin-left: 0px !important;
    }

    .button_section {
        display: flex;
        margin-top: 24px;
    }

    .become_an_agent_onw_boss_left_button.become_an_agent_onw_boss_left_button_old {
        white-space: nowrap;
        width: 22.152vw !important;
    }

    .become_an_agent_onw_boss_left_button {
        padding: 1.041vw 1.388vw !important;
        height: 3.75vw !important;
        width: 14.791vw !important;
    }

    /* become_an_agent_onw_boss ends */

    /* submit_details_popup start */
    .modal {
        width: 35.572vw !important;
        left: 32.211vw !important;
    }

    .su_your_de_margin_b {
        margin-bottom: 1.1111vw;
    }

    .modal-footer {
        border-top: none !important;
    }

    .pop_up {
        padding: 1.0410vw 1.0410vw !important;
    }

    .input_field_bg_color {
        background-color: #EEEEF0;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    }

    .sign_up_form_content_popuo {
        padding: 1.111vw !important;
        margin-top: 4.111vw;
    }

    .sign_up_form_content_popuo form>div {
        padding: 25px 18px;
    }

    /* submit_details_popup end */

    /* thank_you_popup starts */
    .application_form_complete {
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 1.557vw 1.722vw;
        text-align: center;
    }

    .application_form_complete_content img {
        width: 13.111vw;
        height: 10.511vw;
    }

    .application_form_complete_content_first_linepp {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.011vw !important;
    }

    .application_form_complete_content_second_line p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .error_messages_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: red;
    }

    /* thank_you_popup ends */

    .become_agent_application_register {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: white;
        margin-top: 1.6643vw;
    }

    .button_action.become_an_agent_thank_you_close_btn {
        width: 100%;
        height: 65px;
        font-size: 20px;
    }

    .partner_sign_up_form_popup_body {
        padding: 8px;
    }

    .become_an_agent_onw_boss_left_button.become_an_agent_onw_boss_left_button_old {
        white-space: nowrap;
        width: 319px !important;
    }

    .become_an_agent_onw_boss_left_button {
        padding: 15px 20px !important;
        height: 54px !important;
        width: 213px !important;
    }

}