.be_your_own_boss_left_side_img_mobile{

    Display:none;
}

@media only screen and (max-width: 480px) {

     /* be_your_own_boss starts */
     .be_your_own_boss {
        background-color: #FFFFFF;
    }

    .be_your_own_boss_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        display: none;
        
    }
    .be_your_own_boss_left_side_img_mobile{
        grid-column: 1 / span 4;
        display: block;
        width: 100.5%;
        margin-top: -7.778vw;
        margin-left: 3.056vw;
    }

    .be_your_own_boss_left_side_img img {
        width: 100%;
    }
    .be_your_own_boss_left_side_img_mobile img {
        width: 100%;

    }

    
    .be_your_own_boss_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 16.667vw;
    }

    .be_your_own_boss_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.667vw;
    }

    .be_your_own_boss_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.667vw;
    }

    .be_your_own_boss_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* be_your_own_boss ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* be_your_own_boss starts */
   /* .be_your_own_boss {
        background-color: #FFFFFF;
        padding: 0px 16px;
        padding-top: 30px;
    }

    .be_your_own_boss_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .be_your_own_boss_left_side_img img {
        width: 100%;
    }

    .be_your_own_boss_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .be_your_own_boss_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .be_your_own_boss_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .be_your_own_boss_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* be_your_own_boss ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* be_your_own_boss starts */
    .be_your_own_boss {
        background-color: #FFFFFF;
    }

    .be_your_own_boss_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 6.5104vw;
    }

    .be_your_own_boss_left_side_img img {
        width: 100%;
    }

    .be_your_own_boss_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 6.6406vw;
    }

    .be_your_own_boss_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .be_your_own_boss_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .be_your_own_boss_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* be_your_own_boss ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* be_your_own_boss starts */
    .be_your_own_boss {
        background-color: #FFFFFF;
    }

    .be_your_own_boss_left_side_img {
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
        margin-top: 5.139vw;
        margin-left: 0.028vw;
    }

    .be_your_own_boss_left_side_img img {
        width: 38.73vw;
        height: 47.917vw;
    }

    .be_your_own_boss_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 17.153vw;
    }

    .be_your_own_boss_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
    }

    .be_your_own_boss_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .be_your_own_boss_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        /* font-size: 20px; */
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }
    /* be_your_own_boss ends */

}
@media only screen and (min-width: 1441px) {

     /* be_your_own_boss starts */
     .be_your_own_boss {
        background-color: #FFFFFF;
    }

    .be_your_own_boss_left_side_img {
        grid-column: 2 / span 6;
        grid-row: 1;
        margin-top: 74px;
        margin-left: -5px;
    }


    .be_your_own_boss_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 247px;
    }

    .be_your_own_boss_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .be_your_own_boss_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .be_your_own_boss_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }
    /* be_your_own_boss ends */

}