.terms_and_conditions_up_arrow_img_mobile{
    display: none;
}
.terms_and_conditions_arrow_img_mobile{
    display: none;
}


@media only screen and (max-width: 480px) {

    /* terms_and_conditions starts */
    .terms_and_conditions {
        background-color: #1C1CA8;
        padding-top: 10.311vw;
        padding-bottom: 7.7778vw;
        position: relative;
    }

    .terms_and_conditions_heading {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .terms_and_conditions .terms_and_conditions_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 11.113vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .terms_and_conditions_up_arrow_img {
        grid-column: 2 / span 3;
        grid-row: 1;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0vw;
        right: 0vw;
        display: none;
    }
    
    .terms_and_conditions_up_arrow_img_mobile{
        grid-column: 2 / span 3;
        grid-row: 1;
        z-index: 2;
        width: 68.0556vw;
        position: absolute;
        bottom: 0vw;
        right: -1.6667vw;
        display: block;
    }
    

    .terms_and_conditions_up_arrow_img img {
        width: 100%;
    }
    .terms_and_conditions_up_arrow_img_mobile img{
        width: 100%;
    }

    .terms_and_conditions_arrow_img {
        grid-column: 1/span 1;
        position: absolute;
        width: 100%;
        bottom: -10.911vw;
        left: 1.345vw;
        z-index: 8;
        /* position: absolute;
        bottom: -19%;
        left: 8%;
        z-index: 8; */
        display: none;
    }
    .terms_and_conditions_arrow_img_mobile{
        display: block;
        grid-column: 1/span 1;
        position: absolute;
        width: 100%;
        bottom: -10.5556vw;
        left: -1.9444vw;
        z-index: 8;
        /* position: absolute;
        bottom: -19%;
        left: 8%;
        z-index: 8; */
    }

    .terms_and_conditions_arrow_img img {
        width: 19.76vw;
        height: 19.76vw;
    }
    .terms_and_conditions_arrow_img_mobile img {
        width: 19.4444vw;
    }

    /* terms_and_conditions ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* terms_and_conditions starts */
   /* .terms_and_conditions {
        background-color: #1C1CA8;
        padding-top: 14%;
        padding-bottom: 100px;
        position: relative;
    }

    .terms_and_conditions_heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .terms_and_conditions .terms_and_conditions_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .terms_and_conditions_up_arrow_img {
        grid-column: 4 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: 0%;
    }

    .terms_and_conditions_up_arrow_img img {
        width: 100%;
    }

    .terms_and_conditions_arrow_img {
        position: absolute;
        bottom: -26%;
        left: 8%;
    }

    .terms_and_conditions_arrow_img img {
        width: 75%;
    }

    /* terms_and_conditions ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* terms_and_conditions starts */
    .terms_and_conditions {
        background-color: #1C1CA8;
        padding-top: 14.3229vw;
        padding-bottom: 9.8958vw;
        position: relative;
    }

    .terms_and_conditions_heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .terms_and_conditions .terms_and_conditions_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 7.9427vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }
  


    .terms_and_conditions_up_arrow_img {
        grid-column: 4 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: 0%;
    }

    .terms_and_conditions_up_arrow_img img {
        width: 100%;
    }

    .terms_and_conditions_arrow_img {
        position: absolute;
        bottom: -6.3802vw;
        left:7.9427vw;
        width: 18.8802vw;
    }

    .terms_and_conditions_arrow_img img {
        width: 75%;
    }

    /* terms_and_conditions ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* terms_and_conditions starts */
    .terms_and_conditions {
        background-color: #1C1CA8;
        /* padding-top: 14%;
        padding-bottom: 100px; */
        padding-top:  7.701vw;
        padding-bottom: 7.5vw;
        position: relative;
    }

    .terms_and_conditions_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .terms_and_conditions .terms_and_conditions_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 4.23vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0;
    }

    .terms_and_conditions_up_arrow_img {
        grid-column: 7 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 35.3472vw;
        position: absolute;
        bottom: 0vw;
        left: 0vw;
        margin-left: 5.8333vw;
    }

    .terms_and_conditions_up_arrow_img img {
        width: 100%;
    }

    .terms_and_conditions_arrow_img {
        grid-column: 2/span 2;
        width: 100%;
        position: absolute;
        left: 2.5vw;
        bottom: -5.1389vw;
        z-index: 8;
    }

    .terms_and_conditions_arrow_img img {
        width: 10.0694vw;
        
    }

    /* terms_and_conditions ends */

}
@media only screen and (min-width: 1441px) {

     /* terms_and_conditions starts */
     .terms_and_conditions {
        background-color: #1C1CA8;
        padding-top: 110px;
        padding-bottom: 109px;
        position: relative;
    }

    .terms_and_conditions_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .terms_and_conditions .terms_and_conditions_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .terms_and_conditions_up_arrow_img {
        grid-column: 7 / span 5;
        grid-row: 1;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        left: 0%;
        margin-left: 84px;
    }

    .terms_and_conditions_arrow_img {
        grid-column: 2/span 2;
        position: absolute;
        bottom: -74px; 
        left: 36px;
        z-index: 8;
        /* position: absolute;
        bottom: -19%;
        left: 8%;
        z-index: 8; */
    }

    .terms_and_conditions_arrow_img img {
        width: 100%;
    }

    /* terms_and_conditions ends */

}