.about_us_header_section_girl_img_mobile {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* about_us_header_section starts */
    .about_us_header_section {
        background-color: #1C1CA8;
        padding-top: 8.8889vw;
    }

    .about_us_header_section_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_header_section_girl_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        z-index: 2;
        display: none;
    }

    .about_us_header_section_girl_img_mobile {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 92.7778vw;
        z-index: 2;
        margin-left: -5vw;
        display: block;
    }

    .about_us_header_section_girl_img img {
        width: 100%;
    }

    .about_us_header_section_girl_img_mobile img {
        width: 100%;
    }

    .about_us_header_section_up_arrow_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        padding-top: 38%;
        z-index: 1;
    }

    .about_us_header_section_up_arrow_img img {
        width: 100%;
    }

    .about_us_header_section_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 4.4444vw;
    }

    .about_us_header_section_left_side_content_second_line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: -3.3333vw;
    }

    .about_us_header_section_left_side_content_third_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 4.4444vw;
    }

    .about_us_header_section_left_side_content_third_line_inside_line {
        color: #FFBF00;
    }

    /* about_us_header_section ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_header_section starts */
    /* .about_us_header_section {
        background-color: #1C1CA8;
        padding-top: 20px;
    }

    .about_us_header_section_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_header_section_girl_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
    }

    .about_us_header_section_girl_img img {
        width: 100%;
    }

    .about_us_header_section_up_arrow_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 25%;
        z-index: 1;
    }

    .about_us_header_section_up_arrow_img img {
        width: 100%;
    }

    .about_us_header_section_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .about_us_header_section_left_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .about_us_header_section_left_side_content_third_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .about_us_header_section_left_side_content_third_line_inside_line {
        color: #FFBF00;
    } */

    /* about_us_header_section ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* about_us_header_section starts */
    .about_us_header_section {
        background-color: #1C1CA8;
        padding-top: 20px;
        position: relative;
    }

    .about_us_header_section_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_header_section_girl_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: 0px;
    }

    .about_us_header_section_girl_img img {
        width: 100%;
    }

    .about_us_header_section_up_arrow_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 25%;
        z-index: 1;
    }

    .about_us_header_section_up_arrow_img img {
        width: 100%;
    }

    .about_us_header_section_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.125vw;
    }

    .about_us_header_section_left_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 2.3437vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 7.4218vw;
    }

    .about_us_header_section_left_side_content_third_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 4.6875vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .about_us_header_section_left_side_content_third_line_inside_line {
        color: #FFBF00;
    }

    /* about_us_header_section ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_header_section starts */
    .about_us_header_section {
        background-color: #1C1CA8;
        padding-top: 20px;
        aspect-ratio: 1440/599;
    }

    .about_us_header_section_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 7.3611vw;
    }

    .about_us_header_section_girl_img {
        grid-column: 7 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        margin-top: -6.25vw;
        margin-left: -1.6667vw;
    }

    .about_us_header_section_girl_img img {
        width: 50vw;
    }

    .about_us_header_section_up_arrow_img {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 100%;
        padding-top: 25%;
        z-index: 1;
    }

    .about_us_header_section_up_arrow_img img {
        width: 100%;
    }

    .about_us_header_section_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 1.6667vw;
    }

    .about_us_header_section_left_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw;
    }

    .about_us_header_section_left_side_content_third_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw;
    }

    .about_us_header_section_left_side_content_third_line_inside_line {
        color: #FFBF00;
    }

    /* about_us_header_section ends */

}

@media only screen and (min-width: 1441px) {

    /* about_us_header_section starts */
    .about_us_header_section {
        background-color: #1C1CA8;
        height: 599px;
        padding-top: 1px;
    }

    .about_us_header_section_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 106px;
    }

    .about_us_header_section_girl_img {
        grid-column: 7 / span 6;
        grid-row: 1;
        z-index: 2;
        margin-top: -91px;
        margin-left: -24px;
    }

    .about_us_header_section_up_arrow_img {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 100%;
        padding-top: 25%;
        z-index: 1;
    }

    .about_us_header_section_up_arrow_img img {
        width: 100%;
    }

    .about_us_header_section_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .about_us_header_section_left_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .about_us_header_section_left_side_content_third_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .about_us_header_section_left_side_content_third_line_inside_line {
        color: #FFBF00;
    }

    /* about_us_header_section ends */

}