@media only screen and (max-width: 480px) {
    .notification_container {
        position: fixed;
        top: 8.888vw;
        left: 2.222vw;
        width: calc(100vw - 4.444vw);
        padding: 4.444vw 6.666vw;
        border-radius: 16px;
        gap: 4.444vw;
        display: flex;
        align-items: center;
        z-index: 9999;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 6.666vw;
        height: 6.666vw;
    }

    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_suffix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 6.666vw;
        height: 6.666vw;
    }

    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        font-family: 'Circular Std Bk', sans-serif;
    }

    .notification_text a {
        color: inherit;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
    .notification_container {
        position: fixed;
        top: 2.291vw;
        left: 3.333vw;
        width: calc(100vw - 6.666vw);
        padding: 1.111vw 1.666vw;
        border-radius: 16px;
        gap: 1.111vw;
        display: flex;
        align-items: center;
        z-index: 9999;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
    }

    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_suffix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
    }

    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        font-family: 'Circular Std Bk', sans-serif;
    }

    .notification_text a {
        color: inherit;
    }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {
    .notification_container {
        position: fixed;
        top: 2.291vw;
        left: 3.333vw;
        width: calc(100vw - 6.666vw);
        padding: 1.111vw 1.666vw;
        border-radius: 16px;
        gap: 1.111vw;
        display: flex;
        align-items: center;
        z-index: 9999;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
    }

    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_suffix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
    }

    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        font-family: 'Circular Std Bk', sans-serif;
    }

    .notification_text a {
        color: inherit;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .notification_container {
        position: fixed;
        top: 2.291vw;
        left: 3.333vw;
        width: calc(100vw - 6.666vw);
        padding: 1.111vw 1.666vw;
        border-radius: 16px;
        gap: 1.111vw;
        display: flex;
        align-items: center;
        z-index: 9999;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
    }

    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_suffix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
    }

    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        font-family: 'Circular Std Bk', sans-serif;
    }

    .notification_text a {
        color: inherit;
    }
}

@media only screen and (min-width: 1441px) {
    .notification_container {
        position: fixed;
        top: 33px;
        left: 48px;
        width: calc(100vw - 96px);
        padding: 16px 24px;
        border-radius: 16px;
        gap: 16px;
        display: flex;
        align-items: center;
        z-index: 9999;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 24px;
        height: 24px;
    }

    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_suffix_icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 24px;
        height: 24px;
    }

    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Circular Std Bk', sans-serif;
    }

    .notification_text a {
        color: inherit;
    }
}