@media only screen and (max-width: 480px) {

    /* sign_up_form_error starts  */
    .sign_up_form_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 20px 0px;
    }

    /* sign_up_form_error ends  */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* sign_up_form_error starts  */
    .sign_up_form_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 10px 0px;
    }

    /* sign_up_form_error ends  */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* sign_up_form_error starts  */
    .sign_up_form_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 10px 0px;
    }

    /* sign_up_form_error ends  */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* sign_up_form_error starts  */
    .sign_up_form_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 20px 0px;
    }

    /* sign_up_form_error ends  */

}
@media only screen and (min-width: 1601px) {

    /* sign_up_form_error starts  */
    .sign_up_form_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 20px 0px;
    }

    /* sign_up_form_error ends  */

}