.corporate_partner_left_side_img_mobile{
    display:none;

}
@media only screen and (max-width: 480px) {

    /* corporate_partner starts */
    .corporate_partner {
        background-color: #41AD49;
    }

    .corporate_partner_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-left: -14px;
        display: none;
    }
    .corporate_partner_left_side_img_mobile {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 96.1111vw;
        margin-left: -3.8889vw;
        margin-bottom: 16.1111vw;
        display: block;
    }

    .corporate_partner_left_side_img img {
        width: 100%;
    }

    .corporate_partner_left_side_img_mobile img{

            width:100%
    }

    .corporate_partner_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 312px;
        margin-top: 60px;
        /* padding-top: 130px; */
    }

    .corporate_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 11.1111vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }
    .corporate_partner_sign_up_your_business_btn_size svg{
        width: 80%;
        height: auto;
    }

    .corporate_partner_sign_up_your_business_width{
        width: 280px;
        height: 50px;
        margin-bottom: 10vw;
    }

    /* corporate_partner ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* corporate_partner starts */
    /*.corporate_partner {
        background-color: #41AD49;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .corporate_partner_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .corporate_partner_left_side_img img {
        width: 100%;
    }

    .corporate_partner_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .corporate_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 52px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }
    .corporate_partner_sign_up_your_business_btn_size svg{
        width: 60%;
        height: auto;
        aspect-ratio: 245/50;
    }

    .corporate_partner_sign_up_your_business_width{
        width: 206px;
        height: 40px;
    }

    /* corporate_partner ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

     /* corporate_partner starts */
     .corporate_partner {
        background-color: #41AD49;
        height: 68.099vw;
        
    }

    .corporate_partner_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 11.0677vw;
    }
    
    .corporate_partner_left_side_img img {
        width: 100%;
    }
    
    .corporate_partner_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 6.6406vw;
        align-self: center;
    }

    .corporate_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }
    .corporate_partner_sign_up_your_business_btn_size svg{
        width: 60%;
        height: auto;
        aspect-ratio: 245/50;
    }

    .corporate_partner_sign_up_your_business_width{
        width: 31.25vw;
        margin-bottom: 6.25vw;
    }

    /* corporate_partner ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
  
    /* corporate_partner starts */
    .corporate_partner {
        background-color: #41AD49;
        aspect-ratio: 1440/542;
    }

    .corporate_partner_left_side_img {
        /* grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%; */
        grid-column: 2/span 4;
        grid-row: 1;
        width: 29.5139vw;
        margin-top: 1.4583vw;
        margin-left: -4.375vw;
    }

    .corporate_partner_left_side_img img {
        width: 100%;
    }

    .corporate_partner_right_side_content {
        grid-column: 6/span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 9.3056vw;
        /* grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        padding-top: 130px; */
    }

    .corporate_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        font-size: 4.2361vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw;
    }
    .corporate_partner_sign_up_your_business_btn_size svg{
        width: 40%;
        height: auto;
    }
    .corporate_partner_sign_up_your_business_width{
        width: 18.0556vw!important;

    }

    /* corporate_partner ends */

}
@media only screen and (min-width: 1441px) {

      /* corporate_partner starts */
    .corporate_partner {
        background-color: #41AD49;
        aspect-ratio: 1440/542;
    }

    .corporate_partner_left_side_img {
        /* grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%; */
        grid-column: 2/span 4;
        grid-row: 1;
        margin-top: 20px;
        margin-left: -63px;
    }

    .corporate_partner_right_side_content {
        grid-column: 6/span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 148.8896px;
        /* grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        padding-top: 130px; */
    }

    .corporate_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        font-size: 61px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }
    .corporate_partner_sign_up_your_business_btn_size svg{
        width: 40%;
        height: auto;
    }
    .corporate_partner_sign_up_your_business_width{
        width: 260px!important;

    }

    /* corporate_partner ends */

}