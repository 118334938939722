@media only screen and (max-width: 480px) {

    /* help_center_topic_why_become_an_angent starts */
    .help_center_topic_why_become_an_angent {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .help_center_topic_why_become_an_angent_left_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .help_center_topic_why_become_an_angent_left_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_topic_why_become_an_angent_right_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_right_content_h2 {
        padding: 15px 0px;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:focus {
        box-shadow: none;
        background-color: #ffffff;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_accordion_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url(../images/Plus.png);
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:not(.collapsed)::after {
        background-image: url(../images/Minus.png);
    }

    /* help_center_topic_why_become_an_angent ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* help_center_topic_why_become_an_angent starts */
    /* .help_center_topic_why_become_an_angent {
        background-color: #FFFFFF;
        padding-top: 20px;
        padding-bottom: 50px;
    }

    .help_center_topic_why_become_an_angent_left_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .help_center_topic_why_become_an_angent_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .help_center_topic_why_become_an_angent_left_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_topic_why_become_an_angent_right_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_right_content_h2 {
        padding: 15px 0px;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:focus {
        box-shadow: none;
        background-color: #ffffff;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_accordion_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url(../images/Plus.png);
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:not(.collapsed)::after {
        background-image: url(../images/Minus.png);
    } */

    /* help_center_topic_why_become_an_angent ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* help_center_topic_why_become_an_angent starts */
    .help_center_topic_why_become_an_angent {
        background-color: #FFFFFF;
        padding-top: 2.6041vw;
        padding-bottom:6.5104vw;
    }

    .help_center_topic_why_become_an_angent_left_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .help_center_topic_why_become_an_angent_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .help_center_topic_why_become_an_angent_left_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_topic_why_become_an_angent_right_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_right_content_h2 {
        padding: 1.9531vw 0px;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:focus {
        box-shadow: none;
        background-color: #ffffff;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_accordion_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.0833vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font::after {
        flex-shrink: 0;
        width: 2.6041vw;
        height: 2.6041vw;
        margin-left: auto;
        content: "";
        background-image: url(../images/Plus.png);
        background-repeat: no-repeat;
        background-size: 2.6041vw;
        transition: transform .2s ease-in-out;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:not(.collapsed)::after {
        background-image: url(../images/Minus.png);
    }

    /* help_center_topic_why_become_an_angent ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* help_center_topic_why_become_an_angent starts */
    .help_center_topic_why_become_an_angent {
        background-color: #FFFFFF;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .help_center_topic_why_become_an_angent_left_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .help_center_topic_why_become_an_angent_left_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_topic_why_become_an_angent_right_content {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_right_content_h2 {
        padding: 15px 0px;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:focus {
        box-shadow: none;
        background-color: #ffffff;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_accordion_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url(../images/Plus.png);
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:not(.collapsed)::after {
        background-image: url(../images/Minus.png);
    }

    /* help_center_topic_why_become_an_angent ends */

}
@media only screen and (min-width: 1441px) {

     /* help_center_topic_why_become_an_angent starts */
     .help_center_topic_why_become_an_angent {
        background-color: #F7F8FA;
        padding-top: 175px;
        padding-bottom: 175px;
    }

    .help_center_topic_why_become_an_angent_left_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .help_center_topic_why_become_an_angent_left_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .help_center_topic_why_become_an_angent_right_content {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 100%;
    }

    .help_center_topic_why_become_an_angent_right_content_h2 {
        padding: 40px 0px;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:focus {
        box-shadow: none;
        background-color: #ffffff;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        padding:0;

    }

    .help_center_topic_why_become_an_angent_right_content_accordion_para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url(../images/Plus.png);
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }

    .help_center_topic_why_become_an_angent_right_content_styles_and_font:not(.collapsed)::after {
        background-image: url(../images/Minus.png);
    }

    /* help_center_topic_why_become_an_angent ends */

}