@media only screen and (max-width: 480px) {

    /* three_easy_steps starts */
    .three_easy_steps {
        background-color: #FFFFFF;
    }

    .three_easy_steps_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .three_easy_steps_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.6667vw;
    }

    .three_easy_steps_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.6667vw;
    }
    .corporate_partner_3_easy_steps_second_line{
        margin-bottom: 11.1111vw!important;

    }

    .three_easy_steps_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.6667vw;
    }

    .three_easy_steps_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-top: 11.1111vw;
    }
    
    .mb-5{
        margin-bottom: 11.1111vw!important;

    }

    .three_easy_steps_sign_up_button{

        margin-bottom: 0!important;
    }
    /* three_easy_steps ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* three_easy_steps starts */
  /*   .three_easy_steps {
        background-color: #FFFFFF;
        padding-bottom: 50px;
    }

    .three_easy_steps_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .three_easy_steps_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .three_easy_steps_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .three_easy_steps_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .three_easy_steps_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }
    /* three_easy_steps ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* three_easy_steps starts */
    .three_easy_steps {
        background-color: #FFFFFF;
    }

    .three_easy_steps_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .three_easy_steps_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-top: 14.0625vw;
        margin-bottom:3.125vw;
    }

    .three_easy_steps_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom:3.125vw;
    }

    .three_easy_steps_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom:3.125vw;
    }

    .three_easy_steps_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 14.0625vw;
    }

    /* three_easy_steps ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* three_easy_steps starts */
    .three_easy_steps {
        background-color: #FFFFFF;
    }

    .three_easy_steps_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 14.4444vw;
    }

    .three_easy_steps_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.6667vw;
    }

    .three_easy_steps_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;
    }

    .three_easy_steps_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.6667vw;
    }

    .three_easy_steps_right_side_content {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 17.5694vw;
    }

    .mb-5{
        margin-bottom: 4.1667vw!important;
    }
    .my-5{
        margin-bottom: 4.1667vw!important;
    }
    /* three_easy_steps ends */

}
@media only screen and (min-width: 1441px) {

     /* three_easy_steps starts */
     .three_easy_steps {
        background-color: #FFFFFF;
    }

    .three_easy_steps_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 207px;
    }

    .three_easy_steps_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .three_easy_steps_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .three_easy_steps_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .three_easy_steps_right_side_content {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 253px;
    }

    .mb-5{
        margin-bottom: 60px!important;
    }
    .my-5{
        margin-bottom: 60px!important;
    }
    /* three_easy_steps ends */

}