@media only screen and (max-width: 480px) {

    /* help_center_header starts */
    .help_center_header {
        background-color: #1c1ca8;
        padding-top: 4.544vw;
        padding-bottom: 4.444vw;
    }

    .help_center_header_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .help_center_header_content_three_btns ul li {
        margin: 0px auto;
    }

    .help_center_header_content_three_btns {
        width: 100%;
        margin: 0vw auto;
        display: flex;
        gap: 2.222vw;
        justify-content: center;
    }

    .help_center_header_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 11.1111vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 6.695vw;
    }

    .input-group-text {
        border-radius: 4.25rem !important;
    }

    .help_center_header_content_input_group {
        background-color: #ffffff !important;
        border-radius: 4.25rem !important;
        width: 100%;
        margin: 0px auto;
        border: none;
    }

    .help_center_header_input_font_styles {
        border-radius: 4.25rem !important;
        padding-left: 30px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_header_content_three_btns ul li {
        margin: 15px 0px;
    }

    .help_center_header_faq_category_btn {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        cursor: pointer;
        position: relative;
        height: 20.555vw;
        border-radius: 8px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        flex-grow: 1;
    }

    .help_center_header_faq_category_btn.active:before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        background: inherit;
        border-radius: 4px;
    }

    .help_center_header_sales_agent_btn svg {
        width: 100%;
        height: 50px;
    }

    .help_center_header_corporate_partner_btn svg {
        width: 100%;
        height: 50px;
    }

    .help_center_header_customer_btn svg {
        width: 100%;
        height: 50px;
    }

    .help_center_header_content_three_btns_mobile_view {
        display: block;
        margin-top: 8.955vw;
    }

    .help_center_header_sales_agent_btn_mobile_view svg {
        width: 28.889vw;
        height: 20.556vw;
    }

    .help_center_header_corporate_partner_btn_mobile_view svg {
        width: 28.889vw;
        height: 20.556vw;
    }

    .help_center_header_customer_btn_mobile_view svg {
        width: 28.889vw;
        height: 20.556vw;
    }

    .help_center_header_content_three_btns_mobile_view ul {
        display: flex;
        justify-content: space-between;
    }

    /* help_center_header ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* help_center_header starts */
    /* .help_center_header {
        background-color: #1c1ca8;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .help_center_header_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .help_center_header_content_three_btns ul li {
        margin: 0px 10px;
    }

    .help_center_header_content_three_btns ul {
        display: flex;
        margin-bottom: 7px;
    }

    .help_center_header_faq_category_btn {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 20.555vw;
        border-radius: 8px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
    }

    .help_center_header_faq_category_btn.active:before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        background: inherit;
        border-radius: 4px;
    }

    .help_center_header_sales_agent_btn svg {
        width: 100%;
        height: 50px;
    }

    .help_center_header_corporate_partner_btn svg {
        width: 100%;
        height: 50px;
    }

    .help_center_header_customer_btn svg {
        width: 100%;
        height: 50px;
    }

    .help_center_header_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .input-group-text {
        border-radius: 4.25rem !important;
    }

    .help_center_header_content_input_group {
        background-color: #ffffff !important;
        border-radius: 4.25rem !important;
        width: 75%;
        margin: 0px auto;
        border: none;
        margin-bottom: 20px;
    }

    .help_center_header_input_font_styles {
        border-radius: 4.25rem !important;
        padding-left: 30px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_header_content_three_btns_mobile_view {
        display: none;
        margin-top: 10px;
    } */

    /* help_center_header ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* help_center_header starts */
    .help_center_header {
        background-color: #1c1ca8;
        padding-top: 3.9062vw;
        padding-bottom: 3.9062vw;
    }

    .help_center_header_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .help_center_header_content_three_btns {
        width: 100%;
        margin: 0vw auto;
        display: flex;
        gap: 2.857vw;
        justify-content: center;
    }

    .help_center_header_content_three_btns ul li {
        margin: 0px 4.1666vw;
    }

    .help_center_header_content_three_btns ul {
        display: flex;
        margin-bottom: 0.9114vw;
    }

    .help_center_header_faq_category_btn {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        cursor: pointer;
        position: relative;
        width: 23.809vw;
        height: 5.952vw;
        border-radius: 8px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        font-size: 1.904vw;
        line-height: 2.380vw;
    }

    .help_center_header_faq_category_btn.active:before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        background: inherit;
        border-radius: 4px;
    }

    .help_center_header_sales_agent_btn svg {
        width: 100%;
        height: 6.5104vw;
    }

    .help_center_header_corporate_partner_btn svg {
        width: 100%;
        height: 6.5104vw;
    }

    .help_center_header_customer_btn svg {
        width: 100%;
        height: 6.5104vw;
    }

    .help_center_header_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 7.9427vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .input-group-text {
        border-radius: 8.8541vw !important;
    }

    .help_center_header_content_input_group {
        background-color: #ffffff !important;
        border-radius: 8.8541vw !important;
        width: 75%;
        margin: 0px auto;
        border: none;
        margin-bottom:3.125vw;
    }

    .help_center_header_input_font_styles {
        border-radius: 8.8541vw !important;
        padding-left: 3.9062vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_header_content_three_btns_mobile_view {
        display: none;
        margin-top: 1.3020vw;
    }

    /* help_center_header ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* help_center_header starts */
    .help_center_header {
        background-color: #1c1ca8;
        padding-top: 4.193vw;
        padding-bottom: 4.193vw;
    }

    .help_center_header_content {
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .help_center_header_content_three_btns {
        width: 100%;
        margin: 0vw auto;
        display: flex;
        gap: 1.666vw;
        justify-content: center;
    }

    .help_center_header_content_three_btns ul li {
        margin: 0vw 0.855vw;
    }

    .help_center_header_content_three_btns ul {
        display: flex;
        margin-bottom: 11px;
    }

    .help_center_header_faq_category_btn {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        cursor: pointer;
        position: relative;
        width: 13.888vw;
        height: 3.472vw;
        border-radius: 8px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
    }

    .help_center_header_faq_category_btn.active:before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        background: inherit;
        border-radius: 4px;
    }

    .help_center_header_sales_agent_btn svg {
        width: 13.889vw;
        height: 3.473vw;
    }

    .help_center_header_corporate_partner_btn svg {
        width: 13.889vw;
        height: 3.473vw;
    }

    .help_center_header_customer_btn svg {
        width: 13.889vw;
        height: 3.473vw;
    }

    .help_center_header_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 4.23vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.691vw;
    }

    .input-group-text {
        border-radius: 12vw !important;
    }

    .help_center_header_content_input_group {
        background-color: #ffffff !important;
        border-radius: 12vw !important;
        width: 34.862vw;
        height: 5.139vw;
        margin: 0vw auto;
        border: none;
        margin-bottom: 2.817vw;
    }

    .help_center_header_input_font_styles {
        border-radius: 12vw !important;
        padding-left: 1.689vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        /* font-size: 20px; */
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_header_content_three_btns_mobile_view {
        display: none;
        margin-top: 10px;
    }

    .img_class {
        width: 3.473vw;
        height: 3.473vw;
    }

    /* help_center_header ends */

}

@media only screen and (min-width: 1441px) {


    /* help_center_header starts */
    .help_center_header {
        background-color: #1c1ca8;
        padding-top: 60.3792px;
        padding-bottom: 60.3792px;
    }

    .help_center_header_content {
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .help_center_header_content_three_btns {
        width: 100%;
        margin: 0vw auto;
        display: flex;
        gap: 1.666vw;
        justify-content: center;
    }

    .help_center_header_content_three_btns ul li {
        margin: 0vw 12.312px;
    }

    .help_center_header_content_three_btns ul {
        display: flex;
        margin-bottom: 11px;
    }

    .help_center_header_faq_category_btn {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        cursor: pointer;
        position: relative;
        width: 200px;
        height: 50px;
        border-radius: 8px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }

    .help_center_header_faq_category_btn.active:before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        background: inherit;
        border-radius: 4px;
    }

    .help_center_header_sales_agent_btn svg {
        width: 200.0016px;
        height: 50.0112px;
    }

    .help_center_header_corporate_partner_btn svg {
        width: 200.0016px;
        height: 50.0112px;
    }

    .help_center_header_customer_btn svg {
        width: 200.0016px;
        height: 50.0112px;
    }

    .help_center_header_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .input-group-text {
        border-radius: 172.8px !important;
    }

    .help_center_header_content_input_group {
        background-color: #ffffff !important;
        border-radius: 172.8px !important;
        width: 502.0128px;
        height: 74.0016px;
        margin: 0vw auto;
        border: none;
        margin-bottom: 40.5648px;
    }

    .help_center_header_input_font_styles {
        border-radius: 12vw !important;
        padding-left: 24.3216px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        /* font-size: 20px; */
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_header_content_three_btns_mobile_view {
        display: none;
        margin-top: 10px;
    }

    .img_class {
        width: 50.0112px;
        height: 50.0112px;
    }

    /* help_center_header ends */

}