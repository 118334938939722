@media only screen and (max-width: 480px) {

    /* partner_sign_up_form starts */
    .partner_sign_up_form {
        background-color: #1C1CA8;
        padding-bottom: 50px;
    }

    .partner_sign_up_form_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_first_row {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_second_row_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
    }

    .sign_up_form_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 20px;
    }

    .partner_sign_up_form_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        padding-top: 9%;
    }

    /* .partner_sign_up_form_second_row_left_side_content_img_div{
        text-align: center;
    } */
    .partner_sign_up_form_second_row_left_side_content_img_div img {
        width: 100px;
        height: 100px;
    }

    .sign_up_form_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        /* font-size: 31px; */
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 4.4444vw;
    }

    .sign_up_form_lable_name {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .i_am_not_robot_img {
        margin: 15px 0px;
        transform: scale(0.8);
        transform-origin: 0 0;
    }

    .send_btn_size svg {
        width: 100%;
        height: 66px;
    }

    .signup_form_btn_width_height {
        width: 100% !important;
        height: 12.667vw !important;
        margin: 0px auto;
    }

    /* partner_sign_up_form ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* partner_sign_up_form starts */
    .partner_sign_up_form {
        background-color: #1C1CA8;
        padding-bottom: 50px;
        padding-top: 30px;
    }

    .partner_sign_up_form_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_first_row {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .partner_sign_up_form_second_row_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .sign_up_form_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 5px;
    }

    .partner_sign_up_form_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* .partner_sign_up_form_second_row_left_side_content_img_div{
        text-align: center;
    } */
    .partner_sign_up_form_second_row_left_side_content_img_div img {
        width: 80px;
        height: 80px;
    }

    .sign_up_form_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        /* font-size: 31px; */
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.111vw !important;
    }

    .sign_up_form_lable_name {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .i_am_not_robot_img {
        margin: 15px 0px;
        transform-origin: 0 0;
    }

    .send_btn_size svg {
        width: 100%;
        height: 66px;
    }

    .signup_form_btn_width_height {
        width: 100% !important;
        height: 5.333vw !important;
        margin: 0px auto;
    }

    /* partner_sign_up_form ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* partner_sign_up_form starts */
    .partner_sign_up_form {
        background-color: #1C1CA8;
        padding-bottom: 50px;
        padding-top: 30px;
    }

    .partner_sign_up_form_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_first_row {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .partner_sign_up_form_second_row_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .sign_up_form_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 5px;
    }

    .partner_sign_up_form_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_img_div img {
        width: 90px;
        height: 90px;
    }

    .sign_up_form_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        /* font-size: 31px; */
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 2.0833vw !important;
    }

    .sign_up_form_lable_name {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .i_am_not_robot_img {
        margin: 15px 0px;
        transform-origin: 0 0;
    }

    .send_btn_size svg {
        width: 100%;
        height: 66px;
    }

    .signup_form_btn_width_height {
        width: 100% !important;
        height: 6.667vw !important;
        margin: 0px auto;
    }

    /* partner_sign_up_form ends */

}

@media only screen and (min-width: 481px) and (max-width: 630px) {
    .i_am_not_robot_img {
        transform: scale(0.46)
    }
}
@media only screen and (min-width: 631px) and (max-width: 865px) {
    .i_am_not_robot_img {
        transform: scale(0.64);
    }
}

@media only screen and (min-width: 866px) and (max-width: 930px) {
    .i_am_not_robot_img {
        transform: scale(0.6)
    }
}

@media only screen and (min-width: 931px) and (max-width: 1114px) {
    .i_am_not_robot_img {
        transform: scale(0.80)
    }
}

@media only screen and (min-width: 1114px) and (max-width: 1440px) {
    .i_am_not_robot_img {
        transform: scale(0.77);
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    @media only screen and (max-width: 1110px) {

        /* partner_sign_up_form starts */
        .signup_form_btn_width_height {
            width: 100% !important;
            height: 50px !important;
            margin: 0px auto;
            font-size: 13px !important;
        }

        /* partner_sign_up_form ends */
    }

    /* partner_sign_up_form starts */
    .partner_sign_up_form {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .partner_sign_up_form_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_first_row {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_second_row_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .sign_up_form_content {
        background-color: #ffffff;
        padding: 1.7361vw 1.25vw;
        border-radius: 5px;
    }

    .partner_sign_up_form_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* .partner_sign_up_form_second_row_left_side_content_img_div{
        text-align: center;
    } */
    .sign_up_form_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        /* font-size: 31px; */
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.1111vw !important;
    }

    .sign_up_form_lable_name {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .i_am_not_robot_img {
        margin: 15px 0px;
        transform-origin: 0 0;
        width: 16.2555vw;
    }

    .send_btn_size svg {
        width: 100%;
        height: 66px;
    }

    .signup_form_btn_width_height {
        width: 100% !important;
        height: 4.584vw !important;
        margin: 0px auto;
    }

    /* partner_sign_up_form ends */

}

@media only screen and (min-width: 1441px) {


    /* partner_sign_up_form starts */
    .partner_sign_up_form {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .partner_sign_up_form_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_first_row {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_second_row_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .sign_up_form_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 5px;
    }

    .partner_sign_up_form_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* .partner_sign_up_form_second_row_left_side_content_img_div{
        text-align: center;
    } */
    .sign_up_form_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        /* font-size: 31px; */
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sign_up_form_lable_name {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .i_am_not_robot_img {
        margin: 15px 0px;
        transform: scale(0.77);
        transform-origin: 0 0;
    }

    .send_btn_size svg {
        width: 100%;
        height: 66px;
    }

    .signup_form_btn_width_height {
        width: 100% !important;
        height: 4.584vw !important;
        margin: 0px auto;
    }

    /* partner_sign_up_form ends */

}