@media only screen and (max-width: 480px) {

    /* about_us_join_our_team starts */
    .about_us_join_our_team {
        background-image: url(../../assets/images/about\ us/join_our_team_bg_img.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: #000000;
        position: relative;
    }

    .about_us_join_our_team_content {
        padding-top: 21.1111vw;
        padding-bottom: 17.2222vw;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .about_us_join_our_team_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 3.3333vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 6.6667vw;
    }

    .about_us_join_our_team_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .about_us_join_our_team_content_three_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 5.5556vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    /* about_us_join_our_team ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_join_our_team starts */
    /* .about_us_join_our_team {
        background-image: url(../../assets/images/about\ us/join_our_team_bg_img.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: #000000;
        position: relative;
    }

    .about_us_join_our_team_content {
        padding-top: 50px;
        padding-bottom: 50px;
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .about_us_join_our_team_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .about_us_join_our_team_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .about_us_join_our_team_content_three_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    } */

    /* about_us_join_our_team ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* about_us_join_our_team starts */
    .about_us_join_our_team {
        background-image: url(../../assets/images/about\ us/join_our_team_bg_img.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: #000000;
        position: relative;
    }

    .about_us_join_our_team_content {
        padding-top: 6.5104vw;
        padding-bottom: 6.5104vw;
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .about_us_join_our_team_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.128vw;
    }

    .about_us_join_our_team_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.128vw;
    }

    .about_us_join_our_team_content_three_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.6041vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom:3.125vw;
    }

    /* about_us_join_our_team ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_join_our_team starts */
    .about_us_join_our_team {
        background-image: url(../../assets/images/about\ us/join_our_team_bg_img.webp);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        background-position: center;
        background-color: #000000;
        opacity: 9;
    }

    .about_us_join_our_team_content {
        padding-top: 17.0833vw;
        padding-bottom: 17.0833vw;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 44.5833vw;
        text-align: center;
    }

    .about_us_join_our_team_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 1.6667vw;
    }

    .about_us_join_our_team_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw
    }

    .about_us_join_our_team_content_three_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw
    }

    /* about_us_join_our_team ends */

}

@media only screen and (min-width: 1441px) {

    /* about_us_join_our_team starts */
    .about_us_bg_img {
        background-image: url(../../assets/images/about\ us/join_our_team_bg_img.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #000000;
    }

    .about_us_join_our_team_content {
        padding-top: 246px;
        padding-bottom: 246px;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .about_us_join_our_team_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .about_us_join_our_team_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .about_us_join_our_team_content_three_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    /* about_us_join_our_team ends */

}