@media only screen and (max-width: 480px) {

    /* still_have_question starts */
    .still_have_question {
        background-color: #F7F8FA;
        padding-top: 17.867vw;
        padding-bottom: 6.667vw;
    }

    .still_have_question_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .still_have_question_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-top: 25px;
    }

    .still_have_question_right_side_content_box {
        background-color: #FFFFFF;
        padding: 30px 0px;
        border-radius: 15px;
        margin-bottom:64px;
    }

    .still_have_question_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.811vw !important;
    }

    .still_have_question_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size:9.1666vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 5vw;
    }

    .still_have_question_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 4.4444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .still_have_question_timings {
        margin-bottom: 4.4444vw;
    }

    .still_have_question_social_icons svg {
        width: 24px;
        height: 24px;
    }

    .social_media_section ul li {
        margin: 0px 2.2233vw;
    }

    .still_have_question_right_side_content_box_frist_line {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .input_fields_section_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .input_fields_section_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: center;
        margin-bottom: 24px;
    }


    .still_hvae_question_submit_btn svg {
        width: 100%;
        height: 66px;
    }

    .still_have_question_input_fields {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        padding: 20px 20px 20px 48px;
        background-color: #EEEEF0;
        margin-bottom: 24px;
    }

    .still_have_question_input_fields:focus {
        box-shadow: none;
        background-color: #EEEEF0;
    }

    .btn_section_div ul {
        display: flex;
    }

    .btn_section_div ul li {
        margin: 0px 4px;
    }

    .still_have_question_agnet_btn svg {
        width: 104px;
        height: 50px;
        margin-top:0px;
        margin-bottom:16px;
    }

    .still_have_question_partner_btn svg {
        width: 104px;
        height: 50px;
    }

    .still_have_question_customer_btn svg {
        width: 104px;
        height: 50px;
    }

    .submit_btn_width_height {
        width: 91.1111vw !important;
        height: 55px;
        margin: 0px auto;
        font-size: 19px;
        margin-bottom: 6px;
    }

    .send_another_message_btn_width_height {
        width: 78%;
        height: 60px;
        margin: 0px auto;
        font-size: 18px;
    }

    .still_have_question_address_lf_sd {
        width: 32.7055vw;
    }

    .still_have_question_right_side_content_box_first_line {
        font-size: 6.6666vw;
        color:#1C1CA8;
        margin-bottom: 4.4444vw !important;
        margin-top: 1.6688vw !important;
    }

    .send_message_btn {
        margin-bottom: 7px;
    }

    /* still_have_question ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* still_have_question starts */
    /* .still_have_question {
        background-color: #F7F8FA;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .still_have_question_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .still_have_question_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .still_have_question_right_side_content_box {
        background-color: #FFFFFF;
        padding: 13.3333vw;
        border-radius: 15px;
    }

    .still_have_question_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .still_have_question_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .still_have_question_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .still_have_question_social_icons svg {
        width: 24px;
        height: 24px;
    }

    .social_media_section ul li {
        margin: 0px 10px;
    }

    .still_have_question_right_side_content_box_frist_line {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .btn_section_div ul li {
        margin: 0px auto;
    }

    .input_fields_section_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .input_fields_section_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: center;
    }

    .still_hvae_question_submit_btn svg {
        width: 100%;
        height: 66px;
    }

    .still_have_question_input_fields {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        padding: 15px 15px;
        background-color: #EEEEF0;
    }

    .still_have_question_input_fields:focus {
        box-shadow: none;
        background-color: #EEEEF0;
    }

    .still_have_question_agnet_btn svg {
        width: 100%;
        height: 50px;
    }

    .still_have_question_partner_btn svg {
        width: 100%;
        height: 50px;
    }

    .still_have_question_customer_btn svg {
        width: 100%;
        height: 50px;
    }

    .btn_section_div ul {
        display: flex;
    }

    .btn_section_div ul li {
        margin: 0px 3px;
    }

    .submit_btn_width_height {
        width: 95%;
        height: 40px;
        margin: 0px auto;
        font-size: 13px;
    }

    .send_another_message_btn_width_height {
        width: 100%;
        height: 40px;
        margin: 0px auto;
        font-size: 10px;
    } */

    /* still_have_question ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* still_have_question starts */
    .still_have_question {
        background-color: #F7F8FA;
        padding-top: 6.5104vw;
        padding-bottom: 6.5104vw;
    }

    .still_have_question_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .still_have_question_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .still_have_question_right_side_content_box {
        background-color: #FFFFFF;
        padding: 3.9062vw 3.2552vw;
        border-radius: 1.9531vw;
    }

    .still_have_question_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .still_have_question_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin:0px 3.125vw;
    }

    .still_have_question_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.0833vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .still_have_question_social_icons svg {
        width: 3.125vw;
        height: 3.125vw;
    }

    .social_media_section ul li {
        margin: 0px 1.3020vw;
    }

    .still_have_question_right_side_content_box_frist_line {
        font-style: normal;
        font-weight: bold;
        font-size: 3.125vw;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .btn_section_div ul li {
        margin: 0px auto;
    }

    .input_fields_section_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8229vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .input_fields_section_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.0833vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: center;
    }

    .still_hvae_question_submit_btn svg {
        width: 100%;
        height: 8.5937vw;
    }

    .still_have_question_input_fields {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.0833vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        padding: 1.9531vw 1.9531vw;
        background-color: #EEEEF0;
    }

    .still_have_question_input_fields:focus {
        box-shadow: none;
        background-color: #EEEEF0;
    }

    .still_have_question_agnet_btn svg {
        width: 100%;
        height: 6.5104vw;
    }

    .still_have_question_partner_btn svg {
        width: 100%;
        height: 6.5104vw;
    }

    .still_have_question_customer_btn svg {
        width: 100%;
        height: 6.5104vw;
    }

    .btn_section_div ul {
        display: flex;
    }

    .btn_section_div ul li {
        margin: 0px 0.3906vw;
    }

    .submit_btn_width_height {
        width: 95%;
        height: 5.2083vw;
        margin: 0px auto;
        font-size: 1.6927vw;
    }

    .send_another_message_btn_width_height {
        width: 100%;
        height: 6.5104vw;
        margin: 0px auto;
        font-size: 1.9531vw;
    }

    /* still_have_question ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    @media only screen and (max-width: 1110px) {

        /* still_have_question starts */
        /* .submit_btn_width_height {
            width: 95%;
            height: 50px !important;
            margin: 0px auto;
            font-size: 14px !important;
        } */

        .send_another_message_btn_width_height {
            width: 100% !important;
            height: 40px !important;
            margin: 0px auto !important;
            font-size: 8px !important;
        }

        /* still_have_question ends */

    }

    /* still_have_question starts */
    .still_have_question {
        background-color: #F7F8FA;
    }

    .still_have_question_left_side_content {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
        margin-top: 13.755vw;
        margin-bottom: 12.615vw;
        margin-left: 0.354vw;
    }

    .still_have_question_right_side_content {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 6.455vw;
        margin-bottom: 6.395vw;
    }

    .still_have_question_right_side_content_box {
        background-color: #FFFFFF;
        padding: 2.555vw 1.693vw;
        border-radius: 15px;
    }

    .still_have_question_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        /* font-size: 14px; */
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        padding-bottom: 1.761vw !important;
    }

    .still_have_question_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        /* font-size: 48px; */
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        padding-bottom: 3.161vw !important;
    }

    .still_have_question_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        font-size: 1.11111vw;
    }

    .still_have_question_address {
        margin-bottom: 1.895vw;
    }

    .still_have_question_timings {
        margin-bottom: 3.091vw;
    }

    .still_have_question_social_icons svg {
        width: 1.727vw;
        height: 1.727vw;
    }

    .social_media_section ul li {
        margin-right: 1.222vw;
    }

    .still_have_question_address_lf_sd {
        margin-right: 2.2222vw;
    }

    .still_have_question_right_side_content_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        /* font-size: 24px; */
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.711vw !important;
    }

    .input_fields_section_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .input_fields_section {
        margin-bottom: 1.671vw;
    }

    .input_fields_section_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        /* font-size: 16px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: center;
    }

    .still_hvae_question_submit_btn svg {
        width: 100%;
        height: 66px;
    }

    .still_have_question_input_fields {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.033vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        padding: 1.395vw 1.395vw;
        background-color: #EEEEF0;
    }

    .still_have_question_input_fields:focus {
        box-shadow: none;
        background-color: #EEEEF0;
    }

    .still_have_question_agnet_btn svg {
        width: 10.788vw;
        height: 3.473vw;
    }

    .still_have_question_partner_btn svg {
        width: 10.788vw;
        height: 3.473vw;
    }

    .still_have_question_customer_btn svg {
        width: 10.788vw;
        height: 3.473vw;
    }

    .btn_section_div ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.611vw !important;
    }

    .submit_btn_width_height {
        /* width: 34.584vw !important; */
        width: 100% !important;
        height: 4.584vw !important;
        padding: 1.411vw 1.727vw !important;
    }

    .send_another_message_btn_width_height {
        width: 100%;
        height: 60px;
        margin: 0px auto;
        font-size: 18px;
    }

    /* still_have_question ends */

}

@media only screen and (min-width: 1441px) {

    /* still_have_question starts */
    .still_have_question {
        background-color: #F7F8FA;
    }

    .still_have_question_left_side_content {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
        margin-top: 199px;
        margin-bottom: 181.656px;
        margin-left: 5px;
    }

    .still_have_question_right_side_content {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 93px;
        margin-bottom: 92px;
    }

    .still_have_question_right_side_content_box {
        background-color: #FFFFFF;
        padding: 36.792px 24.3792px;
        border-radius: 15px;
    }

    .still_have_question_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        /* font-size: 14px; */
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        padding-bottom: 25.3584px !important;
    }

    .still_have_question_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        /* font-size: 48px; */
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        padding-bottom: 45.5184px !important;
    }

    .still_have_question_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        font-size: 16px;
    }

    .still_have_question_address {
        margin-bottom: 27.288px;
    }

    .still_have_question_timings {
        margin-bottom: 44.5104px;
    }

    .still_have_question_social_icons svg {
        width: 24.8688px;
        height: 24.8688px;
    }

    .social_media_section ul li {
        margin-right: 17.5968px;
    }

    .still_have_question_address_lf_sd {
        margin-right: 31.9997px;
    }

    .still_have_question_right_side_content_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        /* font-size: 24px; */
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24.6384px !important;
    }

    .input_fields_section_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .input_fields_section {
        margin-bottom: 24.0624px;
    }

    .input_fields_section_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        /* font-size: 16px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: center;
    }

    .still_hvae_question_submit_btn svg {
        width: 100%;
        height: 66px;
    }

    .still_have_question_input_fields {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        padding: 20px 20px;
        background-color: #EEEEF0;
    }

    .still_have_question_input_fields:focus {
        box-shadow: none;
        background-color: #EEEEF0;
    }

    .still_have_question_agnet_btn svg {
        width: 155.3472px;
        height: 50.0112px;
    }

    .still_have_question_partner_btn svg {
        width: 155.3472px;
        height: 50.0112px;
    }

    .still_have_question_customer_btn svg {
        width: 155.3472px;
        height: 50.0112px;
    }

    .btn_section_div ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 23.1984px !important;
    }

    .submit_btn_width_height {
        /* width: 34.584vw !important; */
        width: 100% !important;
        height: 66.0096px !important;
        padding: 20.3184px 24.8688px !important;
    }

    .send_another_message_btn_width_height {
        width: 100%;
        height: 60px;
        margin: 0px auto;
        font-size: 18px;
    }

    /* still_have_question ends */

}