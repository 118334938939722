.sales-agent-left-side-img1-mobile {
    display: none;
}

.sales-agent-left-side-img1-tablet2 {
    Display: none;
}

@media only screen and (max-width: 480px) {

    /* sales-agent starts*/
    .sales-agent {
        background: #FFFFFF;

    }

    .sales-agent_images_div {
        grid-column: 1/ span 4;
        grid-row: 2;
        width: 89.5vw;
        margin-top: 8.889vw;
        position: relative;

    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
        transform: none !important;
    }

    [data-aos=fade-up] {
        transform: translate3d(0, 250px, 0) !important;
    }

    body[data-aos-easing=ease] [data-aos] {
        transition-timing-function: ease !important;
    }

    .sales-agent-left-side-img1 {
        bottom: 0%;
        left: 0%;
        z-index: 2;
        border-bottom-left-radius: 45%;
        border-bottom-right-radius: 49%;
        margin-left: 6.731vw;
        margin-right: 6.325vw;
        display: none;
    }

    .sales-agent-left-side-img1-mobile {

        bottom: 0%;
        left: 0%;
        z-index: 2;
        border-bottom-left-radius: 45%;
        border-bottom-right-radius: 49%;
        margin-left: 6.111vw;
        display: block;
    }

    .sales-agent-left-side-img1 img {
        width: 78.056vw !important;
        height: 102.500vw;
    }

    .sales-agent-left-side-img1-mobile img {
        width: 79.168vw;
        height: 102.502vw;
    }

    .sales-agent-left-side-img2 {
        width: 100%;
    }

    .sales-agent-left-side-img2 img {
        width: 100%;
    }

    .sales-agent-left-side-img3 {
        width: 100%;
        position: absolute;
        top: 2%;
        right: -69%;
        z-index: 2;
    }

    .sales-agent-left-side-img3 img {
        width: 24%;
    }

    .sales-agent-left-side-img4 {
        width: 100%;
        position: absolute;
        top: 33%;
        right: -52%;
        z-index: 2;
    }

    .sales-agent-left-side-img4 img {
        width: 24%;
    }

    .sales-agent-right-side-content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 90vw;
        margin: auto 0px;
    }

    .sales-agent-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.667vw;
    }

    .sales-agent-right-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.667vw;
    }

    .sale-agent-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 0.111vw;
    }

    .sales-agent-btn-styles {
        margin-top: 6.667vw;
    }

    .dot_img_sizes svg {
        width: 6.667vw;
        height: 8.34vw;
    }

    /* sales-agent ends*/

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* sales-agent starts*/
    .sales-agent {
        background: #FFFFFF;
        /* aspect-ratio: 1440/900; */
    }

    .sales-agent_images_div {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        margin-left: 2.255vw;
        margin-top: 6.111vw;
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
        transform: none !important;
    }

    [data-aos=fade-up] {
        transform: translate3d(0, 350px, 0) !important;
    }

    body[data-aos-easing=ease] [data-aos] {
        transition-timing-function: ease !important;
    }

    .sales-agent-left-side-img1 {
        width: 100%;
        bottom: 0%;
        left: 0%;
        z-index: 2;
    }

    .sales-agent-left-side-img1 img {
        width: 38.194vw !important;
        height: 47.894vw;
        margin-top: 2.292vw;
        margin-bottom: 12.292vw;

    }

    .sales-agent-left-side-img2 img {
        width: 100%;
    }

    .sales-agent-left-side-img3 {
        width: 100%;
        position: absolute;
        top: 2%;
        right: -69%;
        z-index: 2;
    }

    .sales-agent-left-side-img3 img {
        width: 24%;
    }

    .sales-agent-left-side-img4 {
        width: 100%;
        position: absolute;
        top: 33%;
        right: -52%;
        z-index: 2;
    }

    .sales-agent-left-side-img4 img {
        width: 24%;
    }

    .sales-agent-right-side-content {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 17.917vw !important;
        margin-bottom: 16.250vw !important;
    }

    .sales-agent-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;

    }

    .sales-agent-right-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .sale-agent-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .sales-agent-btn-styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.042vw 2.431vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 1.667vw 0px 0px 0px;
    }

    .dot_img_sizes svg {
        width: 10px;
        height: 30px;
        margin-right: 10px;
        margin-top: -1px;
    }

    /* sales-agent ends*/

    /* sales-agent starts*/
    /*.sales-agent {
        background: #FFFFFF;
        padding-top: 15%;
    }

    .sales-agent_images_div {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
        position: relative;

    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
        transform: none !important;
    }

    [data-aos=fade-up] {
        transform: translate3d(0, 250px, 0) !important;
    }

    body[data-aos-easing=ease] [data-aos] {
        transition-timing-function: ease !important;
    }

    .sales-agent-left-side-img1 {
       
        grid-column: 1/span4;
        position: absolute;
        bottom: 0%;
        left: 0%;
        z-index: 2;
        border-bottom-left-radius: 43%;
        border-bottom-right-radius: 48%;
        overflow: hidden;
    }

    .sales-agent-left-side-img1 img {
        width: 85% !important;
    }

    .sales-agent-left-side-img2 {
        width: 100%;
    }

    .sales-agent-left-side-img2 img {
        width: 100%;
    }

    .sales-agent-left-side-img3 {
        width: 100%;
        position: absolute;
        top: 2%;
        right: -69%;
        z-index: 2;
    }

    .sales-agent-left-side-img3 img {
        width: 24%;
    }

    .sales-agent-left-side-img4 {
        width: 100%;
        position: absolute;
        top: 33%;
        right: -52%;
        z-index: 2;
    }

    .sales-agent-left-side-img4 img {
        width: 24%;
    }

    .sales-agent-right-side-content {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .sales-agent-first-para {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .sales-agent-right-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sale-agent-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .sales-agent-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .dot_img_sizes svg {
        width: 30px;
        height: 30px;
    }

    /* sales-agent ends*/

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* sales-agent starts*/
    .sales-agent {
        background: #FFFFFF;
    }

    .sales-agent_images_div {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 40vw;
        margin: auto 0px;
        position: relative;

    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
        transform: none !important;
    }

    [data-aos=fade-up] {
        transform: translate3d(0, 250px, 0) !important;
    }

    body[data-aos-easing=ease] [data-aos] {
        transition-timing-function: ease !important;
    }

    .sales-agent-left-side-img1 {
        display: none;
        margin-top: 13.411vw;
        width: 40vw !important;
        bottom: 0%;
        z-index: 2;
    }

    .sales-agent-left-side-img1-tablet2 {
        display: block;
        margin-top: 13.411vw;
        width: 40vw !important;
        bottom: 0%;
        z-index: 2;

    }

    .sales-agent-left-side-img1 img {
        width: 38.542vw !important;
        height: 48.707vw;

    }

    .sales-agent-left-side-img1-tablet2 img {
        width: 38.542vw !important;
        height: 48.707vw;

    }

    .sales-agent-left-side-img2 {
        width: 100%;
    }

    .sales-agent-left-side-img2 img {
        width: 100%;
    }

    .sales-agent-left-side-img3 {
        width: 100%;
        position: absolute;
        top: 2%;
        right: -69%;
        z-index: 2;
    }

    .sales-agent-left-side-img3 img {
        width: 24%;
    }

    .sales-agent-left-side-img4 {
        width: 100%;
        position: absolute;
        top: 33%;
        right: -52%;
        z-index: 2;
    }

    .sales-agent-left-side-img4 img {
        width: 24%;
    }

    .sales-agent-right-side-content {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 45vw;
        margin-top: 14.063vw;
    }

    .sales-agent-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .sales-agent-right-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .sale-agent-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .sales-agent-btn-styles {
        margin-top: 3.125vw;
    }

    .dot_img_sizes svg {
        width: 30px;
        height: 30px;
    }

    /* sales-agent ends*/

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* sales-agent starts*/
    .sales-agent {
        background: #FFFFFF;
        /* aspect-ratio: 1440/900; */
    }

    .sales-agent_images_div {
        grid-column: 2/ span 5;
        grid-row: 1;
        width: 100%;
        margin-left: 0.255vw;
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
        transform: none !important;
    }

    [data-aos=fade-up] {
        transform: translate3d(0, 350px, 0) !important;
    }

    body[data-aos-easing=ease] [data-aos] {
        transition-timing-function: ease !important;
    }

    .sales-agent-left-side-img1 {
        width: 100%;
        bottom: 0%;
        left: 0%;
        z-index: 2;
    }

    .sales-agent-left-side-img1 img {
        width: 38.194vw !important;
        height: 47.894vw;
        margin-top: 2.292vw;
        margin-bottom: 12.292vw;

    }

    .sales-agent-left-side-img2 img {
        width: 100%;
    }

    .sales-agent-left-side-img3 {
        width: 100%;
        position: absolute;
        top: 2%;
        right: -69%;
        z-index: 2;
    }

    .sales-agent-left-side-img3 img {
        width: 24%;
    }

    .sales-agent-left-side-img4 {
        width: 100%;
        position: absolute;
        top: 33%;
        right: -52%;
        z-index: 2;
    }

    .sales-agent-left-side-img4 img {
        width: 24%;
    }

    .sales-agent-right-side-content {
        margin-top: 17.917vw !important;
        margin-bottom: 16.250vw !important;
        grid-column: 8/ span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .sales-agent-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;

    }

    .sales-agent-right-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .sale-agent-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .sales-agent-btn-styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.042vw 2.431vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 1.667vw 0px 0px 0px;
    }

    .dot_img_sizes svg {
        width: 10px;
        height: 30px;
        margin-right: 10px;
        margin-top: -1px;
    }

    /* sales-agent ends*/

}

@media only screen and (min-width: 1441px) {

    /* sales-agent starts*/
    .sales-agent {
        background: #FFFFFF;
        aspect-ratio: 1440/900;
    }

    .sales-agent_images_div {
        grid-column: 2/ span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 33px;
        position: relative;

    }

    .sales-agent-left-side-img1 {
        width: 100%;
        bottom: 0%;
        left: 0%;
        z-index: 2;
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
        transform: none !important;
    }

    [data-aos=fade-up] {
        transform: translate3d(0, 350px, 0) !important;
    }

    body[data-aos-easing=ease] [data-aos] {
        transition-timing-function: ease !important;
    }

    .sales-agent-left-side-img1 img {
        margin-bottom: 177px;
        aspect-ratio: 550/690;
    }

    .sales-agent-left-side-img2 {}

    .sales-agent-left-side-img2 img {
        width: 100%;
    }

    .sales-agent-left-side-img3 {
        width: 100%;
        position: absolute;
        top: 2%;
        right: -69%;
        z-index: 2;
    }

    .sales-agent-left-side-img3 img {
        width: 24%;
    }

    .sales-agent-left-side-img4 {
        width: 100%;
        position: absolute;
        top: 33%;
        right: -52%;
        z-index: 2;
    }

    .sales-agent-left-side-img4 img {
        width: 24%;
    }

    .sales-agent-right-side-content {
        margin-top: 258px !important;
        margin-bottom: 234px !important;
        grid-column: 8/ span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .sales-agent-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .sales-agent-right-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .sale-agent-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .sales-agent-btn-styles {
        margin: 24px 0px 0px 0px;
    }

    .dot_img_sizes svg {
        width: 0.8vw;
        height: 3vh;
        margin-right: 10px;
        margin-top: -1px;
    }

    /* sales-agent ends*/


}