@media only screen and (max-width: 480px) {

     /* recommendations starts */
     .recommendations {
        background-color: #055CF5;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .recommendations_carousel_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .recommendations_carousel_content_heading {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 20px;
    }

    .recommendations_carousel_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .recommendations_carousel_content_secoond_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* recommendations ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* recommendations starts */
     .recommendations {
        background-color: #055CF5;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .recommendations_carousel_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .recommendations_carousel_content_heading {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 50px;
    }

    .recommendations_carousel_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .recommendations_carousel_content_secoond_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* recommendations ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

     /* recommendations starts */
     .recommendations {
        background-color: #055CF5;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .recommendations_carousel_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .recommendations_carousel_content_heading {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 50px;
    }

    .recommendations_carousel_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .recommendations_carousel_content_secoond_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* recommendations ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* recommendations starts */
    .recommendations {
        background-color: #055CF5;
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .recommendations_carousel_content {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
    }

    .recommendations_carousel_content_heading {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 50px;
    }

    .recommendations_carousel_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .recommendations_carousel_content_secoond_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* recommendations ends */

}
@media only screen and (min-width: 1601px) {

    /* recommendations starts */
    .recommendations {
        background-color: #055CF5;
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .recommendations_carousel_content {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
    }

    .recommendations_carousel_content_heading {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 50px;
    }

    .recommendations_carousel_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .recommendations_carousel_content_secoond_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* recommendations ends */

}