.make_money_right_side_img_mobile{
display: none;

}
@media only screen and (max-width: 480px) {

    /* make_money starts */
    .make_money {
        background-color: #FFFFFF;
        padding: 0px 16px;
    }

    .make_money_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 11.111vw;
    }

    .make_money_right_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-left: 6%;
        margin-top: 20px;
        display: none;
    }
    .make_money_right_side_img_mobile{
        width: 91.111vw;
        display:block;
        grid-column: 1 / span 4;
    }

    .make_money_right_side_img img {
        width: 100%;
    }
    .make_money_right_side_img_mobile img{
        width: 100%;
        margin-left: 4.444vw;
    }
    .make_money_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.667vw;
    }

    .make_money_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.667vw;
    }

    .make_money_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* make_money ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* make_money starts */
   /* .make_money {
        background-color: #FFFFFF;
        padding: 0px 16px;
        padding-top: 30px;
    }

    .make_money_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .make_money_right_side_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
        margin-left: 7%;
    }

    .make_money_right_side_img img {
        width: 100%;
    }

    .make_money_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .make_money_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .make_money_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* make_money ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* make_money starts */
    .make_money {
        background-color: #FFFFFF;
    }

    .make_money_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 13.6719vw;
    }

    .make_money_right_side_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 23.1771vw;
        margin-left: 3.125vw;
    }

    .make_money_right_side_img img {
        width: 100%;
    }

    .make_money_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .make_money_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .make_money_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* make_money ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
     /* make_money starts */
     .make_money {
        background-color: #FFFFFF;
    }

    .make_money_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 26.736vw;
    }

    .make_money_right_side_img {
        grid-column: 6 / span 7;
        grid-row: 1;
        margin-top: 20.347vw;
        width: 49.167vw;
    }

    .make_money_right_side_img img {
        width: 100%;
        margin-left: 7.000vw;
    }

    .make_money_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
    }

    .make_money_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .make_money_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* make_money ends */

}
@media only screen and (min-width: 1441px) {

   /* make_money starts */
   .make_money {
    background-color: #FFFFFF;
}

.make_money_left_side_content {
    grid-column: 2 / span 4;
    grid-row: 1;
    width: 100%;
    margin-top: 385px;
}

.make_money_right_side_img {
    grid-column: 6 / span 7;
    grid-row: 1;
    margin-top: 293px;
}

.make_money_right_side_img img {
    margin-left: 126.672px;
}

.make_money_right_side_img img {
    width: 100%;
}

.make_money_first_line {
    font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FF4600;
    margin-bottom: 24px;
}

.make_money_second_line {
    font-family: 'Azo Sans Bk';
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #1C1CA8;
    margin-bottom: 24px;
}

.make_money_third_line {
    font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #5B5B62;
}

/* make_money ends */

}