.tablet2_view_headers_men_img {
    display: none;
}

.scrollDownImgTablet2 {
    display: none
}

@media only screen and (max-width: 480px) {

    /* Welcome page start */
    .header_welcome_to_go {
        background-color: #1C1CA8;
        color: #444;
        position: relative;
        height: 184.167vw;
        z-index: 1;
        overflow: hidden;
        grid-template-rows: repeat(2, 1fr);
    }

    .left-side-div {
        grid-column: 1 / span 4;
        grid-row: 1;
        height: auto;
        max-height: 100%;
        margin-top: 8.889vw;
        max-width: 100%;
        z-index: 2;
    }

    .left-side-div .first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 4.444vw;
    }

    .left-side-div .text_content {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 15.889vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .main-contant-image {
        grid-column: 1 / span 4;
        grid-row: 2;
        max-width: 100%;
        z-index: 3;
        position: relative;
        width: 76.944vw;
        height: 81.667vw;
        align-self: end;
        margin-left: 11.111vw;
    }

    .main-contant-image2 {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: relative;
    }

    .main-contant-image3 {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: relative;
    }

    .headersMenImg {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        height: 100%;
        /*position: absolute;
        top: 62%;*/
        object-fit: contain;
        display: none;
    }

    .headersCarouselMenImg2 {
        grid-column: 1 / span 4;
        grid-row: 1;
        max-width: 100%;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 100%;
        z-index: 2;
        margin-left: 4%;
        bottom: 0vw;
    }

    .headersCarouselMenImg3 {
        grid-column: 1 / span 4;
        grid-row: 1;
        max-width: 100%;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 100%;
        z-index: 2;
        margin-left: 4%;
        bottom: 0px;
    }

    .mobile_view_headers_men_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        position: absolute;
        display: block;
        max-height: 100%;
        bottom: 0;

    }


    .scrollDownImg {
        position: absolute;
        z-index: 99;
        bottom: 13.056vw;
        left: 6.667vw;
        width: 28.056vw;
        height: 28.056vw;
    }

    .arrow-image {
        grid-column: 1 / span 4;
        grid-row: 1;
        z-index: 1;
        position: absolute;
        bottom: -11.978vw;
        height: 86.128vw;
        width: 85.081vw;
        margin-left: 6.389vw;
    }

    .second-para {
        /* font-family: 'Circular Std', 'Azo Sans', sans-serif; */
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-top: 4.444vw;
    }

    .header_welcome_to_go_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .backGroundArrowImg {
        width: 80.75vw;
    }

    .btn-w svg {
        width: 6.1111vw;
        height: 6.6667vw;
    }

    .become_an_agent_size svg {
        width: 65%;
        height: auto;
        aspect-ratio: 213/50;
    }

    /* Welcome page end */
}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* Welcome page start */
    /* .header_welcome_to_go {
        background-color: #1C1CA8;
        color: #444;
      
        overflow: hidden;
         padding-top: 5%;
    }

    .left-side-div {
        grid-column: 1 / span 4;
        grid-row: 1;
        height: auto;
        margin-top: 5%;
        max-width: 100%;
        z-index: 2;
        padding-bottom: 10%;
    }

    .left-side-div .first-para {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .left-side-div .text_content {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .main-contant-image {
        grid-column: 5 / span 4;
        grid-row: 1;
        max-width: 100%;
        z-index: 3;
        position: relative;
    }

    .scrollDownImg {
        position: absolute;
        z-index: 6;
        bottom: 5%;
        width: 50%;
        height: auto;
        left: 25%;
    }

    .left-down-img {
        grid-column: 4 /span 2;
        grid-row: 1;
        position: relative;
        z-index: 99;
    }

    .arrow-image {
        grid-column: 5 / span 4;
        grid-row: 1;
        z-index: 1;
        position: relative;
        top: 40%;
    }

    .second-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-top: 10%;
    }

    .header_welcome_to_go_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .backGroundArrowImg {
        width: 95%;
    }

    .btn-w svg {
        width: 22px;
        height: 24px;
    }

    .mobile_view_headers_men_img {
        display: none;
    }

    .become_an_agent_size svg {
        width: 70%;
        height: auto;
    }

    /* Welcome page end */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* Welcome page start */
    .header_welcome_to_go {
        background-color: #1C1CA8;
        position: relative;
        z-index: 1;
        overflow: visible;
        height: 58.073vw;
    }

    .left-side-div {
        grid-column: 1 / span 4;
        grid-row: 1;
        height: auto;
        max-width: 45.9vw;
        z-index: 2;
        margin-top: 4.557vw;
    }

    .left-side-div .first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.125vw;
    }

    .left-side-div .text_content {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .main-contant-image {
        grid-column: 4 / span 5;
        grid-row: 1;
        max-width: 100%;
        z-index: 3;
        position: relative;
        display: flex;
        align-items: end;
        justify-content: center;
        width: 55.078vw;
        height: 58.073vw;

    }

    .main-contant-image2 {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: relative;
    }

    .main-contant-image3 {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 46vw;
        z-index: 3;
        position: relative;
    }

    .headersMenImg {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: auto;
        max-width: 100%;
        height: auto;
        aspect-ratio: 697/1046;
        width: 100%;
        height: 100%;
        bottom: -15.847vw;
        position: absolute;
        display: none;
    }

    .headersCarouselMenImg2 {
        grid-column: 5 / span 4;
        grid-row: 1;
        max-width: 100%;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 100%;
        z-index: 2;
        margin-left: 4%;
        bottom: 0vw;
    }

    .headersCarouselMenImg3 {
        grid-column: 5 / span 4;
        grid-row: 1;
        max-width: 100%;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 100%;
        z-index: 2;
        margin-left: 2vw;
        bottom: 0vw;
    }

    .tablet2_view_headers_men_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        max-width: 100vw;
        width: auto;
        height: 68.869vw;
        position: absolute;
        margin-right: -11.51vw;
        display: block;
    }

    .left-down-img {
        grid-column: 4 / span 2;
        grid-row: 1;
        position: relative;

    }

    .scrollDownImg {
        position: absolute;
        z-index: 99;
        width: 8.464vw;
        height: 8.464vw;
        /*padding-left: 10%;*/
        bottom: 2.083vw;
        margin-left: 6.380vw;
        display: none;
    }

    .scrollDownImgTablet2 {
        position: absolute;
        z-index: 99;
        width: 8.464vw;
        height: 8.464vw;
        /*padding-left: 10%;*/
        bottom: 2.083vw;
        margin-left: 6.380vw;
        display: block;

    }

    .arrow-image {
        grid-column: 5 / span 4;
        grid-row: 1;
        z-index: 1;
        position: absolute;
        top: 30vw;
        overflow: hidden;
    }

    .second-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 165%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-top: 3.125vw;
        margin-bottom: 3.125vw;
    }

    .header_welcome_to_go_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .backGroundArrowImg {
        display: none;
        width: 95%;
    }

    .btn-w svg {
        width: 1.528vw;
        height: 1.667vw;
    }

    .mobile_view_headers_men_img {
        display: none;
    }

    .become_an_agent_size svg {
        width: 70%;
        height: auto;
    }

    /* Welcome page end */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* Welcome page start */
    .header_welcome_to_go {
        background-color: #1c1ca8;
        color: #444;
        overflow: hidden;
        position: relative;
        aspect-ratio: 1440/805;
        z-index: 1;
        grid-row: 1 !important;
        height: 54.9vw;
        z-index: 99;
    }

    .left-side-div {
        grid-column: 2 / span 5;
        grid-row: 1;
        height: auto;
        margin-top: 8.383vw;
        width: 100%;
        padding-left: 0vw;
        z-index: 4;
    }

    .scrollDownImg {
        position: absolute;
        bottom: 4.167vw;
        width: 7vw;
        /*left: 30px;*/
    }

    .left-down-img {
        grid-column: 1 / span 1;
        grid-row: 1;
        width: 3vw;
        z-index: 99;
        /*padding-top: 800px;*/
    }

    .man-img {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        margin: auto 0px;
    }

    .man-img img {
        width: 100%;
    }

    .arrow-img {
        grid-column: 5 / span 7;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        padding-top: 200px;
        margin: auto 0px;
        max-height: 80%;
        overflow: hidden;
    }

    .arrow-img img {
        width: 100%;
    }

    .arrow-image .backGroundArrowImg {
        width: 100%;
    }

    .left-side-div .first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 1.667vw;
    }

    .left-side-div .text_content {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 6.597vw;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .main-contant-image {
        grid-column: 7 / span 6;
        grid-row: 1;
        width: 48.403vw;
        z-index: 3;
        position: relative;
    }

    .main-contant-image2 {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 50.5vw;
        z-index: 3;
        position: relative;
    }

    .main-contant-image3 {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 51vw;
        z-index: 3;
        position: relative;
    }



    .headersMenImg {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: auto;
        height: 83.333vw;
        transform: translate(-3.708vw, 0.833vw);
        aspect-ratio: 697/1046;
        bottom: -12.847vw;
        position: absolute;
    }

    .headersCarouselMenImg2 {
        grid-column: 6 / span 7;
        grid-row: 1;
        max-width: 150vw;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 50.49vw;
        z-index: 2;
        margin-left: 2.17vw;
        bottom: 0px;
    }

    .headersCarouselMenImg3 {
        grid-column: 6 / span 7;
        grid-row: 1;
        max-width: 150vw;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 54vw;
        z-index: 2;
        margin-left: 2.17vw;
        bottom: 0px;
    }


    .arrow-image {
        grid-column: 6 / span 8;
        grid-row: 1;
        z-index: 1;
        position: absolute;
        top: 11.17vw;
        left: -8.5vw;
    }

    .second-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.736vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-top: 1.667vw;
        margin-bottom: 1.667vw;
    }

    .header_welcome_to_go_btn_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #FF4600;
        border-radius: 25px;
        padding: 15px 35px;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        border: none;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .btn-w svg {
        width: 1.528vw;
        height: 1.667vw;
    }

    .mobile_view_headers_men_img {
        display: none;
    }

    .become_an_agent_size svg {
        width: 40%;
        height: auto;
    }

    .carousel_section {
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 99;
        /* position: relative; */
    }

    .carousel-item .active {
        /* display: block !important; */
    }

    .carousel-item {
        /* display: flex !important; */
        /* display: block !important; */
        /* display: none !important; */
    }

    .carousel-indicators {
        bottom: 0px !important;
        position: absolute;
        /* left: -60%;
        top: 85%;*/
    }

    .carousel-indicators [data-bs-target] {
        width: 20px !important;
        height: 20px !important;
        border-radius: 50% !important;
        margin: 0px 10px !important;
        border-top: none !important;
        border-bottom: none !important;
    }

    .carousel-indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */

        width: 49px !important;
        border-radius: 23px !important;
    }

    .carousel-inner {
        overflow: inherit !important;
    }

    .carousel-grid {
        padding-left: 0px;
        padding-right: 0px;
        /*aspect-ratio: 1344/900;*/
        grid-template-rows: none;
        height: 62.5vw;

    }

    .image2 {

        grid-column: 6 / span 7;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        margin-left: 4%;
    }

    /* Welcome page end */

}

@media only screen and (min-width: 1441px) {

    /* Welcome page start */

    .header_welcome_to_go {
        background-color: #1C1CA8;
        color: #444;
        overflow: hidden;
        position: relative;
        height: 805px;
        z-index: 1;
    }

    .left-side-div {
        grid-column: 2 / span 5;
        grid-row: 1;
        height: auto;
        margin-top: 120px;
        width: 100%;
        z-index: 2;
    }

    .left-side-div .first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        font-size: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .left-side-div .text_content {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .main-contant-image {
        grid-column: 7 / span 6;
        grid-row: 1;
        z-index: 3;
    }

    .main-contant-image2 {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: relative;
    }

    .main-contant-image3 {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: relative;
    }

    .headersMenImg {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: auto;
        height: 1200px;
        transform: translate(-111px, 12px);
        aspect-ratio: 697/1046;
        bottom: -185px;
        position: absolute;
    }

    .headersCarouselMenImg2 {
        grid-column: 6 / span 7;
        grid-row: 1;
        max-width: 100%;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 100%;
        z-index: 2;
        margin-left: 4%;
        bottom: 0px;
    }

    .headersCarouselMenImg3 {
        grid-column: 6 / span 7;
        grid-row: 1;
        max-width: 100%;
        height: auto;
        /*aspect-ratio: 697/1046;*/
        position: absolute;
        width: 100%;
        z-index: 2;
        margin-left: 24px;
        bottom: 0px;
    }

    .scrollDownImg {
        position: absolute;
        bottom: 60px;
        width: 101px;
        z-index: 99;
    }

    .arrow-image {
        grid-column: 5 / span 8;
        grid-row: 1;
        z-index: 1;
        position: absolute;
        top: 139px;
        left: -75px;
    }

    .second-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        font-size: 25px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .header_welcome_to_go_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .btn-w svg {
        width: 1.528vw;
        height: 1.667vw;
    }

    .mobile_view_headers_men_img {
        display: none;
    }

    .become_an_agent_size svg {
        width: 40%;
        height: auto;
    }

    /* Welcome page end */

}