.women_img_styles_mobile {

    display: none;

}

@media only screen and (max-width: 480px) {

    /* what_is_go_header starts */
    .what_is_go_header {
        background-color: #1C1CA8;
        position: relative;
        aspect-ratio: 360/625;
    }

    .what_is_go_header_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 4.4444vw;
    }

    .what_is_go_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .what_is_go_header_left_side_content_second_line_inside_content {
        color: #FFBF00;
    }

    .what_is_go_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        width: 86.6667vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0;
    }

    .down_arrow_img_styles {
        /* grid-column: 3 / span 3;
        grid-row: 2;
        width: 100%;
        z-index: 1;
        position: absolute;
        top: 0%;
        right: -6%; */
        grid-column: 2/span 3;
        grid-row: 2;
        width: 100%;
        z-index: 1;
        position: absolute;
        top: -10%;
        right: -25%;
    }

    .down_arrow_img_styles img {
        width: 100%;
    }

    .up_arrow_img_styles {
        grid-column: 1 / span 3;
        grid-row: 2;
        width: 100%;
        z-index: 2;
        padding-top: 41.6667vw;
        position: absolute;
        bottom: 0px;
        left: -27.7778vw;
        display: none;
    }

    .up_arrow_img_styles img {
        width: 100%;
    }

    .up_arrow_mobile_view_img_styles {
        grid-column: 1 / span 3;
        grid-row: 2;
        width: 50%;
        z-index: 2;
        padding-top: 150px;
        position: absolute;
        bottom: 0px;
        left: -16px;
    }

    .up_arrow_mobile_view_img_styles img {
        width: 100%;
    }

    .women_img_styles {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        z-index: 3;
        margin: auto 0px;
        display: none;
    }

    .women_img_styles_mobile {

        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        z-index: 3;
        display: block;
        margin-left: -16px;
        margin-top: -9.4444vw;
        align-self: flex-end;
    }

    .women_img_styles img {
        width: 100vw;
    }

    .women_img_styles_mobile img {

        width: 100vw;
    }

    /* what_is_go_header ends*/

    .header-section-white {
        overflow: hidden;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* what_is_go_header starts */
    /* .what_is_go_header {
        background-color: #1C1CA8;
        position: relative;
        padding-top: 3%;
    }

    .what_is_go_header_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 5;
        margin: auto 0px;
    }

    .what_is_go_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 11px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .application_form_complete_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 11px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .what_is_go_header_left_side_content_second_line_inside_content {
        color: #FFBF00;
        font-size: 27px !important;
    }

    .what_is_go_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 27px !important;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .down_arrow_img_styles {
        grid-column:  6/ span 3;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        right: -10%;
        top: -33%;
    }

    .down_arrow_img_styles img {
        width: 100%;
    }

    .up_arrow_img_styles {
        grid-column: 5 / span 3;
        grid-row: 2;
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: 0px;
        left: -77px;
    }

    .up_arrow_img_styles img {
        width: 100%;
    }

    .women_img_styles {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        margin: auto 0px;
    }

    .women_img_styles img {
        width: 100%;
    }
    .up_arrow_mobile_view_img_styles{
        display: none;
    }
    /* what_is_go_header ends*/

    .header-section-white {
        overflow: hidden;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* what_is_go_header starts */
    .what_is_go_header {
        background-color: #1C1CA8;
        position: relative;
        aspect-ratio: 768/315;
        grid-template-rows: none !important;

    }

    .what_is_go_header_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        margin-top: 4.5573vw;
    }

    .what_is_go_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.125vw;
    }

    .application_form_complete_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.125vw;
    }

    .what_is_go_header_left_side_content_second_line_inside_content {
        color: #FFBF00;
    }

    .what_is_go_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .down_arrow_img_styles {
        grid-column: 6 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        right: -37px;
        top: -30%;
    }

    .down_arrow_img_styles img {
        width: 100%;
    }

    .up_arrow_img_styles {
        grid-column: 4 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0%;
        left: 0%;
    }

    .up_arrow_img_styles img {
        width: 100%;
    }

    .women_img_styles {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 132%;
        z-index: 2;
        align-self: flex-end;
        margin-left: -11.5885vw;
    }

    .women_img_styles img {
        height: 45.833vw;
        width: auto;
    }

    .up_arrow_mobile_view_img_styles {
        display: none;
    }

    /* what_is_go_header ends*/

    .header-section-white {
        overflow: hidden;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* what_is_go_header starts */
    .what_is_go_header {
        background-color: #1C1CA8;
        position: relative;
        aspect-ratio: 1440/599;

    }

    .what_is_go_header_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 9;
        margin-top: 8.75vw;
    }

    .down_arrow_img_styles {
        grid-column: 9 / span 3;
        grid-row: 1;
        /* width: 100%; */
        z-index: 2;
        position: absolute;
        /* top: -16%; */
        right: -37%;
        width: 125%;
        top: -9%;
    }

    .down_arrow_img_styles img {
        width: 120%;
    }

    .up_arrow_img_styles {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0%;
        right: 5%;
    }

    .up_arrow_img_styles img {
        width: 100%;
    }

    .women_img_styles {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: absolute;
        bottom: 0%;
        margin-left: -1.2639vw;
    }

    .women_img_styles img {
        height: 45.833vw;
        width: auto;
    }

    .what_is_go_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 1.6667vw;
    }

    .what_is_go_header_left_side_content_second_line_inside_content {
        color: #FFBF00;
    }

    .what_is_go_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .up_arrow_mobile_view_img_styles {
        display: none;
    }

    /* what_is_go_header ends*/

    .header-section-white {
        overflow: hidden;
    }
}

@media only screen and (min-width: 1441px) {

    /* what_is_go_header starts */
    .what_is_go_header {
        background-color: #1C1CA8;
        position: relative;
        aspect-ratio: 1440/599;

    }

    .what_is_go_header_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 9;
        margin-top: 126px;
    }

    .down_arrow_img_styles {
        grid-column: 9 / span 3;
        grid-row: 1;
        /* width: 100%; */
        z-index: 2;
        position: absolute;
        /* top: -16%; */
        right: -37%;
        width: 125%;
        top: -9%;
    }

    .down_arrow_img_styles img {
        width: 120%;
    }

    .up_arrow_img_styles {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0%;
        right: 5%;
    }

    .up_arrow_img_styles img {
        width: 100%;
    }

    .women_img_styles {
        grid-column: 6 / span 6;
        grid-row: 1;
        z-index: 3;
        position: absolute;
        bottom: 0%;
        margin-left: -47px;
    }

    .women_img_styles img {
        height: 696px;
    }

    .what_is_go_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .what_is_go_header_left_side_content_second_line_inside_content {
        color: #FFBF00;
    }

    .what_is_go_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .up_arrow_mobile_view_img_styles {
        display: none;
    }

    /* what_is_go_header ends*/

    .header-section-white {
        overflow: hidden;
    }
}