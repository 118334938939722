@media only screen and (max-width: 480px) {

     /* help_center_search_on_result_header_error_content starts */
     .help_center_search_on_result_header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .help_center_search_on_result_header_error_content div span img {
        margin-bottom: 7px;
    }

    /* help_center_search_on_result_header_error_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* help_center_search_on_result_header_error_content starts */
    .help_center_search_on_result_header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .help_center_search_on_result_header_error_content div span img {
        margin-bottom: 7px;
    }

    /* help_center_search_on_result_header_error_content ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* help_center_search_on_result_header_error_content starts */
    .help_center_search_on_result_header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .help_center_search_on_result_header_error_content div span img {
        margin-bottom: 7px;
    }

    /* help_center_search_on_result_header_error_content ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* help_center_search_on_result_header_error_content starts */
    .help_center_search_on_result_header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .help_center_search_on_result_header_error_content div span img {
        margin-bottom: 7px;
    }

    /* help_center_search_on_result_header_error_content ends */

}
@media only screen and (min-width: 1601px) {

    /* help_center_search_on_result_header_error_content starts */
    .help_center_search_on_result_header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .help_center_search_on_result_header_error_content div span img {
        margin-bottom: 7px;
    }

    /* help_center_search_on_result_header_error_content ends */

}