.partner-right-side-img-mobile {
    display: none;
}

.partner-right-side-img-tablet2 {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* partner start */
    .partner {
        /* padding: 15px 16px; */
        background: #FFFFFF;
        color: #444;
    }

    .partner-left-side-content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
    }

    .partner-right-side-img {
        grid-column: 1/ span 4;
        grid-row: 2;
        text-align: center;
        width: 85.731vw;
        height: 92.083vw;
        margin-top: 1.667vw;
        margin-bottom: 17.639vw;
        display: none;
    }

    .partner-right-side-img-mobile {
        grid-column: 1/ span 4;
        grid-row: 2;
        text-align: center;
        width: 100%;
        margin-top: 1.667vw;
        margin-bottom: 17.639vw;
        display: block;
        margin-left: -5.833vw;
    }

    .partner-right-side-img img {
        width: 100%;
    }

    .partner-right-side-img-mobile img {
        width: 95vw;
        height: 92.101vw;
    }

    .partner-left-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.667vw;
    }

    .partner-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-top: 16.667vw;
        margin-bottom: 6.667vw;
    }

    .partner-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .partner-btn-styles {
        margin-top: 6.667vw;
    }

    .become_partenr_btn_size svg {
        width: 70%;
        height: auto;
        aspect-ratio: 213/50;
    }

    /* partner end */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* partner start */
    /* .partner {
        padding-top: 10%;
        padding-bottom: 5%;
        background: #FFFFFF;
        color: #444;
    }

    .partner-left-side-content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
    }

    .partner-right-side-img {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .partner-right-side-img img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
    }

    .partner-left-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .partner-first-para {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .partner-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .partner-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .become_partenr_btn_size svg {
        width: 70%;
        height: auto;
        aspect-ratio: 213/50;
    }

    /* partner end */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* partner start */
    .partner {
        background: #FFFFFF;
        color: #444;
    }

    .partner-left-side-content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
        margin-top: 12.760vw;

    }

    .partner-right-side-img {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .partner-right-side-img-tablet2 {
        grid-column: 5/ span 4;
        grid-row: 1;
        height: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 115.1%;
        margin-top: 9.766vw;
        margin-left: -3.776vw;
        display: block;
    }

    .partner-right-side-img img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
    }

    .partner-right-side-img-tablet2 img {
        width: 52.66vw;
    }

    .partner-left-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .partner-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .partner-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .partner-btn-styles {
        margin-top: 3.125vw;
        margin-bottom: 7.422vw;
    }

    .become_partenr_btn_size svg {
        width: 70%;
        height: auto;
        aspect-ratio: 213/50;
    }

    /* partner end */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* partner start */
    .partner {
        background: #FFFFFF;
        aspect-ratio: 1440/900;
    }

    .partner-left-side-content {
        grid-column: 2/ span 4;
        grid-row: 1;
        width: 100%;
        margin: 15vw 0vw;
    }

    .partner-right-side-img {
        grid-column: 6/ span 7;
        grid-row: 1;
        width: 100%;
        margin: 3.859vw 0vw 8.854vw 0vw;
    }

    .partner-right-side-img img {
        width: 53.40vw;
    }

    .partner-left-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .partner-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
    }

    .partner-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .partner-btn-styles {
        margin: 1.667vw 0px 0px 0px;
    }

    .become_partenr_btn_size svg {
        width: 50%;
        height: auto;
    }

    /* .dot_img_size svg circle{
        width:"7px";
        height:"28px"
    } */
    /* partner ends */
    @media only screen and (max-width: 1110px) {

        /* partner starts */
        .partner_become_a_partner_btn_div {
            width: 80% !important;
        }

        /* partner ends */

    }

}

@media only screen and (min-width: 1441px) {

    /* partner start */
    .partner {
        background: #FFFFFF;
        aspect-ratio: 1440/900;
    }

    .partner-left-side-content {
        grid-column: 2/ span 4;
        grid-row: 1;
        width: 101%;
        height: auto;
        margin: 216px 0px;

    }

    .partner-right-side-img {
        grid-column: 6/ span 7;
        grid-row: 1;
        width: 100%;
        height: auto;
        margin: 55px 0px 127px 0px;

    }

    .partner-left-side-content h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .partner-first-para {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .partner-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .partner-btn-styles {
        margin: 24px 0px 0px 0px;
    }

    .become_partenr_btn_size svg {
        width: 50%;
        height: auto;
    }

    /* partner end */

}