@media only screen and (max-width: 480px) {

    .cellphone_input_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding: 0px 16px;
        position: relative;
    }

    .cellphone_input_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .cellphone_input_form_right_side_img {
        display: none;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .cellphone_input_form_right_side_img img {
        width: 100%;
    }

    .cellphone_input_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: block;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .cellphone_input_form_right_side_img_mobile img {
        width: 100%;
    }

    .cellphone_input_breadcrumb {
        font-size: 3.888vw;
        font-weight: bold;
        color: white;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        gap: 2.222vw;
        margin-bottom: 4.44vw;
    }

    .cellphone_input_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .cellphone_input_breadcrumb svg {
        width: 1.111vw;
        margin-right: 0.555vw;
    }

    .cellphone_input_breadcrumb .cellphone_input_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .cellphone_input_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 10vw 4.444vw;
    }

    .cellphone_input_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.222vw;
    }

    .cellphone_input_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 6.666vw;
        font-weight: bold;
        line-height: 8.666vw;
        color: #1C1CA8;
    }

    .cellphone_input_form_step {
        font-size: 3.333vw;
        font-weight: 900;
        line-height: 5vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .cellphone_input_form_group {
        margin-bottom: 6.666vw;
        display: flex;
        flex-direction: column;
    }

    .cellphone_input_form_label {
        margin-bottom: 0.555vw;
        font-size: 3.888vw;
        line-height: 6.222vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .cellphone_input_form_label.error {
        color: #FF1200;
    }

    .cellphone_input_form_input {
        font-size: 4.444vw;
        font-weight: 450;
        line-height: 7.111vw;
        height: 18.333vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 5.555vw;
        outline: none;
        border: 0px;
    }

    .cellphone_input_form_input.error {
        border: 1px solid #FF1200;
    }

    .cellphone_input_form_error {
        font-size: 3.888vw;
        line-height: 5.833vw;
        font-weight: 500;
        margin-top: 2.222vw;
        color: #FF1200;
    }

    .cellphone_input_form_description {
        font-size: 5.555vw;
        line-height: 8.333vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .cellphone_input_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .cellphone_input_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .cellphone_input_invalid svg {
        width: 6.666vw;
        height: 6.666vw;
    }

    .cellphone_input_form_submit_btn {
        outline: none;
        height: 13.888vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 5.555vw;
        padding-right: 5.555vw;
    }

    .cellphone_input_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .cellphone_input_form_submit_btn span {
        font-size: 4.444vw;
        color: white;
        line-height: 5.555vw;
        font-weight: bold;
    }

    .cellphone_input_form_submit_btn svg {
        width: 6.111vw;
        height: 6.666vw;
    }

    .cellphone_input_extra_info {
        font-size: 3.888vw;
        line-height: 5.8338vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .cellphone_input_extra_info a {
        text-decoration: underline;
        color: #5B5B62;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* cellphone_input_form starts */
    .cellphone_input_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding: 0px 25px;
        position: relative;
        height: 64.583vw;
    }

    .cellphone_input_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 6%;
    }

    .cellphone_input_form_right_side_img {
        width: 100vw;
        position: absolute;
        bottom: 0px;
        z-index: 0;
        max-width: 1440px;
    }

    .cellphone_input_form_right_side_img img {
        width: 100%;
    }

    .cellphone_input_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .cellphone_input_form_right_side_img_mobile img {
        width: 100%;
    }

    .cellphone_input_breadcrumb {
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw;
    }

    .cellphone_input_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .cellphone_input_breadcrumb svg {
        width: 1.111vw;
        margin-right: 0.555vw;
    }

    .cellphone_input_breadcrumb .cellphone_input_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .cellphone_input_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .cellphone_input_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.555vw;
    }

    .cellphone_input_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .cellphone_input_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .cellphone_input_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .cellphone_input_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .cellphone_input_form_label.error {
        color: #FF1200;
    }

    .cellphone_input_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .cellphone_input_form_input.error {
        border: 1px solid #FF1200;
    }

    .cellphone_input_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .cellphone_input_form_description {
        font-size: 1.38vw;
        line-height: 2.083vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .cellphone_input_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .cellphone_input_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .cellphone_input_invalid svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .cellphone_input_form_submit_btn {
        outline: none;
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .cellphone_input_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .cellphone_input_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .cellphone_input_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .cellphone_input_extra_info {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .cellphone_input_extra_info a {
        text-decoration: underline;
        color: #5B5B62;
    }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* cellphone_input_form starts */

    .cellphone_input_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        height: 64.583vw;
    }

    .cellphone_input_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 6%;
    }

    .cellphone_input_form_right_side_img {
        display: block;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .cellphone_input_form_right_side_img img {
        width: 100%;
    }

    .cellphone_input_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .cellphone_input_form_right_side_img_mobile img {
        width: 100%;
    }

    .cellphone_input_breadcrumb {
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw;
    }

    .cellphone_input_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .cellphone_input_breadcrumb svg {
        width: 1.111vw;
        margin-right: 0.555vw;
    }

    .cellphone_input_breadcrumb .cellphone_input_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .cellphone_input_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .cellphone_input_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.555vw;
    }

    .cellphone_input_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .cellphone_input_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .cellphone_input_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .cellphone_input_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .cellphone_input_form_label.error {
        color: #FF1200;
    }

    .cellphone_input_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .cellphone_input_form_input.error {
        border: 1px solid #FF1200;
    }

    .cellphone_input_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .cellphone_input_form_description {
        font-size: 1.38vw;
        line-height: 2.083vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .cellphone_input_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .cellphone_input_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .cellphone_input_invalid svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .cellphone_input_form_submit_btn {
        outline: none;
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .cellphone_input_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .cellphone_input_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .cellphone_input_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .cellphone_input_extra_info {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .cellphone_input_extra_info a {
        text-decoration: underline;
        color: #5B5B62;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* cellphone_input_form starts */
    .cellphone_input_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 1.111vw;
        position: relative;
        overflow: hidden;
        height: 64.583vw;
    }

    .cellphone_input_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .cellphone_input_form_right_side_img {
        display: block;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .cellphone_input_form_right_side_img img {
        width: 100%;
    }

    .cellphone_input_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .cellphone_input_form_right_side_img_mobile img {
        width: 100%;
    }

    .cellphone_input_breadcrumb {
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw;
    }

    .cellphone_input_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .cellphone_input_breadcrumb svg {
        width: 1.111vw;
        margin-right: 0.555vw;
    }

    .cellphone_input_breadcrumb .cellphone_input_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .cellphone_input_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .cellphone_input_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.555vw;
    }

    .cellphone_input_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .cellphone_input_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .cellphone_input_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .cellphone_input_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .cellphone_input_form_label.error {
        color: #FF1200;
    }

    .cellphone_input_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .cellphone_input_form_input.error {
        border: 1px solid #FF1200;
    }

    .cellphone_input_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .cellphone_input_form_description {
        font-size: 1.38vw;
        line-height: 2.083vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .cellphone_input_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .cellphone_input_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .cellphone_input_invalid svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .cellphone_input_form_submit_btn {
        outline: none;
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .cellphone_input_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .cellphone_input_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .cellphone_input_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .cellphone_input_extra_info {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .cellphone_input_extra_info a {
        text-decoration: underline;
        color: #5B5B62;
    }
}


@media only screen and (min-width: 1441px) {

    /* cellphone_input_form starts */
    .cellphone_input_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 16px;
        height: 930px;
        overflow: hidden;
        position: relative;
    }

    .cellphone_input_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .cellphone_input_form_right_side_img {
        display: block;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .cellphone_input_form_right_side_img img {
        width: 100%;
    }

    .cellphone_input_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .cellphone_input_form_right_side_img_mobile img {
        width: 100%;
    }

    .cellphone_input_breadcrumb {
        font-size: 14px;
        font-weight: bold;
        color: white;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 36px;
    }

    .cellphone_input_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .cellphone_input_breadcrumb svg {
        width: 16px;
        margin-right: 8px;
    }

    .cellphone_input_breadcrumb .cellphone_input_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .cellphone_input_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 36px 24px;
    }

    .cellphone_input_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .cellphone_input_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 31px;
        font-weight: bold;
        line-height: 40.3px;
        color: #1C1CA8;
    }

    .cellphone_input_form_step {
        font-size: 14px;
        font-weight: 900;
        line-height: 21px;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .cellphone_input_form_group {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
    }

    .cellphone_input_form_label {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 22.4px;
        color: #1C1CA8;
        font-weight: 500;
    }

    .cellphone_input_form_label.error {
        color: #FF1200;
    }

    .cellphone_input_form_input {
        font-size: 16px;
        font-weight: 450;
        line-height: 26px;
        height: 66px;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 20px;
        outline: none;
        border: 0px;
    }

    .cellphone_input_form_input.error {
        border: 1px solid #FF1200;
    }

    .cellphone_input_form_error {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        margin-top: 8px;
        color: #FF1200;
    }

    .cellphone_input_form_description {
        font-size: 20px;
        line-height: 30px;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .cellphone_input_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .cellphone_input_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .cellphone_input_invalid svg {
        width: 24px;
        height: 24px;
    }

    .cellphone_input_form_submit_btn {
        outline: none;
        height: 66px;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .cellphone_input_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .cellphone_input_form_submit_btn span {
        font-size: 20px;
        color: white;
        line-height: 20px;
        font-weight: bold;
    }

    .cellphone_input_form_submit_btn svg {
        width: 22px;
        height: 24px;
    }

    .cellphone_input_extra_info {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .cellphone_input_extra_info a {
        text-decoration: underline;
        color: #5B5B62;
    }
}