@media only screen and (max-width: 480px) {

    /* meet_our_agent starts */
    .meet_our_agent {
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .meet_our_agent_first_div {
        position: relative;
    }


    .meet_our_agent_bg_circle {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
        top: 0;
    }

    .meet_our_agent_bg_circle::before {
        content: "";
        position: absolute;
        width: 400.277vw;
        height: 400.277vw;
        background: #1c1ca8;
        border-radius: 99999px;
        left: calc(-200.138vw + 50%);
        z-index: -1;
    }

    .left_side_yellow_rounded_img {
        position: absolute;
        width: 42.5vw;
        height: 42.5vw;
        background-color: #FFBF00;
        border-radius: 9999px;
        left: -13.611vw;
        top: -10.127vw;
        z-index: 1;
    }

    .meet_our_agent_content1 {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
        z-index: 2;
        margin-top: 25vw;
    }

    .meet_our_agent_content2_mobile_view {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        padding-top: 11.1111vw;
        padding-bottom: 11.1111vw;
        margin-left: -16px;
    }

    .meet_our_agent_content2_mobile_view img {
        width: 360px;
    }

    .meet_our_agent_content2 {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        padding-top: 13.8889vw;
        padding-bottom: 13.8889vw;
        margin-left: -6%;
        display: none;
    }

    .meet_our_agent_content2 img {
        width: 112%;
    }

    .meet_our_agent_content3 {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        margin-bottom: 16.6667vw;
    }

    .meet_our_agent_content3 img {
        width: 100%;
    }

    .meet_our_agent_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.6667vw;
    }

    .meet_our_agent_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 6.6667vw;
    }

    .meet_our_agent_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        color: #FFFFFF;
        margin-bottom: 6.6667vw;
    }

    /* .become_an_agent_size svg{
        width: 65%;
        height: auto;
        
    } */
    .meet_our_agent_become_an_agent_size svg {
        width: 65%;
        height: auto;
    }

    .modal-dialog {
        max-width: 90% !important;
        height: 138.8889vw !important;
    }

    .modal-header {
        border-bottom: none !important;
    }

    .btn-close {
        border-radius: 50%;
        background-color: #FF4600;
        opacity: 1 !important;
    }

    .modal-content {
        background-color: transparent !important;
        border: none !important;
    }

    .i_frame_styls {
        width: 100%;
        height: 200px;
    }

    .btn_become_an_agent {
        width: 60.8333vw !important;
        margin-left: 19.4444vw;
        margin-right: 19.4444vw;
    }

    /* meet_our_agent ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* meet_our_agent starts */
    /* meet_our_agent ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    @media only screen and (max-width: 600px) {

        .meet_our_agent_content2 {

            margin-left: -16px !important;
        }

    }

    /* meet_our_agent starts */
    .meet_our_agent {
        padding-bottom: 6.5104vw;
        padding-top: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .meet_our_agent_first_div {
        position: relative;
    }


    .meet_our_agent_bg_circle {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
        top: 0;
    }

    .meet_our_agent_bg_circle::before {
        content: "";
        position: absolute;
        width: 324.027vw;
        height: 324.027vw;
        background: #1c1ca8;
        border-radius: 99999px;
        left: calc(-162.013vw + 50%);
        z-index: -1;
    }

    .left_side_yellow_rounded_img {
        position: absolute;
        width: 33.263vw;
        height: 33.263vw;
        background-color: #FFBF00;
        border-radius: 9999px;
        left: -9.861vw;
        top: -9.027vw;
        z-index: 1;
    }

    .meet_our_agent_content1 {
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        z-index: 2;
    }

    .meet_our_agent_content2 {
        grid-column: 1 / span 8;
        grid-row: 2;
        width: 100%;
        padding-top: 6.5104vw;
        padding-bottom: 6.5104vw;
        margin-left: -24px;
    }

    .meet_our_agent_content2 img {
        width: 100vw;
    }

    .meet_our_agent_content3 {
        grid-column: 1 / span 8;
        grid-row: 3;
        width: 100%;
    }

    .meet_our_agent_content3 img {
        width: 100%;
    }

    .meet_our_agent_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .meet_our_agent_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .meet_our_agent_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    /* .become_an_agent_size svg {
        width: 40%;
        height: auto;
    } */
    .meet_our_agent_become_an_agent_size svg {
        width: 40%;
        height: auto;
    }

    .meet_our_agent_content2_mobile_view {
        display: none;
    }

    .modal-dialog {
        max-width: 90% !important;
        height: 500px !important;
    }

    .modal-header {
        border-bottom: none !important;
    }

    .btn-close {
        border-radius: 50%;
        background-color: #FF4600;
        opacity: 1 !important;
    }

    .modal-content {
        background-color: transparent !important;
        border: none !important;
    }

    .i_frame_styls {
        width: 100%;
        height: 350px;
    }

    /* meet_our_agent ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {


    /* meet_our_agent starts */
    .meet_our_agent {
        padding-top: 6.9444vw;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .meet_our_agent_first_div {
        position: relative;
    }


    .meet_our_agent_bg_circle {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
        top: 0;
    }

    .meet_our_agent_bg_circle::before {
        content: "";
        position: absolute;
        width: 324.027vw;
        height: 324.027vw;
        background: #1c1ca8;
        border-radius: 99999px;
        left: calc(-162.013vw + 50%);
        z-index: -1;
    }

    .left_side_yellow_rounded_img {
        position: absolute;
        width: 33.263vw;
        height: 33.263vw;
        background-color: #FFBF00;
        border-radius: 9999px;
        left: -9.861vw;
        top: -9.027vw;
        z-index: 1;
    }

    .meet_our_agent_content1 {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 97%;
        text-align: center;
        z-index: 2;
        margin-top: 5.7639vw;
        margin-left: 0.6944vw;
    }

    .meet_our_agent_content2 {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
        padding-top: 4.0972vw;
        padding-bottom: 9.8611vw;
        margin-left: -66px;
    }

    .meet_our_agent_content2 img {
        width: 100vw;
    }

    .meet_our_agent_content3 {
        grid-column: 2 / span 10;
        grid-row: 3;
        width: 100%;
        padding-bottom: 13.0556vw;
    }

    .meet_our_agent_content3 img {
        width: 100%;
    }

    .meet_our_agent_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.6667vw;
    }

    .meet_our_agent_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw;
    }

    .meet_our_agent_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw;
    }

    .meet_our_agent_become_an_agent_size svg {
        width: 28%;
        height: auto;
    }

    .modal-dialog {
        max-width: 90% !important;
        height: 500px !important;
    }

    .modal-header {
        border-bottom: none !important;
    }

    .btn-close {
        border-radius: 50%;
        background-color: #FF4600;
        opacity: 1 !important;
    }

    .modal-content {
        background-color: transparent !important;
        border: none !important;
    }

    .meet_our_agent_content2_mobile_view {
        display: none;
    }

    .i_frame_styls {
        width: 100%;
        height: 500px;
    }

    /* meet_our_agent ends */

}

@media only screen and (min-width: 1441px) {

    /* meet_our_agent starts */
    .meet_our_agent {
        padding-top: 99px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .meet_our_agent_first_div {
        position: relative;
    }


    .meet_our_agent_bg_circle {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
        top: 0;
    }

    .meet_our_agent_bg_circle::before {
        content: "";
        position: absolute;
        width: 4666px;
        height: 4666px;
        background: #1c1ca8;
        border-radius: 99999px;
        left: calc(-2333px + 50%);
        z-index: -1;
    }

    .left_side_yellow_rounded_img {
        position: absolute;
        width: 479px;
        height: 479px;
        background-color: #FFBF00;
        border-radius: 9999px;
        left: -142px;
        top: -130px;
        z-index: 1;
    }

    .meet_our_agent_content1 {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        z-index: 2;
        margin-top: 83px;
        margin-left: 10px;
    }

    .meet_our_agent_content2 {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
        padding-top: 59px;
        padding-bottom: 142px;
        margin-left: -48px;
    }


    .meet_our_agent_content3 {
        grid-column: 2 / span 10;
        grid-row: 3;
        width: 100%;
        padding-bottom: 188px;
    }

    .meet_our_agent_content3 img {
        width: 100%;
    }

    .meet_our_agent_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .meet_our_agent_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .meet_our_agent_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .meet_our_agent_become_an_agent_size svg {
        width: 28%;
        height: auto;
    }

    .modal-dialog {
        max-width: 90% !important;
        height: 500px !important;
    }

    .modal-header {
        border-bottom: none !important;
    }

    .btn-close {
        border-radius: 50%;
        background-color: #FF4600;
        opacity: 1 !important;
    }

    .modal-content {
        background-color: transparent !important;
        border: none !important;
    }

    .meet_our_agent_content2_mobile_view {
        display: none;
    }

    .i_frame_styls {
        width: 100%;
        height: 31.25vw;
    }

    /* meet_our_agent ends */
}