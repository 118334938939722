.w-100 {
    width: 100%;
}

@media only screen and (max-width: 480px) {

    /* here_help starts */
    .here_help {
        background-color: #F7F8FA;
        min-height: 265vw;
        grid-template-rows: 0fr !important;
    }

    .here_help_left_side_content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 16.667vw;
    }

    .here_help_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-top: 8.889vw;
        margin-bottom:18.9899vw;
    }

    .here_help_left_side_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.667vw;
    }

    .agent_faq_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        font-size: 2.1484vw;
    }

    .agent_faq_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    .here_help_left_side_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.667vw;
    }

    .here_help_right_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        font-size: 5vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0px;
        max-width: 77.778vw;
        width: 80%;
    }

    .faq-add {
        width: 20%;
    }

    .here_help_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .plus_img svg {
        width: 15px;
        height: 15px;
    }

    .visit_help_center svg {
        width: 65%;
        height: auto;
    }

    .here_help_right_side_content_padding {
        padding-bottom: 11.111vw !important;
        padding-top: 11.111vw !important;
        align-items: center;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .here_help_right_side_content_collapse_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .here_help_right_side_content_collapse_readmore {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 8px;
    }

    .button_action svg {
        width: 6.1111vw;
        height: 6.6667vw;
    }


    /* here_help ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* here_help starts */
    /*  .here_help {
        background-color: #F7F8FA;
        padding: 50px 16px;
    }

    .here_help_left_side_content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
    }

    .here_help_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .here_help_left_side_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 11px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .here_help_left_side_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 35px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .here_help_right_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0px;
    }

    .here_help_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .plus_img svg {
        width: 15px;
        height: 15px;
    }

    .visit_help_center svg {
        width: 65%;
        height: auto;
    }
    .here_help_right_side_content_padding{
        padding-bottom: 1.25rem!important;
        padding-top: 1.25rem!important;
        align-items: center;
    }

    .button_action svg {
        width: 22px;
        height: 24px;
    }

    /* here_help ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* here_help starts */
    .here_help {
        background-color: #F7F8FA;
    }

    .here_help_left_side_content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 6.6406vw;
        margin-bottom: 6.6406vw;
    }

    .here_help_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 6.6406vw;
        margin-bottom: 6.25vw;
    }

    .here_help_left_side_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .agent_faq_left_side_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        font-size: 2.1484vw;
    }

    .agent_faq_right_side_content {
        grid-column: 1 / span 8;
        grid-row: 2;
    }

    .here_help_left_side_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .here_help_right_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0px;
        max-width: 77.778vw;
        width: 80%;
        font-size: 4.1666vw;
    }

    .faq-add {
        width: 20%;
    }

    .here_help_btn_styles button {
        background-color: #FF4600;
        border-radius: 3.2552vw;
        border: none;
        padding: 1.9531vw 4.5572vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 2.0833vw;
        line-height: 2.6041vw;
        color: #FFFFFF;
        margin: 1.9531vw 0px;
    }

    .plus_img svg {
        width: 2.3438vw;
        height: 2.3438vw;
    }

    .visit_help_center svg {
        width: 65%;
        height: auto;
    }

    .here_help_right_side_content_padding {
        padding-bottom: 5.2083vw !important;
        padding-top: 5.2083vw !important;
        align-items: center;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .here_help_right_side_content_collapse_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.0833vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .here_help_right_side_content_collapse_readmore {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 1.0416vw;
    }

    .button_action svg {
        width: 1.528vw;
        height: 1.667vw;
    }

    /* here_help ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* here_help starts */
    .here_help {
        background-color: #F7F8FA;
        /* height: 62.500vw; */
    }

    .here_help_left_side_content {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
        margin-top: 12.153vw;
        margin-left: 0.112vw;
    }

    .here_help_right_side_content {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 100%;
        margin-top: 12.153vw;
        margin-bottom: 12.193vw;
    }

    .here_help_right_side_content_padding {
        padding-bottom: 2.778vw !important;
        padding-top: 2.778vw !important;
        align-items: center;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .here_help_right_side_content_collapse_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .here_help_right_side_content_collapse_readmore {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 8px;
    }


    .here_help_left_side_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
        font-size: 0.9114vw;
    }

    .here_help_left_side_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
        font-size: 3.125vw;
    }

    .here_help_right_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0vw;
        max-width: 77.778vw;
        width: 80%;
        font-size: 1.4285VW;
    }

    .faq-add {
        width: 20%;
    }


    .here_help_btn_styles button {
        font-family: "Circular Std Bk", "Azo Sans", sans-serif;
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
        margin-bottom: 0px;
    }

    .plus_img svg {
        width: 1.667vw;
        height: 1.667vw;
    }

    .visit_help_center svg {
        width: 65%;
        height: auto;
    }

    .button_action svg {
        width: 1.528vw;
        height: 1.667vw;
    }

    /* here_help ends */

}

@media only screen and (min-width: 1441px) {

    /* here_help starts */
    .here_help {
        background-color: #F7F8FA;
    }

    .here_help_left_side_content {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
        margin-top: 175px;
    }

    .here_help_right_side_content {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 100%;
        margin-top: 129px;
        margin-bottom: 129px;
    }

    .here_help_right_side_content_padding {
        padding-bottom: 40px !important;
        padding-top: 40px !important;
        align-items: center;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .here_help_right_side_content_collapse_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.141vw !important;
    }

    .here_help_right_side_content_collapse_readmore {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 8px;
    }

    .here_help_left_side_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 26.672px;
    }

    .here_help_left_side_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 26.672px;
    }

    .here_help_right_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0px;
        max-width: 612px;
        display: inline-block;
    }

    .faq-add {
        width: 20%;
        display: contents;
    }

    .here_help_btn_styles button {
        font-family: "Circular Std Bk", "Azo Sans", sans-serif;
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
        margin-bottom: 0px;
    }

    .plus_img svg {
        width: 18px;
        height: 18px;
    }

    .visit_help_center svg {
        width: 65%;
        height: auto;
    }

    .button_action svg {
        width: 1.528vw;
        height: 1.667vw;
    }

    /* here_help ends */

}