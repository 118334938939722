@media only screen and (max-width: 480px) {

    /* help_center_articale_the_benifits starts */
    .help_center_articale_the_benifits {
        background-color: #FFFFFF;
        padding-bottom: 30px;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 img {
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .help_center_articale_the_benifits_second_row_content {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        margin-top: 30px;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_articale_the_benifits_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* help_center_articale_the_benifits ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* help_center_articale_the_benifits starts */
    .help_center_articale_the_benifits {
        background-color: #FFFFFF;
        padding-bottom: 0px;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 {
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 img {
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .help_center_articale_the_benifits_second_row_content {
        grid-column: 1 / span 8;
        grid-row: 2;
        width: 100%;
        margin-top: 30px;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_articale_the_benifits_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* help_center_articale_the_benifits ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* help_center_articale_the_benifits starts */
    .help_center_articale_the_benifits {
        background-color: #FFFFFF;
        padding-bottom: 30px;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 {
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 img {
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .help_center_articale_the_benifits_second_row_content {
        grid-column: 2 / span 7;
        grid-row: 2;
        width: 100%;
        margin-top: 20px;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_articale_the_benifits_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* help_center_articale_the_benifits ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* help_center_articale_the_benifits starts */
    .help_center_articale_the_benifits {
        background-color: #FFFFFF;
        padding-bottom: 80px;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 {
        grid-column: 3 / span 2;
        grid-row: 1;
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 img {
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_right_side_content {
        grid-column: 7 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .help_center_articale_the_benifits_second_row_content {
        grid-column: 3 / span 8;
        grid-row: 2;
        width: 100%;
        margin-top: 80px;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_articale_the_benifits_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* help_center_articale_the_benifits ends */

}
@media only screen and (min-width: 1601px) {

    /* help_center_articale_the_benifits starts */
    .help_center_articale_the_benifits {
        background-color: #FFFFFF;
        padding-bottom: 80px;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 {
        grid-column: 3 / span 2;
        grid-row: 1;
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_left_side_img1 img {
        width: 100%;
    }

    .help_center_articale_the_benifits_first_row_right_side_content {
        grid-column: 7 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .help_center_articale_the_benifits_second_row_content {
        grid-column: 3 / span 8;
        grid-row: 2;
        width: 100%;
        margin-top: 80px;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_first_row_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .help_center_articale_the_benifits_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .help_center_articale_the_benifits_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* help_center_articale_the_benifits ends */

}