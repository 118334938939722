.about_us_empower_community_left_side_img_mobile {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* about_us_empower_community starts */
    .about_us_empower_community {
        background-color: #ffffff;
        padding-bottom: 12.2222vw;
    }

    .about_us_empower_community_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        display: none;
    }

    .about_us_empower_community_left_side_img_mobile {
        display: block;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 95.5556vw;
        margin-left: -16px;
    }

    .about_us_empower_community_left_side_img img {
        width: 100%;
    }

    .about_us_empower_community_left_side_img_mobile {
        width: 100%;
    }

    .about_us_empower_community_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
        padding-top: 11.1111vw;
    }

    .about_us_empower_community_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.6667vw;
    }

    .about_us_empower_community_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .about_us_empower_community_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.1111vw;
    }

    .about_us_empower_community_right_side_content_third_line ul {

        margin-bottom: 20px;
    }

    /* about_us_empower_community ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_empower_community starts */
    /* .about_us_empower_community {
        background-color: #ffffff;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .about_us_empower_community_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
        display: flex;
    }

    .about_us_empower_community_left_side_img img {
        width: 100%;
    }

    .about_us_empower_community_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_empower_community_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .about_us_empower_community_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .about_us_empower_community_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    } */

    /* about_us_empower_community ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* about_us_empower_community starts */
    .about_us_empower_community {
        background-color: #ffffff;
        padding-top: 11.9792vw;
        padding-bottom: 2.6041vw;
    }

    .about_us_empower_community_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
        display: flex;
    }

    .about_us_empower_community_left_side_img img {
        width: 100%;
    }

    .about_us_empower_community_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_empower_community_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.128vw;
    }

    .about_us_empower_community_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.128vw;
    }

    .about_us_empower_community_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .empower_playing_our_part_list {
        padding-bottom: 2.74vw;
    }

    .empower_playing_our_part_list ul {
        margin-bottom: 2.0835vw;
    }

    /* about_us_empower_community ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_empower_community starts */
    .about_us_empower_community {
        background-color: #ffffff;
    }

    .about_us_empower_community_left_side_img {
        grid-column: 1/span 7;
        grid-row: 1;
        width: 100%;
        margin-top: 10.4861vw;
        margin-left: -48px;
        display: flex;
    }

    .about_us_empower_community_left_side_img img {
        width: 100%;
    }

    .about_us_empower_community_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 15.2083vw;
    }

    .about_us_empower_community_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.6667vw;
    }

    .about_us_empower_community_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;
    }

    .about_us_empower_community_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* about_us_empower_community ends */

}

@media only screen and (min-width: 1441px) {

    /* about_us_empower_community starts */
    .about_us_empower_community {
        background-color: #ffffff;
        padding-top: 151px;
        padding-bottom: 197px;
    }

    .about_us_empower_community_left_side_img {
        grid-column: 1/span 7;
        grid-row: 1;
        width: 100%;
        margin-left: -48px;
        display: flex;
    }

    .about_us_empower_community_left_side_img img {
        width: 100%;
    }

    .about_us_empower_community_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 68px;
    }

    .about_us_empower_community_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .about_us_empower_community_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .about_us_empower_community_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* about_us_empower_community ends */

}