@media only screen and (max-width: 480px) {

    /* still_have_question_error starts */
    .still_have_question_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 10px 0px;
    }

    /* still_have_question_error ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* still_have_question_error starts */
    .still_have_question_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 10px 0px;
    }

    /* still_have_question_error ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* still_have_question_error starts */
    .still_have_question_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 10px 0px;
    }

    /* still_have_question_error ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* still_have_question_error starts */
    .still_have_question_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        /* font-size: 14px; */
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: auto 0vw;
        margin-left: 1.133vw;
    }

    .opps_left_sd_pic img {
        width: 1.667vw;
        height: 1.667vw;
    }

    /* still_have_question_error ends */

}

@media only screen and (min-width: 1601px) {

    /* still_have_question_error starts */
    .still_have_question_error_text_styles {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin: 10px 0px;
    }

    /* still_have_question_error ends */

}