@media only screen and (max-width: 480px) {
    .partner_sign_up_form_new {
        font-family: 'Circular Std Bk', sans-serif;
        background-color: #1C1CA8;
        padding-bottom: 20vw;
        padding-top: 4.444vw;
    }

    .partner_sign_up_form_first_row_new {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        padding-left: 6px;
        padding-right: 6px;
    }

    .partner_sign_up_form_second_row_left_side_content_new {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
    }

    .partner_sign_up_left_container {
        padding-top: 11.388vw;
    }

    .partner_sign_up_left_first_text {
        text-transform: uppercase;
        color: #FFBF00;
        font-size: 3.333vw;
        line-height: 5vw;
        margin-bottom: 6.666vw;
        font-weight: 900;
    }

    .partner_sign_up_left_second_text {
        font-family: 'Azo Sans', sans-serif;
        color: #FFF;
        font-size: 11.111vw;
        line-height: 12.222vw;
        font-weight: 900;
        margin-bottom: 6.666vw;
    }

    .partner_sign_up_left_third_text {
        color: #FFF;
        font-size: 5.555vw;
        line-height: 8.333vw;
        font-weight: 500;
        margin-bottom: 2.5vw;
    }

    .partner_sign_up_left_fourth_text {
        display: none;
        margin-top: 0px;
    }

    .partner_sign_up_left_ok_img {
        width: 49.166vw;
        height: 54.722vw;
        margin-left: auto;
    }

    .partner_sign_up_left_ok_img_desktop {
        display: none;
    }

    .partner_sign_up_left_ok_img_mobile {
        display: block;
    }

    .partner_sign_up_left_ok_img_container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 6.666vw;
    }

    .partner_sign_up_form_second_row_right_side_content_new {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        margin-left: -8px;
        width: calc(100% + 16px);
    }

    .partner_sign_up_breadcrumb {
        font-size: 3.888vw;
        font-weight: bold;
        color: white;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        gap: 2.222vw;
        margin-bottom: 0px;
    }

    .partner_sign_up_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .partner_sign_up_breadcrumb svg {
        width: 4.444vw;
        margin-right: 2.222vw;
    }

    .partner_sign_up_breadcrumb .partner_sign_up_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .partner_sign_up_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 10vw 4.444vw;
    }

    .partner_sign_up_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6.666vw;
    }

    .partner_sign_up_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 6.666vw;
        font-weight: bold;
        line-height: 8.666vw;
        color: #1C1CA8;
    }

    .partner_sign_up_form_group {
        margin-bottom: 6.666vw;
        display: flex;
        flex-direction: column;
    }

    .partner_sign_up_form_label {
        margin-bottom: 0.555vw;
        font-size: 3.888vw;
        line-height: 6.222vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .partner_sign_up_form_label.error {
        color: #FF1200;
    }

    .partner_sign_up_form_input {
        font-size: 4.444vw;
        font-weight: 450;
        line-height: 7.111vw;
        height: 18.333vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 5.555vw;
        outline: none;
        border: 0px;
    }

    .partner_sign_up_form_input.error {
        border: 1px solid #FF1200;
    }

    .partner_sign_up_form_error {
        font-size: 3.888vw;
        line-height: 5.833vw;
        font-weight: 500;
        margin-top: 2.222vw;
        color: #FF1200;
    }

    .partner_sign_up_form_submit_btn {
        height: 13.888vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 5.555vw;
        padding-right: 5.555vw;
    }

    .partner_sign_up_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .partner_sign_up_form_submit_btn span {
        font-size: 4.444vw;
        color: white;
        line-height: 5.555vw;
        font-weight: bold;
    }

    .partner_sign_up_form_submit_btn svg {
        width: 6.111vw;
        height: 6.666vw;
    }

    .partner_sign_up_thank_you_img {
        width: 44.44vw;
        height: 38.88vw;
        margin: auto;
    }

    .partner_sign_up_thank_you_text {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 11.111vw;
        line-height: 12.222vw;
        font-weight: 900;
        text-align: center;
    }

    .partner_sign_up_thank_you_description {
        color: #5B5B62;
        font-size: 4.444vw;
        line-height: 6.666vw;
        font-weight: 500;
        text-align: center;
    }

    .partner_sign_up_form_recaptcha_container {
        transform: scale(1);
        width: 10px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
    .partner_sign_up_form_new {
        font-family: 'Circular Std Bk', sans-serif;
        background-color: #1C1CA8;
        padding-bottom: 3.611vw;
        padding-top: 1.111vw;
    }

    .partner_sign_up_form_first_row_new {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content_new {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .partner_sign_up_left_container {
        padding-top: 12.847vw;
    }

    .partner_sign_up_left_first_text {
        text-transform: uppercase;
        color: #FFBF00;
        font-size: 0.972vw;
        line-height: 1.458vw;
        margin-bottom: 1.666vw;
        font-weight: 900;
    }

    .partner_sign_up_left_second_text {
        font-family: 'Azo Sans', sans-serif;
        color: #FFF;
        font-size: 4.236vw;
        line-height: 4.659vw;
        font-weight: 900;
        margin-bottom: 1.666vw;
    }

    .partner_sign_up_left_third_text {
        color: #FFF;
        font-size: 1.388vw;
        line-height: 2.083vw;
        font-weight: 500;
        margin-bottom: 4.722vw;
    }

    .partner_sign_up_left_fourth_text {
        color: #69bfed;
        line-height: 2.604vw;
        font-size: 1.736vw;
        font-weight: 700;
        margin-bottom: 1.666vw;
    }

    .partner_sign_up_left_ok_img {
        width: 12.291vw;
        height: 13.680vw;
        margin-left: 12.430vw;
    }

    .partner_sign_up_left_ok_img_desktop {
        display: block;
    }

    .partner_sign_up_left_ok_img_mobile {
        display: none;
    }

    .partner_sign_up_left_ok_img_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .partner_sign_up_form_second_row_right_side_content_new {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_breadcrumb {
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 0px;
    }

    .partner_sign_up_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .partner_sign_up_breadcrumb svg {
        width: 1.111vw;
        margin-right: 0.555vw;
    }

    .partner_sign_up_breadcrumb .partner_sign_up_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .partner_sign_up_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .partner_sign_up_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .partner_sign_up_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .partner_sign_up_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .partner_sign_up_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .partner_sign_up_form_label.error {
        color: #FF1200;
    }

    .partner_sign_up_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .partner_sign_up_form_input.error {
        border: 1px solid #FF1200;
    }

    .partner_sign_up_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .partner_sign_up_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .partner_sign_up_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .partner_sign_up_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .partner_sign_up_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .partner_sign_up_thank_you_img {
        width: 14.37vw;
        height: 11.52vw;
        margin: auto;
    }

    .partner_sign_up_thank_you_text {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 3.333vw;
        line-height: 3.666vw;
        font-weight: 900;
        text-align: center;
    }

    .partner_sign_up_thank_you_description {
        color: #5B5B62;
        font-size: 1.111vw;
        line-height: 1.777vw;
        font-weight: 400;
        text-align: center;
    }

    .partner_sign_up_form_recaptcha_container {
        width: 10px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {
    .partner_sign_up_form_new {
        font-family: 'Circular Std Bk', sans-serif;
        background-color: #1C1CA8;
        padding-bottom: 3.611vw;
        padding-top: 1.111vw;
    }

    .partner_sign_up_form_first_row_new {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content_new {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .partner_sign_up_left_container {
        padding-top: 12.847vw;
    }

    .partner_sign_up_left_first_text {
        text-transform: uppercase;
        color: #FFBF00;
        font-size: 0.972vw;
        line-height: 1.458vw;
        margin-bottom: 1.666vw;
        font-weight: 900;
    }

    .partner_sign_up_left_second_text {
        font-family: 'Azo Sans', sans-serif;
        color: #FFF;
        font-size: 4.236vw;
        line-height: 4.659vw;
        font-weight: 900;
        margin-bottom: 1.666vw;
    }

    .partner_sign_up_left_third_text {
        color: #FFF;
        font-size: 1.388vw;
        line-height: 2.083vw;
        font-weight: 500;
        margin-bottom: 4.722vw;
    }

    .partner_sign_up_left_fourth_text {
        color: #69bfed;
        line-height: 2.604vw;
        font-size: 1.736vw;
        font-weight: 700;
        margin-bottom: 1.666vw;
    }

    .partner_sign_up_left_ok_img {
        width: 12.291vw;
        height: 13.680vw;
        margin-left: 12.430vw;
    }

    .partner_sign_up_left_ok_img_desktop {
        display: block;
    }

    .partner_sign_up_left_ok_img_mobile {
        display: none;
    }

    .partner_sign_up_left_ok_img_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .partner_sign_up_form_second_row_right_side_content_new {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_breadcrumb {
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 0px;
    }

    .partner_sign_up_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .partner_sign_up_breadcrumb svg {
        width: 1.111vw;
        margin-right: 0.555vw;
    }

    .partner_sign_up_breadcrumb .partner_sign_up_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .partner_sign_up_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .partner_sign_up_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .partner_sign_up_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .partner_sign_up_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .partner_sign_up_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .partner_sign_up_form_label.error {
        color: #FF1200;
    }

    .partner_sign_up_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .partner_sign_up_form_input.error {
        border: 1px solid #FF1200;
    }

    .partner_sign_up_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .partner_sign_up_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .partner_sign_up_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .partner_sign_up_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .partner_sign_up_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .partner_sign_up_thank_you_img {
        width: 14.37vw;
        height: 11.52vw;
        margin: auto;
    }

    .partner_sign_up_thank_you_text {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 3.333vw;
        line-height: 3.666vw;
        font-weight: 900;
        text-align: center;
    }

    .partner_sign_up_thank_you_description {
        color: #5B5B62;
        font-size: 1.111vw;
        line-height: 1.777vw;
        font-weight: 400;
        text-align: center;
    }

    .partner_sign_up_form_recaptcha_container {
        width: 10px;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .partner_sign_up_form_new {
        font-family: 'Circular Std Bk', sans-serif;
        background-color: #1C1CA8;
        padding-top: 1.111vw;
        padding-bottom: 3.611vw;
    }

    .partner_sign_up_form_first_row_new {
        grid-column: 2 / span 11;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content_new {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_left_container {
        padding-top: 12.847vw;
    }

    .partner_sign_up_left_first_text {
        text-transform: uppercase;
        color: #FFBF00;
        font-size: 0.972vw;
        line-height: 1.458vw;
        margin-bottom: 1.666vw;
        font-weight: 900;
    }

    .partner_sign_up_left_second_text {
        font-family: 'Azo Sans', sans-serif;
        color: #FFF;
        font-size: 4.236vw;
        line-height: 4.659vw;
        font-weight: 900;
        margin-bottom: 1.666vw;
    }

    .partner_sign_up_left_third_text {
        color: #FFF;
        font-size: 1.388vw;
        line-height: 2.083vw;
        font-weight: 500;
        margin-bottom: 4.722vw;
    }

    .partner_sign_up_left_fourth_text {
        color: #69bfed;
        line-height: 2.604vw;
        font-size: 1.736vw;
        font-weight: 700;
        margin-bottom: 1.666vw;
    }

    .partner_sign_up_left_ok_img {
        width: 12.291vw;
        height: 13.680vw;
        margin-left: 12.430vw;
    }

    .partner_sign_up_left_ok_img_desktop {
        display: block;
    }

    .partner_sign_up_left_ok_img_mobile {
        display: none;
    }

    .partner_sign_up_left_ok_img_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .partner_sign_up_form_second_row_right_side_content_new {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_breadcrumb {
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 0px;
    }

    .partner_sign_up_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .partner_sign_up_breadcrumb svg {
        width: 1.111vw;
        margin-right: 0.555vw;
    }

    .partner_sign_up_breadcrumb .partner_sign_up_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .partner_sign_up_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .partner_sign_up_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .partner_sign_up_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .partner_sign_up_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .partner_sign_up_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .partner_sign_up_form_label.error {
        color: #FF1200;
    }

    .partner_sign_up_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .partner_sign_up_form_input.error {
        border: 1px solid #FF1200;
    }

    .partner_sign_up_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .partner_sign_up_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .partner_sign_up_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .partner_sign_up_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .partner_sign_up_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .partner_sign_up_thank_you_img {
        width: 14.37vw;
        height: 11.52vw;
        margin: auto;
    }

    .partner_sign_up_thank_you_text {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 3.333vw;
        line-height: 3.666vw;
        font-weight: 900;
        text-align: center;
    }

    .partner_sign_up_thank_you_description {
        color: #5B5B62;
        font-size: 1.111vw;
        line-height: 1.777vw;
        font-weight: 400;
        text-align: center;
    }

    .partner_sign_up_form_recaptcha_container {
        width: 10px;
    }
}


@media only screen and (min-width: 481px) and (max-width: 630px) {
    .partner_sign_up_form_recaptcha_container {
        transform: scale(0.46)
    }
}

@media only screen and (min-width: 631px) and (max-width: 865px) {
    .partner_sign_up_form_recaptcha_container {
        transform: scale(0.64);
    }
}

@media only screen and (min-width: 866px) and (max-width: 930px) {
    .partner_sign_up_form_recaptcha_container {
        transform: scale(0.6)
    }
}

@media only screen and (min-width: 931px) and (max-width: 1114px) {
    .partner_sign_up_form_recaptcha_container {
        transform: scale(0.80)
    }
}

@media only screen and (min-width: 1114px) and (max-width: 1440px) {
    .partner_sign_up_form_recaptcha_container {
        transform: scale(0.77);
    }
}

@media only screen and (min-width: 1441px) {
    .partner_sign_up_form_new {
        font-family: 'Circular Std Bk', sans-serif;
        background-color: #1C1CA8;
        padding-top: 16px;
        padding-bottom: 52px;
    }

    .partner_sign_up_form_first_row_new {
        grid-column: 2 / span 11;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_second_row_left_side_content_new {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_left_container {
        padding-top: 185px;
    }

    .partner_sign_up_left_first_text {
        text-transform: uppercase;
        color: #FFBF00;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 24px;
        font-weight: 900;
    }

    .partner_sign_up_left_second_text {
        font-family: 'Azo Sans', sans-serif;
        color: #FFF;
        font-size: 61px;
        line-height: 67.1px;
        font-weight: 900;
        margin-bottom: 24px;
    }

    .partner_sign_up_left_third_text {
        color: #FFF;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 68px;
    }

    .partner_sign_up_left_fourth_text {
        color: #69bfed;
        font-size: 25px;
        line-height: 37.5px;
        font-weight: 700;
        margin-bottom: 24px;
    }

    .partner_sign_up_left_ok_img {
        width: 177px;
        height: 197px;
        margin-left: 179px;
    }

    .partner_sign_up_left_ok_img_desktop {
        display: block;
    }

    .partner_sign_up_left_ok_img_mobile {
        display: none;
    }

    .partner_sign_up_left_ok_img_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .partner_sign_up_form_second_row_right_side_content_new {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_breadcrumb {
        font-size: 14px;
        font-weight: bold;
        color: white;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0px;
    }

    .partner_sign_up_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .partner_sign_up_breadcrumb svg {
        width: 16px;
        margin-right: 8px;
    }

    .partner_sign_up_breadcrumb .partner_sign_up_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .partner_sign_up_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 36px 24px;
    }

    .partner_sign_up_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .partner_sign_up_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 31px;
        font-weight: bold;
        line-height: 40.3px;
        color: #1C1CA8;
    }

    .partner_sign_up_form_group {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
    }

    .partner_sign_up_form_label {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 22.4px;
        color: #1C1CA8;
        font-weight: 500;
    }

    .partner_sign_up_form_label.error {
        color: #FF1200;
    }

    .partner_sign_up_form_input {
        font-size: 16px;
        font-weight: 450;
        line-height: 25.6px;
        height: 66px;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 20px;
        outline: none;
        border: 0px;
    }

    .partner_sign_up_form_input.error {
        border: 1px solid #FF1200;
    }

    .partner_sign_up_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .partner_sign_up_form_submit_btn {
        height: 66px;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .partner_sign_up_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .partner_sign_up_form_submit_btn span {
        font-size: 20px;
        color: white;
        line-height: 20px;
        font-weight: bold;
    }

    .partner_sign_up_form_submit_btn svg {
        width: 22px;
        height: 24px;
    }

    .partner_sign_up_thank_you_img {
        width: 207px;
        height: 166px;
        margin: auto;
    }

    .partner_sign_up_thank_you_text {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 48px;
        line-height: 52.8px;
        font-weight: 900;
        text-align: center;
    }

    .partner_sign_up_thank_you_description {
        color: #5B5B62;
        font-size: 16px;
        line-height: 25.6px;
        font-weight: 400;
        text-align: center;
    }

    .partner_sign_up_form_recaptcha_container {
        width: 10px;
        transform: scale(1);
    }
}