@media only screen and (max-width: 480px) {

    /* help_center_header_cards starts */
    .help_center_header_cards {
        background-color: #1c1ca8;
        padding-bottom: 6.667vw;
        padding-top: 7.911vw;
    }

    .help_center_header_cards_row {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.666vw;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_header_cards_first_row_cards {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .help_center_header_cards_second_row_cards {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .help_center_card_icon_div {
        border-radius: 9999px;
        width: 17.777vw;
        height: 17.777vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .help_center_card_icon_div img {
        width: 8.888vw;
        height: 8.888vw;
    }

    .help_center_card_read_more {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 2.222vw;
    }

    .help_center_card_div {
        background-color: #ffffff;
        border-radius: 15px;
        padding: 6.833vw;
        margin-bottom: 6.711vw;
        width: 100%;
    }

    .help_center_card_div_mobile {
        margin-bottom: 11.1111vw;
    }

    .help_center_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 5.557vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-top: 4.622vw;
        margin-bottom: 1.211vw;
    }

    .help_center_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 4.516vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 0vw !important;
    }

    /* help_center_header_cards ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* help_center_header_cards starts */
    /* .help_center_header_cards {
        background-color: #1c1ca8;
        padding-bottom: 50px;
    }

    .help_center_header_cards_row {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.666vw;
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .help_center_header_cards_first_row_cards {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        display: flex;
        margin: 46px auto;
    }

    .help_center_header_cards_second_row_cards {
        grid-column: 1 / span 8;
        grid-row: 2;
        width: 100%;
        display: flex;
    }

    .help_center_card_icon_div {
        border-radius: 9999px;
        width: 4.444vw;
        height: 4.444vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .help_center_card_icon_div img {
        width: 2.236vw;
        height: 2.236vw;
    }

    .help_center_card_read_more {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 2.222vw;
    }

    .help_center_card_div {
        background-color: #ffffff;
        border-radius: 15px;
        margin: 0px auto;
        width: 100%;
        padding: 25px;
    }

    .help_center_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin: 15px 0px;
    }

    .help_center_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    } */

    /* help_center_header_cards ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* help_center_header_cards starts */
    .help_center_header_cards {
        background-color: #1c1ca8;
        padding-bottom: 3.9062vw;
    }

    .help_center_header_cards_row {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.666vw;
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .help_center_header_cards_first_row_cards {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        display: flex;
    }

    .help_center_header_cards_second_row_cards {
        grid-column: 1 / span 8;
        grid-row: 2;
        width: 100%;
        display: flex;
    }

    .help_center_card_icon_div {
        border-radius: 9999px;
        width: 7.441vw;
        height: 7.441vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .help_center_card_icon_div img {
        width: 3.720vw;
        height: 3.720vw;
    }

    .help_center_card_read_more {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 0.93vw;
    }

    .help_center_card_div {
        background-color: #ffffff;
        border-radius: 1.9531vw;
        margin: 1.9531vw auto;
        width: 100%;
        padding: 3.2552vw;
    }

    .help_center_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 3.125vw;
        margin-top: 3.125vw;

    }

    .help_center_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.0833vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* help_center_header_cards ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* help_center_header_cards starts */
    .help_center_header_cards {
        background-color: #1c1ca8;
        padding-bottom: 7.027vw;
    }

    .help_center_header_cards_row {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.666vw;
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
    }

    .help_center_header_cards_first_row_cards {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
        display: flex;
        margin-bottom: 1.681vw;
        justify-content: space-between;
    }

    .help_center_header_cards_second_row_cards {
        grid-column: 2 / span 10;
        grid-row: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .help_center_card_icon_div {
        border-radius: 9999px;
        width: 4.444vw;
        height: 4.444vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .help_center_card_icon_div img {
        width: 2.236vw;
        height: 2.236vw;
    }

    .help_center_card_read_more {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 0.555vw;
    }

    .help_center_card_div {
        background-color: #ffffff;
        border-radius: 15px;
        /* margin: 0vw 1.600vw 0vw 0vw; */
        width: 100%;
        min-height: 16.667vw;
        padding: 1.733vw;
        justify-content: space-between 24px;
    }

    .help_center_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        /* font-size: 20px; */
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-top: 1.161vw;
        margin-bottom: 0.311vw;
    }

    .help_center_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.1111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin: 0px !important;
        padding: 0px !important;
    }

    /* help_center_header_cards ends */

}

@media only screen and (min-width: 1441px) {

    /* help_center_header_cards starts */
    .help_center_header_cards {
        background-color: #1c1ca8;
        padding-bottom: 101.1888px;
    }

    .help_center_header_cards_row {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1.666vw;
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
    }

    .help_center_header_cards_first_row_cards {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
        display: flex;
        margin-bottom: 24px;
        justify-content: space-between;
    }

    .help_center_header_cards_second_row_cards {
        grid-column: 2 / span 10;
        grid-row: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .help_center_card_icon_div {
        border-radius: 9999px;
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .help_center_card_icon_div img {
        width: 32px;
        height: 32px;
    }

    .help_center_card_read_more {
        color: #1c1ca8;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
        margin-left: 8px;
    }

    .help_center_card_div {
        background-color: #ffffff;
        border-radius: 15px;
        /* margin: 0vw 1.600vw 0vw 0vw; */
        width: 100%;
        min-height: 240px;
        padding: 25px;
        justify-content: space-between 24px;
    }

    .help_center_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        /* font-size: 20px; */
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-top: 16.7184px;
        margin-bottom: 4.4784px;
    }

    .help_center_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin: 0px !important;
        padding: 0px !important;
    }

    /* help_center_header_cards ends */

}