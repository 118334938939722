.become_a_partner_powerful_sales_tool_right_side_img_mobile{
    display:none;
}
@media only screen and (max-width: 480px) {

    /* become_a_partner_powerful_sales_tool starts */
    .become_a_partner_powerful_sales_tool {
        background-color: #FFFFFF;
        position: relative;
    }

    .become_a_partner_powerful_sales_tool_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 312px;
        /* padding-top: 70px; */
    }

    .become_a_partner_powerful_sales_tool_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-top:60px;
        margin-bottom: 24px;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .become_a_partner_powerful_sales_tool_right_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
        display: none;
    }

    .become_a_partner_powerful_sales_tool_right_side_img img {
        width: 100%;
    }

    .become_a_partner_powerful_sales_tool_ok_img {
        grid-column: 3 / span 2;
        grid-row: 1;
        width: 100%;
        position: absolute;
        left: 30%;
        bottom: -1%;
    }

    .become_a_partner_powerful_sales_tool_ok_img img {
        width: 75%;
    }
    .become_a_partner_powerful_sales_tool_right_side_mobile_view_img{
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-top: 4.4444vw;
        margin-left: 0px;
    }
    .become_a_partner_powerful_sales_tool_right_side_mobile_view_img img{
         width: calc(100vw - 16px);
    }
    /* become_a_partner_powerful_sales_tool ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* become_a_partner_powerful_sales_tool starts */
    /* .become_a_partner_powerful_sales_tool {
        background-color: #FFFFFF;
        padding-bottom: 50px;
        position: relative;
    }

    .become_a_partner_powerful_sales_tool_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
       
    }

    .become_a_partner_powerful_sales_tool_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .become_a_partner_powerful_sales_tool_right_side_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
        margin-left: 8%;
    }

    .become_a_partner_powerful_sales_tool_right_side_img img {
        width: 100%;
    }

    .become_a_partner_powerful_sales_tool_ok_img {
        grid-column: 5 / span 3;
        grid-row: 2;
        width: 100%;
        position: absolute;
        left: 24%;
        bottom: -6%;
    }

    .become_a_partner_powerful_sales_tool_ok_img img {
        width: 100%;
    }
    .become_a_partner_powerful_sales_tool_right_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_powerful_sales_tool_ok_img{
        display: none;
    }
    /* become_a_partner_powerful_sales_tool ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    @media only screen and (max-width: 600px){
        .become_a_partner_powerful_sales_tool_right_side_img{
            margin-left: 16px!important;
        }
    }
    /* become_a_partner_powerful_sales_tool starts */
    .become_a_partner_powerful_sales_tool {
        background-color: #FFFFFF;        position: relative;
    }

    .become_a_partner_powerful_sales_tool_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 108px;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .become_a_partner_powerful_sales_tool_right_side_img {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 19.5313vw;
        margin-left: 24px;
    }

    .become_a_partner_powerful_sales_tool_right_side_img img {
        width: 100%;
    }

    .become_a_partner_powerful_sales_tool_ok_img {
        grid-column: 6 / span 2;
        grid-row: 1;
        width: 100%;
        position: absolute;
        left: 0%;
        bottom: -5%;
        display: none;
    }

    .become_a_partner_powerful_sales_tool_ok_img img {
        width: 100%;
    }
    .become_a_partner_powerful_sales_tool_right_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_powerful_sales_tool_ok_img{
        display: none;
    }
    /* become_a_partner_powerful_sales_tool ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* become_a_partner_powerful_sales_tool starts */
    .become_a_partner_powerful_sales_tool {
        background-color: #FFFFFF;
        position: relative;
    }

    .become_a_partner_powerful_sales_tool_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 16.3194vw;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.6667vw;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;

    }

    .become_a_partner_powerful_sales_tool_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        
    }

    .become_a_partner_powerful_sales_tool_right_side_img {
        grid-column: 6 / span 7;
        grid-row: 1;
        width: 100%;
        margin-top: 7.7778vw;
    }

    .become_a_partner_powerful_sales_tool_right_side_img img {
        width: 54.5833vw;
        margin-left: 2.5vw;
    }

    .become_a_partner_powerful_sales_tool_ok_img {
        grid-column: 11 / span 2;
        grid-row: 1;
        width: 100%;
        position: absolute;
        left: 0%;
        bottom: -5%;
    }

    .become_a_partner_powerful_sales_tool_ok_img img {
        width: 100%;
    }
    .become_a_partner_powerful_sales_tool_right_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_powerful_sales_tool_ok_img{
        display: none;
    }
    /* become_a_partner_powerful_sales_tool ends */

}
@media only screen and (min-width: 1440px) {

    /* become_a_partner_powerful_sales_tool starts */
    .become_a_partner_powerful_sales_tool {
        background-color: #FFFFFF;
        position: relative;
    }

    .become_a_partner_powerful_sales_tool_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 235px;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .become_a_partner_powerful_sales_tool_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;

    }

    .become_a_partner_powerful_sales_tool_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        
    }

    .become_a_partner_powerful_sales_tool_right_side_img {
        grid-column: 6 / span 7;
        grid-row: 1;
        margin-top: 112px;
    }

    .become_a_partner_powerful_sales_tool_right_side_img img {
        margin-left: 2.5vw;
    }

    .become_a_partner_powerful_sales_tool_ok_img {
        grid-column: 11 / span 2;
        grid-row: 1;
        position: absolute;
        left: 0%;
        bottom: -5%;
    }

    .become_a_partner_powerful_sales_tool_right_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_powerful_sales_tool_ok_img{
        display: none;
    }
    /* become_a_partner_powerful_sales_tool ends */

}
@media only screen and (min-width: 1441px) {

    .become_a_partner_powerful_sales_tool_right_side_img img {
        width: 100%;
    }

}