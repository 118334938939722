.ready-to-become-sales-agent-img-mobile {
    display: none;
}

.ready-to-become-sales-agent-img-tablet2 {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* ready-to-become-sales-agent */
    .ready-to-become-sales-agent {
        background-color: #055CF5;
        height: 137.778vw;
    }

    .ready-to-become-sales-agent-heading {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .ready-to-become-sales-agent-font-styles {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top: 16.667vw;
        margin-bottom: 6.667vw;
        padding-left: 2.222vw;
        padding-right: 2.222vw;
    }

    .ready-to-become-sales-agent-img {
        grid-column: 1 / span 4;
        grid-row: 2;
        text-align: center;
        height: 50.556vw;
        align-self: end;
        display: none;
    }

    .ready-to-become-sales-agent-img-mobile {
        grid-column: 1 / span 4;
        grid-row: 2;
        text-align: center;
        align-self: end;
        display: block;
        margin-left: -4.722vw;
    }

    .ready-to-become-sales-agent-img img {
        width: 90.833vw;
    }

    .ready-to-become-sales-agent-img-mobile img {
        width: 90.833vw;
        height: 52.444vw;
    }

    .ready-to-become-sales-agent-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 7px 12px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .apply_now_btn_size svg {
        width: 60%;
        height: auto;
        aspect-ratio: 213/50;
    }

    .start_application_btn_size svg {
        width: 100%;
        height: auto;
    }

    .carry_on_where_i_left_off_btn_size svg {
        width: 100%;
        height: auto;
    }

    .apply_now_btm_mrg{
        margin: 0vw auto;
        margin-bottom: 11.311vw;
    }

    /* ready-to-become-sales-agent */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* ready-to-become-sales-agent */
    /* .ready-to-become-sales-agent {
        padding-top: 10%;
        background-color: #055CF5;
    }

    .ready-to-become-sales-agent-heading {
        grid-column: 2 / span 6;
        grid-row: 1;
        text-align: center;
    }

    .ready-to-become-sales-agent-font-styles {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 35px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .ready-to-become-sales-agent-img {
        grid-column: 2 / span 6;
        grid-row: 2;
        text-align: center;
        padding-top: 10%;
    }

    .ready-to-become-sales-agent-img img {
        width: 106%;
    }

    .ready-to-become-sales-agent-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 7px 12px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .apply_now_btn_size svg {
        width: 45.8%;
        height: auto;
        aspect-ratio: 213/50;
    }

    .start_application_btn_size svg {
        width: 100%;
        height: auto;
    }

    .carry_on_where_i_left_off_btn_size svg {
        width: 100%;
        height: auto;
    }

    /* ready-to-become-sales-agent */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* ready-to-become-sales-agent */
    .ready-to-become-sales-agent {
        background-color: #055CF5;
    }

    .ready-to-become-sales-agent-heading {
        grid-column: 2 / span 6;
        grid-row: 1;
        text-align: center;
        margin-top: 7.161vw;
        margin-left: 4.167vw;
        width: 61.198vw;
    }

    .ready-to-become-sales-agent-font-styles {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .ready-to-become-sales-agent-img {
        grid-column: 2 / span 6;
        grid-row: 2;
        text-align: center;
        padding-top: 10%;
        display: none;
    }

    .ready-to-become-sales-agent-img-tablet2 {
        grid-column: 2 / span 6;
        grid-row: 2;
        text-align: center;
        margin-top: 9.375vw;
        display: block;
        width: 50.911vw;
        height: 30.859vw;
        justify-self: center;
        margin-left: -1.563vw;
    }

    .ready-to-become-sales-agent-img img {
        width: 90.833vw;
    }

    .ready-to-become-sales-agent-img-tablet2 img {
        width: 50.91vw;
    }

    .ready-to-become-sales-agent-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 7px 12px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .apply_now_btn_size svg {
        width: 45.5%;
        height: auto;
        aspect-ratio: 213/50;
    }
    .apply_now_btm_mrg{
        margin: 0vw auto;
    }

    /* ready-to-become-sales-agent */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    @media only screen and (max-width: 1110px) {

        /* ready-to-become-sales-agent starts */
        .ready_to_become_sales_agent_apply_now_btn_div {
            width: 36% !important;
        }

        /* ready-to-become-sales-agent ends */
    }

    /* ready-to-become-sales-agent */
    .ready-to-become-sales-agent {
        background-color: #055CF5;
    }

    .ready-to-become-sales-agent-heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        text-align: center;
        padding-top: 11.181vw;
    }

    .ready-to-become-sales-agent-font-styles {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.717vw;
    }
    .apply_now_btm_mrg{
        margin: 0vw auto;
        margin-bottom: 9.155vw;
    }

    .ready-to-become-sales-agent-img {
        grid-column: 3 / span 8;
        grid-row: 2;
        text-align: center;
        width: 51.111vw;
        height: 28.472vw;
        margin-left: 4.583vw;
        align-self: end;

    }

    .ready-to-become-sales-agent-img img {
        width: 51.112vw;
    }

    .ready-to-become-sales-agent-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 13px 39px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .apply_now_btn_size svg {
        width: 36.8%;
        height: auto;
        aspect-ratio: 213/50;
    }

    .btn_dispaly_flex {
        display: flex;
    }

    /* ready-to-become-sales-agent */

}

@media only screen and (min-width: 1441px) {

    /* ready-to-become-sales-agent */
    .ready-to-become-sales-agent {
        aspect-ratio: 1440/900;
        background-color: #055CF5;
    }

    .ready-to-become-sales-agent-heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        text-align: center;
        padding-top: 161px;
    }

    .ready-to-become-sales-agent-font-styles {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .ready-to-become-sales-agent-img {
        grid-column: 3 / span 8;
        grid-row: 2;
        text-align: center;
        align-self: end;
    }

    .ready-to-become-sales-agent-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 13px 39px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .apply_now_btn_size svg {
        /* width: 213px;
        height: 50px; */
        width: 36.8%;
        height: auto;
        aspect-ratio: 213/50;
    }

    .start_application_btn_size svg {
        width: 213px;
        height: 50px;
    }

    .carry_on_where_i_left_off_btn_size svg {
        width: 262px;
        height: 50px;
    }

    .btn_dispaly_flex {
        display: flex;
    }

    .apply_now_btm_mrg{
        margin: 0vw auto;
        margin-bottom: 141px;
    }

}