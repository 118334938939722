@media only screen and (max-width: 480px) {

    /* become_a_partner_create_jobs starts */
    .become_a_partner_create_jobs {
        background-color: #FFFFFF;
    }

    .become_a_partner_create_jobs_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        display: none;
    }

    .become_a_partner_create_jobs_left_side_img img {
        width: 105%;
    }
    .become_a_partner_create_jobs_left_side_mobile_view_img{
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 95.5556vw;
        margin-top: 3.8889vw;
        margin-left:-4.4444vw;
    }
    .become_a_partner_create_jobs_left_side_mobile_view_img img{
        width: 100%;
    }

    .become_a_partner_create_jobs_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 86.6667vw;
        margin-top: 20.2778vw;
    }

    .become_a_partner_create_jobs_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.6667vw;
    }

    .become_a_partner_create_jobs_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.6667vw;
    }

    .become_a_partner_create_jobs_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* become_a_partner_create_jobs ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* become_a_partner_create_jobs starts */
  /*  .become_a_partner_create_jobs {
        background-color: #FFFFFF;
        padding-bottom: 50px;
        padding-top: 100px;
    }
    .become_a_partner_create_jobs_left_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_create_jobs_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
        margin-left: -8%;
    }

    .become_a_partner_create_jobs_left_side_img img {
        width: 100%;
    }

    .become_a_partner_create_jobs_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .become_a_partner_create_jobs_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .become_a_partner_create_jobs_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .become_a_partner_create_jobs_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* become_a_partner_create_jobs ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    @media only screen and (max-width: 600px){
       .become_a_partner_create_jobs_left_side_img{
        margin-left: -16px!important;
    }
    }

    /* become_a_partner_create_jobs starts */
    .become_a_partner_create_jobs {
        background-color: #FFFFFF;
    }
    .become_a_partner_create_jobs_left_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_create_jobs_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 21.4844vw;
        margin-left: -24px;
    }

    .become_a_partner_create_jobs_left_side_img img {
        width: 100%;
    }

    .become_a_partner_create_jobs_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 13.0208vw;
    }

    .become_a_partner_create_jobs_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .become_a_partner_create_jobs_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .become_a_partner_create_jobs_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* become_a_partner_create_jobs ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

     /* become_a_partner_create_jobs starts */
     .become_a_partner_create_jobs {
        background-color: #FFFFFF;
    }
    .become_a_partner_create_jobs_left_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_create_jobs_left_side_img {
        grid-column: 1 / span 7;
        grid-row: 1;
        width: 52.5694vw;
        margin-top: 10.4861vw;
        margin-left: -48px;
    }

    .become_a_partner_create_jobs_left_side_img img {
        width: 100%;
    }

    .become_a_partner_create_jobs_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 15vw;
    }

    .become_a_partner_create_jobs_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.6667vw;
    }

    .become_a_partner_create_jobs_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;
    }

    .become_a_partner_create_jobs_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* become_a_partner_create_jobs ends */

}
@media only screen and (min-width: 1441px) {

      /* become_a_partner_create_jobs starts */
     .become_a_partner_create_jobs {
        background-color: #FFFFFF;
    }
    .become_a_partner_create_jobs_left_side_mobile_view_img{
        display: none;
    }
    .become_a_partner_create_jobs_left_side_img {
        grid-column: 1 / span 7;
        grid-row: 1;
        width: 757px;
        margin-top: 151px;
        margin-left: -48px;
    }

    .become_a_partner_create_jobs_left_side_img img {
        width: 100%;
    }

    .become_a_partner_create_jobs_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 216px;
    }

    .become_a_partner_create_jobs_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .become_a_partner_create_jobs_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .become_a_partner_create_jobs_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* become_a_partner_create_jobs ends */

}