@media only screen and (max-width: 480px) {

    /* popular_sales_agent_below_cards starts */
    .popular_sales_agent_below_cards {
        background-color: #ffffff;
        padding-top: 7.967vw;
        padding-bottom: 17.667vw;
    }

    .popular_sales_agent_below_cards_row {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .popular_sales_agent_below_card_div {
        width: 100%;
        margin: 15px auto;
    }

    .popular_sales_agent_below_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-top: 11.033vw;
        margin-bottom: 2.311vw !important;
    }

    .popular_sales_agent_below_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .popular_sales_agent_below_card_div_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .left_right_arrow_imgs {
        text-align: center;
        margin-top: 11.355vw;
    }

    .left_side_arrow {
        margin-right: 4.611vw;
    }

    .left_side_arrow svg {
        width: 6.67vw;
        height: 6.744vw;
    }

    .right_side_arrow {
        width: 6.67vw;
        height: 6.744vw;
    }

    /* popular_sales_agent_below_cards ends  */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* popular_sales_agent_below_cards starts */
    /* .popular_sales_agent_below_cards {
        background-color: #ffffff;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .popular_sales_agent_below_cards_row {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        display: flex;
    }

    .popular_sales_agent_below_card_div {
        width: 30%;
        margin: 15px auto;
    }

    .popular_sales_agent_below_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .popular_sales_agent_below_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .popular_sales_agent_below_card_div_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .left_right_arrow_imgs {
        display: none;
    } */

    /* popular_sales_agent_below_cards ends  */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* popular_sales_agent_below_cards starts */
    .popular_sales_agent_below_cards {
        background-color: #ffffff;
        padding-top: 3.9062vw;
        padding-bottom: 3.9062vw;
    }

    .popular_sales_agent_below_cards_row {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        display: flex;
    }

    .popular_sales_agent_below_card_div {
        width: 30%;
        margin: 1.9531vw auto;
    }

    .popular_sales_agent_below_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .popular_sales_agent_below_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 3.2552vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom:3.125vw;
    }

    .popular_sales_agent_below_card_div_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.0833vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom:3.125vw;
    }

    .left_right_arrow_imgs {
        display: none;
    }

    /* popular_sales_agent_below_cards ends  */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* popular_sales_agent_below_cards starts */
    .popular_sales_agent_below_cards {
        background-color: #ffffff;
        padding-top: 0vw;
        padding-bottom: 12.267vw;
    }

    .popular_sales_agent_below_cards_row {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
        display: flex;
    }

    .popular_sales_agent_below_card_div {
        width: 24.167vw;
        margin: 0vw 2.223vw 0vw 0vw;
    }

    .popular_sales_agent_below_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        /* font-size: 14px; */
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 0.5555vw;
        /* padding-bottom: 0.634vw !important; */
    }

    .popular_sales_agent_below_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        /* font-size: 25px; */
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        padding-bottom: 0.054vw !important;
    }

    .popular_sales_agent_below_card_div_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.141vw !important;
    }

    .left_right_arrow_imgs {
        display: none;
    }

    /* popular_sales_agent_below_cards ends  */

}

@media only screen and (min-width: 1440px) {

    /* popular_sales_agent_below_cards starts */
    .popular_sales_agent_below_cards {
        background-color: #ffffff;
        padding-bottom: 176px;
    }

    .popular_sales_agent_below_cards_row {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
        display: flex;
    }

    .popular_sales_agent_below_card_div {
        width: 348px;
        margin: 0vw 32px 0vw 0vw;
    }

    .popular_sales_agent_below_card_div_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        /* font-size: 14px; */
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 8px;
        /* padding-bottom: 0.634vw !important; */
    }

    .popular_sales_agent_below_card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        /* font-size: 25px; */
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        padding-bottom: 0.7776px !important;
    }

    .popular_sales_agent_below_card_div_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 16.4304px !important;
    }

    .left_right_arrow_imgs {
        display: none;
    }

    /* popular_sales_agent_below_cards ends  */

}