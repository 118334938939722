@media only screen and (max-width: 480px) {


    /* application_form_complete_new starts */
    .application_form_complete_new {
        background-color: #1C1CA8;
        padding-top: 11.111vw;
        padding-bottom: 22.222vw;
    }

    .application_form_complete_new_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        text-align: center;
        padding: 0px 8px;
    }

    .application_form_complete_img {
        width: 45.833vw;
        height: 38.888vw;
        margin-bottom: 12.5vw;
    }

    .application_form_complete_new_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 11.111vw;
        line-height: 12.222vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 4.444vw;
    }

    .application_form_complete_new_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 4.444vw !important;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 11.111vw;
    }

    .application_form_complete_new_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 4.166vw 5.555vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 4.444vw;
        line-height: 5.555vw;
        color: #FFFFFF;
        height: 13.888vw;
    }

    .go_to_dashboard svg {
        width: 100%;
        height: 13.888vw;
    }

    .go_to_dashboard_btn_width_height {
        width: 100%;
        height: 13.888vw;
        margin: 0px auto;
        font-weight: bold;
        font-size: 4.444vw;
        line-height: 5.555vw;
        padding: 4.166vw 5.555vw;
    }

    /* application_form_complete_new ends */


}

@media only screen and (min-width: 481px) and (max-width: 600px) {


    /* application_form_complete_new starts */
    .application_form_complete_new {
        background-color: #1C1CA8;
        padding-top: 4.722vw;
        padding-bottom: 11.458vw;
    }

    .application_form_complete_new_content {
        grid-column: 3 / span 4;
        grid-row: 1;
        text-align: center;
    }

    .application_form_complete_img {
        width: 16.944vw;
        height: 13.611vw;
        margin-bottom: 2.777vw;
    }

    .application_form_complete_new_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 4.236vw;
        line-height: 4.659vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.111vw;
    }

    .application_form_complete_new_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.013vw !important;
        line-height: 2.638vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 2.777vw;
    }

    .application_form_complete_new_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.041vw 2.43vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.388vw;
        line-height: 1.388vw;
        color: #FFFFFF;
        margin: 1.736vw 0px;
        height: 4.583vw;
    }

    .go_to_dashboard svg {
        width: 20.972vw;
        height: 4.583vw;
    }

    .go_to_dashboard_btn_width_height {
        width: 20.972vw;
        height: 4.583vw;
        margin: 0px auto;
        font-size: 1.388vw;
        line-height: 1.388vw;
        font-weight: 700;
        padding: 1.458vw 1.666vw;
    }

    /* application_form_complete_new ends */


}

@media only screen and (min-width: 601px) and (max-width: 840px) {


    /* application_form_complete_new starts */
    .application_form_complete_new {
        background-color: #1C1CA8;
        padding-top: 4.722vw;
        padding-bottom: 11.458vw;
    }

    .application_form_complete_new_content {
        grid-column: 3 / span 4;
        grid-row: 1;
        text-align: center;
    }

    .application_form_complete_img {
        width: 16.944vw;
        height: 13.611vw;
        margin-bottom: 2.777vw;
    }

    .application_form_complete_new_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 4.236vw;
        line-height: 4.659vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.111vw;
    }

    .application_form_complete_new_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.013vw !important;
        line-height: 2.638vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 2.777vw;
    }

    .application_form_complete_new_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.041vw 2.43vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.388vw;
        line-height: 1.388vw;
        color: #FFFFFF;
        margin: 1.736vw 0px;
        height: 4.583vw;
    }

    .go_to_dashboard svg {
        width: 20.972vw;
        height: 4.583vw;
    }

    .go_to_dashboard_btn_width_height {
        width: 20.972vw;
        height: 4.583vw;
        margin: 0px auto;
        font-size: 1.388vw;
        line-height: 1.388vw;
        font-weight: 700;
        padding: 1.458vw 1.666vw;
    }

    /* application_form_complete_new ends */


}

@media only screen and (min-width: 841px) and (max-width: 1440px) {


    /* application_form_complete_new starts */
    .application_form_complete_new {
        background-color: #1C1CA8;
        padding-top: 4.722vw;
        padding-bottom: 11.458vw;
    }

    .application_form_complete_new_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        text-align: center;
    }

    .application_form_complete_img {
        width: 16.944vw;
        height: 13.611vw;
        margin-bottom: 2.777vw;
    }

    .application_form_complete_new_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 4.236vw;
        line-height: 4.659vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.111vw;
    }

    .application_form_complete_new_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.013vw !important;
        line-height: 2.638vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 2.777vw;
    }

    .application_form_complete_new_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.041vw 2.43vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.388vw;
        line-height: 1.388vw;
        color: #FFFFFF;
        margin: 1.736vw 0px;
        height: 4.583vw;
    }

    .go_to_dashboard svg {
        width: 20.972vw;
        height: 4.583vw;
    }

    .go_to_dashboard_btn_width_height {
        width: 20.972vw;
        height: 4.583vw;
        margin: 0px auto;
        font-size: 1.388vw;
        line-height: 1.388vw;
        font-weight: 700;
        padding: 1.458vw 1.666vw;
    }

    /* application_form_complete_new ends */


}

@media only screen and (min-width: 1441px) {

    /* application_form_complete_new starts */
    .application_form_complete_new {
        background-color: #1C1CA8;
        padding-top: 68px;
        padding-bottom: 165px;
    }

    .application_form_complete_new_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        text-align: center;
    }

    .application_form_complete_img {
        width: 244px;
        height: 196px;
        margin-bottom: 40px;
    }

    .application_form_complete_new_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 67.1px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 16px;
    }

    .application_form_complete_new_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 29px !important;
        line-height: 38px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 40px;
    }

    .application_form_complete_new_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 25px 0px;
        height: 66px;
    }

    .go_to_dashboard svg {
        width: 302px;
        height: 66px;
    }

    .go_to_dashboard_btn_width_height {
        width: 305px;
        height: 65px;
        margin: 0px auto;
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;
        padding: 21px 24px;
    }

    /* application_form_complete_new ends */

}