.sales_agent_left_side_img_mobile {
    display: none;

}

@media only screen and (max-width: 480px) {

    /* sales_agent starts */
    .sales_agent {
        background-color: #055CF5;
        height: 218.889vw;
        grid-template-rows: 0fr !important;
    }

    .sales_agent_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-left: -14px;
        margin-top: 11.111vw;
        display: none;
    }

    .sales_agent_left_side_img_mobile {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 88.889vw;
        margin-left: -2.222vw;
        margin-top: 11.111vw;
        display: block
    }

    .sales_agent_left_side_img img {
        width: 100%;
    }

    .sales_agent_left_side_img_mobile img {
        width: 100%;
    }

    .sales_agent_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .sales_agent_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        margin-top: 16.667vw;
        margin-bottom: 6.667vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .sales_agent_right_side_content_second_line {
        font-family: "Circular Std", "Azo Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        font-size: 5.556vw;
        margin-bottom: 6.667vw;
    }

    .sales_agent_right_side_content_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* sales_agent ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* sales_agent starts */
    /*.sales_agent {
        background-color: #055CF5;
        padding: 50px 20px;
    }

    .sales_agent_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .sales_agent_left_side_img img {
        width: 100%;
    }

    .sales_agent_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .sales_agent_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .sales_agent_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .sales_agent_right_side_content_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* sales_agent ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* sales_agent starts */
    .sales_agent {
        background-color: #055CF5;
        height: 68.099vw;
    }

    .sales_agent_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        margin-top: 11.0677vw;
        width: 42.4479vw;
    }

    .sales_agent_left_side_img img {
        width: 100%;
    }

    .sales_agent_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 6.6406vw;
        align-self: center;
    }

    .sales_agent_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .sales_agent_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .sales_agent_right_side_content_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* sales_agent ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* sales_agent starts */
    .sales_agent {
        background-color: #055CF5;
        height: 37.639vw;
    }

    .sales_agent_left_side_img {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 4.510vw;
        margin-left: -3.611vw;
    }

    .sales_agent_left_side_img img {
        width: 26.806vw;
        height: 28.569vw;
    }

    .sales_agent_right_side_content {
        grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 9.769vw;
    }

    .sales_agent_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.667vw;
    }

    .sales_agent_right_side_content_second_line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700, bold;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.667vw;
    }

    .sales_agent_right_side_content_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* sales_agent ends */

}

@media only screen and (min-width: 1441px) {

    /* sales_agent starts */
    .sales_agent {
        background-color: #055CF5;
        padding-bottom: 55px;
    }

    .sales_agent_left_side_img {
        grid-column: 2 / span 4;
        grid-row: 1;
        margin-top: 75px;
        margin-top: 64.944px;
        margin-left: -51.9984px;
    }

    .sales_agent_right_side_content {
        grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 140px;
    }

    .sales_agent_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .sales_agent_right_side_content_second_line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700, bold;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .sales_agent_right_side_content_btn_styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* sales_agent ends */

}