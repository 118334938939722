@media only screen and (max-width: 480px) {

    /* help_center_here_help starts */
    .help_center_here_help {
        background-color: #ffffff;
        padding-top: 50px;
    }

    /* help_center_here_help ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* help_center_here_help starts */
    .help_center_here_help {
        background-color: #ffffff;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    /* help_center_here_help ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* help_center_here_help starts */
    .help_center_here_help {
        background-color: #ffffff;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    /* help_center_here_help ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* help_center_here_help starts */
    .help_center_here_help {
        background-color: #ffffff;
    }

    .agent_faq_left_side_content {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
        margin-top: 8.253vw;
        margin-left: 0.112vw;
    }

    .agent_faq_right_side_content {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 100%;
        margin-top: 8.253vw;
        margin-bottom: 8.993vw;
    }

    /* help_center_here_help ends */

}

@media only screen and (min-width: 1601px) {

    /* help_center_here_help starts */
    .help_center_here_help {
        background-color: #ffffff;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .agent_faq_left_side_content {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
        margin-top: 8.253vw;
        margin-left: 0.112vw;
    }

    .agent_faq_right_side_content {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 100%;
        margin-top: 8.253vw;
        margin-bottom: 8.993vw;
    }

    /* help_center_here_help ends */

}