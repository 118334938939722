.carousel-control-prev-icon {
    background-image: none;

}

.carousel-control-next-icon {
    background-image: none;
}

@media only screen and (max-width: 480px) {
    .carousel-indicators {
        position: absolute;
        width: 51px;
        bottom: 79.444vw !important;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .carousel-indicators [data-bs-target] {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50% !important;
        margin: 0px 8px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .carousel-indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        width: 15px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

    .carousel_section {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 99;
        aspect-ratio: 360/721;
        /*height: 55.9vw;*/
        /* position: relative; */
    }

    .carousel-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        /*aspect-ratio: 1344/900;*/
        grid-template-rows: none !important;
        height: 184.167vw !important;

    }

    .about_us_testimonials_indicators {
        position: relative;
        width: 51px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 32px;
        margin-bottom: 56px;
        grid-column: 2/span 2;
        width: 84px;
        bottom: 0 !important;
    }

    .about_us_testimonials_indicators [data-bs-target] {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50% !important;
        margin: 0px 8px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .about_us_testimonials_indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        /*transform: scale(1.3);*/
        width: 15px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .carousel_section {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        z-index: 99;
        aspect-ratio: 768/446;
        height: 58.073vw;
        /* position: relative; */
    }

    .carousel-item .active {
        /* display: block !important; */
    }

    .carousel-item {
        /* display: flex !important; */
        /* display: block !important; */
        /* display: none !important; */
    }

    .carousel-indicators {
        /*bottom: -50px !important;*/
        position: absolute;
        /*left: -60%;
        top: 85%;*/
        width: 84px;
        bottom: 7.422vw !important;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .carousel-indicators [data-bs-target] {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        margin: 0px 13px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .carousel-indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        /*transform: scale(1.3);*/
        width: 25px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

    .carousel-inner {
        overflow: inherit !important;
    }

    .carousel-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        /*aspect-ratio: 1344/900;*/
        grid-template-rows: none !important;
        height: 58.073vw !important;
    }

    .about_us_testimonials_indicators {
        position: absolute;
        width: 51px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        left: 45.1389vw;
        top: 100%;
    }

    .about_us_testimonials_indicators [data-bs-target] {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50% !important;
        margin: 0px 8px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .about_us_testimonials_indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        /*transform: scale(1.3);*/
        width: 15px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

}


@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .carousel_section {
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 99;
        aspect-ratio: 1440/805;
        height: 55.9vw;
        /* position: relative; */
    }

    .carousel-item .active {
        /* display: block !important; */
    }

    .carousel-item {
        /* display: flex !important; */
        /* display: block !important; */
        /* display: none !important; */
    }

    .welcome_to_go_indicators {
        /*bottom: -50px !important;*/
        position: absolute;
        /*left: -60%;
        top: 85%;*/
        width: 84px;
        bottom: 16.042vw !important;
        left: 8.125vw;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .welcome_to_go_indicators [data-bs-target] {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        margin: 0px 13px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .welcome_to_go_indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        /*transform: scale(1.3);*/
        width: 25px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

    .carousel-inner {
        overflow: inherit !important;
    }

    .carousel-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        /*aspect-ratio: 1344/900;*/
        grid-template-rows: none !important;
        height: 55.9vw !important;

    }

    .about_us_testimonials_indicators {
        position: absolute;
        width: 51px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        left: 45.1389vw;
    }

    .about_us_testimonials_indicators [data-bs-target] {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50% !important;
        margin: 0px 8px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .about_us_testimonials_indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        /*transform: scale(1.3);*/
        width: 15px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

}

@media only screen and (min-width: 1441px) {
    .carousel_section {
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 99;
        aspect-ratio: 1440/805;
        /* position: relative; */
    }

    .carousel-item .active {
        /* display: block !important; */
    }

    .carousel-item {
        /* display: flex !important; */
        /* display: block !important; */
        /* display: none !important; */
    }

    .carousel-indicators {
        position: absolute;
        width: 84px;
        bottom: 231px !important;
        left: 114px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .carousel-indicators [data-bs-target] {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        margin: 0px 13px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .carousel-indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        width: 25px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

    .carousel-inner {
        overflow: inherit !important;
    }

    .carousel-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        /*aspect-ratio: 1344/900;*/
        grid-template-rows: none !important;
        height: 805px;

    }

    .welcome_to_go_indicators {
        /*bottom: -50px !important;*/
        position: absolute;
        /*left: -60%;
        top: 85%;*/
        width: 84px;
        bottom: 231px !important;
        left: 117px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .welcome_to_go_indicators [data-bs-target] {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        margin: 0px 13px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .welcome_to_go_indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        /*transform: scale(1.3);*/
        width: 25px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

    .carousel-inner {
        overflow: inherit !important;
    }

    .carousel-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        /*aspect-ratio: 1344/900;*/
        grid-template-rows: none !important;
        height: 805px !important;

    }

    .about_us_testimonials_indicators {
        position: absolute;
        width: 51px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        left: 650px;
        top: 333px;
    }

    .about_us_testimonials_indicators [data-bs-target] {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50% !important;
        margin: 0px 8px 0px 0px !important;
        border-top: none !important;
        border-bottom: none !important;
        opacity: 0.2;
    }

    .about_us_testimonials_indicators .active {
        /* border: 1px solid white !important;
        outline: 5px solid white !important; */
        /*transform: scale(1.3);*/
        width: 15px !important;
        border-radius: 12px !important;
        opacity: 1;
    }

}