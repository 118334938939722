@media only screen and (max-width: 480px) {

    /* thank_you_page starts */
    .thank_you_page {
        background-color: #1C1CA8;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .thank_you_page_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .thank_you_page_content img {
        width: 50%;
    }

    .thank_you_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .thank_you_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .thank_you_page .home_btn_size svg {
        width: 75%;
        height: auto;
    }

    /* thank_you_page ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* thank_you_page starts */
     .thank_you_page {
        background-color: #1C1CA8;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .thank_you_page_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .thank_you_page_content img {
        width: 50%;
    }

    .thank_you_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .thank_you_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .thank_you_page .home_btn_size svg {
        width: 50%;
        height: auto;
    }

    /* thank_you_page ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* thank_you_page starts */
    .thank_you_page {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .thank_you_page_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .thank_you_page_content img {
        width: 50%;
    }

    .thank_you_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .thank_you_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .thank_you_page .home_btn_size svg {
        width: 50%;
        height: auto;
    }

    /* thank_you_page ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* thank_you_page starts */
    .thank_you_page {
        background-color: #1C1CA8;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .thank_you_page_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .thank_you_page_content img {
        width: 50%;
    }

    .thank_you_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .thank_you_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .thank_you_page .home_btn_size svg {
        width: 50%;
        height: auto;
    }

    /* thank_you_page ends */

}
@media only screen and (min-width: 1601px) {

     /* thank_you_page starts */
     .thank_you_page {
        background-color: #1C1CA8;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .thank_you_page_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .thank_you_page_content img {
        width: 50%;
    }

    .thank_you_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .thank_you_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .thank_you_page .home_btn_size svg {
        width: 50%;
        height: auto;
    }

    /* thank_you_page ends */

}