.about_us_ready_to_become_partner_left_side_img_mobile{
    display:none;
}
@media only screen and (max-width: 480px) {

     /* about_us_ready_to_become_partner starts */
     .about_us_ready_to_become_partner {
        background-color: #41AD49;
        padding-top: 16.6667vw;
    }

    .about_us_ready_to_become_partner_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
        display:none;
    }

    .about_us_ready_to_become_partner_left_side_img_mobile{
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 96.1111vw;
        margin-left: -3.8889vw;
        margin-bottom: 16.1111vw;
        display: block;
    }

    .about_us_ready_to_become_partner_left_side_img img {
        width: 100%;
    }
    .about_us_ready_to_become_partner_left_side_img_mobile img{
        width: 100%;
    }

    .about_us_ready_to_become_partner_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 86.6667vw;
        margin: auto 0px;
    }

    .about_us_ready_to_become_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 6.6667vw;
    }

    /* about_us_ready_to_become_partner ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_ready_to_become_partner starts */
    .about_us_ready_to_become_partner {
        background-color: #41AD49;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .about_us_ready_to_become_partner_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_ready_to_become_partner_left_side_img img {
        width: 100%;
    }

    .about_us_ready_to_become_partner_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_sign_up_your_business_btn_size svg {
        width: 100%;
        height: 66px;
    }

    .about_us_ready_to_become_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    /* about_us_ready_to_become_partner ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* about_us_ready_to_become_partner starts */
    .about_us_ready_to_become_partner {
        background-color: #41AD49;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .about_us_ready_to_become_partner_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_ready_to_become_partner_left_side_img img {
        width: 100%;
    }

    .about_us_ready_to_become_partner_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .about_us_sign_up_your_business_btn_size svg {
        width: 100%;
        height: 66px;
    }

    .about_us_ready_to_become_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    /* about_us_ready_to_become_partner ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_ready_to_become_partner starts */
    .about_us_ready_to_become_partner {
        background-color: #41AD49;
        aspect-ratio: 1440/542;
    }

    .about_us_ready_to_become_partner_left_side_img {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 29.5139vw;
        margin-top: 1.4583vw;
        margin-left: -4.375vw;

    }

    .about_us_ready_to_become_partner_left_side_img img {
        width: 100%;
    }

    .about_us_ready_to_become_partner_right_side_content {
        grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 11.5972vw;
    }

    .about_us_sign_up_your_business_btn_size svg {
        width: 262px;
        height: 66px;
    }

    .about_us_ready_to_become_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    /* about_us_ready_to_become_partner ends */

}
@media only screen and (min-width: 1441px) {

     /* about_us_ready_to_become_partner starts */
    .about_us_ready_to_become_partner {
        background-color: #41AD49;
        aspect-ratio: 1440/542;
    }

    .about_us_ready_to_become_partner_left_side_img {
        grid-column: 2 / span 4;
        grid-row: 1;
        margin-top: 21px;
        margin-left: -63px;

    }

    .about_us_ready_to_become_partner_right_side_content {
        grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 167px;
    }

    .about_us_sign_up_your_business_btn_size svg {
        width: 262px;
        height: 66px;
    }

    .about_us_ready_to_become_partner_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    /* about_us_ready_to_become_partner ends */

}