@media only screen and (max-width: 480px) {

    /* about_us_our_values starts */
    .about_us_our_values {
        background-color: #1c1ca8;
        padding-top: 12.5vw;
    }

    .about_us_our_values_heading {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-bottom: 7.5vw;
        margin-top: 2.367vw;
    }

    .about_us_our_values_heading h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .about_us_our_values_cards_section {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        display: none;
    }

    .about_us_our_values_cards_mobile {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        position: relative;
    }

    .card_div {
        margin: 0px auto;
        text-align: center;
        padding: 15px;
    }

    .card_div img {
        width: 27.7778vw;
        height: 27.7417vw;
    }

    .card_div svg {
        width: 130px;
        height: 129px;
    }

    .card_div_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 6.6667vw;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top: 11.1111vw !important;
        margin-bottom: 6.6667vw !important;
    }

    .card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 5.5556vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6.9444vw;
    }

    .carousel_indicators {
        position: absolute !important;
        /* width: 8.960vw !important; */
        bottom: 0vw;
        left: 15.555vw !important;
    }

    .leftArrow {
        width: 13.891vw;
        height: 13.891vw;
    }

    .rightArrow {
        width: 13.891vw;
        height: 13.891vw;
    }

    /* about_us_our_values ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_our_values starts */
    /* .about_us_our_values {
        background-color: #1c1ca8;
        padding-top: 50px;
    }

    .about_us_our_values_heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
    }

    .about_us_our_values_heading h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .about_us_our_values_cards_section {
        grid-column: 1 / span 8;
        grid-row: 2;
        display: flex;
        width: 100%;
    }

    .card_div {
        margin: 0px auto;
        text-align: center;
        padding: 15px;
        width: 33%;
    }

    .card_div img {
        width: 100px;
        height: 99px;
    }

    .card_div svg {
        width: 100px;
        height: 99px;
    }

    .card_div_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        margin: 25px 0px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .about_us_our_values_cards_mobile {
        display: none;
    } */

    /* about_us_our_values ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* about_us_our_values starts */
    .about_us_our_values {
        background-color: #1c1ca8;
        padding-top: 6.5104vw;
        padding-bottom: 3.386vw;
    }

    .about_us_our_values_heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-bottom: 6.5104vw;
    }

    .about_us_our_values_heading h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 7.9427vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .about_us_our_values_cards_section {
        grid-column: 1 / span 8;
        grid-row: 2;
        display: flex;
        width: 100%;
    }

    .card_div {
        margin: 0px auto;
        text-align: center;
        padding: 1.9531vw;
        width: 33%;
    }

    .card_div img {
        width: 15.625vw;
        height: 15.4947vw;
    }

    .card_div svg {
        width: 15.625vw;
        height: 15.4947vw;
    }

    .card_div_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 4.0364vw;
        line-height: 130%;
        margin: 3.2552vw 0px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.6041vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .about_us_our_values_cards_mobile {
        display: none;
    }

    /* about_us_our_values ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_our_values starts */
    .about_us_our_values {
        background-color: #1c1ca8;
    }

    .about_us_our_values_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-top: 9.0972vw;
        margin-bottom: 6.3889vw;
    }

    .about_us_our_values_heading h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0px !important;
    }

    .about_us_our_values_cards_section {
        grid-column: 2 / span 11;
        grid-row: 2;
        display: flex;
        margin-bottom: 10.3472vw;
    }

    .card_div {
        text-align: center;
        width: 22.0833vw;
    }

    .card_div img {
        width: 9.0278vw;
        height: 8.9583vw;
    }

    .card_div svg {
        width: 9.0278vw;
        height: 129zpx;
    }

    .card_div.two {
        margin-left: 6.4583vw;
    }

    .card_div.three {
        margin-left: 4.9306vw;
    }

    .card_div_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 2.1528vw;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top: 2.7778vw;
        margin-bottom: 1.6667vw;
    }

    .card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        width: 20.0694vw;
        margin-left: 0.9028vw;
    }

    .about_us_our_values_cards_mobile {
        display: none;
    }

    /* about_us_our_values ends */

}

@media only screen and (min-width: 1441px) {

    /* about_us_our_values starts */
    .about_us_our_values {
        background-color: #1c1ca8;
        padding-top: 131px;
        padding-bottom: 143px;
    }

    .about_us_our_values_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-bottom: 92.81px;
    }

    .about_us_our_values_heading h1 {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .about_us_our_values_cards_section {
        grid-column: 2 / span 10;
        grid-row: 2;
        display: flex;
    }

    .card_div {
        text-align: center;
        width: 318px;
    }

    .card_one {
        text-align: center;
    }

    .card_div.two {
        margin-left: 93px;
    }

    .card_div.three {
        margin-left: 71px;
    }

    .card_div_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top: 40px;
        margin-bottom: 24px;
    }

    .card_div_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        width: 292px;
        margin-left: 13px;
    }

    .about_us_our_values_cards_mobile {
        display: none;
    }

    /* about_us_our_values ends */

}