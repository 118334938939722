@media only screen and (max-width: 480px) {

     /* sipho_is_trained starts */
     .sipho_is_trained {
        background-color: #FFFFFF;
        padding-top: 65%;
        padding-bottom: 30px;
        position: relative;
    }

    .sipho_is_trained_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .sipho_is_trained_right_side_imgs {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .sipho_is_trained_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .sipho_is_trained_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sipho_is_trained_floating_div {
        grid-column: 1 / span 4;
        position: absolute;
        top: -28%;
        left: 0%;
        background-color: #055CF5;
        padding: 25px;
        border-radius: 25px;
        width: 100%;
    }

    .profile_info_third_line svg {
        width: 24px;
        height: 24px;
        margin-bottom: 7px;
    }

    .sipho_is_trained_floating_div_left_side_content .profile_pic {
        text-align: center;
    }

    .profile_pic img {
        border-radius: 50%;
        border: 5px solid white;
        width: 50%;
    }

    .profile_info .box {
        text-align: center;
    }

    .profile_info_first_line {
        padding: 0px !important;
        margin-top: 10px;
    }

    .profile_info_second_line {
        padding: 0px !important;
    }

    .profile_info_third_line {
        padding: 0px !important;
    }

    .sipho_is_trained_floating_div_right_side_imgs {
        display: flex;
        width: 100%;
        text-align: center;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img {
        width: 22%;
        margin: 0px auto;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img img {
        width: 100%;
    }

    .first_row_images {
        display: flex;
    }

    .second_row_images {
        display: flex;
    }

    .first_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .second_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .first_row_images .img img {
        width: 100%;
    }

    .second_row_images .img img {
        width: 100%;
    }

    .profile_info_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .profile_info_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_info_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* sipho_is_trained ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* sipho_is_trained starts */
    .sipho_is_trained {
        background-color: #FFFFFF;
        padding-top: 70px;
        padding-bottom: 30px;
        position: relative;
    }

    .sipho_is_trained_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .sipho_is_trained_right_side_imgs {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .first_row_images {
        display: flex;
    }

    .second_row_images {
        display: flex;
    }

    .first_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .second_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .first_row_images .img img {
        width: 100%;
    }

    .second_row_images .img img {
        width: 100%;
    }

    .sipho_is_trained_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .sipho_is_trained_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sipho_is_trained_floating_div {
        grid-column: 1 / span 8;
        position: absolute;
        top: -12%;
        left: 0%;
        background-color: #055CF5;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        border-radius: 13px;
        width: 100%;
    }

    .sipho_is_trained_floating_div_right_side_imgs {
        display: flex;
        width: 50%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic {
        width: 30%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic img {
        width: 100%;
    }

    .sipho_is_trained_floating_div_left_side_content {
        display: flex;
        width: 40%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img {
        width: 25%;
        margin: 0px auto;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img img {
        width: 60%;
    }

    .profile_info_third_line svg {
        width: 16px;
        height: 16px;
        margin-bottom: 7px;
    }

    .profile_info_third_line span {
        margin-top: 15px;
    }

    .profile_info {
        margin: auto 0px;
        margin-left: 5px;
        width: 67%;
    }

    .profile_pic img {
        border-radius: 50%;
        border: 5px solid white;
        width: 100%;
    }

    .profile_pic {
        width: 33%;
        margin: auto 0px;
    }

    .profile_info_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .profile_info_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 19px !important;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_info_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 10px !important;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* sipho_is_trained ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* sipho_is_trained starts */
    .sipho_is_trained {
        background-color: #FFFFFF;
        padding-top: 90px;
        padding-bottom: 30px;
        position: relative;
    }

    .sipho_is_trained_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .sipho_is_trained_right_side_imgs {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .first_row_images {
        display: flex;
    }

    .second_row_images {
        display: flex;
    }

    .first_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .second_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .first_row_images .img img {
        width: 100%;
    }

    .second_row_images .img img {
        width: 100%;
    }

    .sipho_is_trained_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .sipho_is_trained_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sipho_is_trained_floating_div {
        grid-column: 1 / span 8;
        position: absolute;
        top: -14%;
        left: 0%;
        background-color: #055CF5;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        border-radius: 15px;
        width: 100%;
    }

    .sipho_is_trained_floating_div_right_side_imgs {
        display: flex;
        width: 50%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic {
        width: 30%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic img {
        width: 100%;
    }

    .sipho_is_trained_floating_div_left_side_content {
        display: flex;
        width: 40%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img {
        width: 25%;
        margin: 0px auto;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img img {
        width: 60%;
    }

    .profile_info_third_line svg {
        width: 16px;
        height: 16px;
        margin-bottom: 7px;
    }

    .profile_info_third_line span {
        margin-top: 15px;
    }

    .profile_info {
        margin: auto 0px;
        margin-left: 10px;
        width: 70%;
    }

    .profile_pic img {
        border-radius: 50%;
        border: 5px solid white;
        width: 100%;
    }

    .profile_pic {
        width: 30%;
        margin: auto 0px;
    }

    .profile_info_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .profile_info_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 25px !important;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_info_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 11px !important;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* sipho_is_trained ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* sipho_is_trained starts */
    .sipho_is_trained {
        background-color: #FFFFFF;
        padding-top: 180px;
        padding-bottom: 150px;
        position: relative;
    }

    .sipho_is_trained_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .sipho_is_trained_right_side_imgs {
        grid-column: 7 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .first_row_images {
        display: flex;
    }

    .second_row_images {
        display: flex;
    }

    .first_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .second_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .first_row_images .img img {
        width: 100%;
    }

    .second_row_images .img img {
        width: 100%;
    }

    .sipho_is_trained_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .sipho_is_trained_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sipho_is_trained_floating_div {
        grid-column: 2 / span 10;
        position: absolute;
        top: -14%;
        left: 0%;
        background-color: #055CF5;
        padding: 25px;
        display: flex;
        justify-content: space-between;
        border-radius: 25px;
        width: 100%;
    }

    .sipho_is_trained_floating_div_right_side_imgs {
        display: flex;
        width: 50%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic {
        width: 30%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic img {
        width: 100%;
    }

    .sipho_is_trained_floating_div_left_side_content {
        display: flex;
        width: 40%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img {
        width: 25%;
        margin: 0px auto;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img img {
        width: 60%;
    }

    .profile_info_third_line svg {
        width: 24px;
        height: 24px;
        margin-bottom: 7px;
    }

    .profile_info_third_line span {
        margin-top: 15px;
    }

    .profile_info {
        margin: auto 0px;
        margin-left: 35px;
        width: 67%;
    }

    .profile_pic img {
        border-radius: 50%;
        border: 5px solid white;
        width: 115%;
    }

    .profile_pic {
        width: 33%;
    }

    .profile_info_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .profile_info_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_info_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* sipho_is_trained ends */

}
@media only screen and (min-width: 1601px) {

    /* sipho_is_trained starts */
    .sipho_is_trained {
        background-color: #FFFFFF;
        padding-top: 180px;
        padding-bottom: 150px;
        position: relative;
    }

    .sipho_is_trained_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .sipho_is_trained_right_side_imgs {
        grid-column: 7 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .first_row_images {
        display: flex;
    }

    .second_row_images {
        display: flex;
    }

    .first_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .second_row_images .img {
        width: 21%;
        margin: 15px auto;
    }

    .first_row_images .img img {
        width: 100%;
    }

    .second_row_images .img img {
        width: 100%;
    }

    .sipho_is_trained_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .sipho_is_trained_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sipho_is_trained_floating_div {
        grid-column: 2 / span 10;
        position: absolute;
        top: -14%;
        left: 0%;
        background-color: #055CF5;
        padding: 25px;
        display: flex;
        justify-content: space-between;
        border-radius: 25px;
        width: 100%;
    }

    .sipho_is_trained_floating_div_right_side_imgs {
        display: flex;
        width: 50%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic {
        width: 30%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .profile_pic img {
        width: 100%;
    }

    .sipho_is_trained_floating_div_left_side_content {
        display: flex;
        width: 40%;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img {
        width: 25%;
        margin: 0px auto;
    }

    .sipho_is_trained_floating_div_right_side_imgs .img img {
        width: 60%;
    }

    .profile_info_third_line svg {
        width: 24px;
        height: 24px;
        margin-bottom: 7px;
    }

    .profile_info_third_line span {
        margin-top: 15px;
    }

    .profile_info {
        margin-top: 12px;
        margin-left: 35px;
        width: 67%;
    }

    .profile_pic img {
        border-radius: 50%;
        border: 5px solid white;
        width: 115%;
    }

    .profile_pic {
        width: 33%;
    }

    .profile_info_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .profile_info_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_info_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* sipho_is_trained ends */

}