.brand-benefit-image-mobile {
    display: none;
}

.brand-benefit-image-tablet2 {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* brand benefit start */
    .brand-benefit {
        background: #FFFFFF;
        color: #444;
        aspect-ratio: 360/721;
    }

    .brand-benefit-content {
        grid-column: 1 / span 4;
        grid-row: 1;
        height: auto;
        margin-top: 16.667vw;
        max-width: 100%;
    }

    .brand-benefit-content-header {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.667vw;
        margin-right: 5vw;
    }

    .brand-benefit-image {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 80.278vw;
        height: 100vw;
        margin-left: 6.667vw;
        margin-right: 8.886vw;
        margin-bottom: 16.389vw;
        display: none;
    }

    .brand-benefit-image-mobile {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 80.278vw;
        height: 100vw;
        margin-left: 5.556vw;
        margin-bottom: 16.089vw;
        display: block;
    }

    .brand-benefit-image img {
        width: 100vw;
        width: 80.278vw;
        height: 100vw;
    }

    .brand-benefit-image-mobile img {
        width: 80.278vw;
        height: 98.905vw;
    }

    .brand-benefit-image-tablet2 img {
        width: 100vw;
    }

    .brand-benefit-content-title {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;

        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .brand-benefit-content-text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;

        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-top: 6.667vw;
        margin-bottom: 6.667vw;
    }

    .brand_benefit_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 9px 18px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .learn_more_btn svg {
        width: 70%;
        height: auto;
        aspect-ratio: 213/50;
    }

    /* brand benefit end */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* brand benefit start */
    .brand-benefit {
        background: #FFFFFF;
        color: #444;
    }

    .brand-benefit-content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 15vw;
        margin-bottom: 15.655vw;
    }

    .brand-benefit-content-header {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
    }

    .brand-benefit-image {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 100%;
        margin-left: 4.111vw;
    }

    .brand-benefit-image img {
        margin-top: 2.292vw;
        width: 38.744vw;
        height: 47.912vw;
    }

    .brand-benefit-content-title {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .brand-benefit-content-text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.667vw;
    }

    .brand_benefit_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.042vw 2.431vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .learn_more_btn svg {
        width: 50%;
        height: auto;
    }

    /* brand benefit end */

    /* brand benefit start */
    /* .brand-benefit {
        padding-top: 10%;
        background: #FFFFFF;
        color: #444;
    }

    .brand-benefit-content {
        grid-column: 1 / span 4;
        grid-row: 1;
        height: auto;
        width: 100%;
    }

    .brand-benefit-content-header {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 15px;
    }

    .brand-benefit-image {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
    }

    .brand-benefit-image img {
        width: 100%;
    }

    .brand-benefit-content-title {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 15px;
    }

    .brand-benefit-content-text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .brand_benefit_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .learn_more_btn svg {
        width: 70%;
        height: auto;
        aspect-ratio: 213/50;
    }

    /* brand benefit end */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* brand benefit start */
    .brand-benefit {
        background: #FFFFFF;
        color: #444;
    }

    .brand-benefit-content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 15vw;
        margin-bottom: 15.655vw;
    }

    .brand-benefit-content-header {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
    }

    .brand-benefit-image {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 100%;
        margin-left: 4.111vw;
    }

    .brand-benefit-image img {
        margin-top: 2.292vw;
        width: 38.744vw;
        height: 47.912vw;
        margin-bottom: 12.325vw;
    }

    .brand-benefit-content-title {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .brand-benefit-content-text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.667vw;
    }

    .brand_benefit_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.042vw 2.431vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .learn_more_btn svg {
        width: 50%;
        height: auto;
    }

    /* brand benefit end */

    /* brand benefit start */
    /* .brand-benefit {
        background: #FFFFFF;
    }

    .brand-benefit-content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 6.641vw;
    }

    .brand-benefit-content-header {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .brand-benefit-image {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100vw;
        margin: auto 0vw;
        display: none;
    }

    .brand-benefit-image-tablet2 {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 39.714vw;
        height: 47.786vw;
        margin: auto 0px;
        display: block;
        justify-self: end;
        margin-top: 7.031vw;
        margin-right: -1.432vw;
    }

    .brand-benefit-image img {
        width: 100%;
    }

    .brand-benefit-image-mobile img {
        width: 100%;
    }

    .brand-benefit-image-tablet2 img {
        width: 100%;
    }

    .brand-benefit-content-title {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .brand-benefit-content-text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 3.125vw;
    }

    .brand_benefit_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .learn_more_btn svg {
        width: 70%;
        height: auto;
        aspect-ratio: 213/50;
    } */

    /* brand benefit end */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* brand benefit start */
    .brand-benefit {
        background: #FFFFFF;
        color: #444;
    }

    .brand-benefit-content {
        grid-column: 2/ span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 15vw;
        margin-bottom: 15.655vw;
    }

    .brand-benefit-content-header {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
    }

    .brand-benefit-image {
        grid-column: 7/ span 5;
        grid-row: 1;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .brand-benefit-image img {
        margin-top: 2.292vw;
        width: 38.744vw;
        height: 47.912vw;
        margin-bottom: 12.325vw;
    }

    .brand-benefit-content-title {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.667vw;
    }

    .brand-benefit-content-text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.667vw;
    }

    .brand_benefit_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 1.042vw 2.431vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .learn_more_btn svg {
        width: 50%;
        height: auto;
    }

    /* brand benefit end */

}

@media only screen and (min-width: 1441px) {

    /* brand benefit start */
    .brand-benefit {
        background: #FFFFFF;
        color: #444;
        aspect-ratio: 1440/900;
    }

    .brand-benefit-content {
        grid-column: 2/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
        padding-top: 216px;
    }

    .brand-benefit-content-header {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        font-size: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .brand-benefit-image {
        grid-column: 7/ span 5;
        grid-row: 1;
        height: auto;
        display: flex;
        align-items: flex-start;
    }

    .brand-benefit-image img {
        margin-top: 33px;
    }

    .brand-benefit-content-title {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .brand-benefit-content-text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.395vw;
    }

    .brand_benefit_btn_styles {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .learn_more_btn svg {
        width: 213px;
        height: 50px;
    }

    /* brand benefit end */

}