@media only screen and (max-width: 480px) {
    .location_terms {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 4.444vw;
        padding-bottom: 20.277vw;
        height: fit-content;
        overflow: hidden;
    }

    .location_terms_arrow_img {
        grid-column: 1 / span 13;
        grid-row: 3;
        width: 100%;
        margin: auto 0px;
        z-index: 0;
    }

    .location_terms_arrow_img img {
        width: 100%;
        transform: translate(25vw, 69.444vw);
    }

    .location_terms_left_side_container {
        grid-column: 1 / span 13;
        grid-row: 2;
        width: 100%;
        margin-bottom: 4.444vw;
    }

    .location_terms_right_side_container {
        grid-column: 1 / span 13;
        grid-row: 3;
        width: 100%;
        z-index: 1;
    }

    .location_terms_breadcrumb {
        grid-column: 1 / span 12;
        grid-row: 1;
        height: fit-content;
        width: 100%;
        font-size: 3.888vw;
        font-weight: bold;
        color: white;
        line-height: 6.111vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2.222vw;
        margin-bottom: 4.44vw
    }

    .location_terms_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .location_terms_breadcrumb span {
        white-space: nowrap;
    }

    .location_terms_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .location_terms_breadcrumb svg {
        width: 4.444vw;
        margin-left: 2.222vw;
    }

    .location_terms_form {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 10vw 4.444vw;
    }

    .location_terms_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.222vw;
    }

    .location_terms_form_title {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-weight: 700;
        font-size: 6.666vw;
        line-height: 8.611vw;
        letter-spacing: -0.02em;
    }

    .location_terms_form_step {
        color: #FF4600;
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 5vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .location_terms_form_question {
        color: #5B5B62;
        font-weight: 450;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        margin-bottom: 6.666vw;
    }

    .location_terms_form_address_autocomplete_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5.555vw;
        gap: 0.833vw;
        height: 17.777vw;
        background: #EEEEF0;
        border-radius: 8px;
        margin-bottom: 6.666vw;
        position: relative;
    }

    .location_terms_form_address_autocomplete_wrapper input {
        color: #27272F;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        background-color: transparent;
        border: 0px;
        outline: none;
        flex-grow: 1;
    }

    .location_terms_form_address_autocomplete_wrapper svg {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .location_terms_form_address_autocomplete_prediction_wrapper {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        padding: 3px 0px;
        border-radius: 6px;
        overflow: hidden;
        background: white;
        box-shadow: 0px 4px 6px 0px #00000026;
        z-index: 1000;
    }

    .location_terms_form_address_autocomplete_prediction {
        width: 100%;
        min-height: 11.111vw;
        display: flex;
        align-items: center;
        padding-left: 2.222vw;
        font-size: 4.444vw;
        cursor: pointer;
    }

    .location_terms_form_address_autocomplete_prediction:hover {
        background-color: #0000000d;
    }

    .google-places-autocomplete {
        position: relative;
    }

    .location_terms_use_current_location {
        display: flex;
        align-items: center;
        gap: 1.666vw;
        color: #1C1CA8;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        letter-spacing: -0.01em;
        margin-bottom: 6.666vw;
    }

    .location_terms_use_current_location svg {
        width: 6.666vw;
        height: 6.666vw;
    }

    .location_terms_agree_policy_line {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4.444vw;
        margin-bottom: 6.666vw;
        color: #5B5B62;
        font-weight: 450;
        font-size: 4.444vw;
        line-height: 6.666vw;
    }

    .location_terms_agree_policy_line svg {
        width: 5.555vw;
        height: 5.555vw;
    }

    .location_terms_submit_btn_width_height {
        width: 100%;
        height: 13.888vw;
    }

    .location_terms_submit_btn_width_height:disabled {
        opacity: 0.6;
    }

    .address_details_map_div {
        position: relative;
    }

    .address_details_map_div>div:nth-child(2) {
        border-radius: 15px;
        width: 100%;
        height: 132.5vw;
    }

    .address_details_right_side_div_thumbsUp_pic {
        position: absolute;
        bottom: -18.05vw;
        right: 6.388vw;
    }

    .address_details_right_side_div_thumbsUp_pic img {
        width: 32.5vw;
        height: 34.722vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
    .location_terms {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 1.111vw;
        height: 56.944vw;
        overflow: hidden;
    }

    .location_terms_arrow_img {
        grid-column: 4 / span 7;
        grid-row: 2;
        width: 100%;
        padding-top: 6.944vw;
        margin: auto 0px;
    }

    .location_terms_arrow_img img {
        width: 100%;
    }

    .location_terms_left_side_container {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_right_side_container {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_breadcrumb {
        grid-column: 1 / span 8;
        grid-row: 1;
        height: fit-content;
        width: 100%;
        font-size: 0.972vw;
        font-weight: bold;
        color: white;
        line-height: 1.527vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw
    }

    .location_terms_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .location_terms_breadcrumb span {
        white-space: nowrap;
    }

    .location_terms_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .location_terms_breadcrumb svg {
        width: 1.111vw;
        margin-left: 0.555vw;
    }

    .location_terms_form {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 2.5vw 1.666vw;
    }

    .location_terms_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.555vw;
    }

    .location_terms_form_title {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 2.152vw;
        font-weight: 700;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
    }

    .location_terms_form_step {
        color: #FF4600;
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .location_terms_form_question {
        color: #5B5B62;
        font-weight: 450;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
    }

    .location_terms_form_address_autocomplete_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1.388vw;
        gap: 0.833vw;
        height: 4.583vw;
        background: #EEEEF0;
        border-radius: 8px;
        margin-bottom: 1.666vw;
        position: relative;
    }

    .location_terms_form_address_autocomplete_wrapper input {
        color: #27272F;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        background-color: transparent;
        border: 0px;
        outline: none;
        flex-grow: 1;
    }

    .location_terms_form_address_autocomplete_wrapper svg {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .location_terms_form_address_autocomplete_prediction_wrapper {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        padding: 3px 0px;
        border-radius: 6px;
        overflow: hidden;
        background: white;
        box-shadow: 0px 4px 6px 0px #00000026;
        z-index: 1000;
    }

    .location_terms_form_address_autocomplete_prediction {
        width: 100%;
        min-height: 2.777vw;
        display: flex;
        align-items: center;
        padding-left: 0.555vw;
        font-size: 1.111vw;
        cursor: pointer;
    }

    .location_terms_form_address_autocomplete_prediction:hover {
        background-color: #0000000d;
    }

    .google-places-autocomplete {
        position: relative;
    }

    .location_terms_use_current_location {
        display: flex;
        align-items: center;
        gap: 0.416vw;
        color: #1C1CA8;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
    }

    .location_terms_use_current_location svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .location_terms_agree_policy_line {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 1.111vw;
        margin-bottom: 1.666vw;
        color: #5B5B62;
        font-weight: 450;
        font-size: 1.111vw;
        line-height: 1.805vw;
    }

    .location_terms_agree_policy_line svg {
        width: 1.388vw;
        height: 1.388vw;
    }

    .location_terms_submit_btn_width_height {
        width: 100%;
        height: 4.583vw;
    }

    .location_terms_submit_btn_width_height:disabled {
        opacity: 0.6;
    }

    .address_details_map_div {
        position: relative;
    }

    .address_details_map_div>div:nth-child(2) {
        border-radius: 15px;
        width: 100%;
        height: 33.194vw;
    }

    .address_details_right_side_div_thumbsUp_pic {
        position: absolute;
        bottom: -6.041vw;
        right: 2.638vw;
    }

    .address_details_right_side_div_thumbsUp_pic img {
        width: 9.027vw;
        height: 10.208vw;
    }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {
    .location_terms {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 1.111vw;
        height: 56.944vw;
        overflow: hidden;
    }

    .location_terms_arrow_img {
        grid-column: 4 / span 7;
        grid-row: 2;
        width: 100%;
        padding-top: 6.944vw;
        margin: auto 0px;
    }

    .location_terms_arrow_img img {
        width: 100%;
    }

    .location_terms_left_side_container {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_right_side_container {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_breadcrumb {
        grid-column: 1 / span 8;
        grid-row: 1;
        height: fit-content;
        width: 100%;
        font-size: 0.972vw;
        font-weight: bold;
        color: white;
        line-height: 1.527vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw
    }

    .location_terms_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .location_terms_breadcrumb span {
        white-space: nowrap;
    }

    .location_terms_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .location_terms_breadcrumb svg {
        width: 1.111vw;
        margin-left: 0.555vw;
    }

    .location_terms_form {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 2.5vw 1.666vw;
    }

    .location_terms_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.555vw;
    }

    .location_terms_form_title {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 2.152vw;
        font-weight: 700;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
    }

    .location_terms_form_step {
        color: #FF4600;
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .location_terms_form_question {
        color: #5B5B62;
        font-weight: 450;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
    }

    .location_terms_form_address_autocomplete_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1.388vw;
        gap: 0.833vw;
        height: 4.583vw;
        background: #EEEEF0;
        border-radius: 8px;
        margin-bottom: 1.666vw;
        position: relative;
    }

    .location_terms_form_address_autocomplete_wrapper input {
        color: #27272F;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        background-color: transparent;
        border: 0px;
        outline: none;
        flex-grow: 1;
    }

    .location_terms_form_address_autocomplete_wrapper svg {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .location_terms_form_address_autocomplete_prediction_wrapper {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        padding: 3px 0px;
        border-radius: 6px;
        overflow: hidden;
        background: white;
        box-shadow: 0px 4px 6px 0px #00000026;
        z-index: 1000;
    }

    .location_terms_form_address_autocomplete_prediction {
        width: 100%;
        min-height: 2.777vw;
        display: flex;
        align-items: center;
        padding-left: 0.555vw;
        font-size: 1.111vw;
        cursor: pointer;
    }

    .location_terms_form_address_autocomplete_prediction:hover {
        background-color: #0000000d;
    }

    .google-places-autocomplete {
        position: relative;
    }

    .location_terms_use_current_location {
        display: flex;
        align-items: center;
        gap: 0.416vw;
        color: #1C1CA8;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
    }

    .location_terms_use_current_location svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .location_terms_agree_policy_line {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 1.111vw;
        margin-bottom: 1.666vw;
        color: #5B5B62;
        font-weight: 450;
        font-size: 1.111vw;
        line-height: 1.805vw;
    }

    .location_terms_agree_policy_line svg {
        width: 1.388vw;
        height: 1.388vw;
    }

    .location_terms_submit_btn_width_height {
        width: 100%;
        height: 4.583vw;
    }

    .location_terms_submit_btn_width_height:disabled {
        opacity: 0.6;
    }

    .address_details_map_div {
        position: relative;
    }

    .address_details_map_div>div:nth-child(2) {
        border-radius: 15px;
        width: 100%;
        height: 33.194vw;
    }

    .address_details_right_side_div_thumbsUp_pic {
        position: absolute;
        bottom: -6.041vw;
        right: 2.638vw;
    }

    .address_details_right_side_div_thumbsUp_pic img {
        width: 9.027vw;
        height: 10.208vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .location_terms {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 1.111vw;
        height: 56.944vw;
        overflow: hidden;
    }

    .location_terms_arrow_img {
        grid-column: 6 / span 7;
        grid-row: 2;
        width: 100%;
        padding-top: 6.944vw;
        margin: auto 0px;
    }

    .location_terms_arrow_img img {
        width: 100%;
    }

    .location_terms_left_side_container {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_right_side_container {
        grid-column: 7 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_breadcrumb {
        grid-column: 2 / span 12;
        grid-row: 1;
        height: fit-content;
        width: 100%;
        font-size: 0.972vw;
        font-weight: bold;
        color: white;
        line-height: 1.527vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw
    }

    .location_terms_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .location_terms_breadcrumb span {
        white-space: nowrap;
    }

    .location_terms_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .location_terms_breadcrumb svg {
        width: 1.111vw;
        margin-left: 0.555vw;
    }

    .location_terms_form {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 2.5vw 1.666vw;
    }

    .location_terms_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.555vw;
    }

    .location_terms_form_title {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 2.152vw;
        font-weight: 700;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
    }

    .location_terms_form_step {
        color: #FF4600;
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .location_terms_form_question {
        color: #5B5B62;
        font-weight: 450;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
    }

    .location_terms_form_address_autocomplete_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1.388vw;
        gap: 0.833vw;
        height: 4.583vw;
        background: #EEEEF0;
        border-radius: 8px;
        margin-bottom: 1.666vw;
        position: relative;
    }

    .location_terms_form_address_autocomplete_wrapper input {
        color: #27272F;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        background-color: transparent;
        border: 0px;
        outline: none;
        flex-grow: 1;
    }

    .location_terms_form_address_autocomplete_wrapper svg {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .location_terms_form_address_autocomplete_prediction_wrapper {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        padding: 3px 0px;
        border-radius: 6px;
        overflow: hidden;
        background: white;
        box-shadow: 0px 4px 6px 0px #00000026;
        z-index: 1000;
    }

    .location_terms_form_address_autocomplete_prediction {
        width: 100%;
        min-height: 2.777vw;
        display: flex;
        align-items: center;
        padding-left: 0.555vw;
        font-size: 1.111vw;
        cursor: pointer;
    }

    .location_terms_form_address_autocomplete_prediction:hover {
        background-color: #0000000d;
    }

    .google-places-autocomplete {
        position: relative;
    }

    .location_terms_use_current_location {
        display: flex;
        align-items: center;
        gap: 0.416vw;
        color: #1C1CA8;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
    }

    .location_terms_use_current_location svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .location_terms_agree_policy_line {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 1.111vw;
        margin-bottom: 1.666vw;
        color: #5B5B62;
        font-weight: 450;
        font-size: 1.111vw;
        line-height: 1.805vw;
    }

    .location_terms_agree_policy_line svg {
        width: 1.388vw;
        height: 1.388vw;
    }

    .location_terms_submit_btn_width_height {
        width: 100%;
        height: 4.583vw;
    }

    .location_terms_submit_btn_width_height:disabled {
        opacity: 0.6;
    }

    .address_details_map_div {
        position: relative;
    }

    .address_details_map_div>div:nth-child(2) {
        border-radius: 15px;
        width: 100%;
        height: 33.194vw;
    }

    .address_details_right_side_div_thumbsUp_pic {
        position: absolute;
        bottom: -6.041vw;
        right: 2.638vw;
    }

    .address_details_right_side_div_thumbsUp_pic img {
        width: 9.027vw;
        height: 10.208vw;
    }
}

@media only screen and (min-width: 1441px) {
    .location_terms {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 16px;
        height: 820px;
        overflow: hidden;
    }

    .location_terms_arrow_img {
        grid-column: 6 / span 7;
        grid-row: 2;
        width: 100%;
        padding-top: 100px;
        margin: auto 0px;
    }

    .location_terms_arrow_img img {
        width: 100%;
    }

    .location_terms_left_side_container {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_right_side_container {
        grid-column: 7 / span 5;
        grid-row: 2;
        width: 100%;
    }

    .location_terms_breadcrumb {
        grid-column: 2 / span 12;
        grid-row: 1;
        height: fit-content;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: white;
        line-height: 22px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        margin-bottom: 36px
    }

    .location_terms_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .location_terms_breadcrumb span {
        white-space: nowrap;
    }

    .location_terms_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .location_terms_breadcrumb svg {
        width: 16px;
        margin-left: 8px;
    }

    .location_terms_form {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 36px 24px;
    }

    .location_terms_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .location_terms_form_title {
        font-family: 'Azo Sans', sans-serif;
        color: #1C1CA8;
        font-size: 31px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.02em;
    }

    .location_terms_form_step {
        color: #FF4600;
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .location_terms_form_question {
        color: #5B5B62;
        font-weight: 450;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
    }

    .location_terms_form_address_autocomplete_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        gap: 12px;
        height: 66px;
        background: #EEEEF0;
        border-radius: 8px;
        margin-bottom: 24px;
        position: relative;
    }

    .location_terms_form_address_autocomplete_wrapper input {
        color: #27272F;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        background-color: transparent;
        border: 0px;
        outline: none;
        flex-grow: 1;
    }

    .location_terms_form_address_autocomplete_wrapper svg {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .location_terms_form_address_autocomplete_prediction_wrapper {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        padding: 3px 0px;
        border-radius: 6px;
        overflow: hidden;
        background: white;
        box-shadow: 0px 4px 6px 0px #00000026;
        z-index: 1000;
    }

    .location_terms_form_address_autocomplete_prediction {
        width: 100%;
        min-height: 2.777vw;
        display: flex;
        align-items: center;
        padding-left: 0.555vw;
        font-size: 16px;
        cursor: pointer;
    }

    .location_terms_form_address_autocomplete_prediction:hover {
        background-color: #0000000d;
    }

    .google-places-autocomplete {
        position: relative;
    }

    .location_terms_use_current_location {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #1C1CA8;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
    }

    .location_terms_use_current_location svg {
        width: 24px;
        height: 24px;
    }

    .location_terms_agree_policy_line {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 24px;
        color: #5B5B62;
        font-weight: 450;
        font-size: 16px;
        line-height: 26px;
    }

    .location_terms_agree_policy_line svg {
        width: 20px;
        height: 20px;
    }

    .location_terms_submit_btn_width_height {
        width: 100%;
        height: 66px;
    }

    .location_terms_submit_btn_width_height:disabled {
        opacity: 0.6;
    }

    .address_details_map_div {
        position: relative;
    }

    .address_details_map_div>div:nth-child(2) {
        border-radius: 15px;
        width: 100%;
        height: 478px;
    }

    .address_details_right_side_div_thumbsUp_pic {
        position: absolute;
        bottom: -87px;
        right: 38px;
    }

    .address_details_right_side_div_thumbsUp_pic img {
        width: 130px;
        height: 147px;
    }
}