.become_a_partner_header_men_pic_mobile{
    display:none;
}
.become_a_partner_header_greeting_pic_mobile{

    display: none;
}
@media only screen and (max-width: 480px) {

/* become_a_partner_header starts */
.become_a_partner_header {
    background-color: #1C1CA8;
    position: relative;
}

.become_a_partner_header_left_side_content {
    grid-column: 1 / span 4;
    grid-row: 1;
    width: 86.6667vw;
    z-index: 10;
    margin-top: 4.4444vw;
}

.become_a_partner_header_left_side_content_first_line {
    font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFBF00;
    margin-bottom: 4.4444vw;
}

.become_a_partner_header_left_side_content_second_line {
    font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 4.4444vw;
}

.become_a_partner_header_left_side_content_second_line_inside_content_color {
    color: #FFBF00;
}

.become_a_partner_header_left_side_content_third_line {
    font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin-bottom: 4.4444vw;
}

/* .sign_up_your_business_btn_size svg {
    width: 80%;
    height: auto;
} */
.become_a_partner_header_sign_up_your_business_btn_size svg{
    width: 80%;
    height: auto;
}

.become_a_partner_header_men_pic {
    grid-column: 1 / span 4;
    grid-row: 2;
    width: 100%;
    z-index: 3;
    margin-left: 19%;
    display: none;
}

.become_a_partner_header_men_pic img {
    width: 100%;
}

.become_a_partner_header_men_pic_mobile{
    grid-column: 1 / span 4;
    grid-row: 2;
    width: 100%;
    z-index: 3;
    display:block;
    margin-left: -16px;
    margin-top: -17.7778vw;
}
.become_a_partner_header_men_pic_mobile img{
    width: 100vw;

}
.become_a_partner_header_women_pic{
    grid-column: 1 / span 4;
    grid-row: 2;
    width: 100%;
    z-index: 2;
    padding-top: 9%;
}
.become_a_partner_header_women_pic img{
    width: 82%;
    margin-left: -17%;
}

.become_a_partner_header_up_arrow_pic {
    grid-column: 1 / span 3;
    grid-row: 2;
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0%;    
    left: -43%;    
}

.become_a_partner_header_up_arrow_pic img {
    width: 82%;
}
.become_a_partner_header_down_arrow_pic {
    grid-column: 2 / span 3;
    grid-row: 2;
    width: 100%;
    z-index: 1;
    display: none;
}
.become_a_partner_header_mobile_view_down_arrow_pic{
    grid-column: 2 / span 3;
    grid-row: 2;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0%;
    right: -7%;
}
.become_a_partner_header_mobile_view_down_arrow_pic img{
    width: 100%;
}
.become_a_partner_header_down_arrow_pic img {
    width: 100%;
}

.become_a_partner_header_greeting_pic {
    grid-column:  3/ span 2;
    width: 100%;
    z-index: 4;
    position: absolute;
    bottom: -3%;
    left: 4px;
    display: none;
}
.become_a_partner_header_greeting_pic_mobile{
    grid-column:  3/ span 2;
    width: 100%;
    z-index: 4;
    width:44.4444vw;
    position: absolute;
    bottom: -21.6667vw;
    left: -5vw;
    display: block;

}
.become_a_partner_header_greeting_pic img{
    width: 100%;
}

.become_a_partner_header_greeting_pic_mobile img{
    width: 100%;
}

.corporate_partner_sign_up_your_business_width{

    width: 72.7778vw!important;    
}
/* become_a_partner_header ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

      /* become_a_partner_header starts */
     /* .become_a_partner_header {
        background-color: #1C1CA8;
        padding-top: 2%;
        position: relative;
        padding-bottom: 9%;
    }

    .become_a_partner_header_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 10;
        margin-top: 15%;
    }

    .become_a_partner_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 9px !important;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .become_a_partner_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 20px !important;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .become_a_partner_header_left_side_content_second_line_inside_content_color {
        color: #FFBF00;
    }

    .become_a_partner_header_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 11px !important;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }
    .become_a_partner_header_sign_up_your_business_btn_size svg{
        width: 50%;
        height: auto;
    }

    .become_a_partner_header_men_pic {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: absolute;
        bottom: 0%;
        right: -26%;
    }

    .become_a_partner_header_men_pic img {
        width: 100%;
    }
    .become_a_partner_header_women_pic{
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        margin-left: -12%;
    }
    .become_a_partner_header_women_pic img{
        width: 82%;
    }

    .become_a_partner_header_up_arrow_pic {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: -51%;
        z-index: 1;
    }

    .become_a_partner_header_up_arrow_pic img {
        width: 82%;
    }

    .become_a_partner_header_down_arrow_pic {
        grid-column: 5 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        top: -30%;
        right: -55%; 
    }

    .become_a_partner_header_down_arrow_pic img {
        width: 100%;
    }

    .become_a_partner_header_greeting_pic {
        grid-column: 6 / span 2;
        grid-row: 1;
        width: 100%;
        position: absolute;
        bottom: -19%;
        left: 0%;
        z-index: 4;
    }
    .become_a_partner_header_greeting_pic img{
        width: 100%;
    }
    .become_a_partner_header_mobile_view_down_arrow_pic{
        display: none;
    }

    /* become_a_partner_header ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* become_a_partner_header starts */
    .become_a_partner_header {
        background-color: #1C1CA8;
        position: relative;
    }

    .become_a_partner_header_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 10;
        margin-top: 4.5573vw;
    }

    .become_a_partner_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.125vw;
    }

    .become_a_partner_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .become_a_partner_header_left_side_content_second_line_inside_content_color {
        color: #FFBF00;
    }

    .become_a_partner_header_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    /* .sign_up_your_business_btn_size svg {
        width: 100%;
        height: auto;
    } */
    .become_a_partner_header_sign_up_your_business_btn_size svg{
        width: 50%;
        height: auto;
    }

    .become_a_partner_header_men_pic {
        grid-column: 3 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        position: absolute;
        bottom: 0%;
        right: -26%;
    }

    .become_a_partner_header_men_pic img {
        width: calc(100% + 22.152vw);
    }
    .become_a_partner_header_women_pic{
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        margin-left: -12%;
    }
    .become_a_partner_header_women_pic img{
        width: 82%;
    }

    .become_a_partner_header_up_arrow_pic {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: -51%;
        z-index: 1;
    }

    .become_a_partner_header_up_arrow_pic img {
        width: 82%;
    }

    .become_a_partner_header_down_arrow_pic {
        grid-column: 5 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        top: -30%;
        right: -55%; 
    }

    .become_a_partner_header_down_arrow_pic img {
        width: 100%;
    }

    .become_a_partner_header_greeting_pic {
        grid-column: 6 / span 2;
        grid-row: 1;
        width: 100%;
        position: absolute;
        bottom: -90px;
        left: 0%;
        z-index: 4;
    }
    .become_a_partner_header_greeting_pic img{
        width: 100%;
    }
    .become_a_partner_header_mobile_view_down_arrow_pic{
        display: none;
    }
    /* become_a_partner_header ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    
    /* become_a_partner_header starts */
    .become_a_partner_header {
        background-color: #1C1CA8;
        position: relative;
        aspect-ratio: 1440/667;
    }

    .become_a_partner_header_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        margin-top:4.375vw;
    }

    .become_a_partner_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 1.6667vw;
    }

    .become_a_partner_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw;
    }

    .become_a_partner_header_left_side_content_second_line_inside_content_color {
        color: #FFBF00;
    }

    .become_a_partner_header_left_side_content_third_line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.6667vw;
        }

    /* .sign_up_your_business_btn_size svg {
        width: 49%;
        height: auto;
    } */
    .become_a_partner_header_sign_up_your_business_btn_size svg{
        width: 49%;
        height: auto;
    }

    .become_a_partner_header_men_pic {
        grid-column: 6 / span 6;
        grid-row: 1;
        width: 54.1806vw;
        z-index: 3;
        position: absolute;
        align-self: flex-end;
        margin-left: 2vw;
    }

    .become_a_partner_header_men_pic img {
        width: calc(100% + 22.152vw);
    }
    .become_a_partner_header_women_pic{
        grid-column: 7 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        margin-left: -4%;
    }
    .become_a_partner_header_women_pic img{
        width: 100%;
    }

    .become_a_partner_header_up_arrow_pic {
        grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: 0%;
        z-index: 1;
    }

    .become_a_partner_header_up_arrow_pic img {
        width: 100%;
    }

    .become_a_partner_header_down_arrow_pic {
        grid-column: 11 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        top: -19%;
        right: 0%; 
    }

    .become_a_partner_header_down_arrow_pic img {
        width: 100%;
    }

    .become_a_partner_header_greeting_pic {
        grid-column: 10 / span 2;
        grid-row: 1;
        width: 13.6806vw;
        position: absolute;
        bottom: -6.1111vw;
        left: 3.4028vw;
        z-index: 4;
    }
    .become_a_partner_header_greeting_pic img{
        width: 100%;
    }
    .become_a_partner_header_mobile_view_down_arrow_pic {
        display: none;
    }
    /* become_a_partner_header ends */

}
@media only screen and (min-width: 1441px) {

     /* become_a_partner_header starts */
     .become_a_partner_header {
        background-color: #1C1CA8;
        position: relative;
        aspect-ratio: 1440/667;
    }

    .become_a_partner_header_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        margin-top:63px;
    }

    .become_a_partner_header_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .become_a_partner_header_left_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .become_a_partner_header_left_side_content_second_line_inside_content_color {
        color: #FFBF00;
    }

    .become_a_partner_header_left_side_content_third_line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        font-size: 25px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
        }

    /* .sign_up_your_business_btn_size svg {
        width: 49%;
        height: auto;
    } */
    .become_a_partner_header_sign_up_your_business_btn_size svg{
        height: auto;
    }

    .become_a_partner_header_men_pic {
        grid-column: 6 / span 6;
        grid-row: 1;
        z-index: 3;
        position: absolute;
        align-self: flex-end;
        margin-left: 14.4448px;
    }

    .become_a_partner_header_women_pic{
        grid-column: 7 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        margin-left: -4%;
    }

    .become_a_partner_header_up_arrow_pic {
        grid-column: 6 / span 5;
        grid-row: 1;
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: 0%;
        z-index: 1;
    }

    .become_a_partner_header_up_arrow_pic img {
        width: 100%;
    }

    .become_a_partner_header_down_arrow_pic {
        grid-column: 11 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 1;
        position: absolute;
        top: -19%;
        right: 0%; 
    }

    .become_a_partner_header_down_arrow_pic img {
        width: 100%;
    }

    .become_a_partner_header_greeting_pic {
        grid-column: 10 / span 2;
        grid-row: 1;
        position: absolute;
        bottom: -80px;
        left: 54.4448px;
        z-index: 4;
    }
    .become_a_partner_header_mobile_view_down_arrow_pic {
        display: none;
    }
    /* become_a_partner_header ends */

}