@media only screen and (max-width: 480px) {

    /* verify_an_agent__header_error_content starts */
    .verify_an_agent__header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .verify_an_agent__header_error_content div span img {
        margin-bottom: 7px;
    }

    /* verify_an_agent__header_error_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

      /* verify_an_agent__header_error_content starts */
      /* .verify_an_agent__header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    } */

    /* .verify_an_agent__header_error_content div span img {
        margin-bottom: 7px;
    } */

    /* verify_an_agent__header_error_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

   /* verify_an_agent__header_error_content starts */
   .verify_an_agent__header_error_text {
    font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.7343vw !important;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.verify_an_agent__header_error_content div span img {
    margin-bottom: 0.9114vw;
}

/* verify_an_agent__header_error_content ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* verify_an_agent__header_error_content starts */
    .verify_an_agent__header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .verify_an_agent__header_error_content div span img {
        margin-bottom: 7px;
    }

    /* verify_an_agent__header_error_content ends */

}
@media only screen and (min-width: 1601px) {

    /* verify_an_agent__header_error_content starts */
    .verify_an_agent__header_error_text {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21px !important;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .verify_an_agent__header_error_content div span img {
        margin-bottom: 7px;
    }

    /* verify_an_agent__header_error_content ends */

}