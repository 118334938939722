@media only screen and (max-width: 480px) {

    /* verify_an_agent_safety_first starts */
    .verify_an_agent_safety_first {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .verify_an_agent_safety_first_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        position: relative;

    }

    .verify_an_agent_safety_first_left_side_img .safetyFirstMenImg {
        width: 100%;
    }

    .lock_img {
        position: absolute;
        top: 20%;
        right: -7%;
        width: 30%;
    }

    .verify_an_agent_safety_first_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;

    }

    .verify_an_agent_safety_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .verify_an_agent_safety_first_right_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .verify_an_agent_safety_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* verify_an_agent_safety_first ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* verify_an_agent_safety_first starts */
    /* .verify_an_agent_safety_first {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .verify_an_agent_safety_first_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        position: relative;
        margin: auto 0px;

    }

    .verify_an_agent_safety_first_left_side_img .safetyFirstMenImg {
        width: 100%;
    }

    .lock_img {
        position: absolute;
        top: 20%;
        right: -7%;
        width: 30%;
    }

    .verify_an_agent_safety_first_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto;
    }

    .verify_an_agent_safety_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .verify_an_agent_safety_first_right_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .verify_an_agent_safety_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    } */

    /* verify_an_agent_safety_first ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* verify_an_agent_safety_first starts */
    .verify_an_agent_safety_first {
        background-color: #FFFFFF;
        padding-top:  3.9062vw;
        padding-bottom: 3.9062vw;
    }

    .verify_an_agent_safety_first_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        position: relative;
        margin: auto 0px;
    }

    .verify_an_agent_safety_first_left_side_img .safetyFirstMenImg {
        width: 100%;
    }

    .lock_img {
        position: absolute;
        top: 20%;
        right: -7%;
        width: 30%;
    }

    .verify_an_agent_safety_first_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto;
    }

    .verify_an_agent_safety_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .verify_an_agent_safety_first_right_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-top:3.125vw;
        margin-bottom:3.125vw;
    }

    .verify_an_agent_safety_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-top:1.0416vw;
    }

    /* verify_an_agent_safety_first ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

     /* verify_an_agent_safety_first starts */
     .verify_an_agent_safety_first {
        background-color: #FFFFFF;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .verify_an_agent_safety_first_left_side_img {
        grid-column: 1 / span 6;
        grid-row: 1;
        width: 100%;
        position: relative;

    }

    .verify_an_agent_safety_first_left_side_img .safetyFirstMenImg {
        width: 100%;
    }

    .lock_img {
        position: absolute;
        top: 20%;
        right: -7%;
        width: 30%;
    }

    .verify_an_agent_safety_first_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto;
    }

    .verify_an_agent_safety_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .verify_an_agent_safety_first_right_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .verify_an_agent_safety_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* verify_an_agent_safety_first ends */

}
@media only screen and (min-width: 1441px) {

     /* verify_an_agent_safety_first starts */
     .verify_an_agent_safety_first {
        background-color: #FFFFFF;
        padding-bottom: 140px;
    }

    .verify_an_agent_safety_first_left_side_img {
        grid-column: 1 / span 6;
        grid-row: 1;
        width: 100%;
        position: relative;
        margin-top: 120px;
        margin-left: -48px;
    }


    .lock_img {
        position: absolute;
        top: 20%;
        right: -7%;
        width: 30%;
    }

    .verify_an_agent_safety_first_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto;
        margin-top: 284px;
    }

    .verify_an_agent_safety_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .verify_an_agent_safety_first_right_side_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom:  24px;
    }

    .verify_an_agent_safety_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* verify_an_agent_safety_first ends */

}