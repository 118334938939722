@media only screen and (max-width: 480px) {

    /* terms_and_conditions_content starts */
    .terms_and_conditions_content {
        background-color: #FFFFFF;
        margin-top: 17.967vw;
        margin-bottom: 6.6667vw;
    }

    .terms_and_conditions_info {
        font-family: "Circular Std Bk", "Azo Sans", sans-serif;
        font-weight: 400;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .terms_and_conditions_info h1:first-child,
    .terms_and_conditions_info h2:first-child,
    .terms_and_conditions_info h3:first-child {
        margin-top: 0px;
    }

    .terms_and_conditions_info h1,
    .terms_and_conditions_info h2,
    .terms_and_conditions_info h3 {
        margin-top: 11.211vw;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 6.6667vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.6667vw;
    }

    .terms_and_conditions_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 4.4444vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.6667vw;
    }

    .terms_and_conditions_info_card {
        margin-bottom: 11.211vw;
    }

    .terms_and_conditions_info pre {
        white-space: break-spaces;
    }

    .terms_and_conditions_info ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    .terms_and_conditions_info ol>li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

    .terms_and_conditions_info ol>li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    .terms_and_conditions_info li ol>li {
        margin: 0;
    }

    .terms_and_conditions_info li ol>li:before {
        content: counters(item, ".") " ";
    }

    /* terms_and_conditions_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* terms_and_conditions_content starts */
    .terms_and_conditions_content {
        background-color: #FFFFFF;
        padding-top: 65px;
        padding-bottom: 30px;
    }

    .terms_and_conditions_info {
        font-family: "Circular Std Bk", "Azo Sans", sans-serif;
        font-weight: 400;
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .terms_and_conditions_info h1:first-child,
    .terms_and_conditions_info h2:first-child,
    .terms_and_conditions_info h3:first-child {
        margin-top: 0px;
    }

    .terms_and_conditions_info h1,
    .terms_and_conditions_info h2,
    .terms_and_conditions_info h3 {
        margin-top: 30px;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .terms_and_conditions_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .terms_and_conditions_info_card {
        margin-bottom: 30px;
    }

    .terms_and_conditions_info pre {
        white-space: break-spaces;
    }

    .terms_and_conditions_info ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    .terms_and_conditions_info ol>li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

    .terms_and_conditions_info ol>li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    .terms_and_conditions_info li ol>li {
        margin: 0;
    }

    .terms_and_conditions_info li ol>li:before {
        content: counters(item, ".") " ";
    }

    /* terms_and_conditions_content ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* terms_and_conditions_content starts */
    .terms_and_conditions_content {
        background-color: #FFFFFF;
        padding-top: 8.4635vw;
        padding-bottom: 3.9063vw;
    }

    .terms_and_conditions_info {
        font-family: "Circular Std Bk", "Azo Sans", sans-serif;
        font-weight: 400;
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
    }

    .terms_and_conditions_info h1:first-child,
    .terms_and_conditions_info h2:first-child,
    .terms_and_conditions_info h3:first-child {
        margin-top: 0px;
    }

    .terms_and_conditions_info h1,
    .terms_and_conditions_info h2,
    .terms_and_conditions_info h3 {
        margin-top: 3.9063vw;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 4.0365vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .terms_and_conditions_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2.6042vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 3.125vw;
    }

    .terms_and_conditions_info_card {
        margin-bottom: 3.9063vw;
    }

    .terms_and_conditions_info pre {
        white-space: break-spaces;
    }

    .terms_and_conditions_info ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    .terms_and_conditions_info ol>li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

    .terms_and_conditions_info ol>li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    .terms_and_conditions_info li ol>li {
        margin: 0;
    }

    .terms_and_conditions_info li ol>li:before {
        content: counters(item, ".") " ";
    }

    /* terms_and_conditions_content ends */


}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* terms_and_conditions_content starts */
    .terms_and_conditions_content {
        background-color: #FFFFFF;
        padding-top: 7.401vw;
        padding-bottom: 4.5833vw;
    }

    .terms_and_conditions_info {
        font-family: "Circular Std Bk", "Azo Sans", sans-serif;
        font-weight: 400;
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .terms_and_conditions_info h1:first-child,
    .terms_and_conditions_info h2:first-child,
    .terms_and_conditions_info h3:first-child {
        margin-top: 0px;
    }

    .terms_and_conditions_info h1,
    .terms_and_conditions_info h2,
    .terms_and_conditions_info h3 {
        margin-top: 4.8611vw;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 2.137vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.722vw;
    }

    .terms_and_conditions_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.389vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.0833vw;
    }

    .terms_and_conditions_info_card {
        margin-bottom: 4.8611vw;
    }

    .terms_and_conditions_info pre {
        white-space: break-spaces;
    }

    .terms_and_conditions_info ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    .terms_and_conditions_info ol>li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

    .terms_and_conditions_info ol>li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    .terms_and_conditions_info li ol>li {
        margin: 0;
    }

    .terms_and_conditions_info li ol>li:before {
        content: counters(item, ".") " ";
    }

    /* terms_and_conditions_content ends */

}

@media only screen and (min-width: 1441px) {

    /* terms_and_conditions_content starts */
    .terms_and_conditions_content {
        background-color: #FFFFFF;
        padding-bottom: 66px;
        padding-top: 106px;
    }

    .terms_and_conditions_info {
        font-family: "Circular Std Bk", "Azo Sans", sans-serif;
        font-weight: 400;
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .terms_and_conditions_info h1:first-child,
    .terms_and_conditions_info h2:first-child,
    .terms_and_conditions_info h3:first-child {
        margin-top: 0px;
    }

    .terms_and_conditions_info h1,
    .terms_and_conditions_info h2,
    .terms_and_conditions_info h3 {
        top-bottom: 70px;
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .terms_and_conditions_info p {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 30px;

    }

    .terms_and_conditions_info_card {
        margin-bottom: 70px;
    }

    .terms_and_conditions_info pre {
        white-space: break-spaces;
    }

    .terms_and_conditions_info ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    .terms_and_conditions_info ol>li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

    .terms_and_conditions_info ol>li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    .terms_and_conditions_info li ol>li {
        margin: 0;
    }

    .terms_and_conditions_info li ol>li:before {
        content: counters(item, ".") " ";
    }

    /* terms_and_conditions_content ends */


}