.community_first_left_side_img_mobile {

    display: none;
}

.community_first_top_right_img_mobile {

    display: none;
}

@media only screen and (max-width: 480px) {

    /* community_first starts */
    .community_first {
        background-color: #FFFFFF;
        position: relative;
        overflow: visible;
    }

    .community_first_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 219px;
        margin-top: 40px;
        margin-left: -6%;
        display: none;
    }

    .community_first_left_side_img_mobile {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        margin-left: -16px;
        display: block;
        margin-top: 11.1111vw;

    }

    .community_first_left_side_img img {
        width: 109%;
    }

    .community_first_left_side_img_mobile img {

        width: 97.7778vw;
    }

    .community_first_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 17.7778vw;
    }

    .community_first_top_right_img {
        z-index: 0;
        grid-row: 1;
        width: 108%;
        margin-top: -125%;
        display: none;
    }

    .community_first_top_right_img_mobile {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
        margin-top: -55.8333vw;
        display: block;
        margin-left: 10.8333vw;
    }

    .community_first_top_right_img img {
        width: 100%;
    }

    .community_first_top_right_img_mobile img {

        width: 60.8333vw;
    }

    .community_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.6667vw;
    }

    .community_first_right_side_content_second_line {
        font-family: Azo Sans;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.6667vw;
    }

    .community_first_right_side_content_third_line {
        font-family: 'Circular Std BK', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.6667vw;
    }

    .sign_up_your_business_btn_size svg {
        width: 75%;
        height: auto;
    }

    .sign_up_your_business_width {
        width: 72.7778vw !important;
    }

    /* community_first ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* community_first starts */
    /*  .community_first {
        background-color: #FFFFFF;
        padding-top: 17%;
        padding-bottom: 50px;
        overflow: hidden;
        position: relative;
    }

    .community_first_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin: auto 0px;
        margin-left: -9%;
        padding-top: 15%;
    }

    .community_first_left_side_img img {
        width: 105%;
    }

    .community_first_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 15%;
        margin-left: 2%;
    }

    .community_first_top_right_img {
        z-index: 0;
        grid-row: 1;
        width: 100%;
        position: absolute;
        bottom: 93%;
        left: 8%;
    }

    .community_first_top_right_img img {
        width: 100%;
    }

    .community_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 11px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .community_first_right_side_content_second_line {
        font-family: Azo Sans;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    
    .community_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }
    
    .sign_up_your_business_btn_size svg {
        width: 75% !important;
        height: auto !important;
    }
    
    .otp_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 38px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .sign_up_your_business_width{
        width: 210px;
    }

    /* community_first ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    @media only screen and (max-width: 600px) {
        .community_first_left_side_img {
            margin-left: -16px !important;

        }
    }

    /* community_first starts */
    .community_first {
        background-color: #FFFFFF;
        position: relative;
        overflow: visible;
    }

    .community_first_left_side_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 15.625vw;
        margin-left: -24px;
    }

    .community_first_left_side_img img {
        width: 100%;
    }


    .community_first_top_right_img {
        z-index: 0;
        width: 49.52vw;
        height: 49.652vw;
        grid-row: 1;
        position: absolute;
        top: -31.736vw;
        right: -12.569vw;
    }

    .community_first_top_right_img img {
        width: 100%;
    }

    .community_first_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        padding-top: 19.2708vw;
    }

    .community_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .community_first_right_side_content_second_line {
        font-family: Azo Sans;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .otp_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .community_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 3.125vw;
    }

    .sign_up_your_business_btn_size svg {
        width: 75% !important;
        height: auto !important;
    }

    .sign_up_your_business_width {
        width: 33.8542vw !important;
    }

    /* community_first ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {


    /* community_first starts */
    .community_first {
        background-color: #FFFFFF;
        position: relative;
        overflow: visible;
    }

    .community_first_left_side_img {
        grid-column: 1 / span 7;
        grid-row: 2;
        width: 52.9167vw;
        margin-top: 22.1528vw;
        margin-left: -48px;
    }

    .community_first_left_side_img img {
        width: 100%;
    }

    .community_first_top_right_img {
        z-index: 0;
        width: 49.52vw;
        height: 49.652vw;
        grid-row: 1;
        position: absolute;
        top: -31.736vw;
        right: -12.569vw;
    }

    .community_first_top_right_img img {
        width: 100%;
    }

    .community_first_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
        /* margin-top: 15%; */
        margin-top: 27.7778vw;
    }

    .community_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .community_first_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;
    }

    .otp_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;
    }

    .community_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.6667vw;
    }

    .sign_up_your_business_btn_size svg {
        width: 65% !important;
        height: auto !important;
    }

    .sign_up_your_business_width {
        width: 18.0556vw !important;
    }

    /* community_first ends */

}

@media only screen and (min-width: 1441px) {

    /* community_first starts */
    .community_first {
        background-color: #FFFFFF;
        position: relative;
        overflow: visible;
    }

    .community_first_left_side_img {
        grid-column: 1 / span 7;
        grid-row: 2;
        margin-top: 319px;
        margin-left: -48px;
    }

    .community_first_top_right_img {
        z-index: 0;
        width: 715px;
        height: 715px;
        grid-row: 1;
        position: absolute;
        top: -457px;
        right: -181px;
    }


    .community_first_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
        /* margin-top: 15%; */
        margin-top: 400px;
    }

    .community_first_right_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .community_first_left_side_img img {
        width: 100%;
    }

    .community_first_right_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .otp_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .community_first_right_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .sign_up_your_business_btn_size svg {
        width: 65% !important;
        height: auto !important;
    }

    .sign_up_your_business_width {
        width: 260px !important;
    }

    /* community_first ends */

}