@media only screen and (max-width: 480px) {
  /* get-started-heading-row strts */
  .get-started-heading-row {
    background-color: #ffffff;
  }

  .heading {
    grid-column: 1 / span 4;
    grid-row: 1;
    text-align: center;
    padding-top: 16.389vw;
  }

  .get-started-heading-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ff4600;
    margin-bottom: 6.667vw;
  }

  .get-started-heading-second-line {
    font-family: "Azo Sans Bk";
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1c1ca8;
    margin-top: 6.667vw;
    margin-bottom: 11.311vw !important;
  }

  /* get-started-heading-row ends */

  /* get-started-card-section-row starts */
  .get-started-card-section-row {
    background-color: #ffffff;
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .get-started-card {
    grid-column: 1 / span 4;
    grid-row: 1;
    background: #f7f8fa;
    border-radius: 16px;
    padding: 6.667vw 6.667vw;
    width: 100%;
    margin-bottom: 6.667vw;
  }

  .second-card {
    grid-column: 1 / span 4;
    grid-row: 2;
    background: #f7f8fa;
    border-radius: 16px;
    padding: 6.667vw 4.444vw;
    width: 91.999vw;
    margin-bottom: 6.667vw;
  }

  .third-card {
    grid-column: 1 / span 4;
    grid-row: 3;
    background: #f7f8fa;
    border-radius: 16px;
    padding: 6.667vw 4.444vw;
    width: 91.999vw;
    margin-bottom: 6.667vw;
  }

  /* .get-started-card {
    grid-column: 1 / span 4;
    grid-row: 1;
    background: #F7F8FA;
    border-radius: 16px;
    padding: 25px;
    margin: 10px;
    width: 100%;
}

.second-card {
    grid-column: 1 / span 4;
    grid-row: 2;
    background: #F7F8FA;
    border-radius: 16px;
    padding: 25px;
    margin: 10px;
}

.third-card {
    grid-column: 1 / span 4;
    grid-row: 3;
    background: #F7F8FA;
    border-radius: 16px;
    padding: 25px;
    margin: 10px;
} */

  .get-started-card-section-row-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 5.556vw;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #27272f;
    margin-top: 4.544vw;
    margin-bottom: 1.211vw;
  }

  .get-started-card-section-row-second-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 4.444vw;
    line-height: 160%;
    letter-spacing: -0.01em;
    color: #5b5b62;
    margin-bottom: 0px;
  }

  .get-started-card .get-started-card-icon {
    width: 17.778vw;
    height: 17.778vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
  }

  .get-started-card .get-started-card-icon img {
    width: 8.889vw;
    height: 8.889vw;
  }

  .second-card svg {
    width: 17.778vw;
    height: 17.778vw;
  }

  .third-card svg {
    width: 6517.778vwpx;
    height: 17.778vw;
  }

  /* get-started-card-section-row endss */

  /* get-starte-btn-row starts */
  .get-starte-btn-row {
    background-color: #ffffff;
  }

  .get_starte_btn {
    grid-column: 1 / span 4;
    grid-row: 1;
    text-align: center;
    padding-bottom: 17.778vw;
  }

  /* .get-starte-btn-row-sales-agent-btn-styles {
    grid-column: 1 / span 4;
    grid-row: 1;
    text-align: center;
    width: 50%;
} */

  .get-starte-btn-row-sales-agent-btn-styles button {
    background-color: #ff4600;
    border-radius: 25px;
    border: none;
    padding: 7px 12px;
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    margin: 15px 0px;
  }

  .view_all_faq_btn_size svg {
    width: 70%;
    height: auto;
    aspect-ratio: 213/50;
  }

  /* get-starte-btn-row starts */
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  /* get-started-heading-row strts */
  /*  .get-started-heading-row {
        padding-top: 10%;
        background-color: #FFFFFF;
    }

    .heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        text-align: center;
    }

    .get-started-heading-first-line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .get-started-heading-second-line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 35px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    /* get-started-heading-row ends */

  /* get-started-card-section-row starts */
  /* .get-started-card-section-row {
        padding-top: 16px;
        background-color: #FFFFFF;
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        display: flex!important;
    }

    .get-started-card {
        background: #F7F8FA;
        border-radius: 16px;
        padding-top: 25px;
        width: 100%;
        margin: 0px auto;
    }

    .second-card {
        background: #F7F8FA;
        border-radius: 16px;
        padding-top: 25px;
        width: 31%;
        margin: 0px auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .third-card {
        background: #F7F8FA;
        border-radius: 16px;
        padding-top: 25px;
        width: 31%;
        margin: 0px auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .get-started-card-section-row-first-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .get-started-card-section-row-second-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .get-started-card p svg {
        width: 64px;
        height: 64px;
    }

    .second-card p svg {
        width: 64px;
        height: 65px;
    }

    .third-card p svg {
        width: 65px;
        height: 64px;
    }

    /* get-started-card-section-row endss */

  /* get-starte-btn-row starts */
  /* .get-starte-btn-row {
        padding-top: 10%;
        padding-bottom: 5%;
        background-color: #FFFFFF;
    }

    .get_starte_btn {
        grid-column: 1 / span 8;
        grid-row: 1;
        text-align: center;
    }

    .get-starte-btn-row-sales-agent-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 7px 12px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .view_all_faq_btn_size svg {
        width: 34%;
        height: auto;
        aspect-ratio: 213/50;
    }

    /* get-starte-btn-row starts */
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
  /* get-started-heading-row strts */
  .get-started-heading-row {
    background-color: #ffffff;
  }

  .heading {
    grid-column: 2 / span 6;
    grid-row: 1;
    text-align: center;
  }

  .get-started-heading-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ff4600;
    margin-top: 6.641vw;
    margin-bottom: 3.125vw;
  }

  .get-started-heading-second-line {
    font-family: "Azo Sans Bk";
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1c1ca8;
    margin-bottom: 6.12vw !important;
  }

  /* get-started-heading-row ends */

  /* get-started-card-section-row starts */
  .get-started-card-section-row-cards {
    background-color: #ffffff;
    grid-column: 1 / span 8;
    grid-row: 1;
    gap: 2.94VW;
    width: 100vw;
    display: flex !important;
  }

  .get-started-card {
    background: #f7f8fa;
    border-radius: 16px;
    padding: 3.125vw;
    min-height: 35.677vw;
    width: 100%;
  }

  .second-card {
    background: #f7f8fa;
    border-radius: 16px;
    padding: 3.125vw;
    height: 35.677vw;
    width: 29.7526vw;
    margin: 0px 17.25px 0px 0px;
  }

  .third-card {
    background: #f7f8fa;
    border-radius: 16px;
    padding: 3.125vw;
    height: 35.677vw;
    width: 29.7526vw;
    margin: 0px;
  }

  .get-started-card-section-row-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2.344vw;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #27272f;
    margin-bottom: 0.521vw;
  }

  .get-started-card-section-row-second-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.823vw;
    line-height: 160%;
    letter-spacing: -0.01em;
    color: #5b5b62;
  }

  .get-started-card .get-started-card-icon {
    width: 8.333vw;
    height: 8.333vw;
    margin-bottom: 2.083vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
  }

  .get-started-card .get-started-card-icon img {
    width: 4.1665vw;
    height: 4.1665vw;
  }

  .second-card svg {
    width: 8.333vw;
    height: 8.333vw;
    margin-bottom: 2.083vw;
  }

  .third-card svg {
    width: 8.333vw;
    height: 8.333vw;
    margin-bottom: 2.083vw;
  }

  /* get-started-card-section-row endss */

  /* get-starte-btn-row starts */
  .get-starte-btn-row {
    background-color: #ffffff;
  }

  .get_starte_btn {
    grid-column: 1 / span 8;
    grid-row: 1;
    width: 100%;
    text-align: center;
    margin-top: 7.422vw;
    margin-bottom: 7.422vw;
  }

  .get-starte-btn-row-sales-agent-btn-styles button {
    background-color: #ff4600;
    border-radius: 25px;
    border: none;
    padding: 7px 12px;
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    margin: 15px 0px;
  }

  .view_all_faq_btn_size svg {
    width: 33.6%;
    height: auto;
    aspect-ratio: 213/50;
  }

  /* get-starte-btn-row starts */
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
  @media only screen and (max-width: 1110px) {
    /* get-starte-btn-row starts */
    .get_start_btn_row_view_all_faq_btn_div {
      width: 70% !important;
    }

    /* get-starte-btn-row ends */
  }

  /* get-started-heading-row strts */
  .get-started-heading-row {
    background-color: #ffffff;
  }

  .heading {
    grid-column: 4 / span 6;
    grid-row: 1;
    width: 100%;
    text-align: center;
    padding-top: 10.139vw;
    padding-bottom: 3.889vw;
  }

  .get-started-heading-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ff4600;
    margin-bottom: 1.727vw;
  }

  .get-started-heading-second-line {
    font-family: "Azo Sans Bk";
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1c1ca8;
    margin-bottom: 0vw !important;
    margin-top: 0vw !important;
  }

  /* get-started-heading-row ends */

  /* get-started-card-section-row starts */
  .get-started-card-section-row {
    background-color: #ffffff;
  }
  .get-started-card-section-row-cards {
    grid-column: 2 / span 10;
    grid-row: 1;
    width: 100%;
    gap: 1.715vw;
    display: flex;
    margin-bottom: 5.555vw;
  }

  .get-started-card {
    background: #f7f8fa;
    border-radius: 16px;
    width: 100%;
    min-height: 16.667vw;
    padding: 1.667vw;
  }

  .second-card {
    background: #f7f8fa;
    border-radius: 16px;
    width: 24.727vw;
    height: 16.667vw;
    padding: 1.667vw;
    margin: 0vw 1.555vw;
  }

  .third-card {
    background: #f7f8fa;
    border-radius: 16px;
    width: 24.727vw;
    height: 16.667vw;
    padding: 1.667vw;
  }

  .get-started-card-section-row-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #27272f;
    margin-bottom: 0.278vw;
  }

  .get-started-card-section-row-second-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 160%;
    letter-spacing: -0.01em;
    color: #5b5b62;
    font-size: 1.111vw;
  }

  .get-started-card .get-started-card-icon {
    width: 4.444vw;
    height: 4.444vw;
    margin-bottom: 1.171vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
  }

  .get-started-card .get-started-card-icon img {
    width: 2.222vw;
    height: 2.222vw;
  }

  .second-card svg {
    width: 4.445vw;
    height: 4.445vw;
    margin-bottom: 1.171vw;
  }

  .third-card svg {
    width: 4.445vw;
    height: 4.445vw;
    margin-bottom: 1.111vw;
  }

  /* get-started-card-section-row endss */

  /* get-starte-btn-row starts */
  .get-starte-btn-row {
    background-color: #ffffff;
    text-align: center;
  }

  .get_starte_btn {
    grid-column: 6 / span 2;
    grid-row: 1;
    text-align: center;
    margin-left: 0.347vw;
    margin-bottom: 10.455vw;
  }

  .get-starte-btn-row-sales-agent-btn-styles button {
    background-color: #ff4600;
    border-radius: 25px;
    border: none;
    padding: 15px 35px;
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 20px;
    color: #ffffff;
    margin: 15px 0px;
  }

  .view_all_faq_btn_size svg {
    width: 50%;
    height: auto;
    aspect-ratio: 213/50;
  }

  /* get-starte-btn-row starts */
}

@media only screen and (min-width: 1441px) {
  /* get-started-heading-row strts */
  .get-started-heading-row {
    aspect-ratio: 1440/300;
    background-color: #ffffff;
  }

  .heading {
    grid-column: 4 / span 6;
    grid-row: 1;
    text-align: center;
    padding-top: 146px;
    padding-bottom: 56px;
  }

  .get-started-heading-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ff4600;
    margin-bottom: 24px;
  }

  .get-started-heading-second-line {
    font-family: "Azo Sans Bk";
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1c1ca8;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  /* get-started-heading-row ends */

  /* get-started-card-section-row starts */
  .get-started-card-section-row {
    background-color: #ffffff;
  }

  .get-started-card {
    background: #f7f8fa;
    border-radius: 16px;
    width: 100%;
    min-height: 240px;
    padding: 24px;
  }

  .second-card {
    background: #f7f8fa;
    border-radius: 16px;
    padding-top: 25px;
    width: 356px;
    height: 240px;
    margin-right: 26.73px;
    padding: 24px;
  }

  .third-card {
    margin-right: 0px;
  }

  .get-started-card-section-row-first-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #27272f;
    margin-bottom: 4px;
  }

  .get-started-card-section-row-second-line {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 160%;
    letter-spacing: -0.01em;
    color: #5b5b62;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .get-started-card .get-started-card-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
  }

  .second-card svg {
    margin-bottom: 16px;
  }

  .third-card svg {
    margin-bottom: 16px;
  }

  /* get-started-card-section-row endss */

  /* get-starte-btn-row starts */
  .get-starte-btn-row {
    background-color: #ffffff;
    margin-bottom: 150px;
  }

  .get_starte_btn {
    grid-column: 4 / span 6;
    grid-row: 1;
    text-align: center;
  }

  .get-starte-btn-row-sales-agent-btn-styles button {
    background-color: #ff4600;
    border-radius: 25px;
    border: none;
    padding: 15px 35px;
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 20px;
    color: #ffffff;
    margin: 15px 0px;
  }

  .view_all_faq_btn_size svg {
    width: 213px;
    height: 54px;
  }

  .get_start_btn_row_view_all_faq_btn_div {
    grid-column: 1 / span 4;
    grid-row: 1;
    text-align: center;
    margin: 0px auto;
    background-color: #ff4600;
    width: 55%;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .get_start_btn_row_view_all_faq_btn_div .button_sty {
    width: 100%;
    display: flex;
    background-color: #ff4600;
    justify-content: space-between;
    padding: 6.2% 8%;
    border-radius: 100px;
    border: none;
    text-decoration: none;
  }

  .get_start_btn_row_view_all_faq_btn_div .button_sty span {
    font-family: "Circular Std Bk", "Azo Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #ffffff;
  }

  .get_start_btn_row_view_all_faq_btn_div .button_sty span svg {
    width: 22px;
    height: 24px;
  }
  .get-started-card-section-row-cards {
    grid-column: 2 / span 10;
    grid-row: 1;
    gap: 26.7px;
    width: 100%;
    display: flex;
    margin-bottom: 54px;
  }

  /* get-starte-btn-row starts */
}
