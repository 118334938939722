.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* styles */
@import url('../src/assets/css/fonts.css');
@import url('../src/assets/css/welcome_page.css');
@import url('../src/assets/css/contact.css');
@import url('../src/assets/css/get_started.css');
@import url('../src/assets/css/partner.css');
@import url('../src/assets/css/backgrounds.css');
@import url('../src/assets/css/people_with_products.css');
@import url('../src/assets/css/home_sales_agent.css');
@import url('../src/assets/css/become_go_121_sales_agent.css');
@import url('../src/assets/css/brand_benefit.css');
@import url('../src/assets/css/ready_to_become_sales_agent.css');
@import url('../src/assets/css/become_an_agent.css');
@import url('../src/assets/css/be_your_own_boss.css');
@import url('../src/assets/css/how_to_become_agent.css');
@import url('../src/assets/css/make_money.css');
@import url('../src/assets/css/it_is_convinient.css');
@import url('../src/assets/css/here_help.css');
@import url('../src/assets/css/what_is_go_121.css');
@import url('../src/assets/css/community_first.css');
@import url('../src/assets/css/meet_our_agents.css');
@import url('../src/assets/css/products_and_services.css');
@import url('../src/assets/css/become_a_partner.css');
@import url('../src/assets/css/create_jobs.css');
@import url('../src/assets/css/powerful_sales_tool.css');
@import url('../src/assets/css/three_easy_steps.css');
@import url('../src/assets/css/our_biggest_brands.css');
@import url('../src/assets/css/corporate_partner.css');
@import url('../src/assets/css/about_us_header.css');
@import url('../src/assets/css/partner_sign_up_form.css');
@import url('../src/assets/css/partner_sign_up_form_new.css');
@import url('../src/assets/css/partner_sign_up_error.css');
@import url('../src/assets/css/partner_sign_up_thank_you.css');
@import url('../src/assets/css/about_us_our_values.css');
@import url('../src/assets/css/empower_community.css');
@import url('../src/assets/css/about_us_cards.css');
@import url('../src/assets/css/join_our_team.css');
@import url('../src/assets/css/testemonials.css');
@import url('../src/assets/css/our_story.css');
@import url('../src/assets/css/about_us_ready_to_become_partner.css');
@import url('../src/assets/css/help_center.css');
@import url('../src/assets/css/help_center_cards.css');
@import url('../src/assets/css/help_center_here_help.css');
@import url('../src/assets/css/popular_sales_agent_below_cards.css');
@import url('../src/assets/css/still_have_questions.css');
@import url('../src/assets/css/still_have_a_question_error.css');
@import url('../src/assets/css/still_have_a_question_thank_you.css');
@import url('../src/assets/css/verify_an_agent_why_become_an_agent.css');
@import url('../src/assets/css/help_center_search_on_result_error.css');
@import url('../src/assets/css/was_this_artical_helpful.css');
@import url('../src/assets/css/thank_you_page.css');
@import url('../src/assets/css/default_error_page.css');
@import url('../src/assets/css/privacy_policy.css');
@import url('../src/assets/css/verify_an_agent_why_become_an_agent.css');
@import url('../src/assets/css/help_center_benefits.css');
@import url('../src/assets/css/why_get_pos_machine.css');
@import url('../src/assets/css/verify_an_agent_safety_first.css');
@import url('../src/assets/css/verify_an_agent.css');
@import url('../src/assets/css/stuck_we_here_to_help.css');
@import url('../src/assets/css/verify_an_agent_header_error.css');
@import url('../src/assets/css/verify_an_agent_header_success.css');
@import url('../src/assets/css/sipho_is_trained.css');
@import url('../src/assets/css/recommendations.css');
@import url('../src/assets/css/terms_and_conditions.css');
@import url('../src/assets/css/terms_and_conditions_content.css');
@import url('../src/assets/css/error_message.css');
@import url('../src/assets/css/carousel.css');
@import url('../src/assets/css/navbar.css');
@import url('../src/assets/css/footer.css');
@import url('../src/assets/css/grids.css');
@import url('../src/assets/css/notification.css');
@import url('../src/assets/css/personal_details.css');
@import url('../src/assets/css/personal_details_form_otp.css');
@import url('../src/assets/css/cell_phone_input.css');
@import url('../src/assets/css/address_details.css');
@import url('../src/assets/css/application_form_complete_new.css');
@import url('aos/dist/aos.css');
@import url("animate.css/animate.min.css");