@media only screen and (max-width: 480px) {

    /* how_to_become_angent starts */
    .how_to_become_angent {
        background-color: #FFFFFF;
    }

    .how_to_become_angent_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 16.667vw;

    }

    .how_to_become_angent_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-top: 16.667vw;
    }

    .how_to_become_angent_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.667vw;

    }

    .how_to_become_angent_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.667vw;
    }

    .how_to_become_angent_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.667vw;
    }

    .how_to_become_angent_left_side_content_btn button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .how_to_become_angent_right_side_content_first_line {
        font-family: "Azo Sans";
        font-style: normal;
        font-weight: bold;
        line-height: 130%;
        font-size: 6.667vw;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        align-self: flex-end;
    }

    .how_to_become_angent_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        width: 79.444vw;
        margin-bottom: 16.944vw;
    }

    .svg_img_sizes svg {
        width: 9.167vw;
        height: 9.167vw;
        margin-right: 4.444vw;
    }

    /* how_to_become_angent ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* how_to_become_angent starts */
    /* .how_to_become_angent {
        background-color: #FFFFFF;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .how_to_become_angent_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .how_to_become_angent_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .how_to_become_angent_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .how_to_become_angent_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .how_to_become_angent_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .how_to_become_angent_left_side_content_btn button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .how_to_become_angent_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .how_to_become_angent_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .svg_img_sizes svg {
        width: 33px;
        height: 34px;
        margin-right: 10px;
    }

    /* how_to_become_angent ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* how_to_become_angent starts */
    .how_to_become_angent {
        background-color: #FFFFFF;
    }

    .how_to_become_angent_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 108px;
    }

    .how_to_become_angent_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 108px;
        margin-bottom: 32px;
    }

    .how_to_become_angent_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .how_to_become_angent_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .how_to_become_angent_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;

        margin-bottom: 24px;
    }

    .how_to_become_angent_left_side_content_btn button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .how_to_become_angent_right_side_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .how_to_become_angent_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .svg_img_sizes svg {
        width: 33px;
        height: 34px;
        margin-right: 10px;
    }

    /* how_to_become_angent ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* how_to_become_angent starts */
    .how_to_become_angent {
        background-color: #FFFFFF;
    }

    .how_to_become_angent_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-left: 0.726vw;
        margin-top: 9.255vw;
        margin-bottom: 20.682vw;
    }

    .how_to_become_angent_right_side_content {
        grid-column: 7 / span 6;
        grid-row: 1;
        width: 98%;
        margin-top: 12.751vw;
        margin-bottom: 10.878vw;
        margin-left: 1.452vw;
    }

    .how_to_become_angent_right_side_content .list-item {
        margin-bottom: 4.167vw;

    }

    .how_to_become_angent_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.667vw;
    }

    .how_to_become_angent_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.727vw;
    }

    .how_to_become_angent_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.667vw;
    }

    .how_to_become_angent_left_side_content_btn button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .how_to_become_angent_right_side_content_first_line {
        font-family: 'Azo Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 2.153vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .how_to_become_angent_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 160%;
        font-size: 1.111vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .svg_img_sizes svg {
        width: 2.292vw;
        height: 2.292vw;
        margin-right: 1.111vw;
    }

    /* how_to_become_angent ends */

}

@media only screen and (min-width: 1441px) {

    /* how_to_become_angent starts */
    .how_to_become_angent {
        background-color: #FFFFFF;
    }

    .how_to_become_angent_left_side_content {
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
        margin-left: 7px;
    }

    .how_to_become_angent_right_side_content {
        grid-column: 7 / span 5;
        grid-row: 1;
        width: 100%;
        margin-top: 323px;
        margin-bottom: 156px;
        margin-left: 14px;
    }

    .how_to_become_angent_right_side_content .list-item {
        margin-bottom: 60px;

    }

    .how_to_become_angent_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;

        text-transform: uppercase;
        color: #FF4600;
        margin-top: 273px;
        margin-bottom: 24px;
    }

    .how_to_become_angent_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;

        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;

    }

    .how_to_become_angent_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .how_to_become_angent_left_side_content_btn button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    .how_to_become_angent_right_side_content_first_line {
        font-family: 'Azo Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .how_to_become_angent_right_side_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 160%;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .svg_img_sizes svg {
        width: 33px;
        margin-right: 16px;
    }

    /* how_to_become_angent ends */


}