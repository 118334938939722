.partner-left-side-img-mobile {
    display: none;
}

.partner-left-side-img-tablet2 {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* people-with-products starts */
    .people-with-products {
        background-color: #1C1CA8;
    }

    .partner-left-side-img {
        grid-column: 1/ span 4;
        grid-row: 2;
        width: 150vw;
        height: auto;
        text-align: center;
        margin-left: 6.111vw;
        display: none;
    }

    .partner-left-side-img-mobile {
        grid-column: 1/ span 4;
        grid-row: 2;
        width: 100%;
        text-align: center;
        margin-left: 5.556vw;
        display: block;
        margin-top: 6.789vw;
        margin-bottom: 16.155vw;
    }

    .partner-left-side-img-mobile img {
        width: 88.89vw;
        height: 92.882vw;
    }

    .partner-left-side-img img {
        width: 100%;
    }

    .partner-right-side-content {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
    }

    .people-with-products-first-line {
        margin-top: 15.656vw;
        margin-bottom: 6.667vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .people-with-products-second-line {
        margin-bottom: 6.667vw;
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .people-with-products-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6.667vw;
    }

    .people-with-products-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 11px 18px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* people-with-products ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* people-with-products starts */
    /* .people-with-products {
        padding-top: 10%;
        padding-bottom: 5%;
        background-color: #1C1CA8;
    }

    .partner-left-side-img {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .partner-left-side-img img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
    }

    .partner-right-side-content {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
    }

    .people-with-products-first-line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .people-with-products-second-line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .people-with-products-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .people-with-products-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* people-with-products ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* people-with-products starts */
    .people-with-products {
        background-color: #1C1CA8;
        height: 68.099vw;
    }

    .partner-left-side-img {
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .partner-left-side-img-tablet2 {
        margin-top: 9.375vw;
        display: block;
        grid-column: 1/ span 4;
        grid-row: 1;
        width: 42.708vw;
        height: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -0.391vw;
    }

    .partner-left-side-img img {
        width: 100%;
    }

    .partner-left-side-img-tablet2 img {
        width: 42vw;
    }

    .partner-right-side-content {
        grid-column: 5/ span 4;
        grid-row: 1;
        width: 45vw;
        height: auto;
        margin-top: 6.641vw;
    }

    .people-with-products-first-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.125vw;
    }

    .people-with-products-second-line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.125vw;
    }

    .people-with-products-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 3.125vw !important;
        margin-top: 0px !important;
    }

    .people-with-products-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* people-with-products ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    @media only screen and (max-width: 1110px) {

        /* people-with-products starts */
        .people_with_products_become_an_agent_btn_div {
            width: 85% !important;
        }

        /* people-with-products ends */

    }

    /* people-with-products starts */
    .people-with-products {
        background-color: #1C1CA8;
        aspect-ratio: 1440/900;
    }

    .partner-left-side-img {
        grid-column: 2/ span 6;
        grid-row: 1;
        width: 100%;
        /* aspect-ratio: 616.41/659; */
        margin: 4.494vw 0vw 12.312vw 0.255vw;
    }

    .partner-left-side-img img {
        width: 42.855vw;
        height: 45.755vw;
    }

    .partner-right-side-content {
        grid-column: 8/ span 4;
        grid-row: 1;
        width: 100%;
        margin: 13.264vw 0vw 13.224vw 0.192vw;
        /* height: auto; */
        /* padding-top: 50%; */
    }

    .people-with-products-first-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 1.667vw;
    }

    .people-with-products-second-line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.667vw;
    }

    .people-with-products-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.667vw !important;
    }

    .people-with-products-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* people-with-products ends */

}

@media only screen and (min-width: 1441px) {

    /* people-with-products starts */
    .people-with-products {
        background-color: #1C1CA8;
    }

    .partner-left-side-img {
        grid-column: 2/ span 5;
        grid-row: 1;
        margin-top: 64px;
        margin-bottom: 169px;
        height: auto;
    }

    .partner-right-side-content {
        grid-column: 8/ span 4;
        grid-row: 1;
        height: auto;
        padding-top: 191px;
    }

    .people-with-products-first-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .people-with-products-second-line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .people-with-products-third-line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .people-with-products-btn-styles button {
        background-color: #FF4600;
        border-radius: 25px;
        border: none;
        padding: 15px 35px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 15px 0px;
    }

    /* people-with-products ends */

}