@media only screen and (max-width: 480px) {

    /* about_us_testimonials_carousel starts */
    .about_us_testimonials_carousel {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .about_us_testimonials {
        background-color: #055CF5;
    }

    .about-us-carousel-grid {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .about_us_testimonial_carousel_flex {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }


    .about_us_testimonials_carousel_content {
        text-align: center;
        grid-column: 1 / span 4;
        margin-top: 60px;
    }

    .about_us_testimonials_carousel_content {
        text-align: center;
    }


    .about_us_testimonials_carousel_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 24px;
    }

    .about_us_testimonials_carousel_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .about_us_testimonials_carousel_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .about_us_testimonials_buttons {
        display: flex;
        justify-content: center;
        column-gap: 16px;
    }

    .about_us_testimonial_carousel_buttons {
        padding-left: 0px;
        padding-right: 0px;
        grid-column: 1/span 4;
    }

    .carousel-control-next,
    .carousel-control-prev,
    .carousel-control-next:hover,
    .carousel-control-prev:hover {
        opacity: 1;
    }

    .carousel-control-next {
        position: relative;
        display: block;
        width: 13.8889vw;

    }

    .carousel-control-prev {
        position: relative;
        display: block;
        width: 13.8889vw;

    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: auto;
        height: auto;
    }

    .about_us_testimonials img {
        width: 13.8889vw;

    }

    .about_us_testimonials_indicator {
        /* bottom: -50px !important; */
        margin-top: 32px;
        position: relative;
        grid-column: 2/span 2;
        width: 84px;
        bottom: 0px !important;

    }

    .carousel-indicators {
        bottom: -50px !important;
    }

    /* about_us_testimonials_carousel ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_testimonials_carousel starts */
    /* .about_us_testimonials_carousel {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .about_us_testimonials {
        background-color: #055CF5;
    }

    .about_us_testimonials_carousel_content {
        text-align: center;
    }

    .about_us_testimonials_carousel_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .about_us_testimonials_carousel_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .about_us_testimonials_carousel_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .carousel-indicators {
        bottom: -50px !important;
    } */

    /* about_us_testimonials_carousel ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* about_us_testimonials_carousel starts */
    .about_us_testimonials_carousel {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        padding-bottom: 6.5104vw;
        padding-top: 6.5104vw;
    }

    .about_us_testimonials {
        background-color: #055CF5;
    }

    .about_us_testimonials_carousel_content {
        text-align: center;
        grid-column: 2 / span 6;
    }

    .about_us_testimonials_carousel_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 3.128vw;
    }

    .about_us_testimonials_carousel_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: bold;
        font-size: 2.1528vw;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 3.128vw;
    }

    .about_us_testimonials_carousel_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.389vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 3.128vw;
    }

    .about-us-carousel-grid {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: 4.1666vw;
        height: auto;
    }

    .carousel-control-prev-icon {
        margin-right: 6.5104vw;
    }

    .leftArrow, .rightArrow {
        width: 6.5104vw;
        height: 6.5104vw;
    }

    /* about_us_testimonials_carousel ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_testimonials_carousel starts */
    .about_us_testimonials_carousel {

        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        margin-top: 77px;
        margin-bottom: 65px;
    }

    .about_us_testimonials {
        background-color: #055CF5;
    }

    .about-us-carousel-grid {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .about_us_testimonials_carousel_content {
        text-align: center;
        grid-column: 4 / span 6;
    }

    .about_us_testimonials_carousel_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 2.7778vw;
    }

    .about_us_testimonials_carousel_content_second_line {
        font-family: 'Azo Sans';
        font-style: normal;
        font-weight: bold;
        line-height: 130%;
        font-size: 2.1528vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 2.7778vw;
    }

    .about_us_testimonials_carousel_content_third_line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 3.1944vw;
    }

    .carousel-control-next,
    .carousel-control-prev,
    .carousel-control-next:hover,
    .carousel-control-prev:hover {
        opacity: 1;
    }

    .carousel-control-next {
        margin-top: -0.6806vw;
        margin-right: 3.8194vw
    }

    .carousel-control-prev {
        margin-top: -2.0833vw;
        margin-left: 2.0833vw;
    }

    .about_us_testimonials img {
        width: 3.4722vw
    }

    .about_us_testimonials_indicator {
        position: absolute;
        width: 84px;
        bottom: 0px !important;
    }

    /* about_us_testimonials_carousel ends */

}

@media only screen and (min-width: 1441px) {

    /* about_us_testimonials_carousel starts */
    .about_us_testimonials_carousel {

        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        margin-top: 77px;
        margin-bottom: 65px;
    }

    .about_us_testimonials {
        background-color: #055CF5;
    }

    .about-us-carousel-grid {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .about_us_testimonials_carousel_content {
        text-align: center;
        grid-column: 4 / span 6;
    }

    .about_us_testimonials_carousel_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFBF00;
        margin-bottom: 40px;
    }

    .about_us_testimonials_carousel_content_second_line {
        font-family: 'Azo Sans';
        font-style: normal;
        font-weight: bold;
        line-height: 130%;
        font-size: 31px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 40px;
    }

    .about_us_testimonials_carousel_content_third_line {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 46px;
    }

    .carousel-control-next,
    .carousel-control-prev,
    .carousel-control-next:hover,
    .carousel-control-prev:hover {
        opacity: 1;

    }

    .carousel-control-next {

        margin-top: -9.8px;
        margin-right: 55px
    }

    .carousel-control-prev {
        margin-top: -30px;
        margin-left: 30px;
    }

    .about_us_testimonials img {
        width: 50px
    }

    .about_us_testimonials_indicator {
        /* bottom: -50px !important; */
        position: absolute;
        width: 84px;
        bottom: 0px !important;

    }


}