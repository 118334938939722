.our_values_layer_img_mobile {
    display: none;
}

.rounded_circle_arrow_img2_mobile {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* about_us_cards starts */
    .about_us_cards {
        padding-bottom: 1.3889vw;
        padding-top: 35.5556vw;
        background-image: url(../../assets/images/what_is_go121/Ellipse_bg_img_mobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .our_values_layer_img {
        position: absolute;
        top: -10.75vw;
        right: 15%;
        display: none;
    }

    .our_values_layer_img_mobile {
        display: block;
        position: absolute;
        top: -12.7778vw;
        right: 13.8889vw;
    }

    .our_values_layer_img img {
        width: 100px;
        height: 100px;
    }

    .our_values_layer_img_mobile img {
        width: 100%;
    }

    .about_us_cards_left_side_card {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .about_us_card_left_side_card_box {
        background-color: #055CF5;
        border-radius: 15px;
        padding: 12.2222vw 4.4444vw;
    }

    .about_us_cards_left_side_card_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 6.6667vw 0px;
    }

    .about_us_card_left_side_card_box img {
        width: 15.5556vw;
    }

    .about_us_cards_left_side_card_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 5.5556vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0px;
    }

    .rounded_circle_arrow_img {
        text-align: end;
        padding-top: 50px;
        padding-right: 50px;
    }

    .rounded_circle_arrow_img1_mobile {
        display: block;
        text-align: end;
        padding-top: 50px;
        padding-right: 50px;
        width: 81px;
    }

    .bg_rounded_circle_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        align-self: flex-end;
        display: block;
        margin-left: -73.8889vw;
        margin-bottom: -7.5vw;

    }

    .bg_rounded_circle_img img {
        width: 154.4444vw;
    }

    .about_us_cards_right_side_two_cards {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .about_us_cards_right_side_first_box {
        background-color: #8484E9;
        border-radius: 15px;
        padding: 12.2222vw 4.4444vw 20.5556vw;
        margin: 6.6667vw 0px;
    }

    .about_us_cards_right_side_first_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 6.6667vw 0px;
    }

    .about_us_cards_right_side_first_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 5.5556vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0px;
    }

    .about_us_cards_right_side_second_box {
        background-color: #41AD49;
        border-radius: 15px;
        padding: 12.2222vw 4.4444vw;
    }

    .about_us_cards_right_side_second_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 6.6667vw 0px;
    }

    .about_us_cards_right_side_second_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 5.5556vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0px;
    }

    .rounded_circle_arrow_img1 {
        text-align: end;
        padding-top: 50px;
        padding-right: 50px;
        display: none;
    }

    .rounded_circle_arrow_img2 {
        padding-top: 25px;
        padding-right: 25px;
        display: none;
    }

    .rounded_circle_arrow_img2_mobile {
        margin-top: 6.6667vw;
        margin-left: 5vw;
        display: block;
    }

    .rounded_circle_arrow_img2 img {
        width: 110px;
        height: 110px;
    }

    .rounded_circle_arrow_img2_mobile img {
        width: 22.5vw;
    }

    /* about_us_cards ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* about_us_cards starts */
    /* .about_us_cards {
        padding-bottom: 50px;
        padding-top: 150px;
        background-image: url(../../assets/images/what_is_go121/Ellipse_bg_img.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .our_values_layer_img {
        position: absolute;
        top: -2%;
        right: 15%;
    }

    .our_values_layer_img img {
        width: 100px;
        height: 100px;
    }

    .about_us_cards_left_side_card {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .about_us_card_left_side_card_box {
        background-color: #055CF5;
        border-radius: 15px;
        padding: 40px;
        margin-top: 50px;
    }

    .about_us_cards_left_side_card_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 25px 0px;
    }

    .about_us_cards_left_side_card_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .rounded_circle_arrow_img {
        text-align: end;
        padding-top: 50px;
        padding-right: 50px;
    }

    .bg_rounded_circle_img {
        grid-column: 1 / span 8;
        grid-row: 1;
        display: block;
        padding-top: 350px;
    }

    .bg_rounded_circle_img img {
        width: 100%;
    }

    .about_us_cards_right_side_two_cards {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .about_us_cards_right_side_first_box {
        background-color: #8484E9;
        border-radius: 15px;
        padding: 40px;
        margin: 25px 0px;
    }

    .about_us_cards_right_side_first_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 25px 0px;
    }

    .about_us_cards_right_side_first_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .about_us_cards_right_side_second_box {
        background-color: #41AD49;
        border-radius: 15px;
        padding: 40px;
    }

    .about_us_cards_right_side_second_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 25px 0px;
    }

    .about_us_cards_right_side_second_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .rounded_circle_arrow_img1 {
        text-align: end;
        padding-top: 10%;
        padding-right: 10%;
        display: block;
    }
    .rounded_circle_arrow_img1 img{
        width: 37%;
    }

    .rounded_circle_arrow_img2 {
        padding-top: 25px;
        padding-right: 25px;
        display: none;
    }

    .rounded_circle_arrow_img2 img {
        width: 110px;
        height: 110px;
    } */

    /* about_us_cards ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* about_us_cards starts */
    .about_us_cards {
        padding-bottom: 6.5104vw;
        padding-top: 19.5312vw;
        background-image: url(../../assets/images/what_is_go121/Ellipse_bg_img.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .our_values_layer_img {
        position: absolute;
        top: -2%;
        right: 15%;
    }

    .our_values_layer_img img {
        width: 13.02083vw;
        height: 13.02083vw;
    }

    .about_us_cards_left_side_card {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .about_us_card_left_side_card_box {
        background-color: #055CF5;
        border-radius: 1.9531vw;
        padding: 5.2083vw;
        margin-top: 6.5104vw;
    }

    .about_us_cards_left_side_card_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 3.2552vw 0px;
    }

    .about_us_cards_left_side_card_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .rounded_circle_arrow_img {
        text-align: end;
        padding-top: 6.5104vw;
        padding-right: 6.5104vw;
    }

    .bg_rounded_circle_img {
        grid-column: 1 / span 6;
        grid-row: 1;
        display: block;
        padding-top: 13.0208vw;
    }

    .bg_rounded_circle_img img {
        width: 100%;
    }

    .about_us_cards_right_side_two_cards {
        grid-column: 5 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .about_us_cards_right_side_first_box {
        background-color: #8484E9;
        border-radius: 1.9531vw;
        padding: 5.2083vw;
        margin: 3.2552vw 0px;
    }

    .about_us_cards_right_side_first_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 3.2552vw 0px;
    }

    .about_us_cards_right_side_first_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .about_us_cards_right_side_second_box {
        background-color: #41AD49;
        border-radius: 1.9531vw;
        padding: 5.2083vw;
    }

    .about_us_cards_right_side_second_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 3.2552vw 0px;
    }

    .about_us_cards_right_side_second_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 2.6041vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .rounded_circle_arrow_img1 {
        text-align: end;
        padding-top: 10%;
        padding-right: 10%;
        display: block;
    }

    .rounded_circle_arrow_img1 img {
        width: 37%;
    }

    .rounded_circle_arrow_img2 {
        padding-top: 3.2552vw;
        padding-right: 3.2552vw;
        display: none;
    }

    .rounded_circle_arrow_img2 img {
        width: 14.3229vw;
        height: 14.3229vw;
    }

    /* about_us_cards ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* about_us_cards starts */
    .about_us_cards {
        background-image: url(../../assets/images/what_is_go121/Ellipse_bg_img.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        margin-top: 7.3611vw;
    }

    .our_values_layer_img {
        position: absolute;
        top: -2.8472vw;
        right: 19.0278vw;
        width: 12.2222vw;
    }

    .our_values_layer_rounded_circle_arrow_img {
        width: 100%;
    }

    .about_us_cards_left_side_card {
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 27.1528vw;
        z-index: 2;
    }

    .about_us_card_left_side_card_box {
        background-color: #055CF5;
        border-radius: 15px;
        padding: 4.1667vw 2.7778vw;

    }

    .about_us_cards_left_side_card_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        padding: 1.6667vw 0px;
    }

    .about_us_cards_left_side_card_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0 !important;
    }

    .rounded_circle_arrow_img {
        text-align: end;
        padding-top: 3.4722vw;
        padding-right: 3.4722vw;
    }

    .bg_rounded_circle_img {
        grid-column: 2 / span 7;
        grid-row: 1;
        width: 100%;
        margin-top: 35.2083vw;
        margin-left: -1.6667vw;
        z-index: 1;
    }

    .bg_rounded_circle_img img {
        width: 57.2917vw;
    }

    .about_us_cards_right_side_two_cards {
        grid-column: 7 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 22.7083vw;
        z-index: 2;
    }

    .about_us_cards_right_side_first_box {
        background-color: #8484E9;
        border-radius: 15px;
        padding: 4.1667vw 2.7778vw;
    }

    .about_us_cards_right_side_first_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        padding: 1.6667vw 0px;
    }

    .about_us_cards_right_side_first_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0 !important;
    }

    .about_us_cards_right_side_second_box {
        background-color: #41AD49;
        border-radius: 15px;
        padding: 4.1667vw 2.7778vw;
        margin-top: 1.5972vw;
        margin-bottom: 0 !important;

    }

    .about_us_cards_right_side_second_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        padding: 1.6667vw 0px;
    }

    .about_us_cards_right_side_second_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0 !important;
    }

    .rounded_circle_arrow_img1 {
        text-align: end;
        margin-top: 4.3056vw;
        margin-left: 17.2917vw;
        width: 10.0694vw;
    }

    .rounded_circle_arrow_img1 img {
        width: 100%;
    }

    .rounded_circle_arrow_img2 {
        text-align: end;
        padding-top: 50px;
        padding-right: 50px;
        display: none;
    }

    /* about_us_cards ends */

}

@media only screen and (min-width: 1441px) {

    /* about_us_cards starts */
    .about_us_our_values_bg_img {

        padding-top: 327px;
        background-image: url(../../assets/images/what_is_go121/Ellipse_bg_img.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .our_values_layer_img {
        position: absolute;
        top: -40.9997px;
        right: 274px;
    }

    .about_us_cards_left_side_card {
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        margin-top: 64px;
        z-index: 2;
    }

    .about_us_card_left_side_card_box {
        background-color: #055CF5;
        border-radius: 15px;
        padding: 60px 40px;
    }

    .about_us_cards_left_side_card_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .about_us_cards_left_side_card_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0px;
    }

    .rounded_circle_arrow_img {
        text-align: end;
        padding-top: 50px;
        padding-right: 50px;
    }

    .bg_rounded_circle_img {
        grid-column: 2 / span 7;
        grid-row: 1;
        width: 100%;
        margin-top: 175px;
        margin-left: -24px;
        z-index: 1;
    }

    .about_us_cards_right_side_two_cards {
        grid-column: 7 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 2;
    }

    .about_us_cards_right_side_first_box {
        background-color: #8484E9;
        border-radius: 15px;
        padding: 60px 40px;
    }

    .about_us_cards_right_side_first_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 24px 0px;
    }

    .about_us_cards_right_side_first_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0px;
    }

    .about_us_cards_right_side_second_box {
        background-color: #41AD49;
        border-radius: 15px;
        padding: 60px 40px;
        margin-top: 29px;
    }

    .about_us_cards_right_side_second_box_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin: 24px 0px;
    }

    .about_us_cards_right_side_second_box_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0px;
    }

    .rounded_circle_arrow_img1 {
        text-align: end;
        margin-top: 62px;
        margin-right: 40px;
    }

    .rounded_circle_arrow_img2 {
        text-align: end;
        padding-top: 50px;
        padding-right: 50px;
        display: none;
    }

    /* about_us_cards ends */

}