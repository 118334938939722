@media only screen and (max-width: 480px) {

    .otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding: 0px 16px;
        position: relative;
    }

    .otp_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .otp_form_right_side_img {
        display: none;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .otp_form_right_side_img img {
        width: 100%;
    }

    .otp_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: block;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .otp_form_right_side_img_mobile img {
        width: 100%;
    }

    .otp_breadcrumb {
        flex-wrap: wrap;
        white-space: nowrap;
        font-size: 3.888vw;
        font-weight: bold;
        color: white;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        gap: 2.222vw;
        margin-bottom: 4.44vw;
    }

    .otp_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .otp_breadcrumb svg {
        width: 6.666vw;
        margin-left: 0.555vw;
    }

    .otp_breadcrumb .otp_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 10vw 4.444vw;
    }

    .otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6.666vw;
    }

    .otp_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 6.666vw;
        font-weight: bold;
        line-height: 8.666vw;
        color: #1C1CA8;
    }

    .otp_form_step {
        font-size: 3.333vw;
        font-weight: 900;
        line-height: 5vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .otp_form_group {
        margin-bottom: 6.666vw;
        display: flex;
        flex-direction: column;
    }


    .otp_form_description {
        font-size: 5.555vw;
        line-height: 8.333vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .otp_input_container {
        width: 100% !important;
    }

    .otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .otp_input_container .styles_react-code-input__CRulA>input {
        width: 11.7vw !important;
        height: 11.7vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 6.944vw !important;
        line-height: 10.416vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .otp_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .otp_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .otp_invalid {
        display: flex;
        align-items: center;
        gap: 3.611vw;
        font-weight: 400;
        color: #1C1CA8;
        font-size: 3.888vw;
        line-height: 6.222vw;
        justify-content: center;
    }

    .otp_invalid svg {
        width: 6.666vw;
        height: 6.666vw;
    }

    .otp_resend_btn {
        cursor: pointer;
        font-size: 4.444vw;
        line-height: 5.555vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        align-items: center;
        gap: 2.222vw;
    }

    .otp_resend_btn svg{
        width: 6.666vw;
        height: 6.666vw;
    }

    .otp_form_submit_btn {
        height: 13.888vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 5.555vw;
        padding-right: 5.555vw;
    }

    .otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .otp_form_submit_btn span {
        font-size: 4.444vw;
        color: white;
        line-height: 5.555vw;
        font-weight: bold;
    }

    .otp_form_submit_btn svg {
        width: 6.111vw;
        height: 6.666vw;
    }

    .otp_extra_info {
        font-size: 3.888vw;
        line-height: 5.8338vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .otp_extra_info span {
        cursor: pointer;
        text-decoration: underline;
        color: #5B5B62;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* otp_form starts */
    .otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding: 0px 25px;
        position: relative;
        height: 56.25vw;
    }

    .otp_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 6%;
    }

    .otp_form_right_side_img {
        width: 100vw;
        position: absolute;
        bottom: 0px;
        z-index: 0;
        max-width: 1440px;
    }

    .otp_form_right_side_img img {
        width: 100%;
    }

    .otp_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .otp_form_right_side_img_mobile img {
        width: 100%;
    }

    .otp_breadcrumb {
        flex-wrap: wrap;
        white-space: nowrap;
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw;
    }

    .otp_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .otp_breadcrumb svg {
        width: 1.111vw;
        margin-left: 0.555vw;
    }

    .otp_breadcrumb .otp_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .otp_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .otp_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .otp_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }


    .otp_form_description {
        font-size: 1.38vw;
        line-height: 2.083vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .otp_input_container {
        width: 100% !important;
    }

    .otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .otp_input_container .styles_react-code-input__CRulA>input {
        width: 3.8vw !important;
        height: 3.8vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 1.736vw !important;
        line-height: 2.604vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .otp_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .otp_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .otp_invalid {
        display: flex;
        align-items: center;
        gap: 0.928vw;
        font-weight: 400;
        color: #1C1CA8;
        font-size: 0.972vw;
        line-height: 1.555vw;
        justify-content: center;
    }

    .otp_invalid svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .otp_resend_btn {
        cursor: pointer;
        font-size: 1.111vw;
        line-height: 1.388vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        align-items: center;
        gap: 0.555vw;
    }

    .otp_resend_btn svg{
        width: 1.666vw;
        height: 1.666vw;
    }

    .otp_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .otp_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .otp_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .otp_extra_info {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .otp_extra_info span {
        cursor: pointer;
        text-decoration: underline;
        color: #5B5B62;
    }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* otp_form starts */

    .otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        height: 56.25vw;
    }

    .otp_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 6%;
    }

    .otp_form_right_side_img {
        display: block;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .otp_form_right_side_img img {
        width: 100%;
    }

    .otp_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .otp_form_right_side_img_mobile img {
        width: 100%;
    }

    .otp_breadcrumb {
        flex-wrap: wrap;
        white-space: nowrap;
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw;
    }

    .otp_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .otp_breadcrumb svg {
        width: 1.111vw;
        margin-left: 0.555vw;
    }

    .otp_breadcrumb .otp_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .otp_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .otp_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .otp_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }


    .otp_form_description {
        font-size: 1.38vw;
        line-height: 2.083vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .otp_input_container {
        width: 100% !important;
    }

    .otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .otp_input_container .styles_react-code-input__CRulA>input {
        width: 3.8vw !important;
        height: 3.8vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 1.736vw !important;
        line-height: 2.604vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .otp_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .otp_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .otp_invalid {
        display: flex;
        align-items: center;
        gap: 0.928vw;
        font-weight: 400;
        color: #1C1CA8;
        font-size: 0.972vw;
        line-height: 1.555vw;
        justify-content: center;
    }

    .otp_invalid svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .otp_resend_btn {
        cursor: pointer;
        font-size: 1.111vw;
        line-height: 1.388vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        align-items: center;
        gap: 0.555vw;
    }

    .otp_resend_btn svg{
        width: 1.666vw;
        height: 1.666vw;
    }

    .otp_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .otp_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .otp_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .otp_extra_info {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .otp_extra_info span {
        cursor: pointer;
        text-decoration: underline;
        color: #5B5B62;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* otp_form starts */
    .otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 1.111vw;
        position: relative;
        overflow: hidden;
        height: 56.25vw;
    }

    .otp_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .otp_form_right_side_img {
        display: block;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .otp_form_right_side_img img {
        width: 100%;
    }

    .otp_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .otp_form_right_side_img_mobile img {
        width: 100%;
    }

    .otp_breadcrumb {
        flex-wrap: wrap;
        white-space: nowrap;
        font-size: 0.97vw;
        font-weight: bold;
        color: white;
        line-height: 1.52vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2.5vw;
    }

    .otp_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .otp_breadcrumb svg {
        width: 1.111vw;
        margin-left: 0.555vw;
    }

    .otp_breadcrumb .otp_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
    }

    .otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .otp_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
    }

    .otp_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .otp_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }


    .otp_form_description {
        font-size: 1.38vw;
        line-height: 2.083vw;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .otp_input_container {
        width: 100% !important;
    }

    .otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .otp_input_container .styles_react-code-input__CRulA>input {
        width: 3.8vw !important;
        height: 3.8vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 1.736vw !important;
        line-height: 2.604vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .otp_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .otp_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .otp_invalid {
        display: flex;
        align-items: center;
        gap: 0.928vw;
        font-weight: 400;
        color: #1C1CA8;
        font-size: 0.972vw;
        line-height: 1.555vw;
        justify-content: center;
    }

    .otp_invalid svg {
        width: 1.666vw;
        height: 1.666vw;
    }

    .otp_resend_btn {
        cursor: pointer;
        font-size: 1.111vw;
        line-height: 1.388vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .otp_resend_btn svg{
        width: 24px;
        height: 24px;
    }

    .otp_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
    }

    .otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .otp_form_submit_btn span {
        font-size: 1.388vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .otp_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .otp_extra_info {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .otp_extra_info span {
        cursor: pointer;
        text-decoration: underline;
        color: #5B5B62;
    }
}


@media only screen and (min-width: 1441px) {

    /* otp_form starts */
    .otp_form {
        position: relative;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        padding-top: 16px;
        height: 810px;
        overflow: hidden;
    }

    .otp_form_left_side_form {
        position: relative;
        z-index: 2;
        grid-column: 2 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .otp_form_right_side_img {
        display: block;
        width: 100vw;
        position: absolute;
        z-index: 0;
        bottom: 0px;
        max-width: 1440px;
    }

    .otp_form_right_side_img img {
        width: 100%;
    }

    .otp_form_right_side_img_mobile {
        width: calc(100vw - 4.444vw);
        display: none;
        margin-top: 16.111vw;
        margin-left: -4.444vw;
        grid-row: 2;
        grid-column: 1 / span 4;
        height: auto;
    }

    .otp_form_right_side_img_mobile img {
        width: 100%;
    }

    .otp_breadcrumb {
        flex-wrap: wrap;
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
        color: white;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 36px;
    }

    .otp_breadcrumb>a {
        color: white;
        text-decoration: none;
    }

    .otp_breadcrumb svg {
        width: 16px;
        margin-left: 8px;
    }

    .otp_breadcrumb .otp_breadcrumb_second {
        cursor: pointer;
        color: #FF4600;
    }

    .otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 36px 24px;
    }

    .otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .otp_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-size: 31px;
        font-weight: bold;
        line-height: 40.3px;
        color: #1C1CA8;
    }

    .otp_form_step {
        font-size: 14px;
        font-weight: 900;
        line-height: 21px;
        color: #FF4600;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .otp_form_group {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
    }


    .otp_form_description {
        font-size: 20px;
        line-height: 30px;
        color: #5B5B62;
        font-weight: 450;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
    }

    .otp_input_container {
        width: 100% !important;
    }

    .otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .otp_input_container .styles_react-code-input__CRulA>input {
        width: 54.5px !important;
        height: 54.5px !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 25px !important;
        line-height: 37.5px !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .otp_input_container.error .styles_react-code-input__CRulA>input {
        border: 1px solid #FF1200 !important;
        color: #FF1200 !important;
    }

    .otp_input_container .styles_react-code-input__CRulA>input:focus {
        border: 1px solid #1C1CA8 !important;
    }

    .otp_invalid {
        display: flex;
        align-items: center;
        gap: 13px;
        font-weight: 400;
        color: #1C1CA8;
        font-size: 14px;
        line-height: 22.4px;
        justify-content: center;
    }

    .otp_invalid svg {
        width: 24px;
        height: 24px;
    }

    .otp_resend_btn {
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .otp_resend_btn svg{
        width: 24px;
        height: 24px;
    }

    .otp_form_submit_btn {
        height: 66px;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .otp_form_submit_btn span {
        font-size: 20px;
        color: white;
        line-height: 20px;
        font-weight: bold;
    }

    .otp_form_submit_btn svg {
        width: 22px;
        height: 24px;
    }

    .otp_extra_info {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #5B5B62;
        text-align: center;
    }

    .otp_extra_info span {
        cursor: pointer;
        text-decoration: underline;
        color: #5B5B62;
    }
}