@media only screen and (max-width: 480px) {

    /* our_biggest_brands starts */
    .our_biggest_brands {
        background-color: #FFFFFF;
    }

    .our_biggest_brands_first_row_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-top:20px
    }

    .our_biggest_brands_container {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 32px;
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        display: none;
    }
    
    .our_biggest_brands_container img {
        width: 100%;
    }
    .our_biggest_brands_mobile_view_container{
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }
    .our_biggest_brands_mobile_view_img_container{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
    }
    .our_biggest_brands_mobile_view_container img{
        width: 100%;
    }
    .our_biggest_brands_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .our_biggest_brands_first_row_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }
    .left_right_arrow_img {
        display: flex;
        margin-bottom: 98px;
    }
    .left_side_arrow_pic{
         width: 50%;
         text-align: end;
         margin-right: 16px;
    }
    .left_side_arrow_pic svg{
        width: 50px;
        height: 50px;        
    }
    .right_side_arrow_pic{
        width: 50%;
        text-align: start;
    }
    .right_side_arrow_pic svg{
        width: 50px;
        height: 50px;
    }
    /* our_biggest_brands ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* our_biggest_brands starts */
   /* .our_biggest_brands {
        background-color: #FFFFFF;
        padding-bottom: 50px;
    }

    .our_biggest_brands_first_row_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .our_biggest_brands_container {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 32px;
        grid-column: 1 / span 8;
        grid-row: 2;
        width: 100%;
    }

    .our_biggest_brands_container img {
        width: 100%;
    }

    .our_biggest_brands_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .our_biggest_brands_first_row_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }
    .our_biggest_brands_mobile_view_container{
        display: none;
    }
    .our_biggest_brands_mobile_view_img_container{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
    }
    /* our_biggest_brands ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

     /* our_biggest_brands starts */
     .our_biggest_brands {
        background-color: #FFFFFF;
        margin-bottom: 108px;
    }

    .our_biggest_brands_first_row_content {
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-top:84px;
    }

    .our_biggest_brands_container {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 32px;
        grid-column: 1 / span 8;
        grid-row: 2;
        width: 100%;
    }

    .our_biggest_brands_container img {
        width: 100%;
    }

    .our_biggest_brands_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .our_biggest_brands_first_row_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }
    .our_biggest_brands_mobile_view_container{
        display: none;
    }
    .our_biggest_brands_mobile_view_img_container{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
    }
    /* our_biggest_brands ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* our_biggest_brands starts */
    .our_biggest_brands {
        background-color: #FFFFFF;
    }

    .our_biggest_brands_first_row_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-top: 14.306vw;
    }

    .our_biggest_brands_container {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 32px;
        grid-column: 2 / span 10;
        grid-row: 2;
        width: 100%;
        margin-bottom: 8.75vw;
    }

    .our_biggest_brands_container img {
        width: 100%;
    }

    .our_biggest_brands_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.6667vw;
    }

    .our_biggest_brands_first_row_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 4.375vw;
    }
    .our_biggest_brands_mobile_view_container{
        display: none;
    }
    .our_biggest_brands_mobile_view_img_container{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
    }
    /* our_biggest_brands ends */

}
@media only screen and (min-width: 1441px) {

     /* our_biggest_brands starts */
    .our_biggest_brands {
        background-color: #FFFFFF;
    }

    .our_biggest_brands_first_row_content {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-top: 206px;
    }
    
    .our_biggest_brands_container img {
        width: 100%;
    }

    .our_biggest_brands_container {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 32px;
        grid-column: 2 / span 10;
        grid-row: 2;
        width: 100%;
        margin-bottom: 126px;
    }

    .our_biggest_brands_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .our_biggest_brands_first_row_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 63px;
    }
    .our_biggest_brands_mobile_view_container{
        display: none;
    }
    .our_biggest_brands_mobile_view_img_container{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
    }
    /* our_biggest_brands ends */

}