@media only screen and (max-width: 480px) {

    .why_get_pos_machine {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 80px;
    }

    .why_get_pos_machine_first_row_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .why_get_pos_machine_second_row_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin-top: 30px;
    }

    .why_get_pos_machine_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1C1CA8;

    }

    .why_get_pos_machine_first_row_content_first_line span svg {
        width: 16px;
        height: 16px;
    }

    .why_get_pos_machine_first_row_content_img img {
        width: 100%;
    }

    .why_get_pos_machine_first_row_content_first_line .inside_content2 {
        color: #FF4600;
    }

    .why_get_pos_machine_first_row_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin: 25px 0px;
    }

    .why_get_pos_machine_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .why_get_pos_machine_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* why_get_pos_machine ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* why_get_pos_machine starts */
    .why_get_pos_machine {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .why_get_pos_machine_first_row_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .why_get_pos_machine_second_row_content {
        grid-column: 1 / span 8;
        grid-row: 2;
        width: 100%;
        margin-top: 30px;
    }

    .why_get_pos_machine_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1C1CA8;

    }

    .why_get_pos_machine_first_row_content_first_line span svg {
        width: 16px;
        height: 16px;
    }

    .why_get_pos_machine_first_row_content_img img {
        width: 100%;
    }

    .why_get_pos_machine_first_row_content_first_line .inside_content2 {
        color: #FF4600;
    }

    .why_get_pos_machine_first_row_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin: 25px 0px;
    }

    .why_get_pos_machine_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .why_get_pos_machine_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* why_get_pos_machine ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

     /* why_get_pos_machine starts */
     .why_get_pos_machine {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .why_get_pos_machine_first_row_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .why_get_pos_machine_second_row_content {
        grid-column: 2 / span 7;
        grid-row: 2;
        width: 100%;
        margin-top: 30px;
    }

    .why_get_pos_machine_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1C1CA8;

    }

    .why_get_pos_machine_first_row_content_first_line span svg {
        width: 16px;
        height: 16px;
    }

    .why_get_pos_machine_first_row_content_img img {
        width: 100%;
    }

    .why_get_pos_machine_first_row_content_first_line .inside_content2 {
        color: #FF4600;
    }

    .why_get_pos_machine_first_row_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin: 25px 0px;
    }

    .why_get_pos_machine_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .why_get_pos_machine_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* why_get_pos_machine ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* why_get_pos_machine starts */
    .why_get_pos_machine {
        background-color: #FFFFFF;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .why_get_pos_machine_first_row_content {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
    }

    .why_get_pos_machine_second_row_content {
        grid-column: 3 / span 8;
        grid-row: 2;
        width: 100%;
        margin-top: 80px;
    }

    .why_get_pos_machine_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1C1CA8;

    }

    .why_get_pos_machine_first_row_content_first_line span svg {
        width: 16px;
        height: 16px;
    }

    .why_get_pos_machine_first_row_content_img img {
        width: 100%;
    }

    .why_get_pos_machine_first_row_content_first_line .inside_content2 {
        color: #FF4600;
    }

    .why_get_pos_machine_first_row_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin: 25px 0px;
    }

    .why_get_pos_machine_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .why_get_pos_machine_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* why_get_pos_machine ends */

}
@media only screen and (min-width: 1601px) {

    /* why_get_pos_machine starts */
    .why_get_pos_machine {
        background-color: #FFFFFF;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .why_get_pos_machine_first_row_content {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
    }

    .why_get_pos_machine_second_row_content {
        grid-column: 3 / span 8;
        grid-row: 2;
        width: 100%;
        margin-top: 80px;
    }

    .why_get_pos_machine_first_row_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1C1CA8;

    }

    .why_get_pos_machine_first_row_content_first_line span svg {
        width: 16px;
        height: 16px;
    }

    .why_get_pos_machine_first_row_content_img img {
        width: 100%;
    }

    .why_get_pos_machine_first_row_content_first_line .inside_content2 {
        color: #FF4600;
    }

    .why_get_pos_machine_first_row_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin: 25px 0px;
    }

    .why_get_pos_machine_second_row_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .why_get_pos_machine_second_row_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* why_get_pos_machine ends */

}