.default_error_img_mobile {
    display: none;
}

@media only screen and (max-width: 480px) {

    /* defualt_error_page starts */
    .defualt_error_page {
        background-color: #1C1CA8;
        padding-top: 6.8181vw;
        padding-bottom: 6.8181vw;
        height: calc(100vh - 16.2vw);
        overflow: hidden;
    }

    .defualt_error_page_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .default_error_img {
        display: none;
    }

    .default_error_img_mobile {
        width: 53.8889vw;
        display: block;
        align-self: center;
        margin: 0px auto 10.2778vw
    }

    .default_error_img_mobile img {
        width: 100%;
    }

    .defualt_error_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 6.3889vw;
    }

    .defualt_error_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6.3889vw;
    }

    .home_btn_width_height svg {
        width: 6.1111vw !important;
        height: auto;
    }

    .home_btn_width_height {
        width: 86.6667vw !important;
        height: 13.8889vw !important;
        margin: 0px auto;
        font-size: 4.4444vw;
    }

    /* defualt_error_page ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* defualt_error_page starts */
    /* .defualt_error_page {
        background-color: #1C1CA8;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .defualt_error_page_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .defualt_error_page_content img {
        width: 50%;
    }

    .defualt_error_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .defualt_error_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .home_btn_size svg {
        width: 50%;
        height: auto;
    }

    .home_btn_width_height{
        width: 224px;
        height: 45px;
        margin: 0px auto;
        font-size: 14px;
    }

    /* defualt_error_page ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* defualt_error_page starts */
    .defualt_error_page {
        background-color: #1C1CA8;
        padding-top: 6.8181vw;
        padding-bottom: 6.8181vw;
        height: calc(100vh - 7.6vw);
        overflow: hidden;
    }

    .defualt_error_page_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .defualt_error_page_content img {
        width: 31.7708vw;
    }

    .defualt_error_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 12.3698vw;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .defualt_error_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 3.2552vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .home_btn_size svg {
        width: 50%;
        height: auto;
    }

    .home_btn_width_height {
        width: 39.1927vw !important;
        margin: 0px auto;
        font-size: 1.8229vw;
    }

    /* defualt_error_page ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* defualt_error_page starts */
    .defualt_error_page {
        background-color: #1C1CA8;
        padding-top: 4.7222vw;
        padding-bottom: 17.8472vw;
        height: calc(100vh - 6.3vw);
        overflow: hidden;
    }

    .defualt_error_page_content {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .defualt_error_page_content img {
        width: 16.9444vw;
        margin-bottom: 1.875vw;
    }

    .defualt_error_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top: 0.4861vw;
        margin-bottom: 1.1806vw;
    }

    .defualt_error_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.7361vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 2.7778vw;
    }

    .home_btn_size svg {
        width: 20%;
        height: auto;
    }

    .home_btn_width_height {
        width: 20.9028vw !important;
        height: 4.5833vw !important;
        margin: 0px auto;
        font-size: 1.3889vw !important;
        padding: 23px 24px !important;
    }

    /* defualt_error_page ends */

}

@media only screen and (min-width: 1441px) {

    /* defualt_error_page starts */
    .defualt_error_page {
        background-color: #1C1CA8;
        padding-top: 68px;
        padding-bottom: 257px;
        height: calc(100vh - 95.4px);
    }

    .defualt_error_page_content {
        grid-column: 2 / span 10;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .defualt_error_page_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
        margin-top: 26px;
    }

    .defualt_error_page_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 40px;
    }

    .home_btn_size svg {
        width: 20%;
        height: auto;
    }

    .home_btn_width_height {
        width: 302px !important;
        height: 66px !important;
        margin: 0px auto;
        font-size: 20px;
    }

    /* defualt_error_page ends */

}