@media only screen and (max-width: 480px) {

    /* stuck_we_here_to_help starts */
    .stuck_we_here_to_help {
        background-color: #FFFFFF;
        padding-bottom: 30px;
    }

    .stuck_we_here_to_help_left_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .stuck_we_here_to_help_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .stuck_we_here_to_help_left_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    /* stuck_we_here_to_help ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* stuck_we_here_to_help starts */
    /* .stuck_we_here_to_help {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .stuck_we_here_to_help_left_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .stuck_we_here_to_help_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .stuck_we_here_to_help_left_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    } */

    /* stuck_we_here_to_help ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* stuck_we_here_to_help starts */
    .stuck_we_here_to_help {
        background-color: #FFFFFF;
        padding-top: 2.6041vw;
        padding-bottom: 3.9062vw;
    }

    .stuck_we_here_to_help_left_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .stuck_we_here_to_help_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8229vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .stuck_we_here_to_help_left_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 6.25vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-top:3.125vw;
        margin-bottom:3.125vw;
    }

    /* stuck_we_here_to_help ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

     /* stuck_we_here_to_help starts */
     .stuck_we_here_to_help {
        background-color: #FFFFFF;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .stuck_we_here_to_help_left_content {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .stuck_we_here_to_help_left_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .stuck_we_here_to_help_left_content_second_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    /* stuck_we_here_to_help ends */

}
@media only screen and (min-width: 1441px) {

   /* stuck_we_here_to_help starts */
   .stuck_we_here_to_help {
    background-color: #FFFFFF;
    padding-top: 175px;
    padding-bottom: 175px;
}

.stuck_we_here_to_help_left_content {
    grid-column: 2 / span 3;
    grid-row: 1;
    width: 100%;
}

.stuck_we_here_to_help_left_content_first_line {
    font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FF4600;
    margin-bottom: 24px;
}

.stuck_we_here_to_help_left_content_second_line {
    font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #1C1CA8;
    margin-bottom: 24px;
}

/* stuck_we_here_to_help ends */

}