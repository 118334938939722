@media only screen and (max-width: 480px) {

    .partner_sign_up_form_thank_you {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .partner_sign_up_form_thank_you_first_row {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_thank_you_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_second_row_right_side_content {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_img_div {
        text-align: center;
    }

    .sign_up_form_thank_you_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 15px;
    }

    .sign_up_form_thank_you_content_thank_you_img {
        width: 100%;
    }

    .sign_up_form_thank_you_content_thank_you_img img {
        width: 50%;
    }

    .sign_up_form_thank_you_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        text-align: center;
    }

    .sign_up_form_thank_you_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* partner_sign_up_form_thank_you ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* partner_sign_up_form_thank_you starts */
     .partner_sign_up_form_thank_you {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .partner_sign_up_form_thank_you_first_row {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_thank_you_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .partner_sign_up_form_thank_you_second_row_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_img_div {
        text-align: center;
    }

    .sign_up_form_thank_you_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 15px;
    }

    .sign_up_form_thank_you_content_thank_you_img {
        width: 100%;
    }

    .sign_up_form_thank_you_content_thank_you_img img {
        width: 50%;
    }

    .sign_up_form_thank_you_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        text-align: center;
    }

    .sign_up_form_thank_you_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* partner_sign_up_form_thank_you ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* partner_sign_up_form_thank_you starts */
    .partner_sign_up_form_thank_you {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .partner_sign_up_form_thank_you_first_row {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_thank_you_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        margin: auto 0px;
    }

    .partner_sign_up_form_thank_you_second_row_right_side_content {
        grid-column: 5 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_img_div {
        text-align: center;
    }

    .sign_up_form_thank_you_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 15px;
    }

    .sign_up_form_thank_you_content_thank_you_img {
        width: 100%;
    }

    .sign_up_form_thank_you_content_thank_you_img img {
        width: 50%;
    }

    .sign_up_form_thank_you_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        text-align: center;
    }

    .sign_up_form_thank_you_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* partner_sign_up_form_thank_you ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

    /* partner_sign_up_form_thank_you starts */
    .partner_sign_up_form_thank_you {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .partner_sign_up_form_thank_you_first_row {
        grid-column: 2 / span 5;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_thank_you_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
        padding-top: 100px;
    }

    .partner_sign_up_form_thank_you_second_row_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_img_div {
        text-align: center;
    }

    .sign_up_form_thank_you_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 15px;
    }

    .sign_up_form_thank_you_content_thank_you_img {
        width: 100%;
    }

    .sign_up_form_thank_you_content_thank_you_img img {
        width: 50%;
    }

    .sign_up_form_thank_you_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        text-align: center;
    }

    .sign_up_form_thank_you_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* partner_sign_up_form_thank_you ends */

}
@media only screen and (min-width: 1601px) {

    /* partner_sign_up_form_thank_you starts */
    .partner_sign_up_form_thank_you {
        background-color: #1C1CA8;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .partner_sign_up_form_thank_you_first_row {
        grid-column: 2 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_first_row_first_line svg {
        width: 16px;
        height: 16px;
    }

    .partner_sign_up_form_thank_you_first_row_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_first_row_first_line_inside_line {
        color: #FF4600;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content {
        grid-column: 2 / span 5;
        grid-row: 2;
        width: 100%;
        padding-top: 100px;
    }

    .partner_sign_up_form_thank_you_second_row_right_side_content {
        grid-column: 8 / span 4;
        grid-row: 2;
        width: 100%;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFBF00;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_fourth_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .partner_sign_up_form_thank_you_second_row_left_side_content_img_div {
        text-align: center;
    }

    .sign_up_form_thank_you_content {
        background-color: #ffffff;
        padding: 25px 18px;
        border-radius: 15px;
    }

    .sign_up_form_thank_you_content_thank_you_img {
        width: 100%;
    }

    .sign_up_form_thank_you_content_thank_you_img img {
        width: 50%;
    }

    .sign_up_form_thank_you_content_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        text-align: center;
    }

    .sign_up_form_thank_you_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    /* partner_sign_up_form_thank_you ends */

}