@media only screen and (max-width: 480px) {


    .font-xxsmall {
        font-size: 3.333vw;
    }

    .font-xsmall {
        font-size: 3.889vw;
    }


    .font-small {
        font-size: 4.444vw;
    }

    .font-medium {
        font-size: 5.556vw;
    }

    .font-large {
        font-size: 9.167vw;
    }

    .font-mlarge {
        font-size: 11.111vw;
    }

    .font-xlarge {
        font-size: 13.333vw;
    }

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    .font-xxsmall {
        font-size: 10px;
    }

    .font-xsmall {
        font-size: 12px;
    }


    .font-small {
        font-size: 14px;
    }

    .font-medium {
        font-size: 15px;
    }

    .font-large {
        font-size: 30px;
    }

    .font-mlarge {
        font-size: 30px;
    }

    .font-xlarge {
        font-size: 50px;
    }

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    .font-xxsmall {
        font-size: 1.563vw;
    }

    .font-xsmall {
        font-size: 1.823vw;
    }


    .font-small {
        font-size: 2.083vw;
    }

    .font-medium {
        font-size: 2.344vw;
    }

    .font-large {
        font-size: 4.688vw;
    }

    .font-mlarge {
        font-size: 4.688vw;
    }

    .font-xlarge {
        font-size: 7.552vw;
    }

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /*12px*/
    .font-xxsmall {
        font-size: 0.833vw;
    }

    /*14px*/
    .font-xsmall {
        font-size: 0.972vw;
    }

    /*20px*/
    .font-small {
        font-size: 1.3888vw;
    }

    /*25px*/
    .font-medium {
        font-size: 1.736vw;
    }

    /*48px*/
    .font-large {
        font-size: 3.333vw;
    }

    /*61px*/
    .font-mlarge {
        font-size: 4.236vw;
    }

    /*95px*/
    .font-xlarge {
        font-size: 6.597vw;
    }

    @media only screen and (max-width: 1110px) {
        .font-xxsmall {
            font-size: 0.833vw;
        }

        .font-xsmall {
            font-size: 0.972vw;
        }

        .font-small {
            font-size: 1.389vw;
        }

        .font-medium {
            font-size: 1.736vw;
        }

        .font-large {
            font-size: 3.333vw;
        }

        .font-mlarge {
            font-size: 4.236vw;
        }

        .font-xlarge {
            font-size: 6.597vw;
        }
    }
}

@media only screen and (min-width: 1441px) {

    .font-xxsmall {
        font-size: 12px;
    }

    .font-xsmall {
        font-size: 14px;
    }


    .font-small {
        font-size: 20px;
    }

    .font-medium {
        font-size: 24px;
    }

    .font-large {
        font-size: 48px;
    }

    .font-mlarge {
        font-size: 61px;
    }

    .font-xlarge {
        font-size: 95px;
    }

}