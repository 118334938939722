.greating_img_mobile{
    display:none;
}

@media only screen and (max-width: 480px) {

    /* it_is_convenient starts */
    .it_is_convenient {
        background-color: #41AD49;
        height: 167.778vw;
    }

    .greating_img {
        grid-column: 3 / span 2;
        grid-row: 1;
        width: 49.167vw;
        display:none;
    }

    .greating_img_mobile{
        display: block;
        grid-column: 3 / span 2;
        grid-row: 1;
        margin-top: 4.167vw;
    }

    .greating_img img {
        width: 100%;
    }
    .greating_img_mobile img{
        width:45.833vw;
        margin-left: 1.667vw;
    }

    .it_is_convenient_left_side_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        padding-top: 30.556vw;
        width: 82.7778vw;
    }

    .arrow_img {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        display: none;
    }

    .arrow_img img {
        width: 100%;
    }

    .mobile_view_arrow_img {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 80.833vw;
        display: block;
        align-self: flex-end;
        margin-left: 4.722vw;
    }

    .mobile_view_arrow_img img {
        width: 100%;
    }

    .it_is_convenient_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    /* it_is_convenient ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* it_is_convenient starts */
   /* .it_is_convenient {
        background-color: #41AD49;
        padding-bottom: 0px;
        padding-top: 0px;
        position: relative;
        aspect-ratio: 600/440;
    }

    .it_is_convenient_left_side_content {
        grid-column: 2 / span 6;
        grid-row: 1;
        padding-top: 27%;
        padding-bottom: 27%;
        width: 85%;
        z-index: 2;

    }

    .greating_img {
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 75%;
        padding-top: 30%;
        position: absolute;
        top: 0%;
    }

    .greating_img img {
        width: 100%;
    }

    .arrow_img {
        grid-column: 4 /span 5;
        grid-row: 1;
        width: 70%;
        position: absolute;
        bottom: 0px;
        right: 5%;
        z-index: 1;
    }

    .arrow_img img {
        width: 100%;
    }

    .it_is_convenient_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 39px !important;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .mobile_view_arrow_img {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        display: none;
        padding-top: 40px;
    }
    /* it_is_convenient ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

     /* it_is_convenient starts */
     .it_is_convenient {
        background-color: #41AD49;
        position: relative;
        height: 65.625vw;
        overflow:hidden;
    }

    .it_is_convenient_left_side_content {
        grid-column: 2 / span 6;
        grid-row: 1;
        margin-top: 18.3594vw;
    }

    .greating_img {
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 75%;
        position: absolute;
        top: 0%;
        padding-top:6.25vw;
    }

    .greating_img img {
        width: 100%;
    }

    .arrow_img {
        grid-column: 4 /span 5;
        width: 78%;
        grid-row: 2;
        z-index: 1;
        
        margin-top: -7.0313vw;
        margin-left:5.7292vw;
    }

    .arrow_img img {
        width: 100%;
    }

    .it_is_convenient_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .mobile_view_arrow_img {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        display: none;
        padding-top: 40px;
    }
    /* it_is_convenient ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* it_is_convenient starts */
    .it_is_convenient {
        background-color: #41AD49;
        position: relative;
        height: 52.500vw;
        overflow: hidden;
    }

    .it_is_convenient_left_side_content {
        grid-column: 3 / span 7;
        grid-row: 1;
        width: 105%;
        margin-top: 15.536vw;
        z-index: 2;
        margin-left: -0.684vw;
    }

    .greating_img {
        grid-column: 10 / span 2;
        grid-row: 1;
        width: 12.292vw;
        margin-top: 9.028vw;
        margin-right: -1.806vw;
        justify-self: flex-end;
    }

    .greating_img img {
        width: 100%;
    }

    .arrow_img {
        grid-column: 8 /span 5;
        grid-row: 1;
        width: 34.514vw;
        z-index: 1;
        position: absolute;
        bottom: -14.911vw;
        left: 0.6944vw;

    }

    .arrow_img img {
        width: 100%;
    }

    .it_is_convenient_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .mobile_view_arrow_img {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        display: none;
        padding-top: 40px;
    }
    /* it_is_convenient ends */

}
@media only screen and (min-width: 1441px) {

     /* it_is_convenient starts */
     .it_is_convenient {
        background-color: #41AD49;
        position: relative;
        height: 756px;
        overflow: hidden;
    }

    .it_is_convenient_left_side_content {
        grid-column: 3 / span 7;
        grid-row: 1;
        margin-top: 223px;
        width: 105%;
        z-index: 2;
        margin-left: -5px;
        width: 810px;
    }

    .greating_img {
        grid-column: 10 / span 2;
        grid-row: 1;
        margin-top: 129px;
        margin-right: -35px;
        justify-self: flex-end;
    }


    .arrow_img {
        grid-column: 8 /span 5;
        grid-row: 1;
        z-index: 1;
        position: absolute;
        bottom: -235.776px;
        left:19px;

    }

    .arrow_img img {
        width: 100%;
    }

    .it_is_convenient_first_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .mobile_view_arrow_img {
        grid-column: 1 / span 4;
        grid-row: 3;
        width: 100%;
        display: none;
        padding-top: 40px;
    }
    /* it_is_convenient ends */
}