.nabar-section {
    background-color: #1C1CA8;
}

.navbar-font-xsmall {
    font-size: 0.972vw !important;
}

@media only screen and (max-width: 480px) {

    /*nav-bar start*/
    .nav-bar {
        background-color: #1C1CA8;
        height: 16.111vw;
        aspect-ratio: 360/58;
    }

    .user_icon svg {
        max-width: 100%;
    }

    .menu_icon svg {
        max-width: 100%;
    }

    .nav-bar-logo {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 26.389vw;
        height: 6.861vw;
        padding-top: 4.752vw;
        padding-bottom: 4.528vw;
    }

    .menu-bar-icon {
        grid-column: 4/ span 1;
        display: block;
    }

    .menu-bar-icon .list-inline {
        align-items: center;
        justify-content: right;
        margin-top: 4.722vw;
        margin-bottom: 4.722vw;
    }

    .menu-bar-icon ul li svg {
        width: 6.667vw;
        height: 6.667vw;
        justify-self: end;
    }

    .menu-bar-icon ul .user_icon svg {
        width: 6.667vw;
        height: 6.667vw;
        margin-right: 2.322vw;
    }

    .nav-bar-links {
        display: none;
    }

    .nav-bar-right-side-login-img {
        display: none;
    }

    .nav-bar-right-side-login-img span {
        display: none;
    }

    .nav-bar-right-side-login-img img {
        display: none;
    }

    .nav-bar-login-text {
        padding-right: 13px;
    }

    .mobile_view_side_nav_bar {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        background-color: #1C1CA8;
        z-index: 99;
        padding: 0vw 16px;
        position: absolute;
        left: 0;
        overflow: hidden;
        height: 100%;
    }

    .mobile_view_side_nav_bar .nav_bar_first_div {
        display: flex;
        justify-content: space-between;

    }

    .nav_bar_first_div svg {
        width: 26.389vw;
        margin-top: 4.722vw;
        margin-bottom: 4.722vw;
    }

    .cancel_icon {
        margin-right: -0.556vw;
    }

    .cancel_icon svg {
        width: 6.667vw;
        height: 6.667vw;
    }

    .mobile_nav_link_style {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 2.222vw;
        /* padding: 6.333vw 6.333vw; */
        padding: 3.888vw 5.555vw;
        border-radius: 45px;
        width: 84.167vw;
    }

    .mobile_nav_link_style_img {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 2.222vw;
        padding-left: 5.555vw;
        /* padding: 6.333vw 6.333vw; */
        border-radius: 45px;
        width: 84.167vw;
    }

    .mobile_nav_img_text {

        margin-left: 5.556vw;
        padding-top: 3.889vw;
        padding-bottom: 3.889vw;
    }

    .mobile_nav_img img {

        padding-top: 3.056vw;
        padding-bottom: 3.333vw;

    }

    /* .mobile_nav_link_style.active, .mobile_nav_link_style:focus, .mobile_nav_link_style:hover{
        color: #FFBF00 !important;
    } */
    .link_styles {
        color: #FFBF00;
    }

    .bg_color {
        background-color: #FF4600 !important;
        color: #FFFFFF;
        padding: 3.056vw 5.555vw;
    }

    /* .mobile_nav_link_style{
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 2.22222vw 0px;
        padding: 6.333vw 0px;
        border-radius: 45px;
    }
   .nav_bar_second_div ul li{
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 2.222vw 0px;
        padding: 6.333vw 0px;
        border-radius: 45px;
    }
    .nav_bar_second_div ul li:hover, .nav_bar_second_div ul li.active, .nav_bar_second_div ul li:focus{
        padding: 6.333vw 5.555vw;
        background-color: #FF4600;
    }
    .mobile_nav_link_style{
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 2.222vw 0px;
        padding: 6.333vw 0px;
        border-radius: 45px;
    }
    .mobile_nav_link_style:hover, .mobile_nav_link_style.active, .mobile_nav_link_style:focus{
        padding: 6.333vw 5.555vw;
        background-color: #FF4600;
    } */

    .nav_bar_second_div {
        padding-top: 12.5vw;
        padding-bottom: 12.5vw;
        /* z-index: 999; */
    }

    .nav_bar_second_div ul li {
        padding-left: 5.555vw;
    }

    .nav-bar-logo .logo_link svg {
        width: 26.392vw;
        height: 6.862vw;
    }

    /*nav-bar ends*/
}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /*nav-bar start*/
    .nav-bar {
        background-color: #1C1CA8;
    }

    .header_div {
        grid-column: 1 / span 12;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
    }

    .nav-bar-logo .logo_link svg {
        width: 8.542vw;
        height: 2.223vw;
    }

    .nav-bar-logo {
        padding-top: 1.015vw;
        padding-bottom: 1.015vw;
    }

    .nav-bar-links {
        width: 59.792vw;
        z-index: 10;
    }

    .mobile_view_side_nav_bar {
        display: none;
    }

    .nav_link_m_l {
        margin-left: 1.695vw;
    }

    .nav-bar-links ul li {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        align-items: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        padding: 0.655vw 1.155vw 0.655vw !important;
    }

    .menu-bar-icon {
        display: none;
    }

    .nav-bar-right-side-login-img svg {
        width: 5.07vw;
        height: 1.667vw;
    }

    .nav-bar-right-side-login-img {
        padding-top: 1.355vw;
        padding-bottom: 1.255vw;
    }

    .content_margin_bottom {
        margin-bottom: 0vw;
        padding-top: 1.695vw;
        padding-bottom: 1.695vw;
    }

    .nav-bar-login-text {
        padding-right: 0.903vw;
    }

    .active_class {
        padding: 0% 0%;
    }

    .active_class.active,
    .active_class:focus,
    .active_class:hover {
        color: #FFBF00 !important;
    }

    .highlight_background {
        background-color: #FF4600;
        color: #FFFFFF !important;
        padding: 0% 0%;
    }

    /*nav-bar ends*/

    /*nav-bar start*/
    /*.nav-bar {
        background-color: #1C1CA8;
        padding-top: 16px;
    }

    .nav-bar-logo {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 100%;
    }

    .menu-bar-icon {
        grid-column: 8/ span 1;
        display: block;
        z-index: 10;
    }

    .menu-bar-icon .list-inline {
        justify-content: right;
        align-items: center;
    }

    .user_icon svg {
        max-width: 100%;
    }

    .menu_icon svg {
        max-width: 100%;
    }

    .menu-bar-icon img {
        width: 40px;
        height: 40px;
    }

    .nav-bar-links {
        display: none;
    }

    .nav-bar-right-side-login-img img {
        display: none;
    }

    .menu-bar-icon ul li svg {
        width: 24px;
        height: 24px;
        margin: 0px 10px;
    }

    .menu-bar-icon ul .user_icon svg {
        width: 24px;
        height: 24px;
        margin: 0px 5px;
    }

    .nav-bar-login-text{
        display: none;
        padding-right: 13px;

    }
    .mobile_view_side_nav_bar {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        background-color: #1C1CA8;
    }
    .mobile_view_side_nav_bar .nav_bar_first_div{
        display: flex;
        justify-content: space-between;
    }
    .nav_bar_first_div svg{
        width: 43%;
    }
    .cancel_icon {
        padding-top: 1%;
    }
    .cancel_icon svg{
        width: 25px;
        height: 25px;
    }
    /* .nav_bar_text_style{
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 9px 0px;
        padding: 15px 0px;
        border-radius: 45px;
    } */
    /* .nav_bar_text_style:hover{
        background-color: #FF4600;
    }
    /* .nav_bar_second_div{
         margin-top: 10%;
    } */
    /* .nav_bar_second_div ul li{
        padding-left: 20px;
    }
    /*nav-bar ends*/


}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /*nav-bar start*/
    .nav-bar {
        background-color: #1C1CA8;
    }

    .header_div {
        grid-column: 1 / span 12;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
    }

    .nav-bar-logo .logo_link svg {
        width: 8.542vw;
        height: 2.223vw;
    }

    .nav-bar-logo {
        padding-top: 1.815vw;
        padding-bottom: 1.815vw;
    }

    .nav-bar-links {
        width: 59.792vw;
        z-index: 10;
    }

    .mobile_view_side_nav_bar {
        display: none;
    }

    .nav_link_m_l {
        margin-left: 1.695vw;
    }

    .nav-bar-links ul li {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        align-items: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        padding: 0.655vw 1.155vw 0.655vw !important;
    }

    .menu-bar-icon {
        display: none;
    }

    .nav-bar-right-side-login-img svg {
        width: 5.07vw;
        height: 1.667vw;
    }

    .nav-bar-right-side-login-img {
        padding-top: 1.955vw;
        padding-bottom: 1.955vw;
    }

    .content_margin_bottom {
        margin-bottom: 0vw;
        padding-top: 1.695vw;
        padding-bottom: 1.695vw;
    }

    .nav-bar-login-text {
        padding-right: 0.903vw;
    }

    .active_class {
        padding: 0% 0%;
    }

    .active_class.active,
    .active_class:focus,
    .active_class:hover {
        color: #FFBF00 !important;
    }

    .highlight_background {
        background-color: #FF4600;
        color: #FFFFFF !important;
        padding: 0% 0%;
    }

    /*nav-bar ends*/

    /*nav-bar start*/
    /* .nav-bar {
        background-color: #1C1CA8;
        z-index: 99;
    }

    .nav-bar-logo {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 12.370vw;
        padding-top: 2.214vw;
    }

    .menu-bar-icon {
        grid-column: 8/ span 1;
        display: block;
        z-index: 10;
        margin-top: 2.214vw;
        margin-right: -0.391vw;
        justify-self: flex-end;
    }

    .nav-bar-links {
        display: none;
    }

    .nav-bar-right-side-login-img {
        display: none;
    }

    .nav-bar-right-side-login-img img {
        display: none;
    }

    .menu-bar-icon ul li svg {
        width: 3.125vw;
        height: 3.125vw;

    }

    .menu-bar-icon ul .user_icon svg {
        width: 3.125vw;
        height: 3.125vw;
        margin-right: 1.042vw;
    }

    .nav-bar-login-text {
        display: none;

    }

    .mobile_view_side_nav_bar {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        background-color: #1C1CA8;
    }

    .mobile_view_side_nav_bar .nav_bar_first_div {
        display: flex;
        justify-content: space-between;
    }

    .nav_bar_first_div svg {
        width: 43%;
    }

    .cancel_icon {
        padding-top: 1%;
    }

    .cancel_icon svg {
        width: 25px;
        height: 25px;
    } */

    /* .nav_bar_text_style{
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin: 9px 0px;
        padding: 15px 0px;
        border-radius: 45px;
    } */
    /* .nav_bar_text_style:hover {
        background-color: #FF4600;
    } */

    /* .nav_bar_second_div{
         margin-top: 10%;
    } */
    /* .nav_bar_second_div ul li {
        padding-left: 20px;
    } */

    /*nav-bar ends*/

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /*nav-bar start*/
    .nav-bar {
        background-color: #1C1CA8;
    }

    .header_div {
        grid-column: 1 / span 12;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
    }

    .nav-bar-logo .logo_link svg {
        width: 8.542vw;
        height: 2.223vw;
    }

    .nav-bar-logo {
        padding-top: 2.015vw;
        padding-bottom: 2.015vw;
    }

    .nav-bar-links {
        width: 59.792vw;
        z-index: 10;
    }

    .mobile_view_side_nav_bar {
        display: none;
    }

    .nav_link_m_l {
        margin-left: 1.695vw;
    }

    .nav-bar-links ul li {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        align-items: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        padding: 0.655vw 1.155vw 0.655vw !important;
    }

    .menu-bar-icon {
        display: none;
    }

    .nav-bar-right-side-login-img svg {
        width: 5.07vw;
        height: 1.667vw;
    }

    .nav-bar-right-side-login-img {
        padding-top: 2.355vw;
        padding-bottom: 2.255vw;
    }

    .content_margin_bottom {
        margin-bottom: 0vw;
        padding-top: 1.695vw;
        padding-bottom: 1.695vw;
    }

    .nav-bar-login-text {
        padding-right: 0.903vw;
    }

    .active_class {
        padding: 0% 0%;
    }

    .active_class.active,
    .active_class:focus,
    .active_class:hover {
        color: #FFBF00 !important;
    }

    .highlight_background {
        background-color: #FF4600;
        color: #FFFFFF !important;
        padding: 0% 0%;
    }

    /*nav-bar ends*/

}

@media only screen and (min-width: 1441px) {

    .navbar-font-xsmall {
        font-size: 14px !important;
    }
    

    /*nav-bar start*/
    .nav-bar {
        background-color: #1C1CA8;
    }

    .header_div {
        grid-column: 1 / span 12;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
    }

    .nav-bar-logo .logo_link svg {
        width: 123.08px;
        height: 32px;
    }

    .nav-bar-logo {
        padding-top: 34px;
        padding-bottom: 29px;
    }

    .nav-bar-links {
        width: 861px;
        z-index: 10;
    }

    .mobile_view_side_nav_bar {
        display: none;
    }

    .nav_link_m_l {
        margin-left: 24px;
    }

    .nav-bar-links ul li {

        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        align-items: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        padding: 10px 16px !important;
    }

    .menu-bar-icon {
        display: none;
    }

    .nav-bar-right-side-login-img svg {
        width: 73px;
        height: 24px;
    }

    .nav-bar-right-side-login-img {
        padding-top: 38px;
        padding-bottom: 33px;
    }

    .content_margin_bottom {
        margin-bottom: 0vw;
        padding-top: 29px;
        padding-bottom: 24px;
        justify-content: space-between;
    }

    .nav-bar-login-text {
        padding-right: 0.903vw;
    }

    .active_class {
        padding: 0% 0%;
    }

    .active_class.active,
    .active_class:focus,
    .active_class:hover {
        color: #FFBF00 !important;
    }

    .highlight_background {
        background-color: #FF4600;
        color: #FFFFFF !important;
        padding: 0% 0%;
    }

    /*nav-bar ends*/

    /*nav-bar start*/

    /* .navbar-font-xsmall {
        font-size: 15.552px !important;
    }

    .nav-bar {
        background-color: #1C1CA8;
        height: 105.5px;
        padding-top:5.552px;
        align-items: center;
    }

    .nav-bar-logo {
        grid-column: 1/ span 2;
        grid-row: 1;
        width: 138.387px;
        padding-top:32.224px;
        padding-bottom:32.224px;
    }

    .nav-bar-links {
        grid-column: 3/ span 8;
        grid-row: 1;
        width: 100%;
        z-index: 10;
    }

    .nav-bar-links div ul li {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15.552px;
        line-height: 160%;
        align-items: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        padding:  37.776px 0px 37.776px 62.224px;

    }

    .menu-bar-icon {
        display: none;
    }

    .nav-bar-right-side-login-img {
        grid-column: 11/ span 2;
        grid-row: 1;
        width: 100%;
        text-align: end;
        z-index: 10;
        
    }
    .content_margin_bottom{
        margin-bottom: 0px;
    }

    .nav-bar-login-text{

        padding-right: 14.448px;

    }
    .mobile_view_side_nav_bar{
        display: none;
    }
    .active_class.active, .active_class:focus, active_class:hover{
        color: #FFBF00 !important;
    } */
    /*nav-bar ends*/

    /*nav-bar start*/
    /* .nav-bar {
        background-color: #1C1CA8;
    }
    .header_div{
        grid-column: 1 / span 12;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
    } */

    /* .nav-bar-logo .logo_link svg{
        width: 8.542vw;
        height: 2.223vw; 
    } */
    /* .nav-bar-logo{
        padding-top: 2.015vw;
        padding-bottom: 2.015vw;
    } */

    /* .nav-bar-links {
        width: 59.792vw;
        z-index: 10;
        padding: 0vw 0.555vw;
    } */
    /* .mobile_view_side_nav_bar{
        display: none;
    } */

    /* .nav-bar-links div {
        margin: auto 100px;
    } */

    /* .nav-bar-links ul li {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        align-items: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        padding: 0.495vw 1.155vw;
        margin-left: 0.995vw;
    }

    .menu-bar-icon {
        display: none;
    } */

    /* .nav-bar-right-side-login-img svg{
        width: 5.07vw;
        height: 1.667vw; */
    /* grid-column: 11/ span 2; */
    /* grid-row: 1; */
    /* width: 100%; */
    /* text-align: end; */
    /* z-index: 10; */
    /*padding-top: 15px;*/
    /* } */
    /* .nav-bar-right-side-login-img{
        padding-top: 2.355vw;
        padding-bottom: 2.255vw;
    }
    .content_margin_bottom{
        margin-bottom: 0vw;
        white-space: nowrap;
        padding-top: 1.855vw;
        padding-bottom: 1.855vw;
    }
    .nav-bar-login-text{
        padding-right: 0.903vw;
    }
    .active_class{
        padding: 0% 0%;
    }
    .active_class.active, .active_class:focus, .active_class:hover{
        color: #FFBF00 !important;
    }
    .highlight_background{
        background-color: #FF4600;
        color: #FFFFFF !important;
        padding: 0% 0%;
    } */
    /* .active_class:hover{
        color: #FFBF00 !important;
    } */
    /*nav-bar ends*/
}