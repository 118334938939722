@media only screen and (max-width: 480px) {

 /* was_this_artical_helpful starts */
 .was_this_artical_helpful {
    background-color: #FFFFFF;
    padding-top: 30px;
    padding-bottom: 50px;
}

.was_this_artical_helpful_center_content {
    grid-column: 1 / span 4;
    grid-row: 1;
    width: 100%;
}

.was_this_artical_helpful_center_content_card {
    background-color: #F7F8FA;
    padding: 7% 17%;
    border-radius: 15px;
}

.was_this_artical_helpful_center_content_first_line {
    font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1C1CA8;
}

.was_this_artical_helpful_center_content_second_line {
    font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #5B5B62;
}

.yes_and_no_btns span {
    margin: 0px 3%;
}

.yes_btn svg {
    width: 43%;
    height: auto;
}

.no_btn svg {
    width: 43%;
    height: auto;
}

/* was_this_artical_helpful ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* was_this_artical_helpful starts */
    .was_this_artical_helpful {
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .was_this_artical_helpful_center_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .was_this_artical_helpful_center_content_card {
        background-color: #F7F8FA;
        padding: 7% 17%;
        border-radius: 15px;
    }

    .was_this_artical_helpful_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .was_this_artical_helpful_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .yes_and_no_btns span {
        margin: 0px 3%;
    }

    .yes_btn svg {
        width: 43%;
        height: auto;
    }

    .no_btn svg {
        width: 43%;
        height: auto;
    }

    /* was_this_artical_helpful ends */

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* was_this_artical_helpful starts */
    .was_this_artical_helpful {
        background-color: #FFFFFF;
        padding-bottom: 30px;
    }

    .was_this_artical_helpful_center_content {
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
    }

    .was_this_artical_helpful_center_content_card {
        background-color: #F7F8FA;
        padding: 7% 17%;
        border-radius: 15px;
    }

    .was_this_artical_helpful_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .was_this_artical_helpful_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .yes_and_no_btns span {
        margin: 0px 3%;
    }

    .yes_btn svg {
        width: 43%;
        height: auto;
    }

    .no_btn svg {
        width: 43%;
        height: auto;
    }

    /* was_this_artical_helpful ends */

}

@media only screen and (min-width: 841px) and (max-width: 1600px) {

     /* was_this_artical_helpful starts */
     .was_this_artical_helpful {
        background-color: #FFFFFF;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .was_this_artical_helpful_center_content {
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .was_this_artical_helpful_center_content_card {
        background-color: #F7F8FA;
        padding: 7% 17%;
        border-radius: 15px;
    }

    .was_this_artical_helpful_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .was_this_artical_helpful_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .yes_and_no_btns span {
        margin: 0px 3%;
    }

    .yes_btn svg {
        width: 43%;
        height: auto;
    }

    .no_btn svg {
        width: 43%;
        height: auto;
    }

    /* was_this_artical_helpful ends */

}
@media only screen and (min-width: 1601px) {

    /* was_this_artical_helpful starts */
    .was_this_artical_helpful {
        background-color: #FFFFFF;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .was_this_artical_helpful_center_content {
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
    }

    .was_this_artical_helpful_center_content_card {
        background-color: #F7F8FA;
        padding: 7% 17%;
        border-radius: 15px;
    }

    .was_this_artical_helpful_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .was_this_artical_helpful_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .yes_and_no_btns span {
        margin: 0px 3%;
    }

    .yes_btn svg {
        width: 43%;
        height: auto;
    }

    .no_btn svg {
        width: 43%;
        height: auto;
    }

    /* was_this_artical_helpful ends */

}