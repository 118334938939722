.contact_page_up_arrow_img_mobile {
    display: none;
}

.contact_page_arrow_img_mobile {
    display: none;
}

@media only screen and (max-width: 480px) {
    .contact_page_body {
        min-height: 100vh;
        background-color: #1c1ca8;
    }

    .contact_page {
        background-color: #1C1CA8;
        padding-top: 16.388vw;
        padding-bottom: 16.111vw;
        position: relative;
    }

    .contact_page_heading {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .contact_page_first_line_break {
        display: block;

    }

    .contact_page .contact_page_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 11.113vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0;
        transform: translateY(0.833vw);
    }

    .contact_page_up_arrow_img {
        grid-column: 2 / span 3;
        grid-row: 1;
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 0vw;
        right: 0vw;
        display: none;
    }

    .contact_page_up_arrow_img_mobile {
        grid-column: 2 / span 3;
        grid-row: 1;
        z-index: 2;
        width: 68.0556vw;
        position: absolute;
        bottom: 0vw;
        right: -1.6667vw;
        display: block;
    }


    .contact_page_up_arrow_img img {
        width: 100%;
    }

    .contact_page_up_arrow_img_mobile img {
        width: 100%;
    }

    .contact_page_arrow_img {
        grid-column: 1/span 1;
        position: absolute;
        width: 100%;
        bottom: -10.911vw;
        left: 1.345vw;
        z-index: 8;
        /* position: absolute;
        bottom: -19%;
        left: 8%;
        z-index: 8; */
        display: none;
    }

    .contact_page_arrow_img_mobile {
        display: block;
        grid-column: 1/span 1;
        position: absolute;
        width: 100%;
        bottom: -20vw;
        left: -4.444vw;
        z-index: 8;
        /* position: absolute;
        bottom: -19%;
        left: 8%;
        z-index: 8; */
    }

    .contact_page_arrow_img img {
        width: 19.76vw;
        height: 19.76vw;
    }

    .contact_page_arrow_img_mobile img {
        width: 27.222vw;
        height: 28.888vw;
    }

    .contact_content {
        font-family: 'Circular Std Bk', 'Azo Sans Bk', sans-serif;
        padding: 16.666vw 6.666vw 17.777vw 6.666vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .contact_content_left {
        width: 100%;
        margin-bottom: 15.555vw;
    }

    .contact_content_label {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.666vw;
    }

    .contact_content_title {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-weight: 900;
        font-size: 9.166vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 8.888vw;
        transform: translateY(-0.277vw);
    }

    .contact_content_visit_btn {
        padding: 3.611vw 5.555vw !important;
        width: 59.166vw !important;
        height: 13.888vw !important;
        font-weight: 700 !important;
        font-size: 4.444vw !important;
        line-height: 125% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .contact_content_call_btn {
        background-color: #055CF5;
        border-radius: 9999px;
        padding: 2.555vw 5.555vw;
        outline: none;
        border: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 2.222vw;
        width: 86.111vw;
        height: 12.222vw;
        margin-right: 0.555vw;
    }

    .contact_content_call_btn_icon {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .contact_content_call_btn_phone_number {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        flex-grow: 1;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 162%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        text-align: left;
    }

    .contact_content_call_btn_description {
        font-weight: 500;
        font-size: 3.333vw;
        line-height: 150%;
        text-align: right;
        color: #FFFFFF;
    }

    .contact_content_call_or {
        margin-top: 1.388vw;
        margin-bottom: 1.388vw;
        width: calc(100% - 8.055vw);
        text-align: center;
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 138%;
        text-align: center;
        color: #66696E;
        position: relative;
    }

    .contact_content_call_or>div {
        transform: translateX(3.333vw)
    }

    .contact_content_call_or::before {
        content: '';
        width: 30vw;
        border-top: 0.277vw solid #EDEDF0;
        position: absolute;
        right: calc(50% + 7.466vw);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_call_or::after {
        content: '';
        width: 30vw;
        border-top: 1px solid #EDEDF0;
        position: absolute;
        left: calc(50% + 7.125vw);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_operating_hours {
        margin-top: 5.555vw;
    }

    .contact_content_operating_hours_title {
        font-weight: 500;
        font-size: 3.888vw;
        line-height: 143%;
        letter-spacing: -0.01em;
        color: #262629;
        margin-bottom: 1.111vw;
    }

    .contact_content_operating_hours_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .contact_content_operating_hours_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contact_content_operating_hours_item span {
        font-weight: 500;
        font-size: 3.888vw;
        line-height: 143%;
        letter-spacing: -0.01em;
        color: #66696E;
    }

    .contact_content_operating_hours_item span:nth-child(2) {
        width: 25.833vw;
        margin-right: 14.444vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .contact_page_body {
        min-height: 100vh;
        background-color: #1c1ca8;
    }

    .contact_page {
        background-color: #1C1CA8;
        /* padding-top: 14%;
        padding-bottom: 100px; */
        padding-top: 5.277vw;
        padding-bottom: 5.277vw;
        position: relative;
    }

    .contact_page_heading {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .contact_page_first_line_break {
        display: none;
    }

    .contact_page .contact_page_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 4.23vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0;
        transform: translateY(0.416vw);
    }

    .contact_page_up_arrow_img {
        grid-column: 4 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 35.3472vw;
        position: absolute;
        bottom: 0vw;
        left: 0vw;
        margin-left: 5.8333vw;
    }

    .contact_page_up_arrow_img img {
        width: 100%;
    }

    .contact_page_arrow_img {
        grid-column: 2/span 2;
        width: 100%;
        position: absolute;
        left: 2.638vw;
        bottom: -5.1389vw;
        z-index: 8;
    }

    .contact_page_arrow_img img {
        width: 10.0694vw;

    }

    .contact_content {
        font-family: 'Circular Std Bk', 'Azo Sans Bk', sans-serif;
        padding: 11.111vw 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .contact_content_left {
        width: 18.055vw;
        margin-right: 20.138vw;
    }

    .contact_content_label {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.666vw;
    }

    .contact_content_title {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.666vw;
        transform: translateY(0.347vw);
    }

    .contact_content_visit_btn {
        padding: 0.902vw 1.388vw !important;
        width: 100% !important;
        height: 3.472vw !important;
        font-weight: 700 !important;
        font-size: 1.111vw !important;
        line-height: 125% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .contact_content_call_btn {
        background-color: #055CF5;
        border-radius: 9999px;
        padding: 0.972vw 1.666vw 1.111vw 2.222vw;
        outline: none;
        border: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        width: 23.194vw;
        height: 4.166vw;
        margin-right: 0.416vw;
    }

    .contact_content_call_btn_icon {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .contact_content_call_btn_phone_number {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        flex-grow: 1;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 122%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        text-align: left;
    }

    .contact_content_call_btn_description {
        font-weight: 500;
        font-size: 0.833vw;
        line-height: 150%;
        text-align: right;
        color: #FFFFFF;
    }

    .contact_content_call_or {
        margin-top: 0.347vw;
        margin-bottom: 0.347vw;
        width: calc(100% - 0.972vw);
        text-align: center;
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #66696ecc;
        position: relative;
    }

    .contact_content_call_or>div {
        transform: translateX(-0.277vw)
    }

    .contact_content_call_or::before {
        content: '';
        width: 9.305vw;
        border-top: 1px solid #EDEDF0;
        position: absolute;
        right: calc(50% + 1.866vw);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_call_or::after {
        content: '';
        width: 9.305vw;
        border-top: 1px solid #EDEDF0;
        position: absolute;
        left: calc(50% + 1.781vw);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_operating_hours {
        margin-top: 2.222vw;
    }

    .contact_content_operating_hours_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        opacity: 0.8;
        margin-bottom: 0.277vw;
    }

    .contact_content_operating_hours_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.277vw;
    }

    .contact_content_operating_hours_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contact_content_operating_hours_item span {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #66696E;
        opacity: 0.8;
    }

    .contact_content_operating_hours_item span:nth-child(2) {
        width: 9.236vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .contact_page_body {
        min-height: 100vh;
        background-color: #1c1ca8;
    }

    .contact_page {
        background-color: #1C1CA8;
        /* padding-top: 14%;
        padding-bottom: 100px; */
        padding-top: 5.277vw;
        padding-bottom: 5.277vw;
        position: relative;
    }

    .contact_page_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .contact_page_first_line_break {
        display: none;
    }

    .contact_page .contact_page_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 4.23vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0;
        transform: translateY(0.416vw);
    }

    .contact_page_up_arrow_img {
        grid-column: 7 / span 5;
        grid-row: 1;
        z-index: 2;
        width: 35.3472vw;
        position: absolute;
        bottom: 0vw;
        left: 0vw;
        margin-left: 5.8333vw;
    }

    .contact_page_up_arrow_img img {
        width: 100%;
    }

    .contact_page_arrow_img {
        grid-column: 2/span 2;
        width: 100%;
        position: absolute;
        left: 2.638vw;
        bottom: -5.1389vw;
        z-index: 8;
    }

    .contact_page_arrow_img img {
        width: 10.0694vw;

    }

    .contact_content {
        font-family: 'Circular Std Bk', 'Azo Sans Bk', sans-serif;
        padding: 11.111vw 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .contact_content_left {
        width: 18.055vw;
        margin-right: 20.138vw;
    }

    .contact_content_label {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.666vw;
    }

    .contact_content_title {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.666vw;
        transform: translateY(0.347vw);
    }

    .contact_content_visit_btn {
        padding: 0.902vw 1.388vw !important;
        width: 100% !important;
        height: 3.472vw !important;
        font-weight: 700 !important;
        font-size: 1.111vw !important;
        line-height: 125% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .contact_content_call_btn {
        background-color: #055CF5;
        border-radius: 9999px;
        padding: 0.972vw 1.666vw 1.111vw 2.222vw;
        outline: none;
        border: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        width: 23.194vw;
        height: 4.166vw;
        margin-right: 0.416vw;
    }

    .contact_content_call_btn_icon {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .contact_content_call_btn_phone_number {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        flex-grow: 1;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 122%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        text-align: left;
    }

    .contact_content_call_btn_description {
        font-weight: 500;
        font-size: 0.833vw;
        line-height: 150%;
        text-align: right;
        color: #FFFFFF;
    }

    .contact_content_call_or {
        margin-top: 0.347vw;
        margin-bottom: 0.347vw;
        width: calc(100% - 0.972vw);
        text-align: center;
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #66696ecc;
        position: relative;
    }

    .contact_content_call_or>div {
        transform: translateX(-0.277vw)
    }

    .contact_content_call_or::before {
        content: '';
        width: 9.305vw;
        border-top: 1px solid #EDEDF0;
        position: absolute;
        right: calc(50% + 1.866vw);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_call_or::after {
        content: '';
        width: 9.305vw;
        border-top: 1px solid #EDEDF0;
        position: absolute;
        left: calc(50% + 1.781vw);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_operating_hours {
        margin-top: 2.222vw;
    }

    .contact_content_operating_hours_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        opacity: 0.8;
        margin-bottom: 0.277vw;
    }

    .contact_content_operating_hours_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.277vw;
    }

    .contact_content_operating_hours_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contact_content_operating_hours_item span {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #66696E;
        opacity: 0.8;
    }

    .contact_content_operating_hours_item span:nth-child(2) {
        width: 9.236vw;
    }
}

@media only screen and (min-width: 1441px) {
    .contact_page_body {
        min-height: 100vh;
        background-color: #1c1ca8;
    }

    .contact_page {
        background-color: #1C1CA8;
        padding-top: 76px;
        padding-bottom: 76px;
        position: relative;
    }

    .contact_page_heading {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 4;
    }

    .contact_page_first_line_break {
        display: none;
    }

    .contact_page .contact_page_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0;
        transform: translateY(6px);
    }

    .contact_page_up_arrow_img {
        grid-column: 7 / span 5;
        grid-row: 1;
        z-index: 2;
        position: absolute;
        bottom: 0%;
        left: 0%;
        margin-left: 84px;
    }

    .contact_page_arrow_img {
        grid-column: 2/span 2;
        position: absolute;
        bottom: -74px;
        left: 38px;
        z-index: 8;
        /* position: absolute;
       bottom: -19%;
       left: 8%;
       z-index: 8; */
    }

    .contact_page_arrow_img img {
        width: 100%;
    }

    .contact_content {
        font-family: 'Circular Std Bk', 'Azo Sans Bk', sans-serif;
        padding: 160px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .contact_content_left {
        width: 260px;
        margin-right: 290px;
    }

    .contact_content_label {
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .contact_content_title {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-weight: 900;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
        transform: translateY(5px);
    }

    .contact_content_visit_btn {
        padding: 13px 20px !important;
        width: 100% !important;
        height: 50px !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 125% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .contact_content_call_btn {
        background-color: #055CF5;
        border-radius: 9999px;
        padding: 14px 24px 16px 32px;
        outline: none;
        border: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        width: 334px;
        height: 60px;
        margin-right: 6px;
    }

    .contact_content_call_btn_icon {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .contact_content_call_btn_phone_number {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        flex-grow: 1;
        font-weight: 700;
        font-size: 18px;
        line-height: 122%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        text-align: left;
    }

    .contact_content_call_btn_description {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        color: #FFFFFF;
    }

    .contact_content_call_or {
        margin-top: 5px;
        margin-bottom: 5px;
        width: calc(100% - 14px);
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #66696ecc;
        position: relative;
    }

    .contact_content_call_or>div {
        transform: translateX(-4px)
    }

    .contact_content_call_or::before {
        content: '';
        width: 134px;
        border-top: 1px solid #EDEDF0;
        position: absolute;
        right: calc(50% + 26.88px);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_call_or::after {
        content: '';
        width: 134px;
        border-top: 1px solid #EDEDF0;
        position: absolute;
        left: calc(50% + 25.65px);
        top: 50%;
        transform: translateY(-50%);
    }

    .contact_content_operating_hours {
        margin-top: 32px;
    }

    .contact_content_operating_hours_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        opacity: 0.8;
        margin-bottom: 4px;
    }

    .contact_content_operating_hours_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .contact_content_operating_hours_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contact_content_operating_hours_item span {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #66696E;
        opacity: 0.8;
    }

    .contact_content_operating_hours_item span:nth-child(2) {
        width: 133px;
    }
}