@media only screen and (max-width: 480px) {

      /* products_and_services starts */
      .products_and_services {
        background-color: #FFFFFF;
    }

    .products_and_services_content1 {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
        padding-top: 15.5556vw;
    }

    .products_and_services_content2 {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
        text-align: center;
    }

    .products_and_services_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 6.6667vw;
    }

    .products_and_services_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 6.6667vw;
    }

    .products_and_services_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.6667vw;
    }

    .products_and_services_content2_mobile_view_pic {
        display: none;
    }

    .products_and_services_icons_div {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        text-align: center;
        display: none;
    }

    .products_and_services_icons_mobile_view_div {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        text-align: center;
    }

    .products_and_services_icons_div_first_row {
        display: flex;
        text-align: center;
    }

    .products_and_services_icons_div_second_row {
        display: flex;
        text-align: center;
        margin: 35px 0px;
    }

    .products_and_services_icons_mobile_view_div {
        grid-column: 1 / span 4;
        grid-row: 2;
        width: 100%;
        text-align: center;
    }

    .products_and_services_icons_mobile_view_div_first_row {
        display: flex;
        text-align: center;
        margin-right: 8.0556vw;
        margin-left: 8.0556vw;
        margin-bottom: 8.8889vw;
    }

    .products_and_services_icons_mobile_view_div_second_row {
        display: flex;
        text-align: center;
        margin-right: 8.0556vw;
        margin-left: 8.0556vw;
        margin-bottom: 8.8889vw;
    }

    .products_and_services_icons_mobile_view_div_third_row {
        display: flex;
        text-align: center;
        margin-right: 8.0556vw;
        margin-left: 8.0556vw;
        margin-bottom: 8.8889vw;
    }

    .products_and_services_icons_mobile_view_div_fourth_row {
        display: flex;
        text-align: center;
        margin-right: 8.0556vw;
        margin-left: 8.0556vw;
        margin-bottom: 16.9444vw;
    }

    .icon_one {
        margin-right: 13.3333vw;
        width: 30.8333vw;
    }

    .icon_one img {
        width: 100%;
    }

    .icon_two {
        width: 30.8333vw;
    }

    .icon_two img {
        width: 100%;
    }

    .icon_three {
        margin-right: 13.3333vw;
        width: 30.8333vw;
    }

    .icon_three img {
        width: 100%;
    }

    .icon_four {
        width: 30.8333vw;
    }

    .icon_four img {
        width: 100%;
    }

    .icon_five {
        margin-right: 13.3333vw;
        width: 30.8333vw;
    }

    .icon_five img {
        width: 100%;
    }

    .icon_six {
        width: 30.8333vw;
    }

    .icon_six img {
        width: 100%;
    }

    .icon_seven {
        margin-right: 13.3333vw;
        width: 30.8333vw;
    }

    .icon_seven img {
        width: 100%;
    }

    .icon_eight {
        width: 30.8333vw;
    }

    .icon_eight img {
        width: 100%;
    }

    /* products_and_services ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* products_and_services starts */
   /*  .products_and_services {
        background-color: #FFFFFF;
        padding-bottom: 50px;
    }

    .products_and_services_content1 {
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 5%;
    }

    .products_and_services_content2 {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
        text-align: center;
        padding-bottom: 50px;
    }

    .products_and_services_icons_mobile_view_div {
        display: none;
    }

    .products_and_services_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .products_and_services_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        font-size: 35px !important;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
    }

    .products_and_services_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .products_and_services_content2_mobile_view_pic {
        display: none;
    }

    .products_and_services_icons_div {
        grid-column: 2 / span 6;
        grid-row: 2;
        width: 100%;
        text-align: center;
    }

    .products_and_services_icons_div_first_row {
        display: flex;
        text-align: center;
    }

    .products_and_services_icons_div_second_row {
        display: flex;
        text-align: center;
        margin: 35px 0px;
    }

    .icon_one {
        margin: 0px auto;
        width: 20%;
    }

    .icon_one img {
        width: 80%;
    }

    .icon_two {
        margin: 0px auto;
        width: 20%;
    }

    .icon_two img {
        width: 80%;
    }

    .icon_three {
        margin: 0px auto;
        width: 20%;
    }

    .icon_three img {
        width: 80%;
    }

    .icon_four {
        margin: 0px auto;
        width: 20%;
    }

    .icon_four img {
        width: 80%;
    }

    .icon_five {
        margin: 0px auto;
        width: 20%;
    }

    .icon_five img {
        width: 80%;
    }

    .icon_six {
        margin: 0px auto;
        width: 20%;
    }

    .icon_six img {
        width: 80%;
    }

    .icon_seven {
        margin: 0px auto;
        width: 20%;
    }

    .icon_seven img {
        width: 80%;
    }

    .icon_eight {
        margin: 0px auto;
        width: 20%;
    }

    .icon_eight img {
        width: 80%;
    }

    /* products_and_services ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

     /* products_and_services starts */
     .products_and_services {
        background-color: #FFFFFF;
        padding-bottom: 6.5104vw;
    }

    .products_and_services_content1 {
        grid-column: 2 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        padding-top: 14.0625vw;
    }

    .products_and_services_content2 {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
        text-align: center;
        padding-bottom: 6.5104vw;
    }

    .products_and_services_icons_mobile_view_div {
        display: none;
    }

    .products_and_services_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 3.125vw;
    }

    .products_and_services_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 3.125vw;
    }

    .products_and_services_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 3.125vw;
    }

    .products_and_services_content2_mobile_view_pic {
        display: none;
    }

    .products_and_services_icons_div {
        grid-column: 2 / span 6;
        grid-row: 2;
        width: 100%;
        text-align: center;
    }

    .products_and_services_icons_div_first_row {
        display: flex;
        text-align: center;
    }

    .products_and_services_icons_div_second_row {
        display: flex;
        text-align: center;
        margin: 4.5573vw 0px;
    }

    .icon_one {
        margin: 0px auto;
        width: 20%;
    }

    .icon_one img {
        width: 80%;
    }

    .icon_two {
        margin: 0px auto;
        width: 20%;
    }

    .icon_two img {
        width: 80%;
    }

    .icon_three {
        margin: 0px auto;
        width: 20%;
    }

    .icon_three img {
        width: 80%;
    }

    .icon_four {
        margin: 0px auto;
        width: 20%;
    }

    .icon_four img {
        width: 80%;
    }

    .icon_five {
        margin: 0px auto;
        width: 20%;
    }

    .icon_five img {
        width: 80%;
    }

    .icon_six {
        margin: 0px auto;
        width: 20%;
    }

    .icon_six img {
        width: 80%;
    }

    .icon_seven {
        margin: 0px auto;
        width: 20%;
    }

    .icon_seven img {
        width: 80%;
    }

    .icon_eight {
        margin: 0px auto;
        width: 20%;
    }

    .icon_eight img {
        width: 80%;
    }

    /* products_and_services ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

     /* products_and_services starts */
     .products_and_services {
        background-color: #FFFFFF;
    }

    .products_and_services_content1 {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-top: 13.3333vw;
        
    }

    .products_and_services_content2 {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
        text-align: center;
        padding-bottom: 3.4722vw;
    }

    .products_and_services_icons_mobile_view_div {
        display: none;
    }

    .products_and_services_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 1.6667vw;
    }

    .products_and_services_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.6667vw;
    }

    .products_and_services_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.3889vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 5vw;
    }

    .products_and_services_content2_mobile_view_pic {
        display: none;
    }

    .products_and_services_icons_div {
        grid-column: 4 / span 6;
        grid-row: 2;
        width: 100%;
        text-align: center;
    }

    .products_and_services_icons_div_first_row {
        display: flex;
        text-align: center;
        justify-content: center;
        
    }

    .products_and_services_icons_div_second_row {
        display: flex;
        text-align: center;
        margin-top: 46px;
        margin-bottom: 329px;
        justify-content: center;
    }

    .icon_one {
        margin-right:3.3333vw;
    }

    .icon_one img {
        width: 100%;
    }

    .icon_two {
        margin-right:3.3333vw;
    }

    .icon_two img {
        width: 100%;
    }

    .icon_three {
        margin-right:3.3333vw;
    }

    .icon_three img {
        width: 100%;
    }

    .icon_four {
        
    }

    .icon_four img {
        width: 100%;
    }

    .icon_five {
        margin-right:3.3333vw;
    }

    .icon_five img {
        width: 100%;
    }

    .icon_six {
        margin-right:3.3333vw;
    }

    .icon_six img {
        width: 100%;
    }

    .icon_seven {
        margin-right:3.3333vw;
    }

    .icon_seven img {
        width: 100%;
    }

    .icon_eight {
        
    }

    .icon_eight img {
        width: 100%;
    }

    /* products_and_services ends */

}
@media only screen and (min-width: 1441px) {

      /* products_and_services starts */
      .products_and_services {
        background-color: #FFFFFF;
    }

    .products_and_services_content1 {
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        text-align: center;
        margin-top: 191px;
        
    }

    .products_and_services_content2 {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
        text-align: center;
        padding-bottom: 46px;
    }

    .products_and_services_icons_mobile_view_div {
        display: none;
    }

    .products_and_services_content1_first_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 24px;
    }

    .products_and_services_content1_second_line {
        font-family: 'Azo Sans Bk';
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .products_and_services_content1_third_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 72px;
    }

    .products_and_services_content2_mobile_view_pic {
        display: none;
    }

    .products_and_services_icons_div {
        grid-column: 4 / span 6;
        grid-row: 2;
        width: 100%;
        text-align: center;
    }

    .products_and_services_icons_div_first_row {
        display: flex;
        text-align: center;
        justify-content: center;
        
    }

    .products_and_services_icons_div_second_row {
        display: flex;
        text-align: center;
        margin-top: 46px;
        margin-bottom: 329px;
        justify-content: center;
    }

    .icon_one {
        margin-right:48px;
    }

    .icon_one img {
        width: 100%;
    }

    .icon_two {
        margin-right:48px;
    }

    .icon_two img {
        width: 100%;
    }

    .icon_three {
        margin-right:48px;
    }

    .icon_three img {
        width: 100%;
    }

    .icon_four {
        
    }

    .icon_four img {
        width: 100%;
    }

    .icon_five {
        margin-right:48px;
    }

    .icon_five img {
        width: 100%;
    }

    .icon_six {
        margin-right:48px;
    }

    .icon_six img {
        width: 100%;
    }

    .icon_seven {
        margin-right:48px;
    }

    .icon_seven img {
        width: 100%;
    }

    .icon_eight {
        
    }

    .icon_eight img {
        width: 100%;
    }

    /* products_and_services ends */

}