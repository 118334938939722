@media only screen and (max-width: 480px) {

     /* verify_an_agent_header starts */
     .verify_an_agent_header {
        background-color: #1C1CA8;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .verify_an_agent_header_center_content {
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .verify_an_agent_header_center_content_input_group {
        background-color: #ffffff !important;
        border-radius: 4.25rem !important;
        width: 100%;
        margin: 0px auto;
        border: none;
        margin-bottom: 30px;
        margin-top: 15px;
    }

    .verify_an_agent_header_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .verify_an_agent_header_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* verify_an_agent_header ends */

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

     /* verify_an_agent_header starts */
     /* .verify_an_agent_header {
        background-color: #1C1CA8;
        padding-top: 30px;
        padding-bottom: 10px;
    }

    .verify_an_agent_header_center_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .verify_an_agent_header_center_content_input_group {
        background-color: #ffffff !important;
        border-radius: 4.25rem !important;
        width: 75%;
        margin: 0px auto;
        border: none;
        margin-bottom: 30px;
        margin-top: 15px;
    }

    .verify_an_agent_header_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .verify_an_agent_header_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    } */

    /* verify_an_agent_header ends */

}

@media only screen and (min-width: 481px) and (max-width: 840px) {

    /* verify_an_agent_header starts */
    .verify_an_agent_header {
        background-color: #1C1CA8;
        padding-top: 3.9062vw;
        padding-bottom: 3.9062vw;
    }

    .verify_an_agent_header_center_content {
        grid-column: 1 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
    }

    .verify_an_agent_header_center_content_input_group {
        background-color: #ffffff !important;
        border-radius: 8.8541vw !important;
        width: 75%;
        margin: 0px auto;
        border: none;
        margin-bottom: 3.9062vw;
        margin-top: 1.9531vw;
    }

    .verify_an_agent_header_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 7.9427vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .verify_an_agent_header_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 3.2552vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-top:3.125vw;
        margin-bottom:3.125vw;
    }

    /* verify_an_agent_header ends */

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* verify_an_agent_header starts */
    .verify_an_agent_header {
        background-color: #1C1CA8;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .verify_an_agent_header_center_content {
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: center;
        padding: 0px 80px;
    }

    .verify_an_agent_header_center_content_input_group {
        background-color: #ffffff !important;
        border-radius: 4.25rem !important;
        width: 50%;
        margin: 0px auto;
        border: none;
        margin-bottom: 30px;
        margin-top: 15px;
    }

    .verify_an_agent_header_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .verify_an_agent_header_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    /* verify_an_agent_header ends */

}
@media only screen and (min-width: 1441px) {

    /* verify_an_agent_header starts */
    .verify_an_agent_header {
        background-color: #1C1CA8;
        padding-top: 83px;
        padding-bottom: 150px;
    }

    .verify_an_agent_header_center_content {
        grid-column: 3 / span 8;
        grid-row: 1;
        width: 100%;
        text-align: -webkit-center;
    }

    .verify_an_agent_header_center_content_input_group {
        background-color: #ffffff !important;
        border-radius: 4.25rem !important;
        width: 502px;
        height: 74px;
        margin: 0px auto;
        border: none;
    }

    .verify_an_agent_header_center_content_first_line {
        font-family: 'Azo Sans Bk', 'Circular Std Bk', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 61px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .verify_an_agent_header_center_content_second_line {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        width: 698px;
        justify-self: center;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 40px;
    }

    /* verify_an_agent_header ends */

}